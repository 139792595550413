import { TYPE_ERROR } from 'components/Toast';
import HTTP_STATUS from 'constants/httpStatus';
import loaderActions from 'store/loader';
import { openToast } from 'store/toast';

export const showDefaultError = (dispatch, t) => {
    dispatch(openToast(TYPE_ERROR, t('toast:something_went_wrong')));
    dispatch(loaderActions.setShowLoader(false));
};

export const convertToClientTranslation = (backendMessage, exceptionPath, t) => {
    let translation = backendMessage;
    switch (exceptionPath) {
        case '/api/v1/onboarding/signups/me/account':
            translation = t('backend_errors:personal_info.already_signed_up');
            break;
        case 'api/v1/authorization/users/create':
            translation = t('backend_errors:personal_info.already_have_email');
            break;
        case '/api/v1/authorization/users/change-password':
            translation = t('backend_errors:personal_info.oldpassword_mismatch');
            break;
        default:
            translation = backendMessage;
            break;
    }
    return translation;
};

export const showApiError = (errorResponse, dispatch, t) => {
    if (errorResponse && errorResponse.data) {
        const status = errorResponse.status;

        // Check for 50x errors
        if (status >= HTTP_STATUS.SERVER_ERRORS.MIN && status <= HTTP_STATUS.SERVER_ERRORS.MAX) {
            dispatch(openToast(TYPE_ERROR, t('toast:something_went_wrong')));
        }

        // NEW ERROR HANDLING WITH 'code'
        else if (errorResponse.data.code) {
            const output =
                '<div>' +
                t('errorAPI:' + errorResponse.data.code) +
                '</div><div class="text-xs">ID: ' +
                errorResponse.data.id +
                ' | Timestamp: ' +
                errorResponse.data.timestamp +
                ' | Path: ' +
                errorResponse.data.path +
                '</div>';

            dispatch(openToast(TYPE_ERROR, output));
        } else if (errorResponse.data.error_description) {
            dispatch(openToast(TYPE_ERROR, errorResponse.data.error_description));
        } else if (errorResponse.data.message) {
            const message = convertToClientTranslation(errorResponse.data.message, errorResponse.data.path, t);
            dispatch(openToast(TYPE_ERROR, message));
        } else if (errorResponse.data.error) {
            dispatch(openToast(TYPE_ERROR, errorResponse.data.error));
        }
    } else {
        if (!navigator.onLine) {
            dispatch(openToast(TYPE_ERROR, t('toast:offline')));
        } else {
            showDefaultError(dispatch, t);
        }
    }

    dispatch(loaderActions.setShowLoader(false));
};
