import { SET_CURRENT_CHECKOUT_STEP, SET_CURRENT_PRODUCT_CONFIGURATION_STEP, SET_NAV_PREVIOUS_ROUTE } from '.';
import { routePaths } from "../../route_paths";
import { INavigation } from '../../typeScript/interfaces/INavigation';

export const initialState: INavigation = {
    previousNavRoute: routePaths.portalOverview,
    currentProductConfigurationStep: 0,
    currentCheckoutStep: 0,
};

export function navReducer(state: INavigation = initialState, action): INavigation {
    switch (action.type) {
        case SET_NAV_PREVIOUS_ROUTE:
            return {
                ...state,
                previousNavRoute: action.value
            };
        case SET_CURRENT_PRODUCT_CONFIGURATION_STEP:
            return {
                ...state,
                currentProductConfigurationStep: action.value
            };
        case SET_CURRENT_CHECKOUT_STEP:
            return {
                ...state,
                currentCheckoutStep: action.value
            };
        default:
            // We need the default to ALWAYS return the initial state.
            return state
    }
}