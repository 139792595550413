import { TYPE_SUCCESS } from 'components/Toast';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import loaderActions from 'store/loader';
import paymentActions from 'store/payment';
import { openToast } from 'store/toast';
import api from '../api/api';
import { Button, Checkbox } from '../components';
import loadPaymentStatus from '../mixins/loadPaymentStatus';
import { routePaths } from '../route_paths';
import { IRootState } from '../store';
import { ButtonType } from '../typeScript/types/ButtonTypes';
import { createAdyenCheckout } from '../utils/adyen';
import { showApiError } from '../utils/error-handler';
import eventBus from '../utils/eventBus';

interface IAdyenCheckoutObject {
    props: {
        onSubmit: (state: any, component: any) => void;
    };
    submit: () => void;
}

interface AdyenPaymentProps {
    capturePaymentDataOnly: boolean;
    theme?: 'dark' | 'light';
    // Just a helper ... kill after BAN-4092
    hideAdyenForm?: () => void;
}

// TODO::: Write a test ---> AdyenPayment.spec.ts

export default function AdyenPayment(props: AdyenPaymentProps) {
    const { capturePaymentDataOnly, theme = 'light', hideAdyenForm } = props;
    const paymentReference = useRef(null);
    const { t, i18n } = useTranslation(['checkout', 'common']);
    const [buttonIsLoading, setButtonIsLoading] = useState(true);
    const dispatch = useDispatch();
    const [adyenCheckoutObject, setAdyenCheckoutObject] = useState<IAdyenCheckoutObject>();
    const [adyenLoaded, setAdyenLoaded] = useState(false);
    const [isConfirmationCheckboxChecked, setIsConfirmationCheckboxChecked] = useState(false);
    const downPaymentTotalAmount = useSelector<IRootState, number>((state) => state.cart.downPaymentTotalAmount!);
    const autoUserLoadTriggered = useSelector<IRootState>((state) => state.user.autoUserLoadTriggered);
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);

    eventBus.on('adyenIsReady', () => {
        setAdyenLoaded(true);
    });
    eventBus.on('paymentMethodSelected', () => {
        setButtonIsLoading(false);
    });

    useEffect(() => {
        loadPaymentStatus(dispatch);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(loaderActions.setLoaderMessage(t('loader:loading_data')));
        if (autoUserLoadTriggered) {
            if (isLogged) {
                initializeAdyenForPayments();
            }
        }
        // eslint-disable-next-line
    }, [autoUserLoadTriggered, dispatch, isLogged, t]);

    useEffect(() => {
        if (isLogged) {
            if (adyenCheckoutObject && adyenLoaded) {
                dispatch(loaderActions.setShowLoader(false));
            }
        }
        // eslint-disable-next-line
    }, [adyenLoaded, isLogged, adyenCheckoutObject]);

    const onPaymentSubmit = (state, component) => {
        const originSubPath = capturePaymentDataOnly ? routePaths.portalPayment : '/checkout/finish';

        // we submit to the backend at the confirmation step,
        // but Adyen will happily handle front end validation for us at the payment step
        const paymentRequest = {
            paymentMethod: state.data.paymentMethod,
            amount: {
                currency: 'EUR',
                value: downPaymentTotalAmount * 100
            },
            reference: new Date().getTime().toString(),
            returnUrl: process.env.REACT_APP_API_URL + '/onboarding/payments/redirect',
            origin: window.location.origin + originSubPath,
            paymentConfirmation: isConfirmationCheckboxChecked,
            capturePaymentDataOnly: capturePaymentDataOnly
        };

        if (state.data.paymentMethod.type === 'scheme') {
            paymentRequest['billingAddress'] = state.data.billingAddress;
            paymentRequest['browserInfo'] = state.data.browserInfo;
        }

        setButtonIsLoading(true);
        dispatch(loaderActions.setShowLoader(true));
        dispatch(loaderActions.setLoaderMessage(t('loader:processing_payment')));

        setTimeout(() => {
            dispatch(loaderActions.setLoaderMessage(t('loader:still_working')));
        }, 12000);

        api.payment
            .doPayment(paymentRequest)
            .then((response) => {
                if (response.data.action) {
                    // in the case of giropay:
                    component.handleAction(response.data.action);
                } else {
                    // in the case of debit card (visa):
                    handleFinalPaymentState();
                }
                setButtonIsLoading(false);
            })
            .catch((error) => {
                dispatch(loaderActions.setShowLoader(false));
                setButtonIsLoading(false);
                showApiError(error.response, dispatch, t);
            });
    };

    const handleFinalPaymentState = () => {
        hideAdyenForm && hideAdyenForm();
        loadPaymentStatus(dispatch)
            .then(() => dispatch(paymentActions.hasRecurringPaymentDetails(true)))
            .then(() => dispatch(loaderActions.setShowLoader(false)))
            .then(() => dispatch(openToast(TYPE_SUCCESS, t('toast:added_payment_method_successful'))));
    };

    const onAdditionalPaymentDetails = (state, component) => {
        api.payment
            .submitAdditionalDetails(state.data)
            .then((response) => {
                if (response.data.action) {
                    component.handleAction(response.data.action);
                } else {
                    handleFinalPaymentState();
                }
            })
            .catch((error) => {
                showApiError(error.response, dispatch, t);
            });
    };

    const initializeAdyenForPayments = () => {
        if (!adyenCheckoutObject && !adyenLoaded) {
            const data = {
                countryCode: 'DE',
                amount: {
                    value: downPaymentTotalAmount * 100,
                    currency: 'EUR'
                },
                capturePaymentDataOnly: capturePaymentDataOnly,
                language: i18n.language
            };
            const onSuccessCallback = (res) => {
                setAdyenCheckoutObject(res);
            };

            createAdyenCheckout(
                data,
                paymentReference,
                (state, component) => onPaymentSubmit(state, component),
                (state, component) => onAdditionalPaymentDetails(state, component),
                i18n.language,
                onSuccessCallback,
                true
            );
        }
    };

    /******  PAYMENT STEP ******/
    const handleContinueButtonPaymentStep = (event) => {
        // eslint-disable-next-line
        event ? event.preventDefault() : '';

        if (adyenCheckoutObject) {
            if (adyenLoaded) {
                adyenCheckoutObject.props.onSubmit = (state, component) => {
                    onPaymentSubmit(state, component);
                };
            } else {
                console.log('Adyen not loaded.');
            }

            try {
                adyenCheckoutObject.submit();
            } catch (error: any) {
                showApiError(error.response, dispatch, t);
            }
        } else {
            window.location.reload();
            console.log('Adyen Checkout Object not existing!');
        }
    };

    return (
        <>
            <div id='adyen' className='mb__l' ref={paymentReference} />
            <p className='mb__l text-sm'>{t('payment_methode_selection_hint')}</p>
            <Checkbox
                id='terms'
                className={`${theme === 'dark' && 'tertiary'} mb__l`}
                checked={isConfirmationCheckboxChecked}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setIsConfirmationCheckboxChecked(!isConfirmationCheckboxChecked)
                }
                label={t('payment_methode_selection_confirmation')}
                type='normal'
            />
            <div>
                <div className='flex justify-end'>
                    {hideAdyenForm && (
                        <Button className='mr-6' type={ButtonType.secondary} onClick={() => hideAdyenForm()}>
                            {t('common:buttons.cancel')}
                        </Button>
                    )}
                    <Button
                        onClick={handleContinueButtonPaymentStep}
                        disabled={buttonIsLoading || !isConfirmationCheckboxChecked}
                        loading={buttonIsLoading}
                    >
                        {t('common:buttons.save')}
                    </Button>
                </div>
            </div>
        </>
    );
}
