import { SET_IS_INITIAL_LOAD, SET_LOADER_MESSAGE, SET_SHOW_LOADER } from '.';
import { ILoader } from '../../typeScript/interfaces/ILoader';

export const initialState = {
    showLoader: true,
    loaderMessage: null,
    isInitialLoad: true
};

export function loaderReducer(state: ILoader = initialState, action): ILoader {
    switch (action.type) {
        case SET_SHOW_LOADER:
            const msg = action.value ? state.loaderMessage : null;
            return {
                ...state,
                showLoader: action.value,
                loaderMessage: msg
            };
        case SET_LOADER_MESSAGE:
            return {
                ...state,
                loaderMessage: action.value
            };
        case SET_IS_INITIAL_LOAD:
            return {
                ...state,
                isInitialLoad: action.value
            };
        default:
            // We need the default to ALWAYS return the initial state.
            return state
    }
}