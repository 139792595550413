import { RESET_USER, SET_USER_DATA, SET_USER_IS_LOGGED, SET_USER_LOADING, SET_AUTO_USER_LOGIN_TRIGGERED, SET_USER_CHARGERS, SET_REGISTRATION_COMPLETE } from '.'

import { ILoggedUser, IUser } from '../../typeScript/interfaces/IUser'

export const initialState = {
	isLogged: false,
	user: null,
	userLoading: false,
	autoUserLoadTriggered: false,
	userLoaded: false,
	userChargers: null
};

export function userReducer(state: IUser = initialState, action): IUser {
	switch (action.type) {
		case SET_USER_IS_LOGGED:
			return {
				...state,
				isLogged: action.value,
				autoUserLoadTriggered: true
			};
		case SET_USER_DATA:
			return {
				...state,
				user: {...state.user, ...action.value}
			};
		case RESET_USER:
			return {
				...state,
				user: null,
				isLogged: false,
				userLoading: false,
				userLoaded: false,
			};
		case SET_USER_LOADING:
			return {
				...state,
				userLoading: action.value,
				userLoaded: !action.value
			};
		case SET_AUTO_USER_LOGIN_TRIGGERED:
			return {
				...state,
				autoUserLoadTriggered: true
			};
		case SET_USER_CHARGERS:
			return {
				...state,
				userChargers: action.value
			};
		case SET_REGISTRATION_COMPLETE:
			return {
				...state,
				user: {...state.user, ...{registrationComplete: action.value}} as ILoggedUser
			}
		default:
			// We need the default to ALWAYS return the initial state.
			return state
	}
}