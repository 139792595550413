export default {
    contracts: {
        title: 'Verträge',
        hardware_installation_electricity: {
            title: 'Charger, Installation & Strom',
        },
        smart_meter: {
            title: "Smart-Meter"
        },
        customer_portal: {
            title: "Kundenportal",
        },
        default: {
            title: "Univers-Vertrag",
        },
        ref_no: "Vertragsnummer",
        contract_period_of_confirmed: "Vertragslaufzeit",
        contract_period_of_draft_title: "Vertragsstart",
        contract_period_of_draft: "Erster Ladevorgang oder zwei Monate nach Installation",
        contract_period_of_canceled: "Vertrag gekündigt zum:",
        download_terms: 'Herunterladen',
        open_terms: 'Öffnen',
        cancel: 'Vertrag kündigen',
        no_contracts: 'Keine Verträge verfügbar',
        loading: 'Laden von Verträgen...',
        no_contract: 'Keine Dokumente verfügbar'
    },
    tariff: {
        loading: 'Laden von Tarif...',
        title: 'Ihr Tarifpaket',
        your_tariff: 'Gebuchtes Tarifpaket',
        kwh_per_month: 'Inkludierte kWh/Monat',
        range_km: 'Reichweite (ca., in Km)',
        monthly_cost: 'Monatlicher Preis',
        included_energy_cost: 'Inkludierte kWh',
        additional_energy_cost: 'Jede weitere kWh',
        no_tariff_available: 'Keine Tarifdetails verfügbar'
    },
    contractTermination: {
        submitted: {
            title: 'Kündigungsanfrage erfolgreich übermittelt',
            text:'Wir haben Ihre Kündigungsanfrage erhalten und werden diese nun prüfen. Im Anschluss werden wir uns mit Ihnen in Verbindung setzen.'
        }
    }
}