import { DisplayCardTypes } from 'components/DisplayCard';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import api from '../../api/api';
import {
    Button,
    DisplayCard,
    InnerSpinner,
    Page,
    PayoutIBANFormFields,
    PayoutTariffFormFields,
    Badge,
    Checkbox
} from '../../components';
import { BadgeState } from '../../components/Badge';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { showApiError } from '../../utils/error-handler';
import {
    getValidationRules,
    ruleNames,
    validationService,
    hasValidationError,
    validationErrorTranslation
} from '../../validation';
import moment from 'moment';
import { ButtonType } from 'typeScript/types/ButtonTypes';
import styles from './Payout.module.scss';

const tick = require('../../assets/GreenTick.svg') as string;
const termsLink = 'https://www.univers.de/de-DE/service/agb';

enum Status {
    PENDING = 'PENDING',
    PLANNED = 'PLANNED',
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    REJECTED = 'REJECTED'
}

enum Locale {
    GERMAN = 'de-DE',
    ENGLISH = 'en-US'
}
interface IPayoutHistory {
    energyPrice?: number;
    priceUnit?: string;
    contractFileType?: string;
    createdDate?: string;
    lastModifiedDate?: string;
    startDate?: string;
    registrationStatus?: string;
    deliveryAddress?: {
        streetName?: string;
        houseNumber?: string;
        postalCode?: string;
        cityName?: string;
        country?: string;
    };
}

interface IIBANInfo {
    lastModifiedDate: string;
}

const locale = Locale.GERMAN;

function Payout() {
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [validationErrors, setValidationErrors] = useState<object[]>([]);

    const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
    const [history, setHistory] = useState<IPayoutHistory[]>([]);
    const [IBANInfo, setIBANInfo] = useState<IIBANInfo>();
    const [isIBANDataValid, setIsIBANDataValid] = useState(true);
    const [isInitialFormVisible, setIsInitialFormVisible] = useState(false);
    const [isTariffFormVisible, setIsTariffFormVisible] = useState(false);
    const [isIBANFormVisible, setIsIBANFormVisible] = useState(false);
    const [isButtonClickable, setIsButtonClickable] = useState(true);

    const fieldValuesIBANObject = {
        firstName: '',
        lastName: '',
        iban: ''
    };
    const fieldValuesTariffObject = {
        energyPrice: '',
        startDate: '',
        energyContract: '',
        streetName: '',
        houseNumber: '',
        postalCode: '',
        cityName: '',
        country: t('common:labels.country_code_default').toString()
    };

    const [fieldValues, setFieldValues] = useReducer((state, newState) => ({ ...state, ...newState }), {
        ...fieldValuesTariffObject,
        ...fieldValuesIBANObject
    });
    const [ibanFieldValues, setIbanFieldValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        fieldValuesIBANObject
    );
    const [tariffFieldValues, setTariffFieldValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        fieldValuesTariffObject
    );

    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);

    const validationRulesTariff = [
        ruleNames.payout.energyPrice,
        ruleNames.payout.startDate,
        ruleNames.payout.energyContract,
        ruleNames.payout.streetName,
        ruleNames.payout.houseNumber,
        ruleNames.payout.postalCode,
        ruleNames.payout.cityName,
        ruleNames.payout.country
    ];
    const validationRulesIban = [ruleNames.payout.firstName, ruleNames.payout.lastName, ruleNames.payout.iban];
    const validationRulesTermsAndConditionsAccepted = [ruleNames.payout.termsAndConditionsAccepted];

    useEffect(() => {
        getPayoutHistory();
        getPayoutIBANInfo();
        // eslint-disable-next-line
    }, []);

    const formatterDecimal = (price) =>
        new Intl.NumberFormat(locale, {
            style: 'decimal',
            maximumFractionDigits: 8
        }).format(price);

    const formatToLocaleCent = (price) => {
        if (price && !isNaN(price)) {
            return formatterDecimal(price);
        }
    };

    const validationRules = (params, validationRules) => {
        let rules = getValidationRules(validationRules);
        const validation = validationService(params, rules);
        return validation;
    };

    /**
     * Convert the german comma to dot
     * @param params
     */
    const formatEnergyPriceInParams = (params) => {
        if (params.energyPrice && typeof params.energyPrice === 'string') {
            locale === Locale.GERMAN && (params.energyPrice = parseFloat(params.energyPrice.replace(/,/g, '.')));
        }
    };

    const getPayoutHistory = () => {
        api.payout
            .getPayoutHistory()
            .then((resp) => {
                const tariffs = resp.data.energyTariffs;

                if (tariffs && tariffs.length > 0) {
                    // Sorting by startDate
                    tariffs.sort((a, b) => a.startDate + b.startDate);

                    setHistory(tariffs);
                    setIsInitialFormVisible(false);
                    setIsTariffFormVisible(false);
                    setIsIBANFormVisible(false);
                } else {
                    setIsInitialFormVisible(true);
                }
            })
            .catch(() => {})
            .then(() => {
                setIsHistoryLoaded(true);
            });
    };

    const getPayoutIBANInfo = () => {
        api.payout
            .getPayoutIBANInfo()
            .then((resp) => {
                setIBANInfo(resp.data.ibanRegistration);
            })
            .catch(() => {})
            .then(() => {});

        setIsInitialFormVisible(false);
        setIsTariffFormVisible(false);
        setIsIBANFormVisible(false);
    };

    const handleInitialSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (!isButtonClickable) return;

        const params = { ...fieldValues, termsAndConditionsAccepted };
        const validation = validationRules(
            params,
            validationRulesTariff.concat(validationRulesIban).concat(validationRulesTermsAndConditionsAccepted)
        );

        if (validation.passes()) {
            setValidationErrors([]);
            setIsButtonClickable(false);
            setIsIBANDataValid(true);

            formatEnergyPriceInParams(params);

            doIBANRequest(params, doTariffRequest);
        } else {
            const errors = validation.getErrors();
            setValidationErrors(errors);
        }
    };

    const handleTariffSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (!isButtonClickable) return;

        const params = { ...tariffFieldValues, termsAndConditionsAccepted };
        const validation = validationRules(
            params,
            validationRulesTariff.concat(validationRulesTermsAndConditionsAccepted)
        );

        if (validation.passes()) {
            setValidationErrors([]);
            setIsButtonClickable(false);

            formatEnergyPriceInParams(params);
            doTariffRequest(params);
        } else {
            const errors = validation.getErrors();
            setValidationErrors(errors);
        }
    };

    const handleIBANSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (!isButtonClickable) return;

        const params = { ...ibanFieldValues, termsAndConditionsAccepted };
        const validation = validationRules(
            params,
            validationRulesIban.concat(validationRulesTermsAndConditionsAccepted)
        );

        if (validation.passes()) {
            setValidationErrors([]);
            setIsButtonClickable(false);
            setIsIBANDataValid(true);

            doIBANRequest(params, null);
        } else {
            const errors = validation.getErrors();
            setValidationErrors(errors);
        }
    };

    // const isIbanValid = (iban: string) => {
    //     fetch(`https://openiban.com/validate/${iban}`)
    //         .then((results) => results.json())
    //         .then((data) => {
    //             console.warn(999, data);
    //         });
    // };

    const doIBANRequest = (params, successCallback) => {
        const IBANParams = {
            firstName: params.firstName,
            lastName: params.lastName,
            iban: params.iban,
            termsAndConditionsAccepted: params.termsAndConditionsAccepted
        };

        // isIbanValid(params.iban);

        api.payout
            .payoutIBANRegistration(IBANParams)
            .then(() => {
                setIbanFieldValues(fieldValuesIBANObject);
                !successCallback && setTermsAndConditionsAccepted(false);
                successCallback ? successCallback(params) : getPayoutIBANInfo();
            })
            .catch((error) => {
                setIsIBANDataValid(false);
                showApiError(error.response, dispatch, t);
            })
            .finally(() => {
                setIsInitialFormVisible(false);
                setIsButtonClickable(true);
            });
    };

    const doTariffRequest = (params) => {
        const energyTariffParams = {
            energyPrice: params.energyPrice,
            startDate: params.startDate,
            deliveryAddress: {
                streetName: params.streetName,
                houseNumber: params.houseNumber,
                postalCode: params.postalCode,
                cityName: params.cityName,
                countryCode: params.country
            },
            termsAndConditionsAccepted: params.termsAndConditionsAccepted
        };

        api.payout
            .payoutEnergyTariffRegistration(energyTariffParams, params.energyContract)
            .then(() => {
                setTariffFieldValues(fieldValuesTariffObject);
                setTermsAndConditionsAccepted(false);
                getPayoutHistory();
            })
            .catch((error) => {
                console.warn(111, error.response);

                showApiError(error.response, dispatch, t);
            })
            .finally(() => {
                setIsButtonClickable(true);
            });
    };

    const renderedSubmitButton = (callback) => (
        <div className='buttons'>
            {history.length > 0 && (
                <Button
                    type={ButtonType.secondary}
                    onClick={() => {
                        callback();
                        window.scrollTo(0, 0);
                        setTermsAndConditionsAccepted(false);
                    }}
                >
                    {t('payout:button_hide_form')}
                </Button>
            )}
            <Button buttonType='submit' disabled={!isButtonClickable}>
                {t('common:buttons.submit')}
            </Button>
        </div>
    );
    const renderedFormToggleButton = () => (
        <div className={`buttons`}>
            <Button
                buttonType='button'
                onClick={() => {
                    setIsTariffFormVisible(!isTariffFormVisible);
                    window.scrollTo(0, 0);
                }}
            >
                {isTariffFormVisible ? t('payout:button_hide_form') : t('payout:button_show_tariff_form')}
            </Button>
        </div>
    );
    const renderedCheckbox = () => (
        <Checkbox
            id='termsAndConditionsAccepted'
            className='mb__xs'
            checked={fieldValues.termsAndConditionsAccepted}
            hasValidationError={hasValidationError(ruleNames.payout.termsAndConditionsAccepted, validationErrors)}
            validationErrorLabel={validationErrorTranslation(
                ruleNames.payout.termsAndConditionsAccepted,
                validationErrors,
                t
            )}
            onChange={() => {
                setTermsAndConditionsAccepted(!termsAndConditionsAccepted);
            }}
            label={
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('checkout:personal_info_terms', { termsLink: termsLink })
                    }}
                />
            }
        />
    );

    return (
        <Page
            pageTheme={PageTheme.light}
            title={t('payout:header.title')}
            intro={history.length === 0 ? t('payout:no_history') : t('payout:with_history')}
        >
            <div className={`2xl:w-9/12 ${styles.payout}`}>
                <InnerSpinner isActive={!isHistoryLoaded}>
                    {!(isInitialFormVisible || isTariffFormVisible || isIBANFormVisible) && (
                        <DisplayCard type={DisplayCardTypes.PLAIN} className='xl:w-8/12'>
                            <h3 className='mb-5 text-fg_primary'>{t('payout:iban_header')}</h3>

                            <div className='flex justify-between'>
                                <div>
                                    {t('payout:iban_added')}
                                    {'  '}
                                    <img width='25px' style={{ verticalAlign: 'middle' }} src={tick} alt='' />
                                </div>

                                <span className={styles.link} onClick={() => setIsIBANFormVisible(!isIBANFormVisible)}>
                                    {isIBANFormVisible
                                        ? t('payout:button_hide_form')
                                        : t('payout:button_show_IBAN_form')}
                                </span>
                            </div>
                            <br />

                            <p className={styles.date}>
                                {t('payout:iban_creation_date')}{' '}
                                {moment(IBANInfo?.lastModifiedDate).locale('de').format('DD.MM.YYYY HH:mm')}
                            </p>

                            <hr className={`-mx-5 xl:-mx-8 my-5  ${styles.divider}`} />

                            <h3 className='mb-5 text-fg_primary'>{t('payout:tariff_header')}</h3>
                            <p className='mb-8 text-fg_primary'>{t('payout:tariff_intro')}</p>

                            {history.map((historyItem, idx) => (
                                <div className={`${styles.itemBox}`} key={idx.toLocaleString()}>
                                    <div className='flex justify-between mb-2'>
                                        <div className={styles.itemName}>{t('payout:history_header_price')}</div>
                                        <div>
                                            {formatToLocaleCent(historyItem.energyPrice)} {historyItem.priceUnit}
                                        </div>
                                    </div>
                                    <div className='flex justify-between mb-2'>
                                        <div className={styles.itemName}>{t('payout:history_header_start_date')}</div>
                                        <div>{moment(historyItem.startDate).format('DD.MM.YYYY')}</div>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className={styles.itemName}>{t('payout:history_header_status')}</div>

                                        <Badge
                                            state={
                                                historyItem.registrationStatus === Status.ACTIVE
                                                    ? BadgeState.SUCCESS
                                                    : historyItem.registrationStatus === Status.PLANNED
                                                    ? BadgeState.INFO
                                                    : historyItem.registrationStatus === Status.REJECTED
                                                    ? BadgeState.ERROR
                                                    : BadgeState.DEFAULT
                                            }
                                        >
                                            {historyItem.registrationStatus === Status.ACTIVE
                                                ? t('payout:history_status_succeeded')
                                                : historyItem.registrationStatus === Status.PLANNED
                                                ? t('payout:history_status_ongoing')
                                                : historyItem.registrationStatus === Status.REJECTED
                                                ? t('payout:history_status_failed')
                                                : historyItem.registrationStatus === Status.PENDING
                                                ? t('payout:history_status_pending')
                                                : t('payout:history_status_expired')}
                                        </Badge>
                                    </div>
                                </div>
                            ))}

                            {history.length > 0 && renderedFormToggleButton()}
                        </DisplayCard>
                    )}
                </InnerSpinner>

                {/* INITIAL FORM */}
                {isInitialFormVisible && (
                    <DisplayCard
                        type={DisplayCardTypes.PLAIN}
                        label={t('payout:form_title')}
                        intro={t('payout:form_intro')}
                    >
                        <form onSubmit={handleInitialSubmit}>
                            <>
                                <PayoutTariffFormFields
                                    validationErrors={validationErrors}
                                    setFieldValues={setFieldValues}
                                    fieldValues={fieldValues}
                                    isUpdateForm={history.length > 0}
                                />
                                <PayoutIBANFormFields
                                    validationErrors={validationErrors}
                                    setFieldValues={setFieldValues}
                                    isIBANDataValid={isIBANDataValid}
                                />
                                {renderedCheckbox()}
                                {renderedSubmitButton(() => setIsInitialFormVisible(!isInitialFormVisible))}
                            </>
                        </form>
                    </DisplayCard>
                )}

                {/* TARIFF FORM */}
                {isTariffFormVisible && (
                    <DisplayCard
                        type={DisplayCardTypes.PLAIN}
                        label={t('payout:form_title')}
                        intro={t('payout:form_intro')}
                    >
                        <form onSubmit={handleTariffSubmit}>
                            <>
                                <PayoutTariffFormFields
                                    validationErrors={validationErrors}
                                    setFieldValues={setTariffFieldValues}
                                    fieldValues={tariffFieldValues}
                                    isUpdateForm={history.length > 0}
                                />
                                {renderedCheckbox()}
                                {renderedSubmitButton(() => setIsTariffFormVisible(!isTariffFormVisible))}
                            </>
                        </form>
                    </DisplayCard>
                )}

                {/* IBAN FORM */}
                {isIBANFormVisible && (
                    <DisplayCard
                        type={DisplayCardTypes.PLAIN}
                        label={t('payout:form_title')}
                        intro={t('payout:form_intro')}
                    >
                        <form onSubmit={handleIBANSubmit}>
                            <>
                                <PayoutIBANFormFields
                                    validationErrors={validationErrors}
                                    setFieldValues={setIbanFieldValues}
                                    isIBANDataValid={isIBANDataValid}
                                />
                                {renderedCheckbox()}
                                {renderedSubmitButton(() => setIsIBANFormVisible(!isIBANFormVisible))}
                            </>
                        </form>
                    </DisplayCard>
                )}
            </div>
        </Page>
    );
}

export default Payout;
