import axios from "axios";
import axiosRetry from 'axios-retry';
import TokenWrapper from './auth-token-wrapper';

/** AXIOS RETRY **/
//axiosRetry(axios, { retries: 2 });
// export const DEVELOPMENT_API_BASE_URL = 'http://evcharging-api.apaas-beta1.eniot.io/api/v1';
// export const PRODUCTION_API_URL = 'http://evcharging-api.apaas-beta1.eniot.io/api/v1';
// export const API_URL = process.env.APP_ENV === 'prod' ? PRODUCTION_API_URL : DEVELOPMENT_API_BASE_URL;

let config;

config = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
}

function simpleNetworkErrorCheck(error) {
    // https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
    if( error.response ) {
        return false;
    } else if( error.request) {
        return true;
    }

    return false;
}

const axiosInstance = axios.create(config);
axiosRetry(axiosInstance, { retryDelay: () => { return 1500}, retries: 2, retryCondition: simpleNetworkErrorCheck });

/**
 * @type {{callPatch(*=, *=, *=): Promise<AxiosResponse<any>>, callPut(*=, *=, *=): Promise<AxiosResponse<any>>, callCustomUrl(*=, *=, *=): AxiosPromise<any>, callDelete(*=, *, *=): Promise<AxiosResponse<any>>, callPost(*=, *=, *=): Promise<AxiosResponse<any>>, callGet(*=, *=, *=): Promise<AxiosResponse<any>>}}
 */
const API_WRAPPER = {

    callDelete(endpoint, params, customConfig) {
        let config = getConfigForRequest(customConfig);
        config.data = params;

        return axiosInstance.delete(endpoint, config);
    },

    callGet(endpoint, params, customConfig) {
        return axiosInstance.get(endpoint, {params: params, ...getConfigForRequest(customConfig)});
    },

    callPost(endpoint, params, customConfig) {
        return axiosInstance.post(endpoint, params, getConfigForRequest(customConfig));
    },

    callPut(endpoint, params, customConfig) {
        return axiosInstance.put(endpoint, params, getConfigForRequest(customConfig));
    },

    callPatch(endpoint, params, customConfig) {
        return axiosInstance.patch(endpoint, params, getConfigForRequest(customConfig));
    },

    callCustomUrl(method, urlEndpoint, params) {
        return axiosInstance({
            method: method,
            url: urlEndpoint,
            data: params
        });
    }
};

function getConfigForRequest( customConfig ) {
    let requestConfig = {};
    // This validates and removes the token if it's not valid.
    let token = TokenWrapper.getToken();

    if (customConfig && customConfig.headers) {
        requestConfig.headers = {
            ...customConfig.headers
        }
    }

    if(customConfig && customConfig.cancelToken) {
        requestConfig.cancelToken = customConfig.cancelToken
    }

    if (token && (TokenWrapper.tokenIsValid() || TokenWrapper.refreshTokenIsValid() ) ) {
        requestConfig.headers = {
            authorization: "Bearer " + token
        }
    } else {
        TokenWrapper.removeAuthTokens();
    }

    return requestConfig;
}

export default API_WRAPPER

export const createCancelToken = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return source
}
