import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, DisplayCard, iconTypes } from '../components';
import { DisplayCardTypes } from './DisplayCard';

type Props = {
    className?: string;
    linkPath: string;
};

function PaymentInvitationCard(props: Props) {
    const { className = '', linkPath = '' } = props;
    const { t } = useTranslation(['home']);

    return (
        <DisplayCard type={DisplayCardTypes.ICON_LEFT} className={className} src={iconTypes.paymentcard}>
            <h3 className='mb__s'>{t('add_payment.title')}</h3>
            <p className='mb__m'>{t('add_payment.text')}</p>
            <div className='display-card__buttons'>
                <Link to={linkPath} className='display-card__button'>
                    <Button>{t('add_payment.button')}</Button>
                </Link>
            </div>
        </DisplayCard>
    );
}

export default PaymentInvitationCard;
