import Constants from '../../constants';

export default {
    reference_number: 'Referenznummer: {{number}}',
    personal_info: 'Persönliche Information',
    personal_info_b2b2e: 'Persönliche Informationen',
    personal_info_tag: "Für die Terminvereinbarung benötigen wir zunächst ein paar Informationen von Ihnen.",
    personal_info_terms: 'Ja, ich stimme den <a href="{{termsLink}}" target="_blank" rel="noopener noreferrer">Nutzungsbedingungen</a> des <strong>my<b>En</b>OS<sup>TM</sup></strong> Kundenportals zu.',
    mandatory_fields: '* Pflichtfelder',

    create_envision_account: 'my<b>En</b>OS<sup>TM</sup> Account erstellen',
    envision_account_text_not_logged: 'Ein Account für alles. Ihr my<b>En</b>OS<sup>TM</sup> Account ist Ihr zukünftiger Zugang zu allen Univers Produkten und Services. Mit ihm können Sie sich im Kundenportal und in der App anmelden.',

    create_envision_account_previsit_only: 'my<b>En</b>OS<sup>TM</sup> Portal Zugang',
    envision_account_text_not_logged_previsit_only: 'Im Portal stellen wir Ihnen unter anderem Informationen zum Stromvertrag, AGB, Rechnungen und Produktdatenblätter zu Ihrer Ladestation bereit. Die Zugangsdaten sind gleichzeitig Ihre Anmeldeinformationen für die "EnOS Charge" App für Ihr Smartphone zur Steuerung der Charger.',


    envision_account_text_logged: 'Sie sind derzeit eingeloggt als {{email}}.',
    password_policy: 'Das Passwort muss eine Zahl, ein Symbol (!@#%&*(){}[]-_=|) und einen Klein- und Großbuchstaben enthalten. Das Passwort muss mindestens aus 8 Zeichen bestehen.',

    sign_into_envision: 'Anmelden bei myUnivers',
    sign_into_envision_text: 'Melden Sie sich bei myUnivers an, um dem Installateur die notwendigen Details für die Planung seines Besuchs mitzuteilen und Ihren Installationsprozess zu verfolgen.',

    make_mistake: 'Wenn Sie glauben, dass Sie einen Fehler gemacht haben, kontaktieren Sie uns bitte.',

    install_info: 'Information zur Installation',
    install_address: 'Informationen zur Installation',
    installation_details: 'Details zum Pre-Visit',
    install_info_tagline: 'Bitte geben Sie die Adresse ein, an welcher der Pre-Visit durchgeführt werden soll.',
    preferred_install_date: 'Wunschdatum',
    payment_info: "Zahlungsoptionen",
    payment_method: 'Zahlungsinformation',
    payment_subtext_title: "1. Anzahlung jetzt bei Bestellung",
    payment_subtext: "Die Zahlart, die Sie für die Anzahlung wählen, hinterlegen wir für die Abbuchung des monatlichen Betrags für Ihren Tarifpaket.*",
    payment_previsit_only_subtext: "Die Zahlart, die Sie für die Einmalzahlung wählen, hinterlegen wir für die Abbuchung des monatlichen Betrags, der für Ihr Tarifpaket inkl. Strom fällig wird, wenn Sie sich dazu entscheiden sollten, Univers Kunde zu werden.*",
    payment_second_subtext_title: "2. Restzahlung nach erfolgreicher Installation",
    payment_second_subtext: "Den vollen Betrag zahlen Sie erst nach der Installation Ihres Chargers. Den Zugang erhalten Sie mit Ihrer Rechnung per E-Mail. Den noch offenen Betrag können Sie auch bequem und flexibel mit Klarna finanzieren.",
    payment_review: "* Bei der Auswahl von Giropay als Zahlart erteile ich der Univers Operations GmbH ein SEPA-Lastschriftmandat für obiges Bankkonto. Ich kann die Erstattung einer Lastschrift innerhalb von 8 Wochen verlangen, nachdem der" +
        " Betrag belastet wurde.",
    payment_methode_selection_hint: "Hinweis zur Aktualisierung der Zahlungsmethode: Bei der Auswahl von Kreditkarte oder SEPA Lastschrift als Zahlungsmethode für die monatliche Rechnung wird zur Verifizierung der neu hinterlegten Zahlungsinformationen eine Transaktion oder Reservierung in Höhe von 0 Euro durchgeführt.",
    payment_methode_selection_confirmation: "Hiermit berechtige ich die Univers Operations GmbH für die Abbuchung des monatlich fälligen Betrags für das Tarifpaket inkl. Strom die von mir hinterlegte Zahlungsmethode automatisch zu belasten.",
    review_order: 'Bitte überprüfen Sie Ihre Bestellung',
    review_order_small_print: 'Alle Preise verstehen sich inkl. der gesetzlichen Mehrwertsteuer von aktuell 19%.',
    small_print_all_prices: 'Univers Operations GmbH (Univers) stellt Ihnen, ohne weitere Kosten, die für die Stromlieferung im Rahmen von Charging by <b>En</b>OS<sup>TM</sup> benötigte Messstelle zur Verfügung. Zu diesem Zweck schließt Univers in Ihren Auftrag und in Ihrem Namen einen Vertrag mit einem Messstellenbetreiber, der Discovergy GmbH. ' +
        'Die Laufzeit des Messstellenbetriebsvertrags ist 2 Jahre. Sie beginnt mit dem erstmaligen Laden Ihres E-Fahrzeugs über den Charger, spätestens jedoch zwei Monate nach Inbetriebnahme des Chargers. Es gelten hierfür die Allgemeinen Geschäftsbedingungen des Messstellenbetreibers, welche Ihnen vor Vertragsschluss zur Verfügung gestellt werden. ' +
        '<br><br>Soweit die von Ihnen während des Online-Bestellvorgangs übermittelten Informationen zu den tatsächlichen und / oder technischen Rahmenbedingungen vor Ort nicht zutreffend sind, bietet Univers Ihnen ein anderes Installationspaket an. Außerdem steht Univers in diesem Fall ein Sonderrücktrittsrecht zu. Der genaue Umfang unserer beider Rechte und Pflichten ergibt sich aus den Allgemeinen Geschäftsbedingungen Charging by <b>En</b>OS<sup>TM</sup>. ' +
        '<br><br>Haben Sie Fragen zu Ihrer Bestellung? Unser Service Team hilft Ihnen gerne weiter. Sie können uns per E-Mail kundenservice@univers.de oder per Telefon unter ' + Constants.CUSTOMER_SUPPORT_PHONE_NUMBER + ' erreichen.',
    small_print_pre_visit_only: 'Haben Sie Fragen zu Ihrer Bestellung? Unser Service Team hilft Ihnen gerne weiter. Sie können uns per E-Mail kundenservice@univers.de oder per Telefon unter ' + Constants.CUSTOMER_SUPPORT_PHONE_NUMBER + ' erreichen. Der genaue Umfang Ihrer Rechte und Pflichten ergibt sich aus den Allgemeinen Geschäftsbedingungen Pre-Visit.',
    payment_confirmation_page: {
        thank_you_for_order: 'Vielen Dank für Ihre Bestellung',
        error_header: 'Zahlung fehlgeschlagen',
        refused_header: 'Zahlung abgelehnt',
        cancelled_header: 'Zahlung storniert',
        payment_success: '<0>Wir haben Ihre Bestellung erhalten. In Kürze erhalten Sie Ihre Bestellbestätigung per E-Mail.</0> <br><br> Haben Sie Fragen zu Ihrer Bestellung? Unser Service Team hilft Ihnen gerne weiter. Sie erreichen uns per E-Mail unter' +
            ' <1>kundenservice@univers.de</1> oder per Telefon unter <2>' + Constants.CUSTOMER_SUPPORT_PHONE_NUMBER + '</2>',
        payment_received: '<0>Ihre Zahlung wird gerade bearbeitet. In Kürze erhalten Sie Ihre Bestellbestätigung per E-Mail.</0> <br><br> Haben Sie Fragen zu Ihrer Bestellung? Unser Service Team hilft Ihnen gerne weiter. Sie erreichen uns per E-Mail unter <1>kundenservice@univers.de</1> oder per Telefon unter <2>' + Constants.CUSTOMER_SUPPORT_PHONE_NUMBER + '.</2>',
        payment_refused: 'Bei der Bearbeitung Ihrer Zahlung ist ein Fehler aufgetreten.',
        payment_error: 'Bei der Bearbeitung Ihrer Zahlung ist ein Fehler aufgetreten.',
        payment_cancelled: 'Ihre Zahlung wurde während des Bestellvorgangs storniert.',
        continue_header: 'my<b>En</b>OS<sup>TM</sup> Kundenportal',
        continue_text: 'Im Kundenportal halten wir Sie über Ihre Termine auf dem Laufenden. Hier finden Sie auch alle Vertragsdetails, können Änderungen Ihrer Daten vornehmen und finden nützliche Tipps.<br><br><b>Je mehr Informationen wir vom Installationsort bekommen, desto besser können wir planen! Unsere Partner freuen sich, wenn Sie über den Installationsassistenten im Kundenportal einige Fotos hochladen.</b>',
        back_header: 'Zahlung wiederholen',
        back_text: 'Bitte versuchen Sie es erneut oder wenden Sie sich an unseren Kundenservice: {{phone}}',
        continue_button: 'Jetzt einloggen',
    },

    confirmation_step: {
        terms: "Ja, ich stimme den <1>Allgemeinen Geschäftsbedingungen</1> zu und habe von meinem Widerrufsrecht Kenntnis genommen.",
        info_install_address: "{{streetAddress}} {{streetNo}}<1/>{{postCode}} {{city}}, {{country}}",
        payment_title: 'Zahlung',
        payment_text: "Im nächsten Schritt zu klären",
    },

    payment_type: {
        [Constants.PAYMENT_TYPES.DIRECT_DEBIT]: 'Bankeinzug',
        [Constants.PAYMENT_TYPES.CREDIT_CARD]: 'Kreditkarte',
        giropay: 'Giropay',
        scheme: 'Debit/ Credit Card'
            //[Constants.PAYMENT_TYPES.PAYPAL]: 'Paypal',
    },
    payment_summary: {
        [Constants.PAYMENT_TYPES.DIRECT_DEBIT]: '{{accountHolder}}, IBAN {{iban}}, BIC {{bic}}, {{bankName}}',
        [Constants.PAYMENT_TYPES.CREDIT_CARD]: '{{cardHolder}}, Karte {{cardNo}}, Ablaufdatum {{expiryDate}}',
    },

    salutations: {
        marriedCouple: 'Eheleute',
        mr: 'Herr',
        mrs: 'Frau'
    },

    contact_methods: {
        email: 'E-Mail',
        phone: 'Telefon',
        post: 'Post'
    },

    product: {
        title: 'Produktauswahl',
        hardware: {
            smart_meter: 'Smart Meter',
            title: 'Charger inkl. Smart Meter',
            type: 'Version',
            payment: 'Komplettpreis',
            payment_by_order: 'Anzahlung bei Bestellung',
            payment_discount: 'Friends & Family Discount',
            payment_by_installation: 'Restzahlung (nach erfolgreicher Installation)',
        },
        package: {
            title: 'Installationspaket {{name}}',
            pre_visit_title: 'Pre-Visit',
            pre_visit_desc: 'Pre-Visit',
            install_cost: 'Installationskosten',
            total_cost: 'Gesamtpreis',
            payment_by_order: 'Anzahlung bei Bestellung',
            payment_by_installation: 'Restzahlung (nach erfolgreicher Installation)',
            answer_package: 'Wieso {{package_name}}?',
            check_answer: 'Antworten überprüfen'
        },
        total_price: "Jetzt zu zahlende Summe"
    }
}


const checkoutNissan = {
    envision_account_text_not_logged: 'In unserem Portal stellen wir Ihnen Informationen bereit und Sie können Ihre Daten und Verträge einsehen. Ihre E-Mail und Ihr hier vergebenes Passwort sind zudem Zugang zur unserer App, mit der Sie nach der Installation Ihre Ladestation steuern können. Sollten Sie sich dazu entscheiden, Ökostromkunde zu werden, können Sie im Portal zudem Ihre monatliche Stromrechnung einsehen und herunterladen.',
    envision_account_text_not_logged_previsit_only: 'In unserem Portal stellen wir Ihnen Informationen bereit und Sie können Ihre Daten und Verträge einsehen. Ihre E-Mail und Ihr hier vergebenes Passwort sind zudem Zugang zur unserer App, mit der Sie nach der Installation Ihre Ladestation steuern können. Sollten Sie sich dazu entscheiden, Ökostromkunde zu werden, können Sie im Portal zudem Ihre monatliche Stromrechnung einsehen und herunterladen.',
};


const checkoutGoinGreen = {
    installation_details: 'Details zum Vor-Ort-Besuch',
    install_info_tagline: 'Bitte geben Sie die Adresse ein, an welcher der Vor-Ort-Besuch durchgeführt werden soll.',
    envision_account_text_not_logged: 'In unserem Portal stellen wir Ihnen Informationen bereit und Sie können Ihre Angebote einsehen. Ihre E-Mail und Ihr hier vergebenes Passwort sind zudem Zugang zur unserer App, mit der Sie nach der Installation Ihre Ladestation steuern können. Sollten Sie sich dazu entscheiden, Ökostromkunde zu werden, können Sie im Portal zudem Ihre monatliche Stromrechnung einsehen und herunterladen.',
    envision_account_text_not_logged_previsit_only: 'In unserem Portal stellen wir Ihnen Informationen bereit und Sie können Ihre Angebote einsehen. Ihre E-Mail und Ihr hier vergebenes Passwort sind zudem Zugang zur unserer App, mit der Sie nach der Installation Ihre Ladestation steuern können. Sollten Sie sich dazu entscheiden, Ökostromkunde zu werden, können Sie im Portal zudem Ihre monatliche Stromrechnung einsehen und herunterladen.',
};

export { checkoutNissan, checkoutGoinGreen };
