// TODO::: Found unused UserTypes!
enum UserType {
    PRIVATE_CUSTOMER = "Private Customer",
    // INSTALLER = "Installer",
    EMPLOYEE = "Employee"
    // B2B_COMPANY = "B2B Company",
    // LEASE_COMPANY = "Lease Company",
    // PERSON_ACCOUNT = "Personenaccount",
    // SOURCE_COMPANY = "Source Company",
    // MASTER = "Master"
}

export default UserType;
