import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const checkmarkIcon = require("../assets/Checkmark.svg")
const checkmarkIconWhite = require("../assets/Checkmark_white.svg")

type Props = {
	navType?: string,
	icon: string,
	active?: boolean,
	completed: boolean,
	routePath: string,
	nameKey: string,
	canAlwaysBeAccessed: boolean,
	paddingLeft: boolean,
	step: number,
	currentStep: number,
	allowForwardNavigation: boolean
};


// NavLinkStep.propTypes = {
// 	navType: PropTypes.string,
// 	icon: PropTypes.string,
// 	active: PropTypes.bool,
// 	completed: PropTypes.bool,
// 	routePath: PropTypes.string,
// 	nameKey: PropTypes.string,
// 	canAlwaysBeAccessed: PropTypes.bool
// };

function NavLinkStep(props: Props) {
	const {
		icon,
		completed = false,
		routePath,
		nameKey,
		paddingLeft = false,
		canAlwaysBeAccessed = false,
		step = 1,
		currentStep = 1,
		allowForwardNavigation = false,
	} = props;

	const active = step === currentStep;

	const { t } = useTranslation(['nav']);
	const history = useHistory();

	const getActiveLinkClass = () => {
		return `nav__steps-link ${paddingLeft && 'nav__steps-link--padding-right'} ${active && 'nav__steps-link--active'} ${completed && 'nav__steps-link--completed'}`;
	};

	const getDoneLinkClass = () => {
		return `nav__link__icon nav__link__icon__mobile ${completed ? 'nav__link__icon--done' : ''} ${active && 'nav__link__icon--active'}`;
	};

	const goToStepIfAllowed = () => {
		if (canAlwaysBeAccessed || (completed && !active && routePath)) {
			if (allowForwardNavigation) {
				history.push(routePath);
			} else {
				if (step <= currentStep) {
					history.push(routePath);
				}
			}
		}
	};

	return (
		<>
			<div className={getActiveLinkClass()} onClick={goToStepIfAllowed}>
				<img className={getDoneLinkClass()} src={!completed ? checkmarkIcon : checkmarkIconWhite} alt="checkmark icon" />
				{icon && <img className="nav__link__icon--mobile" src={icon} alt="personal icon" />}
				<p className="nav__steps-link__text">{t(`nav:${nameKey}`)}</p>
			</div>
		</>
	)
}


export default NavLinkStep;
