import { SET_SUPPORT_TICKET_SUBJECT, SET_SUPPORT_TICKET_DESCRIPTION, RESET_SUPPORT_TICKET } from '.'

import { ISupport } from '../../typeScript/interfaces/ISupport'

export const initialState: ISupport = {
	subject: '',
	description: ''
};

export function supportReducer(state: ISupport = initialState, action: any): ISupport {
	switch (action.type) {
		case SET_SUPPORT_TICKET_SUBJECT:
			return {
				...state,
				subject: action.value
            }
        case SET_SUPPORT_TICKET_DESCRIPTION:
            return {
                ...state,
                description: action.value
            }
		case RESET_SUPPORT_TICKET:
			return {
				subject: '',
				description: ''
			}
		default:
			// We need the default to ALWAYS return the initial state.
			return state
	}
}