import validate from "validate.js";
import moment from 'moment';
/**
 * Custom validators
 * @param value
 * @param options
 * @param key
 * @param attributes
 * @returns {null|*|string}
 */
validate.validators.file_size = (value, options) => {
    let message = null;

    if (value) {
        message = options.bytes && value.size > options.bytes ? options.message || 'Too large' : null;
    }

    return message;
};

// just a simple check that we have a minimum of 8 numbers in our string
validate.validators.phone_no = (value, options) => {
    let message = null;

    if (value) {
        let numbersCounter = 0;
        for( let i = 0; i < value.length; i++){
            if(!isNaN(value.charAt(i))){           //if the string is a number, do the following
                numbersCounter++;
            }
        }
        let numbersValid = numbersCounter >= 8;
        message = numbersValid ? null : options.message || 'Not valid';
    }

    return message;
};

// just a simple check that we have a minimum of 8 numbers in our string
validate.validators.boolean = (value, options) => {
    let message = null;

    if(options.truthy === true) {
        if(value !== true) {
            return options.message
        }
    } else if(options.truthy === false) {
        if( value === true ) {
            return options.message
        }
    }

    return message;
};

validate.validators.regex = function(value, options, key, attributes) {
    let regExp = new RegExp(options.pattern);

    if (!regExp.test(value)) {
        return options.message;
    }
};

// date extension
validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse: function(value) {
        return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function(value, options) {
        var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
        return moment.utc(value).format(format);
    }
});