import { DisplayCardTypes } from 'components/DisplayCard';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import api from '../../api/api';
import { Button, DisplayCard, InnerSpinner, Page, Checkbox, Input, Switch, Label, Tooltip } from '../../components';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { showApiError } from '../../utils/error-handler';
import {
    getValidationRules,
    ruleNames,
    validationService,
    hasValidationError,
    validationErrorTranslation
} from '../../validation';
import { ButtonType } from 'typeScript/types/ButtonTypes';
import styles from './FleetData.module.scss';
import { Fleetdata as IFleetdata, FleetDataErrorCode } from './../../_generated/openapi';

const LicensePlateExampleImage = require('../../assets/images/LicensePlateExample.png');

const tick = require('../../assets/GreenTick.svg') as string;
const close = require('../../assets/red-x-line-icon.svg') as string;

function FleetData() {
    interface ILicensePlate {
        vehicleRegistrationNumberPart1?: string;
        vehicleRegistrationNumberPart2?: string;
        vehicleRegistrationNumberPart3?: string;
        vehicleRegistrationNumberPart4?: string;
    }
    interface IFleetDataAdvanced extends IFleetdata, ILicensePlate {}

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [validationErrors, setValidationErrors] = useState<object[]>([]);

    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [isEULicensePlate, setIsEULicensePlate] = useState(true);
    const [isCostUnitError, setIsCostUnitError] = useState(false);
    const [isEmployeeIdError, setIsEmployeeIdError] = useState(false);
    const [isAnyPartOfRegistrationNumberInvalid, setIsAnyPartOfRegistrationNumberInvalid] = useState(false);

    const [isButtonClickable, setIsButtonClickable] = useState(true);

    const fieldValuesInit: IFleetDataAdvanced = {
        employeeCostUnit: '',
        employeeId: '',
        vehicleRegistrationNumber: '',
        vehicleRegistrationNumberPart1: '',
        vehicleRegistrationNumberPart2: '',
        vehicleRegistrationNumberPart3: '',
        vehicleRegistrationNumberPart4: '',
        renewableEnergy: false
    };

    const [fleetData, setFleetData] = useState<IFleetDataAdvanced>({
        employeeCostUnit: '',
        employeeId: '',
        vehicleRegistrationNumber: '',
        vehicleRegistrationNumberPart1: '',
        vehicleRegistrationNumberPart2: '',
        vehicleRegistrationNumberPart3: '',
        vehicleRegistrationNumberPart4: '',
        renewableEnergy: false
    });

    const [fieldValues, setFieldValues] = useReducer(
        (state: Partial<IFleetDataAdvanced>, newState: Partial<IFleetDataAdvanced>) => ({ ...state, ...newState }),
        fleetData
    );

    const validationRulesFleetDataEU = [
        ruleNames.fleetData.vehicleRegistrationNumberPart1,
        ruleNames.fleetData.vehicleRegistrationNumberPart2,
        ruleNames.fleetData.vehicleRegistrationNumberPart3,
        ruleNames.fleetData.vehicleRegistrationNumberPart4,
        ruleNames.fleetData.renewableEnergy
    ];
    const validationRulesFleetDataNotEU = [
        ruleNames.fleetData.vehicleRegistrationNumber,
        ruleNames.fleetData.renewableEnergy
    ];

    useEffect(() => {
        getFleetData();
    }, []);

    const validationRules = (params, validationRules) => {
        let rules = getValidationRules(validationRules);
        const validation = validationService(params, rules);
        return validation;
    };

    const getFleetData = () => {
        api.fleetData
            .getFleetData()
            .then((resp) => {
                if (resp.data) {
                    setFleetData(resp.data);
                    setFieldValues(resp.data);
                    setIsFormVisible(false);
                } else {
                    setIsFormVisible(true);
                }
            })
            .catch(() => {})
            .then(() => {
                setIsDataFetched(true);
            });
    };

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        let licensePlate = isEULicensePlate
            ? fieldValues.vehicleRegistrationNumberPart1 +
              '-' +
              fieldValues.vehicleRegistrationNumberPart2 +
              fieldValues.vehicleRegistrationNumberPart3 +
              fieldValues.vehicleRegistrationNumberPart4
            : fieldValues.vehicleRegistrationNumber;

        licensePlate = licensePlate === '-' ? '' : licensePlate;

        const params: IFleetdata = {
            employeeCostUnit: fieldValues.employeeCostUnit || '',
            employeeId: fieldValues.employeeId || '',
            vehicleRegistrationNumber: licensePlate?.toUpperCase() || '',
            renewableEnergy: fieldValues.renewableEnergy || false
        };

        const validation = validationRules(
            fieldValues,
            isEULicensePlate ? validationRulesFleetDataEU : validationRulesFleetDataNotEU
        );

        if (validation.passes()) {
            setValidationErrors([]);
            setIsButtonClickable(false);
            setIsAnyPartOfRegistrationNumberInvalid(false);

            setIsCostUnitError(false);
            setIsEmployeeIdError(false);

            doRequest(params);
        } else {
            const errors = validation.getErrors();

            const part1 = errors.find((x) => x.field === 'fleetData.vehicleRegistrationNumberPart1');
            const part2 = errors.find((x) => x.field === 'fleetData.vehicleRegistrationNumberPart2');
            const part3 = errors.find((x) => x.field === 'fleetData.vehicleRegistrationNumberPart3');
            const part4 = errors.find((x) => x.field === 'fleetData.vehicleRegistrationNumberPart4');

            if (part1 || part2 || part3 || part4) {
                setIsAnyPartOfRegistrationNumberInvalid(true);
            } else {
                setIsAnyPartOfRegistrationNumberInvalid(false);
            }

            setValidationErrors(errors);
        }
    };

    const doRequest = (params) => {
        api.fleetData
            .fleetDataUpdate(params)
            .then(() => {
                setFieldValues(fieldValuesInit);
                getFleetData();
            })
            .catch((error) => {
                setIsCostUnitError(false);
                setIsEmployeeIdError(false);
                switch (error.response.data.code) {
                    case FleetDataErrorCode.EmployeeCostUnitIsNull:
                    case FleetDataErrorCode.EmployeeCostUnitValidationError:
                        setIsCostUnitError(true);
                        break;
                    case FleetDataErrorCode.EmployeeIdIsNull:
                    case FleetDataErrorCode.EmployeeIdValidationError:
                        setIsEmployeeIdError(true);
                        break;

                    default:
                        break;
                }

                showApiError(error.response, dispatch, t);
            })
            .then(() => {
                setIsButtonClickable(true);
            });
    };

    const renderedSubmitButton = (callback) => (
        <div className='buttons'>
            {/* Cancel */}
            <Button
                type={ButtonType.secondary}
                onClick={() => {
                    callback();
                    // setFieldValues(fieldValuesInit);
                    window.scrollTo(0, 0);
                    setIsButtonClickable(true);
                }}
            >
                {t('fleetData:button_hide_form')}
            </Button>
            {/* Submit */}
            <Button buttonType='submit' disabled={!isButtonClickable}>
                {t('common:buttons.submit')}
            </Button>
        </div>
    );
    const renderedFormToggleButton = () => (
        <div className={`buttons`}>
            <Button
                buttonType='button'
                onClick={() => {
                    setIsFormVisible(!isFormVisible);
                    window.scrollTo(0, 0);
                    fleetData.vehicleRegistrationNumber ? setIsEULicensePlate(false) : setIsEULicensePlate(true);
                }}
            >
                {isFormVisible ? t('fleetData:button_hide_form') : t('fleetData:button_show_form')}
            </Button>
        </div>
    );

    const onSwitchClicked = () => {
        setIsEULicensePlate(!isEULicensePlate);
    };

    return (
        <Page
            pageTheme={PageTheme.light}
            title={t('fleetData:title')}
            intro={!fleetData.employeeCostUnit ? t('fleetData:no_history') : t('fleetData:with_history')}
        >
            <div className={`2xl:w-9/12 ${styles.fleetData}`}>
                <InnerSpinner isActive={!isDataFetched}>
                    {isFormVisible ? (
                        <DisplayCard
                            type={DisplayCardTypes.PLAIN}
                            label={t('fleetData:form_title')}
                            intro={t('fleetData:form_intro')}
                        >
                            <form onSubmit={handleSubmit}>
                                <>
                                    <div>
                                        <div className='input-row mb__xl'>
                                            <Input
                                                label={t('common:labels.employeeCostUnit')}
                                                value={fieldValues.employeeCostUnit}
                                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                    setFieldValues({ employeeCostUnit: e.currentTarget.value })
                                                }
                                                // hasValidationError={
                                                //     isCostUnitError ||
                                                //     hasValidationError(
                                                //         ruleNames.fleetData.employeeCostUnit,
                                                //         validationErrors
                                                //     )
                                                // }
                                                // validationErrorLabel={validationErrorTranslation(
                                                //     ruleNames.fleetData.employeeCostUnit,
                                                //     validationErrors,
                                                //     t
                                                // )}
                                                placeholder={t('common:placeholder.employeeCostUnit')}
                                                containerClass='input__container--6-el input__container--6-el-row'
                                            />
                                            <Input
                                                label={t('common:labels.employeeId')}
                                                value={fieldValues.employeeId}
                                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                    setFieldValues({ employeeId: e.currentTarget.value })
                                                }
                                                // hasValidationError={
                                                //     isEmployeeIdError ||
                                                //     hasValidationError(ruleNames.fleetData.employeeId, validationErrors)
                                                // }
                                                // validationErrorLabel={validationErrorTranslation(
                                                //     ruleNames.fleetData.employeeId,
                                                //     validationErrors,
                                                //     t
                                                // )}
                                                placeholder={t('common:placeholder.employeeId')}
                                                containerClass='input__container--6-el input__container--6-el-row'
                                            />
                                        </div>

                                        <div className='input-row mb__xl'>
                                            <div className='input__container--6-el input__container--6-el-row'>
                                                <div className={styles.switchContainer}>
                                                    <Label>{t('fleetData:license_plate_check')}</Label>
                                                    <Switch
                                                        className=''
                                                        checked={isEULicensePlate}
                                                        onChange={onSwitchClicked}
                                                        switchInnerText={t('fleetData:license_plate_check_boolean')}
                                                        toggleButtonClassName={''}
                                                    />
                                                </div>
                                            </div>
                                            {isEULicensePlate ? (
                                                <div
                                                    className={`${styles.licenseplateContainer} input__container--6-el input__container--6-el-row`}
                                                >
                                                    <div>
                                                        <Label
                                                            type={
                                                                isAnyPartOfRegistrationNumberInvalid
                                                                    ? 'error'
                                                                    : 'default'
                                                            }
                                                            errorLabel={
                                                                isAnyPartOfRegistrationNumberInvalid
                                                                    ? t(
                                                                          'common:validation.vehicleRegistrationNumber_required'
                                                                      )
                                                                    : ''
                                                            }
                                                        >
                                                            {t('common:labels.vehicleRegistrationNumber')}
                                                        </Label>
                                                    </div>
                                                    <div className={`${styles.licenseplate} mb__sm`}>
                                                        <Input
                                                            label={t('common:labels.vehicleRegistrationNumberPart1')}
                                                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                                setFieldValues({
                                                                    vehicleRegistrationNumberPart1:
                                                                        e.currentTarget.value
                                                                })
                                                            }
                                                            hasValidationError={hasValidationError(
                                                                ruleNames.fleetData.vehicleRegistrationNumberPart1,
                                                                validationErrors
                                                            )}
                                                            validationErrorLabel={validationErrorTranslation(
                                                                ruleNames.fleetData.vehicleRegistrationNumberPart1,
                                                                validationErrors,
                                                                t
                                                            )}
                                                            placeholder={t(
                                                                'common:placeholder.vehicleRegistrationNumberPart1'
                                                            )}
                                                            containerClass={`${styles.inputContainer1}`}
                                                        />
                                                        <Input
                                                            label={t('common:labels.vehicleRegistrationNumberPart2')}
                                                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                                setFieldValues({
                                                                    vehicleRegistrationNumberPart2:
                                                                        e.currentTarget.value
                                                                })
                                                            }
                                                            hasValidationError={hasValidationError(
                                                                ruleNames.fleetData.vehicleRegistrationNumberPart2,
                                                                validationErrors
                                                            )}
                                                            validationErrorLabel={validationErrorTranslation(
                                                                ruleNames.fleetData.vehicleRegistrationNumberPart2,
                                                                validationErrors,
                                                                t
                                                            )}
                                                            placeholder={t(
                                                                'common:placeholder.vehicleRegistrationNumberPart2'
                                                            )}
                                                            containerClass={`${styles.inputContainer2}`}
                                                        />
                                                        <Input
                                                            label={t('common:labels.vehicleRegistrationNumberPart3')}
                                                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                                setFieldValues({
                                                                    vehicleRegistrationNumberPart3:
                                                                        e.currentTarget.value
                                                                })
                                                            }
                                                            hasValidationError={hasValidationError(
                                                                ruleNames.fleetData.vehicleRegistrationNumberPart3,
                                                                validationErrors
                                                            )}
                                                            validationErrorLabel={validationErrorTranslation(
                                                                ruleNames.fleetData.vehicleRegistrationNumberPart3,
                                                                validationErrors,
                                                                t
                                                            )}
                                                            placeholder={t(
                                                                'common:placeholder.vehicleRegistrationNumberPart3'
                                                            )}
                                                            containerClass={`${styles.inputContainer3}`}
                                                        />
                                                        <Input
                                                            label={t('common:labels.vehicleRegistrationNumberPart4')}
                                                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                                setFieldValues({
                                                                    vehicleRegistrationNumberPart4:
                                                                        e.currentTarget.value
                                                                })
                                                            }
                                                            hasValidationError={hasValidationError(
                                                                ruleNames.fleetData.vehicleRegistrationNumberPart4,
                                                                validationErrors
                                                            )}
                                                            validationErrorLabel={validationErrorTranslation(
                                                                ruleNames.fleetData.vehicleRegistrationNumberPart4,
                                                                validationErrors,
                                                                t
                                                            )}
                                                            placeholder={t(
                                                                'common:placeholder.vehicleRegistrationNumberPart4'
                                                            )}
                                                            containerClass={`${styles.inputContainer4}`}
                                                        />
                                                    </div>
                                                    {isAnyPartOfRegistrationNumberInvalid && (
                                                        <>
                                                            <p>{t('fleetData:license_plate_tooltip_intro')}</p>

                                                            <div className={styles.licensePlateExampleImage}>
                                                                <img src={LicensePlateExampleImage} alt='' />
                                                            </div>
                                                            <p>
                                                                <span className={styles.number}>1</span>
                                                                {t('fleetData:license_plate_part_1')}
                                                            </p>
                                                            <p>
                                                                <span className={styles.number}>2</span>
                                                                {t('fleetData:license_plate_part_2')}
                                                            </p>
                                                            <p>
                                                                <span className={styles.number}>3</span>
                                                                {t('fleetData:license_plate_part_3')}
                                                            </p>
                                                            <p>
                                                                <span className={styles.number}>4</span>
                                                                {t('fleetData:license_plate_part_4')}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <Input
                                                    label={t('common:labels.vehicleRegistrationNumber')}
                                                    value={fieldValues.vehicleRegistrationNumber}
                                                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                        setFieldValues({
                                                            vehicleRegistrationNumber: e.currentTarget.value
                                                        })
                                                    }
                                                    hasValidationError={hasValidationError(
                                                        ruleNames.fleetData.vehicleRegistrationNumber,
                                                        validationErrors
                                                    )}
                                                    validationErrorLabel={validationErrorTranslation(
                                                        ruleNames.fleetData.vehicleRegistrationNumber,
                                                        validationErrors,
                                                        t
                                                    )}
                                                    placeholder={t('common:placeholder.vehicleRegistrationNumber')}
                                                    containerClass='input__container--6-el input__container--6-el-row'
                                                />
                                            )}
                                        </div>

                                        <Checkbox
                                            id='renewableEnergy'
                                            className='mb__xs'
                                            checked={fieldValues.renewableEnergy ? true : false}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                setFieldValues({ renewableEnergy: !fieldValues.renewableEnergy });
                                            }}
                                            label={t('common:placeholder.renewableEnergy')}
                                        />
                                    </div>
                                    {renderedSubmitButton(() => {
                                        setIsFormVisible(!isFormVisible);
                                        getFleetData();
                                    })}
                                </>
                            </form>
                        </DisplayCard>
                    ) : (
                        <DisplayCard type={DisplayCardTypes.PLAIN} className='xl:w-8/12'>
                            <h3 className='mb-5 text-fg_primary'>{t('fleetData:card_title')}</h3>

                            <div className={`${styles.itemBox}`}>
                                <div className='flex justify-between mb-2 gap-2'>
                                    <div className={styles.itemName}>{t('common:labels.employeeCostUnit')}</div>
                                    <div>{fleetData.employeeCostUnit}</div>
                                </div>
                                <div className='flex justify-between mb-2 gap-2'>
                                    <div className={styles.itemName}>{t('common:labels.employeeId')}</div>
                                    <div>{fleetData.employeeId}</div>
                                </div>
                                <div className='flex justify-between mb-2 gap-2'>
                                    <div className={styles.itemName}>
                                        {t('common:labels.vehicleRegistrationNumber')}
                                    </div>
                                    <div>{fleetData.vehicleRegistrationNumber}</div>
                                </div>
                                <div className='flex justify-between mb-2 gap-2'>
                                    <div className={styles.itemName}>{t('fleetData:show_data_renewable_energy')}</div>
                                    <div>
                                        {fleetData.renewableEnergy ? (
                                            <img width='25px' style={{ verticalAlign: 'middle' }} src={tick} alt='' />
                                        ) : (
                                            <img width='25px' style={{ verticalAlign: 'middle' }} src={close} alt='' />
                                        )}
                                    </div>
                                </div>
                            </div>

                            {renderedFormToggleButton()}
                        </DisplayCard>
                    )}
                </InnerSpinner>
            </div>
        </Page>
    );
}

export default FleetData;
