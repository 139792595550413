import moment from "moment";
import businessActions, { setInstallationDetailStepCompleted, setPersonalDetailStepCompleted } from "store/business";
import checkoutActions from "store/checkout";
import {
    getValidationRules,
    ruleNames,
    validationService
} from "../validation";

export const updateBusinessPersonalInfo = (attribute, value, dispatch) => {
    dispatch(businessActions.setPersonalDetailStepCompleted(false));
    dispatch(businessActions.setInstallationDetailStepCompleted(false));
    dispatch(businessActions.setOrderConfirmationStepCompleted(false));

    updateInfoState(attribute, value, dispatch);
};

export const updateBusinessInstallationData = (attribute, value, dispatch) => {
    dispatch(businessActions.setInstallationDetailStepCompleted(false));
    dispatch(businessActions.setOrderConfirmationStepCompleted(false));

    updateInfoState(attribute, value, dispatch);
};

export const updateInfoState = (attribute, value, dispatch) => {
    dispatch(checkoutActions.updateCheckoutInfo(attribute, value));
};

export const revalidateSteps = (
    infoState,
    dispatch,
    skipPasswords,
    isLogged
) => {
    const personalInfoDataValidation = validationForPersonalInfoStep(
        getPersonalInfoApiData(infoState),
        skipPasswords,
        isLogged,
        true
    );
    const installationInfoData = validationForInstallationInfoStep(
        getInstallationApiData(infoState)
    );

    if (personalInfoDataValidation.passes()) {
        dispatch(setPersonalDetailStepCompleted(true));
    }

    if(installationInfoData.passes()) {
        dispatch(setInstallationDetailStepCompleted(true));
    }
};

export const getInstallationApiData = (infoState) => {
	return {
		installationAddress: {
			city: infoState.city,
			country: infoState.country,
			postalCode: infoState.postal_code,
			street: infoState.street_address,
			streetNumber: infoState.street_no,
		},
		installationMessage: infoState.message_to_installer,
		preferredInstallationDate: moment(infoState.installation_date).format('YYYY-MM-DD'),
		preferredContactMethod: infoState.contact_method ? infoState.contact_method.value : null,
	};
}

export const validationForInstallationInfoStep = (params) => {
    let rules = getValidationRules([ruleNames.streetAddress, ruleNames.streetNo, ruleNames.postalCode, ruleNames.city, ruleNames.country, ruleNames.installationDate, ruleNames.contactMethod, ruleNames.message]);
    const validation = validationService(params, rules);
    return validation;
}


export const getPersonalInfoApiData = (infoState) => {
	const dateOfBirth = infoState.birthday_year && infoState.birthday_month && infoState.birthday_day ?
		`${infoState.birthday_year}-${infoState.birthday_month.padStart(2, '0')}-${infoState.birthday_day.padStart(2, '0')}`
		: false;

    return {
        email: infoState.email,
        password: infoState.password,
        passwordConfirmation: infoState.password_confirmation,
        firstName: infoState.first_name,
        lastName: infoState.last_name,
        name: infoState.first_name,
        surname: infoState.last_name,
        phone: infoState.phone_no,
        dateOfBirth: dateOfBirth,
        vatNumber: infoState.vat_number,
        termsAccepted: infoState.terms_accepted_account,
        termsAcceptedNissan: infoState.terms_accepted_account_secondary,
        isNewsletterSubscribed: infoState.receive_info,
        salutation: infoState.salutation.value
        // language: infoState.language.value,
    };
};

export const validationForPersonalInfoStep = (params, skipPasswords, isLogged, isB2B2E) => {
    let rulesArray = [
        ruleNames.firstName,
        ruleNames.lastName,
        ruleNames.email,
        ruleNames.phoneNo,
        ruleNames.birthday,
        ruleNames.salutation,
        ruleNames.language
    ];

    // If the user is already logged in, then we do not need to add the password to the validation.
    if (!isLogged && !skipPasswords) {
        rulesArray.push(ruleNames.createPassword);
        rulesArray.push(ruleNames.passwordConfirmation);
        if (!isB2B2E) {
            rulesArray.push(ruleNames.termsAcceptedAccount);
        }
    }

    let rules = getValidationRules(rulesArray);

    return validationService(params, rules);
};

export const getEmployeeConfirmationApiData = (infoState) => {
    return {
        account: getPersonalInfoApiData(infoState),
        installation: getInstallationApiData(infoState),
        code: infoState.company_code
    };
};
