import React, { useState, useEffect, useRef } from "react";

type Props = {
    src?: string;
    label?: string;
    type?: "normal" | "icon";
    header: React.ReactNode;
    content: React.ReactNode;
    headerClass?: string;
    open?: boolean;
};

function Expander(props: Props) {
    const { header = "", content = "", headerClass = "", open = false } = props;

    const [height, setHeight] = useState(0);
    const [showContent, setShowContent] = useState(open);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleExpander = (showContent) => {
        if (contentRef && contentRef.current) {
            if (showContent) {
                setHeight(contentRef.current.clientHeight);
            } else {
                setHeight(0);
            }
            setShowContent(showContent);
        }
    };

    useEffect(() => {
        toggleExpander(open);
    }, [open]);

    return (
        <div className={`expander ${headerClass}`}>
            <div
                className="expander__header"
                onClick={() => toggleExpander(!showContent)}
            >
                {header}
            </div>
            <div className="expander__content" style={{ maxHeight: height }}>
                <div className="expander__content__inner" ref={contentRef}>
                    {content}
                </div>
            </div>
        </div>
    );
}

// Expander.propTypes = {
//     src: PropTypes.string,
//     label: PropTypes.string,
//     type: PropTypes.oneOf(['normal', 'icon'])
// };

export default Expander;
