import React from "react";
import Constants from "../constants";
import { StatusColors } from "../componentData/installation_status";
import { Icon } from ".";
import { iconTypes } from "./Icon";

const installationEventStatus = Constants.INSTALLATION_PROGRESS.STATUS;

type Props = {
    eventStatus: string;
    category: string;
    status: string;
    description: string;
    eventDetail: string | null;
    nextStepAvailable: boolean;
    isCurrentActiveStep: boolean;
    className: string;
    color: string;
};

function InstallationProgressStep(props: Props) {
    const {
        eventStatus,
        category,
        status,
        description,
        eventDetail,
        nextStepAvailable,
        isCurrentActiveStep,
        className,
        color
    } = props;

    const statusToClassMap = {
        [installationEventStatus.NOT_PLANNED]: "--not-reached",
        [installationEventStatus.PLANNED]: "--upcoming",
        [installationEventStatus.COMPLETED]: "--completed",
        [installationEventStatus.CANCELLED]: "--not-reached"
    };

    const getClassAddition = () => {
        if (
            isCurrentActiveStep &&
            eventStatus === installationEventStatus.NOT_PLANNED
        )
            return "--upcoming";
        else {
            return statusToClassMap[eventStatus];
        }
    };

    const getColorClassAddition = () => {
        switch (color) {
            case StatusColors.RED:
            case StatusColors.YELLOW:
                return color;
            default:
                return getClassAddition();
        }
    };

    const getIcon = () => {
        switch (getColorClassAddition()) {
            case StatusColors.RED:
                return <Icon size={24} name={iconTypes.x}/>;
            case StatusColors.YELLOW:
                return <Icon size={24} name={iconTypes.exclamationMark}/>;
            case statusToClassMap[installationEventStatus.COMPLETED]:
                return <Icon size={24} name={iconTypes.check} />;
            default:
                return "";
        }
    };

    const classAddition = getClassAddition();
    const colorClassAddition = getColorClassAddition();
    const iconClass =
        className +
        "__progress-step__icon " +
        className +
        "__progress-step__icon" +
        colorClassAddition;
    const lineClass =
        className +
        "__progress-step__line " +
        className +
        "__progress-step__line" +
        colorClassAddition;
    const titleClass =
        className +
        "__progress-step__title " +
        className +
        "__progress-step__title" +
        classAddition +
        " mb__xxs";
    const textClass =
        className +
        "__progress-step__text " +
        className +
        "__progress-step__text" +
        classAddition;
    const subtextClass =
        className +
        "__progress-step__subtext " +
        className +
        "__progress-step__subtext" +
        classAddition;

    return (
        <div className={`${className + "__progress-step"}`}>
            <div className={iconClass}>{getIcon()}</div>
            {nextStepAvailable && <div className={lineClass}></div>}
            <div>
                <div className={titleClass}>{category}</div>
                {status && (
                    <div
                        className={`${
                            className + "__progress-step__subheader mb__xxs"
                        }`}
                    >
                        {status}
                    </div>
                )}
                {description && (
                    <p
                        className={`${textClass} mb__xxs`}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                )}
                {eventDetail && <p className={subtextClass}>{eventDetail}</p>}
            </div>
        </div>
    );
}

export default InstallationProgressStep;
