import React, { MouseEventHandler } from 'react';
import { ButtonType } from '../typeScript/types/ButtonTypes';

export interface ButtonProps {
    className?: string;
    disabled?: boolean;
    dataSet?: string;
    type?: ButtonType;
    onClick?: MouseEventHandler;
    loading?: boolean;
    loadingText?: string;
    buttonType?: 'submit' | 'button' | 'reset';
    children?: React.ReactNode;
}

function Button(props: ButtonProps) {
    const {
        className = '',
        disabled = false,
        dataSet = '',
        type = ButtonType.primary,
        onClick = () => {},
        loading = false,
        loadingText = '',
        buttonType = 'button',
        ...restprops
    } = props;

    const buttonClass = () => {
        let resultClass = 'button button--' + type;
        resultClass += className ? ' ' + className : '';
        resultClass += disabled || loading ? ' button--disabled' : '';
        return resultClass;
    };

    return (
        <button
            {...restprops}
            type={buttonType}
            className={buttonClass()}
            disabled={disabled}
            data-answer={dataSet ? dataSet : ''}
            onClick={onClick}
        >
            {loading && loadingText ? loadingText : props.children}
        </button>
    );
}

export default Button;
