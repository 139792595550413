import {
  FleetDataErrorCode
} from './../../_generated/openapi';

export default {
  USER_NOT_FOUND: "The link is broken or the user does not exist.",
  USER_DOES_NOT_HAVE_CHARGER: "User hasn't any charger",
  WRONG_REQUEST_PARAMS: "Wrong request params was send",
  PAYOUT_NEW_TARIFF_START_DATE_SHOULD_BE_AFTER_THE_EFFECTIVE_ONE: "Payout new tariff start date should be after the effective one",

  IBAN_VALIDATION_FAILED: "Please check your bank details",

  [FleetDataErrorCode.EmployeeCostUnitIsNull]: 'Please check your cost unit',
  [FleetDataErrorCode.EmployeeCostUnitValidationError]: 'Please check your cost unit',
  [FleetDataErrorCode.EmployeeIdIsNull]: 'Please check your employer id',
  [FleetDataErrorCode.EmployeeIdValidationError]: 'Please check your employer id',
  [FleetDataErrorCode.RenewableEnergyIsNull]: 'Please check the renewable energy',
  [FleetDataErrorCode.RenewableEnergyValidationError]: 'Please check the renewable energy',
  [FleetDataErrorCode.VehicleRegistrationNumberIstNull]: 'Please check your vehicle registration number',
  [FleetDataErrorCode.VehicleRegistrationNumberValidationError]: 'Please check your vehicle registration number',
}
