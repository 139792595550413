import React, { MouseEventHandler } from 'react';
// import './DisplayCard.scss';
import Icon from './Icon';
export interface DisplayCardProps {
    src?: string;
    label?: string;
    intro?: string;
    type: DisplayCardTypes;
    className?: string;
    children: React.ReactNode;
    onClick?: MouseEventHandler;
}

export enum DisplayCardTypes {
    TRANSPARENT_REDUCED,
    PLAIN,
    PLAIN_REDUCED,
    IMAGE_LEFT,
    IMAGE_TOP,
    ICON_LEFT,
    ICON_TOP,
    HIGHLIGHT
}

function DisplayCard(props: DisplayCardProps) {
    const { src, label, intro, type = DisplayCardTypes.PLAIN, className = '', onClick } = props;

    const renderTextContent = () => {
        return (
            <>
                {label && <h3 className='mb-5 text-fg_primary' dangerouslySetInnerHTML={{ __html: label }}></h3>}
                {intro && <p className='mb-8 text-fg_primary' dangerouslySetInnerHTML={{ __html: intro }}></p>}
                <div>{props.children}</div>
            </>
        );
    };

    const gapNormal = 'px-5 py-5 xl:px-8 xl:py-12';
    const gapReduced = 'px-3 py-2';

    return (
        <div
            onClick={onClick}
            className={`displayCard w-full rounded ${className} ${
                type === DisplayCardTypes.HIGHLIGHT ? 'highlighted' : ''
            } ${
                !src
                    ? type === DisplayCardTypes.PLAIN_REDUCED || type === DisplayCardTypes.TRANSPARENT_REDUCED
                        ? gapReduced
                        : gapNormal
                    : ''
            } ${onClick ? 'hover-effect' : ''}`}
        >
            {type !== DisplayCardTypes.PLAIN &&
            type !== DisplayCardTypes.PLAIN_REDUCED &&
            type !== DisplayCardTypes.TRANSPARENT_REDUCED &&
            type !== DisplayCardTypes.HIGHLIGHT &&
            !src ? (
                <div>ERROR: Wrong 'type' or missing 'src'! + {type}</div>
            ) : (
                <>
                    {/* IMAGE / ICON ON LEFT */}
                    {(type === DisplayCardTypes.IMAGE_LEFT || type === DisplayCardTypes.ICON_LEFT) && (
                        <>
                            <div
                                className={`display-card__left ${
                                    type === DisplayCardTypes.ICON_LEFT ? 'display-card__left--gradient' : ''
                                }`}
                            >
                                {src && type === DisplayCardTypes.IMAGE_LEFT && (
                                    <img className='display-card__image' src={src} alt='' />
                                )}
                                {src && type === DisplayCardTypes.ICON_LEFT && (
                                    <Icon className='display-card__icon' name={src} size={80}></Icon>
                                )}
                            </div>
                            <div className='display-card__right'>{renderTextContent()}</div>
                        </>
                    )}

                    {/* IMAGE / ICON ON TOP */}
                    {(type === DisplayCardTypes.IMAGE_TOP || type === DisplayCardTypes.ICON_TOP) && (
                        <div className='w-full'>
                            <div
                                className={`text-center border-b border-solid border-grey-border ${
                                    type === DisplayCardTypes.ICON_TOP ? 'display-card__left--gradient' : ''
                                }`}
                            >
                                {src && type === DisplayCardTypes.IMAGE_TOP && (
                                    <img className='max-w-1/2 w-full' src={src} alt='' />
                                )}
                                {src && type === DisplayCardTypes.ICON_TOP && (
                                    <Icon className='display-card__icon' name={src} size={80}></Icon>
                                )}
                            </div>
                            <div className='p-5'>{renderTextContent()}</div>
                        </div>
                    )}

                    {/* PLAIN, No IMAGE / ICON, JUST TEXT */}
                    {(type === DisplayCardTypes.PLAIN ||
                        type === DisplayCardTypes.PLAIN_REDUCED ||
                        type === DisplayCardTypes.HIGHLIGHT ||
                        type === DisplayCardTypes.TRANSPARENT_REDUCED) && (
                        <div className='w-full'>{renderTextContent()}</div>
                    )}
                </>
            )}
        </div>
    );
}

export default DisplayCard;
