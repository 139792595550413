export default {
    intro: {
        title: "Willkommen beim Foto-Upload",
        text: "Hier können Sie Bilder des Installationsorts, der vorhandenen Infrastruktur oder des Zählerschranks hochladen und so unsere Elektriker bei der Vorbereitung unterstützen.",
        button: "Los geht’s"
    },
    photo_upload: {
        title: 'Fotos hochladen',
        text: 'Mit Ihren Fotos können sich unsere Elektriker ein Bild machen',
        // house_connection: 'Foto Ihres Hausanschlusses',
        // meter_location: 'Foto von Ihrem Zählerstandort',
        // meter_fuse: 'Foto von Ihrer Zählersicherung',
        // charging_location: 'Foto von Ihrem geplanten Ladeort',
        extra_image: 'Extra-Bild #{{number}}',
        distribution_board: 'Verteilerkasten',
        distribution_board_fuse: 'Verteilerkasten Sicherung',
        garage_inside: 'Garage Innen',
        garage_outside: 'Garage außen',
        distance_house_garage: 'Entfernung Haus/Garage'
    },
    end: {
        title: "Großartig, {{name}}",
        text: "Das wars! Die Bilder werden auf dem schnellsten Weg an den zuständigen Elektriker weitergeleiten. Vielen Dank!",
    }
}
