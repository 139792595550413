import React from 'react';
import { useTranslation } from 'react-i18next';
const ChargerImageEnabled = require('../../assets/Charger-Auth-Icon-Enabled.svg');
const ChargerImageDisabled = require('../../assets/Charger-Auth-Icon-Disabled.svg');

type Props = {
    authMode: boolean;
};

const ChargerAuthIcon = (props: Props) => {
    const { authMode } = props;
    const { t } = useTranslation(['charger']);

    return (
        <span
            className={`charger-card__auth-mode__status text-sm ${
                authMode ? 'charger-card__auth-mode__status__enabled' : 'charger-card__auth-mode__status__disabled'
            }`}
        >
            {authMode ? <img src={ChargerImageEnabled} alt=''></img> : <img src={ChargerImageDisabled} alt=''></img>}

            <span>{authMode ? t('charger_auth.activated') : t('charger_auth.disabled')}</span>
        </span>
    );
};

export default ChargerAuthIcon;
