export const AppStoreImage = `${process.env.REACT_APP_BLOB_ASSET_URL}AppStore.png`;

export const CarChargingImage = `${process.env.REACT_APP_BLOB_ASSET_URL}CarCharging.jpg`;
export const ChargerStepImage = `${process.env.REACT_APP_BLOB_ASSET_URL}ChargerStep.jpg`;
export const GarageImage = `${process.env.REACT_APP_BLOB_ASSET_URL}Garage.jpg`;
export const GarageCarsImage = `${process.env.REACT_APP_BLOB_ASSET_URL}GarageCars.jpg`;
export const GooglePlayImage = `${process.env.REACT_APP_BLOB_ASSET_URL}GooglePlay.png`;
export const HomeGarageImage = `${process.env.REACT_APP_BLOB_ASSET_URL}HomeGarage.jpg`;
export const InstallerDoneImage = `${process.env.REACT_APP_BLOB_ASSET_URL}InstallerDone.jpg`;
// export const MobileAppImage = `${process.env.REACT_APP_BLOB_ASSET_URL}MobileApp.jpg`; This will be replaced as soon as we get the this access : https://evchargeeu2.blob.core.windows.net/spa-dev/assets/MobileApp.jpg
export const MobileAppImage = require('../assets/images/MobileAppUnivers.jpg');
export const HeaderHomeImage = `${process.env.REACT_APP_BLOB_ASSET_URL}HeaderHome.png`;
export const HeaderHomeMobileImage = `${process.env.REACT_APP_BLOB_ASSET_URL}HeaderHomeMobile.png`;

// AKTUELL::: no AZURE-CLOUD - Fallback for images
// export const HeaderHomeImage_nissan = `${process.env.REACT_APP_BLOB_ASSET_URL}HeaderHome_nissan.jpg`;
// export const HeaderHomeMobileImage_nissan = `${process.env.REACT_APP_BLOB_ASSET_URL}HeaderHomeMobile_nissan.jpg`;
// export const HeaderCheckoutImage_nissan = `${process.env.REACT_APP_BLOB_ASSET_URL}HeaderCheckout_nissan.jpg`;
// export const HeaderCheckoutMobileImage_nissan = `${process.env.REACT_APP_BLOB_ASSET_URL}HeaderCheckoutMobile_nissan.jpg`;
export const HeaderHomeImage_nissan = require('../assets/images/HeaderHome_nissan.jpg');
export const HeaderHomeMobileImage_nissan = require('../assets/images/HeaderHomeMobile_nissan.jpg');
export const HeaderCheckoutImage_nissan = require('../assets/images/HeaderCheckout_nissan.jpg');
export const HeaderCheckoutMobileImage_nissan = require('../assets/images/HeaderCheckoutMobile_nissan.jpg');

export const GridChargerIcon = `${process.env.REACT_APP_BLOB_ASSET_URL}GridCharger.png`;

export const IMAGES = [
    ChargerStepImage,
    GarageCarsImage,
    InstallerDoneImage,
    GarageImage,
    MobileAppImage,
    CarChargingImage,
    HomeGarageImage,
    AppStoreImage,
    GooglePlayImage,
    HeaderHomeImage,
    HeaderHomeMobileImage,
    GridChargerIcon,
    HeaderHomeImage_nissan,
    HeaderHomeMobileImage_nissan,
    HeaderCheckoutImage_nissan,
    HeaderCheckoutMobileImage_nissan
];
