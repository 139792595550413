import { TYPE_SUCCESS } from 'components/Toast';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openToast } from 'store/toast';
import { Button, DatePicker, Dropdown, Input } from '.';
import api from '../api/api';
import InputType from '../constants/inputType';
import { IContractTerminationUserInfo } from '../typeScript/interfaces/IContractTerminationUserInfo';
import { getContractTerminationApiData, validationForContractTermination } from '../utils/contractTermination';
import { showApiError } from '../utils/error-handler';
import { hasValidationError, ruleNames, validationErrorTranslation } from '../validation';
import { ButtonType } from 'typeScript/types/ButtonTypes';

export enum TerminationType {
    ORDINARY = 'ordinary termination',
    EXTRA_ORDINARY = 'extraordinary termination'
}
export enum TerminationDateDecision {
    IMMEDIATELY = 'immediately',
    SELECTED_DATE = 'selected_date'
}

export interface ContractTerminationFormProps {
    setShowContractTerminationForm: any;
    setIsContractTerminationFormSubmitted: any;
}

function ContractTerminationForm(props: ContractTerminationFormProps) {
    const { t } = useTranslation();
    const [validationErrors, setValidationErrors] = useState<any[]>([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const dispatch = useDispatch();

    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [userInfo, setUserInfo] = useState<IContractTerminationUserInfo>({
        terminationType: { label: '', value: '' },
        terminationReason: '',
        customerNumber: '',
        contractNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        terminationDateDecision: { label: '', value: '' },
        terminationDate: tomorrow,
        comment: ''
    });
    // const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);

    // useEffect(() => {
    //     if (isLogged) {
    //         loadUserInfoData();
    //     }
    // }, [isLogged]);

    const onInputChange = (field, value) => {
        setUserInfo((state) => {
            return {
                ...state,
                [field]: value
            };
        });
    };

    // const loadUserInfoData = () => {
    //     api.user
    //         .getAccount()
    //         .then((response) => {
    //             setUserInfo({
    //                 firstName: response.data.name,
    //                 lastName: response.data.surname,
    //                 email: response.data.email
    //             });
    //         })
    //         .catch(() => {});
    // };

    const handleContractTerminationForm = (event) => {
        if (event) {
            event.preventDefault();
        }

        const params = {
            termination_type: userInfo.terminationType.value,
            termination_reason:
                userInfo.terminationType.value === TerminationType.ORDINARY ? 'null' : userInfo.terminationReason,
            customer_number: userInfo.customerNumber,
            contract_number: userInfo.contractNumber,
            email: userInfo.email,
            name: userInfo.firstName,
            surname: userInfo.lastName,
            termination_date_decision:
                userInfo.terminationType.value === TerminationType.EXTRA_ORDINARY
                    ? TerminationDateDecision.IMMEDIATELY
                    : userInfo.terminationDateDecision.value,
            terminationDate:
                userInfo.terminationType.value === TerminationType.EXTRA_ORDINARY
                    ? new Date()
                    : userInfo.terminationDateDecision.value === TerminationDateDecision.SELECTED_DATE
                    ? moment(userInfo.terminationDate).format('YYYY-MM-DD')
                    : new Date()
        };

        let validation = validationForContractTermination(params);

        // console.warn(111111, validation.getErrors());

        if (validation.passes()) {
            setValidationErrors([]);
            setButtonDisabled(true);
            const params = getContractTerminationApiData(userInfo);
            api.contactForm
                .cancellation(params)
                .then((res) => {
                    dispatch(openToast(TYPE_SUCCESS, t('toast:contract_termination_200')));
                    setButtonDisabled(false);
                    props.setIsContractTerminationFormSubmitted(true);
                })
                .catch((error) => {
                    showApiError(error.response, dispatch, t);
                    setButtonDisabled(false);
                });
        } else {
            const errors: object[] = validation.getErrors();
            setValidationErrors(errors);
        }
    };

    const MIN_INSTALLATION_DAYS = 1;

    const minTerminationDate = (date) => {
        return date < moment().add(MIN_INSTALLATION_DAYS, 'days');
    };

    return (
        <>
            <form onSubmit={handleContractTerminationForm} noValidate>
                <div className='mb-4'>
                    <Dropdown
                        id='termination_type'
                        required
                        hasValidationError={hasValidationError(ruleNames.terminationType, validationErrors)}
                        validationErrorLabel={validationErrorTranslation(
                            ruleNames.terminationType,
                            validationErrors,
                            t
                        )}
                        label={t('common:labels.termination_type')}
                        options={[
                            {
                                value: TerminationType.ORDINARY,
                                label: t('common:labels.termination_type_ordinary')
                            },
                            {
                                value: TerminationType.EXTRA_ORDINARY,
                                label: t('common:labels.termination_type_extra_ordinary')
                            }
                        ]}
                        placeholder={t('common:placeholder.termination_type')}
                        selected={userInfo.terminationType}
                        onSelect={(selected) => {
                            onInputChange('terminationType', selected);
                        }}
                    />
                </div>
                {userInfo.terminationType.value === TerminationType.EXTRA_ORDINARY && (
                    <div className='grid grid-cols-8 gap-4 mb-4'>
                        <Input
                            id='termination_reason'
                            label={t('common:labels.termination_reason')}
                            value={userInfo.terminationReason}
                            mandatory
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('terminationReason', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.terminationReason, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(
                                ruleNames.terminationReason,
                                validationErrors,
                                t
                            )}
                            placeholder={t('common:placeholder.termination_reason')}
                            containerClass='md:col-span-8 col-span-8'
                            multiline
                        />
                    </div>
                )}
                <div className='grid grid-cols-8 gap-4 mb-4'>
                    <Input
                        id='customer_number'
                        label={t('common:labels.customer_number')}
                        value={userInfo.customerNumber}
                        mandatory
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            onInputChange('customerNumber', e.currentTarget.value)
                        }
                        hasValidationError={hasValidationError(ruleNames.customerNumber, validationErrors)}
                        validationErrorLabel={validationErrorTranslation(ruleNames.customerNumber, validationErrors, t)}
                        placeholder={t('common:placeholder.customer_number')}
                        containerClass='md:col-span-4 col-span-8'
                    />
                    <Input
                        id='contract_number'
                        label={t('common:labels.contract_number')}
                        value={userInfo.contractNumber}
                        mandatory
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            onInputChange('contractNumber', e.currentTarget.value)
                        }
                        hasValidationError={hasValidationError(ruleNames.contractNumber, validationErrors)}
                        validationErrorLabel={validationErrorTranslation(ruleNames.contractNumber, validationErrors, t)}
                        placeholder={t('common:placeholder.contract_number')}
                        containerClass='md:col-span-4 col-span-8'
                    />
                </div>
                <div className='grid grid-cols-8 gap-4 mb-4'>
                    <Input
                        id='first_name'
                        label={t('common:labels.first_name')}
                        value={userInfo.firstName}
                        autoComplete='given-name'
                        mandatory
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            onInputChange('firstName', e.currentTarget.value)
                        }
                        hasValidationError={hasValidationError(ruleNames.firstName, validationErrors)}
                        validationErrorLabel={validationErrorTranslation(ruleNames.firstName, validationErrors, t)}
                        placeholder={t('common:placeholder.first_name')}
                        containerClass='md:col-span-4 col-span-8'
                    />
                    <Input
                        id='last_name'
                        label={t('common:labels.last_name')}
                        autoComplete='family-name'
                        value={userInfo.lastName}
                        mandatory
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            onInputChange('lastName', e.currentTarget.value)
                        }
                        hasValidationError={hasValidationError(ruleNames.lastName, validationErrors)}
                        validationErrorLabel={validationErrorTranslation(ruleNames.lastName, validationErrors, t)}
                        placeholder={t('common:placeholder.last_name')}
                        containerClass='md:col-span-4 col-span-8'
                    />
                </div>

                <div className='grid grid-cols-8 gap-4 mb-4'>
                    <div className='md:col-span-4 col-span-8'>
                        <Input
                            id='email'
                            label={t('common:labels.email')}
                            type={InputType.EMAIL}
                            mandatory
                            value={userInfo.email}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('email', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.email, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.email, validationErrors, t)}
                            placeholder={t('common:placeholder.email')}
                            containerClass='md:col-span-4 col-span-8'
                        />
                    </div>
                    <div className='md:col-span-4 col-span-8'>
                        {userInfo.terminationType.value !== TerminationType.EXTRA_ORDINARY && (
                            <Dropdown
                                id='termination_date_decision'
                                required
                                hasValidationError={hasValidationError(
                                    ruleNames.terminationDateDecision,
                                    validationErrors
                                )}
                                validationErrorLabel={validationErrorTranslation(
                                    ruleNames.terminationDateDecision,
                                    validationErrors,
                                    t
                                )}
                                label={t('common:labels.termination_date')}
                                options={[
                                    {
                                        value: TerminationDateDecision.IMMEDIATELY,
                                        label: t('common:labels.termination_date_decision_1')
                                    },
                                    {
                                        value: TerminationDateDecision.SELECTED_DATE,
                                        label: t('common:labels.termination_date_decision_2')
                                    }
                                ]}
                                placeholder={t('common:labels.termination_date')}
                                selected={userInfo.terminationDateDecision}
                                onSelect={(selected) => {
                                    onInputChange('terminationDateDecision', selected);
                                }}
                            />
                        )}

                        <br />
                        {userInfo.terminationDateDecision.value === TerminationDateDecision.SELECTED_DATE && (
                            <DatePicker
                                id='termination_date'
                                onChange={(selectedDate) =>
                                    setUserInfo((state) => {
                                        return {
                                            ...state,
                                            terminationDate: new Date(selectedDate)
                                        };
                                    })
                                }
                                hasValidationError={hasValidationError(ruleNames.terminationDate, validationErrors)}
                                validationErrorLabel={validationErrorTranslation(
                                    ruleNames.terminationDate,
                                    validationErrors,
                                    t
                                )}
                                value={userInfo.terminationDate.toString()}
                                isOutsideRange={minTerminationDate}
                                displayFormat={() => 'DD.MM.YYYY'}
                                placeholder={t('common:placeholder:termination_date')}
                                isWeekendAllowed
                                disabled={
                                    userInfo.terminationDateDecision.value !== TerminationDateDecision.SELECTED_DATE
                                }
                            />
                        )}
                    </div>
                </div>
                <div className='grid grid-cols-8 gap-4 mb-4'>
                    <Input
                        id='termination_comment'
                        label={t('common:labels.termination_comment')}
                        value={userInfo.comment}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            onInputChange('comment', e.currentTarget.value)
                        }
                        placeholder={t('common:placeholder.termination_comment')}
                        containerClass='md:col-span-8 col-span-8'
                        multiline
                    />
                </div>

                <div className='buttons'>
                    <Button type={ButtonType.secondary} onClick={() => props.setShowContractTerminationForm(false)}>
                        {t('common:buttons:cancel')}
                    </Button>
                    <Button buttonType='submit' disabled={buttonDisabled}>
                        {t('portal:contractTermination.btn_contract_termination_confirm')}
                    </Button>
                </div>
            </form>
        </>
    );
}

export default ContractTerminationForm;
