export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_PRE_CHECK_COMPLETED_AND_PASSED = 'SET_PRE_CHECK_COMPLETED_AND_PASSED';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_CHARGER_SELECTION = 'SET_CHARGER_SELECTION';
export const SET_DEFAULT_CHARGER_SELECTION = 'SET_DEFAULT_CHARGER_SELECTION';
export const SET_DISCOUNT_AMOUNT = 'SET_DISCOUNT_AMOUNT';
export const SET_DOWN_PAYMENT_PRODUCT_SELECTION = 'SET_DOWN_PAYMENT_PRODUCT_SELECTION';
export const SET_TARIFF_SELECTION = 'SET_TARIFF_SELECTION';
export const UPDATE_INSTALLATION_ANSWERS_SELECTION = 'UPDATE_INSTALLATION_ANSWERS_SELECTION';
export const RESET_INSTALLATION_ANSWERS_SELECTION = 'RESET_INSTALLATION_ANSWERS_SELECTION';
export const SET_INSTALLATION_ANSWERS_COMPLETED = 'SET_INSTALLATION_ANSWERS_COMPLETED';
export const SET_INSTALLATION_PACKAGE_SELECTION = 'SET_INSTALLATION_PACKAGE_SELECTION';
export const SET_NEEDS_PRE_VISIT = 'SET_NEEDS_PRE_VISIT';
export const SET_REDIRECT_TO_CHECKOUT_ON_SELECT = 'SET_REDIRECT_TO_CHECKOUT_ON_SELECT';
export const RESET_CONFIGURATION_DATA = 'RESET_CONFIGURATION_DATA';
export const UPDATE_PRE_CHECK_ANSWERS = 'UPDATE_PRE_CHECK_ANSWERS';
export const SET_PRE_VISIT_PACKAGE_SELECTION = 'SET_PRE_VISIT_PACKAGE_SELECTION';
export const SET_FRIENDS_AND_FAMILY_PROMOTION_CODE = 'SET_FRIENDS_AND_FAMILY_PROMOTION_CODE';
export const RESET_CONFIGURATION_DATA_WITHOUT_PROMOTION_CODE = 'RESET_CONFIGURATION_DATA_WITHOUT_PROMOTION_CODE';
export const RESET_CONFIGURATION_DATA_WITHOUT_SELECTED_PRODUCT = 'RESET_CONFIGURATION_DATA_WITHOUT_SELECTED_PRODUCT';

export function updatePreCheckAnswers(step, value) {
	return {
		type: UPDATE_PRE_CHECK_ANSWERS,
		step,
		value
	}
}

export function setCurrentStep(value) {
	return {
		type: SET_CURRENT_STEP,
		value
	}
}

export function setProductSelection(value) {
	return {
		type: SET_SELECTED_PRODUCT,
		value
	}
}

export function setPreCheckCompletedAndPassed(value) {
	return {
		type: SET_PRE_CHECK_COMPLETED_AND_PASSED,
		value
	}
}

export function setChargerSelection(value) {
	return {
		type: SET_CHARGER_SELECTION,
		value
	}
}

export function setDefaultChargerSelection(value) {
	return {
		type: SET_DEFAULT_CHARGER_SELECTION,
		value
	}
}

export function setDiscountAmount(value) {
	return {
		type: SET_DISCOUNT_AMOUNT,
		value
	}
}


// export function setSmartMeterSelection(value) {
// 	return {
// 		type: SET_SMART_METER_SELECTION,
// 		value
// 	}
// }

export function setDownPaymentProductSelection(value) {
	return {
		type: SET_DOWN_PAYMENT_PRODUCT_SELECTION,
		value
	}
}

export function setTariffSelection(value) {
	return {
		type: SET_TARIFF_SELECTION,
		value
	}
}

export function setInstallationAnswersCompleted(value) {
	return {
		type: SET_INSTALLATION_ANSWERS_COMPLETED,
		value
	}
}

export function updateInstallationAnswersSelection(step, value) {
	return {
		type: UPDATE_INSTALLATION_ANSWERS_SELECTION,
		step,
		value
	}
}

export function resetInstallationAnswersSelection() {
	return {
		type: RESET_INSTALLATION_ANSWERS_SELECTION,
	}
}

export function setInstallationPackageSelection(value) {
	return {
		type: SET_INSTALLATION_PACKAGE_SELECTION,
		value
	}
}

export function setPreVisitPackageSelection(value) {
	return {
		type: SET_PRE_VISIT_PACKAGE_SELECTION,
		value
	}
}

export function setNeedsPreVisit(value, text) {
	return {
		type: SET_NEEDS_PRE_VISIT,
		value,
		text
	}
}

export function setRedirectToCheckoutOnSelect(value) {
	return {
		type: SET_REDIRECT_TO_CHECKOUT_ON_SELECT,
		value
	}
}

export function setFriendsAndFamilyPromotionCode(value) {
	return {
		type: SET_FRIENDS_AND_FAMILY_PROMOTION_CODE,
		value
	}
}

export function resetConfigurationData() {
	return {
		type: RESET_CONFIGURATION_DATA
	}
}

export function resetConfigurationDataWithoutPromotionCode() {
	return {
		type: RESET_CONFIGURATION_DATA_WITHOUT_PROMOTION_CODE
	}
}

export function resetConfigurationDataWithoutSelectedProduct() {
	return {
		type: RESET_CONFIGURATION_DATA_WITHOUT_SELECTED_PRODUCT
	}
}

export default {
	setCurrentStep,
	setPreCheckCompletedAndPassed,
	setChargerSelection,
	setDiscountAmount,
	// setSmartMeterSelection,
	setDownPaymentProductSelection,
	setTariffSelection,
	updateInstallationAnswersSelection,
	resetInstallationAnswersSelection,
	setInstallationAnswersCompleted,
	setInstallationPackageSelection,
	setNeedsPreVisit,
	setRedirectToCheckoutOnSelect,
	updatePreCheckAnswers,
	resetConfigurationData,
	setPreVisitPackageSelection,
	setFriendsAndFamilyPromotionCode,
	setDefaultChargerSelection,
	setProductSelection,
	resetConfigurationDataWithoutPromotionCode,
	resetConfigurationDataWithoutSelectedProduct
}
