export default {
  documents: {
    header: {
        title: "Dokumente & Verträge",
        text: "Willkommen auf Ihrer Dokumentenseite, auf der Sie alle relevanten Dateien für Ihre Ladestation und Ihren Vertrag finden.",
    },
    information:{
        title:"Allgemeine Informationen Charger-Modelle",
        alfen_eve_double: "Alfen Eve Double Pro-line Datenblatt",
        alfen_eve_single: "Alfen Eve Single Datenblatt",
        charger_manuel: "Etrel INCH Home Datenblatt",
        employer_permission: "Zustimmung des Arbeitgebers"
    },
    contract:{
        title:"Verträge & AGBs"
    },
    invoices:{
        title:"Angebote & Rechnungen",
        invoice_charger: "Rechnung Charger",
        installation_quote: "Installationsangebot",
        loading: 'Loading invoices & quotes...'
    },
    no_documents: "Keine Dokumente verfügbar"
  },
  tariff: {
    header: {
        title: "Vertrag",
        intro: "Hier finden Sie alle Informationen zu Ihrem Tarif. Die Abrechnung erfolgt monatlich.",
      },
  },
  contractTermination: {
    btn_contract_termination: "Vertrag kündigen",
    btn_contract_termination_confirm: "Jetzt kündigen",
  }
}
