export default {
    menu: {
        planning: 'Planning and procedure ',
        personal_detail: 'Personal information',
        installation_detail: 'Pre-visit details',
        confirmation: 'confirmation'
    },
    login: {
        title: 'Charging by <b>En</b>OS<sup>TM</sup>',
        companyCode: 'company code',
        definition:
            'Charger, green power and expert installation - the complete package for charging at home. It all starts with planning. Enter your code to book a pre-visit appointment with one of our electricians:',
        note: 'This is an exclusive offer for employees. Publishing or sharing the company code with third parties is not permitted.'
    }
};

const businessNissan = {
    menu: {
        planning: 'Planning and procedure ',
        personal_detail: 'Personal information',
        installation_detail: 'Pre-visit details',
        confirmation: 'Confirmation'
    },
    login: {
        title: 'Charging by <b>En</b>OS<sup>TM</sup> - Charging Solutions by Univers',
        companyCode: 'Nissan Code',
        definition:
            'Your expert installation of your Nissan charging point at your home. It all starts with planning. Enter your code to book an appointment for an on-site visit with one of our electricians',
        note: 'This is an exclusive offer for Nissan customers. Publishing or sharing the code with any third party is not permitted.'
    }
};

const businessGoinGreen = {
    menu: {
        installation_detail: 'Pre-visit details',
    },
    login: {
        title: 'Charging by <b>En</b>OS<sup>TM</sup> - Charging Solutions by Univers',
        companyCode: 'Promotion Code',
        definition:
            'Univers is the expert for charging solutions for electric cars. Every installation project starts with the planning. Enter your promotional code to book an appointment for a pre-visit check with one of our electricians.',
    }
};

export { businessNissan, businessGoinGreen };
