export const SET_PAYMENT_STATUS = "SET_PAYMENT_STATUS";
export const SET_PAYMENT_STATUS_LOADING = "SET_PAYMENT_STATUS_LOADING";
export const SET_HAS_RECURRING_PAYMENT_DETAILS = "SET_HAS_RECURRING_PAYMENT_DETAILS";
export const SET_IS_PAYMENT_INFORMATION_IS_NEEDED = "SET_IS_PAYMENT_INFORMATION_IS_NEEDED";

export function setPaymentStatus(value) {
    return {
        type: SET_PAYMENT_STATUS,
        value
    };
}

export function setPaymentStatusLoading(value) {
    return {
        type: SET_PAYMENT_STATUS_LOADING,
        value
    };
}

export function hasRecurringPaymentDetails(value) {
    return {
        type: SET_HAS_RECURRING_PAYMENT_DETAILS,
        value
    };
}

export default {
    setPaymentStatus,
    setPaymentStatusLoading,
    hasRecurringPaymentDetails
};
