import { AppStoreImage, GooglePlayImage, MobileAppImage } from 'constants/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayCard } from '.';
import Constants from '../constants';
import { DisplayCardTypes } from './DisplayCard';

export interface ApplicationDownloadCardProp {
    extraClass?: string;
}

export default function ApplicationDownloadCard(props: ApplicationDownloadCardProp) {
    const { extraClass } = props;

    const { t } = useTranslation(['home']);

    return (
        <DisplayCard type={DisplayCardTypes.IMAGE_LEFT} src={MobileAppImage} className={extraClass ? extraClass : ''}>
            <h3 className='mb__s' dangerouslySetInnerHTML={{ __html: t('get_app.title') }}></h3>
            <p className='mb__m' dangerouslySetInnerHTML={{ __html: t('get_app.text') }}></p>
            <div className='display-card__buttons'>
                <a href={Constants.APP_URL_IOS} target='_blank' rel='noopener noreferrer'>
                    <img src={AppStoreImage} className={'home__app-store-card'} alt='' />
                </a>
                <a href={Constants.APP_URL_GOOGLE} target='_blank' rel='noopener noreferrer'>
                    <img src={GooglePlayImage} className={'home__app-store-card'} alt='' />
                </a>
            </div>
        </DisplayCard>
    );
}
