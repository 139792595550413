import moment from "moment";
import i18n from "../i18n/index";
import 'moment/locale/en-gb';
import 'moment/locale/de';


moment.locale(i18n.language === 'en' ? 'en-bg' : i18n.language);

// hardcoding this for now.. potentially in the future the backend can return a currency code for us to work with
const languageToCurrencyMap = {
	de: 'EUR',
	en: 'EUR',
};

const numberFormat = (value) => {
	let formatter = new Intl.NumberFormat(i18n.language, {
		style: 'decimal',
	});
	return formatter.format(value);
};

const currencyFormat = (value) => {
	let currency = languageToCurrencyMap[i18n.language];
	let formatter = new Intl.NumberFormat(i18n.language, {
		style: 'currency',
		currency
	});

	const result = formatter.format(value);

	if (currency === 'EUR') return result.replace(/^(\D+)/, '$1 ');
	return result;
};

const getCurrencySymbol = (locale) => {
	return languageToCurrencyMap[locale] ? languageToCurrencyMap[locale] : languageToCurrencyMap['DE'];
};


export default {
	numberFormat,
	currencyFormat,
	getCurrencySymbol
}
