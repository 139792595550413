import Constants from '../constants/index';
import moment from 'moment';

const getRule = (ruleName) => {
    switch (ruleName) {
        case ruleNames.salutation:
            return createRuleRequired('^salutation_required', 'salutation');
        // case ruleNames.language:
        //     return createRuleRequired('^language_required', 'language');
        case ruleNames.firstName:
            return createRuleRequiredAndMoreThanXChars('^first_name_required', 'name', 2);
        case ruleNames.lastName:
            return createRuleRequiredAndMoreThanXChars('^last_name_required', 'surname', 2);
        case ruleNames.supportSubject:
            return createRuleRequired('^support_subject_required', 'type');
        case ruleNames.supportDescription:
            return createRuleRequired('^support_description_required', 'description');
        case ruleNames.email:
            return {
                constraints: {
                    presence: {
                        allowEmpty: false,
                        message: '^email_required'
                    },
                    email: {
                        message: '^email_invalid'
                    }
                },
                param: 'email'
            };
        case ruleNames.phoneNo:
            return {
                constraints: {
                    presence: {
                        allowEmpty: false,
                        message: '^phone_no_required'
                    },
                    phone_no: {
                        message: '^phone_no_required'
                    }
                },
                param: 'phone'
            };
        case ruleNames.birthday:
            return {
                constraints: {
                    datetime: {
                        datetime: true,
                        message: '^birthday_invalid_date',
                        latest: Date.now(),
                        earliest: new Date(1900, 0, 0)
                    }
                },
                param: 'dateOfBirth'
            };
        case ruleNames.installationDate:
            return {
                constraints: {
                    datetime: {
                        dateOnly: true,
                        message: '^installation_invalid_date',
                        earliest: Date.now()
                    },
                    presence: {
                        allowEmpty: false,
                        message: '^installation_date_required'
                    }
                },
                param: 'preferredInstallationDate'
            };

        case ruleNames.createPassword:
        case ruleNames.password:
            return createPasswordRule('password');
        case ruleNames.newPassword:
            return createPasswordRule('newPassword');
        case ruleNames.oldPassword:
            return createRuleRequired('^password_required', 'oldPassword');
        case ruleNames.loginPassword:
            return createRuleRequired('^password_required', 'password');
        case ruleNames.passwordConfirmation:
            return createPasswordConfirmationRule('password', 'passwordConfirmation');
        case ruleNames.newPasswordConfirmation:
            return createPasswordConfirmationRule('newPassword', 'passwordConfirmation');
        case ruleNames.streetAddress:
            return createRuleRequired('^street_address_required', 'installationAddress.street');
        case ruleNames.streetNo:
            return {
                constraints: {
                    presence: {
                        allowEmpty: false,
                        message: '^street_no_required'
                    }
                },
                param: 'installationAddress.streetNumber'
            };
        case ruleNames.postalCode:
            return createRuleRequired('^postal_code_required', 'installationAddress.postalCode');
        case ruleNames.city:
            return createRuleRequired('^city_required', 'installationAddress.city');
        case ruleNames.country:
            return createRuleRequired('^country_required', 'installationAddress.country');
        case ruleNames.contactMethod:
            return createRuleRequired('^contact_method', 'preferredContactMethod');
        case ruleNames.message:
            return createRuleTooLongXChars('^installation_message_toolong', 'installationMessage', 5000);
        case ruleNames.firstNameBilling:
            return createRuleRequired('^first_name_required', 'billingAddress.firstName');
        case ruleNames.lastNameBilling:
            return createRuleRequired('^last_name_required', 'billingAddress.familyName');
        case ruleNames.streetAddressBilling:
            return createRuleRequired('^street_address_required', 'billingAddress.street');
        case ruleNames.streetNoBilling:
            return {
                constraints: {
                    presence: {
                        allowEmpty: false,
                        message: '^street_no_required'
                    }
                },
                param: 'billingAddress.streetNumber'
            };
        case ruleNames.postalCodeBilling:
            return createRuleRequired('^postal_code_required', 'billingAddress.postalCode');
        case ruleNames.cityBilling:
            return createRuleRequired('^city_required', 'billingAddress.city');
        case ruleNames.stateBilling:
            return createRuleRequired('^state_required', 'billingAddress.state');
        case ruleNames.countryBilling:
            return createRuleRequired('^country_required', 'billingAddress.country');
        case ruleNames.accountHolder:
            return createRuleRequired('^account_holder_required', 'account_holder');
        case ruleNames.bankName:
            return createRuleRequired('^bank_name_required', 'bank_name');
        case ruleNames.iban:
            return createRuleRequired('^iban_required', 'iban');
        case ruleNames.bic:
            return createRuleRequired('^bic_required', 'bic');
        case ruleNames.cardHolder:
            return createRuleRequired('^card_holder_required', 'card_holder');
        case ruleNames.creditCardNo:
            return createRuleRequired('^credit_card_no_required', 'credit_card_no');
        case ruleNames.expiryDate:
            return createRuleRequired('^expiry_date_required', 'expiry_date');
        case ruleNames.ccv:
            return createRuleRequired('^ccv_required', 'ccv');
        case ruleNames.referralCode:
            return {
                constraints: {
                    inclusion: {
                        within: Constants.REFERRAL_CODES_ARRAY,
                        message: '^referral_code'
                    }
                },
                param: 'referralCode'
            };
        case ruleNames.termsAccepted:
        case ruleNames.termsAcceptedAccount:
            return {
                constraints: {
                    boolean: {
                        truthy: true,
                        message: '^terms_not_accepted'
                    }
                },
                param: 'termsAccepted'
            };
        case ruleNames.chargerName:
            return createRuleMinMaxRangeCharacters('^charger_name_min_max_range', 'charger_name', 1, 50);
        case ruleNames.terminationReason:
            return createRuleRequiredAndMoreThanXChars('^termination_reason_required', 'termination_reason', 2);
        case ruleNames.terminationType:
            return createRuleRequired('^termination_type_required', 'termination_type');
        case ruleNames.customerNumber:
            return createRuleRequiredAndMoreThanXChars('^customer_number_required', 'customer_number', 2);
        case ruleNames.contractNumber:
            return createRuleRequiredAndMoreThanXChars('^contract_number_required', 'contract_number', 2);
        case ruleNames.terminationDateDecision:
            return createRuleRequired('^termination_date_decision_required', 'termination_date_decision');
        case ruleNames.terminationDate:
            return {
                constraints: {
                    presence: {
                        allowEmpty: false,
                        message: '^termination_date_required'
                    }
                },
                param: 'terminationDate'
            };
        // PAYOUT
        case ruleNames.payout.energyPrice:
            return {
                constraints: {
                    regex: {
                        pattern: '^\\d+(\\,\\d{1,8})?$', // https://regex101.com/r/fAg0v5/1
                        message: '^payout_household_tariff_required'
                    }
                },
                param: 'energyPrice'
            };
        case ruleNames.payout.startDate:
            return {
                constraints: {
                    presence: {
                        allowEmpty: false,
                        message: '^payout_start_date_required'
                    },
                    datetime: {
                        dateOnly: true,
                        message: '^payout_invalid_start_date',
                        earliest: moment(Date.now()).add(1, 'days').format('YYYY-MM-DD')
                    }
                },
                param: 'startDate'
            };
        case ruleNames.payout.energyContract:
            return createRuleRequired('^payout_energyContract_required', 'energyContract');
        case ruleNames.payout.firstName:
            return createRuleRequired('^bank_account_firstName_required', 'firstName');
        case ruleNames.payout.lastName:
            return createRuleRequired('^bank_account_lastName_required', 'lastName');
        case ruleNames.payout.iban:
            return createRuleRequired('^iban_required', 'iban');
        // return {
        //     constraints: {
        //         regex: {
        //             pattern: '^[A-Za-z0-9]{22}$', // https://regex101.com/r/fAg0v5/1
        //             message: '^iban_required'
        //         }
        //     },
        //     param: 'iban'
        // }
        case ruleNames.payout.streetName:
            return createRuleRequired('^street_address_required', 'streetName');
        case ruleNames.payout.houseNumber:
            return createRuleRequired('^street_no_required', 'houseNumber');
        case ruleNames.payout.postalCode:
            return createRuleRequired('^postal_code_required', 'postalCode');
        case ruleNames.payout.cityName:
            return createRuleRequired('^city_required', 'cityName');
        case ruleNames.payout.country:
            return createRuleRequired('^country_required', 'country');
        case ruleNames.payout.termsAndConditionsAccepted:
            return {
                constraints: {
                    boolean: {
                        truthy: true,
                        message: '^payout_termsAndConditionsAccepted_required'
                    }
                },
                param: 'termsAndConditionsAccepted'
            };
        case ruleNames.fleetData.employeeCostUnit:
            return createRuleRequired('^employeeCostUnit_required', 'employeeCostUnit');
        case ruleNames.fleetData.employeeId:
            return createRuleRequired('^employeeId_required', 'employeeId');
        case ruleNames.fleetData.vehicleRegistrationNumber:
            return {
                constraints: {
                    regex: {
                        pattern: '^(.{0,44}?)$', // https://regex101.com/r/ecjicM/1
                        message: '^vehicleRegistrationNumber_required'
                    }
                },
                param: 'vehicleRegistrationNumber'
            };
        case ruleNames.fleetData.vehicleRegistrationNumberPart1:
            return {
                constraints: {
                    regex: {
                        pattern: '^([a-zA-ZäüöÄÜÖ]{0,3}?)$', // https://regex101.com/r/ecjicM/1
                        message: '^vehicleRegistrationNumberPart1_required'
                    }
                },
                param: 'vehicleRegistrationNumberPart1'
            };
        case ruleNames.fleetData.vehicleRegistrationNumberPart2:
            return {
                constraints: {
                    regex: {
                        pattern: '^([a-zA-Z]{0,2}?)$', // https://regex101.com/r/ecjicM/1
                        message: '^vehicleRegistrationNumberPart2_required'
                    }
                },
                param: 'vehicleRegistrationNumberPart2'
            };
        case ruleNames.fleetData.vehicleRegistrationNumberPart3:
            return {
                constraints: {
                    regex: {
                        pattern: '^([0-9]{0,4}?)$', // https://regex101.com/r/ecjicM/1
                        message: '^vehicleRegistrationNumberPart3_required'
                    }
                },
                param: 'vehicleRegistrationNumberPart3'
            };
        case ruleNames.fleetData.vehicleRegistrationNumberPart4:
            return {
                constraints: {
                    regex: {
                        pattern: '^([a-zA-ZäüöÄÜÖ]{0,1}?)$', // https://regex101.com/r/ecjicM/1
                        message: '^vehicleRegistrationNumberPart4_required'
                    }
                },
                param: 'vehicleRegistrationNumberPart4'
            };
        default:
            break;
    }
};

export const getValidationRules = (rulesArray) => {
    let result = {};
    let rule;
    if (Array.isArray(rulesArray)) {
        rulesArray.forEach((ruleKey) => {
            rule = getRule(ruleKey);
            if (rule) {
                result[ruleKey] = rule;
            }
        });
    } else {
        rule = getRule(rulesArray);
        if (rule) {
            result[rulesArray] = rule;
        }
    }

    return result;
};

export const ruleNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phoneNo: 'phoneNo',
    birthday: 'birthday',
    createPassword: 'createPassword',
    password: 'password',
    newPassword: 'newPassword',
    oldPassword: 'oldPassword',
    loginPassword: 'loginPassword',
    passwordConfirmation: 'passwordConfirmation',
    newPasswordConfirmation: 'newPasswordConfirmation',
    streetAddress: 'streetAddress',
    streetNo: 'streetNo',
    postalCode: 'postalCode',
    city: 'city',
    country: 'country',
    firstNameBilling: 'firstNameBilling',
    lastNameBilling: 'lastNameBilling',
    streetAddressBilling: 'streetAddressBilling',
    streetNoBilling: 'streetNoBilling',
    postalCodeBilling: 'postalCodeBilling',
    cityBilling: 'cityBilling',
    stateBilling: 'stateBilling',
    countryBilling: 'countryBilling',
    accountHolder: 'accountHolder',
    bankName: 'bankName',
    iban: 'iban',
    bic: 'bic',
    cardHolder: 'cardHolder',
    creditCardNo: 'creditCardNo',
    expiryDate: 'expiryDate',
    ccv: 'ccv',
    termsAccepted: 'termsAccepted',
    termsAcceptedAccount: 'termsAcceptedAccount',
    installationDate: 'installationDate',
    contactMethod: 'contactMethod',
    message: 'message',
    referralCode: 'referralCode',
    salutation: 'salutation',
    // language: 'language',
    supportSubject: 'supportSubject',
    supportDescription: 'supportDescription',
    chargerName: 'chargerName',
    terminationType: 'terminationType',
    terminationReason: 'terminationReason',
    customerNumber: 'customerNumber',
    contractNumber: 'contractNumber',
    terminationDateDecision: 'terminationDateDecision',
    terminationDate: 'terminationDate',
    payoutHouseNumber: 'payoutHouseNumber',
    payout: {
        energyPrice: 'payout.energyPrice',
        startDate: 'payout.startDate',
        energyContract: 'payout.energyContract',
        firstName: 'payout.firstName',
        lastName: 'payout.lastName',
        iban: 'payout.iban',
        houseNumber: 'payout.houseNumber',
        streetName: 'payout.streetName',
        postalCode: 'payout.postalCode',
        cityName: 'payout.cityName',
        country: 'payout.country',
        termsAndConditionsAccepted: 'payout.termsAndConditionsAccepted'
    },
    fleetData: {
        employeeCostUnit: 'fleetData.employeeCostUnit',
        employeeId: 'fleetData.employeeId',
        vehicleRegistrationNumber: 'fleetData.vehicleRegistrationNumber',
        vehicleRegistrationNumberPart1: 'fleetData.vehicleRegistrationNumberPart1',
        vehicleRegistrationNumberPart2: 'fleetData.vehicleRegistrationNumberPart2',
        vehicleRegistrationNumberPart3: 'fleetData.vehicleRegistrationNumberPart3',
        vehicleRegistrationNumberPart4: 'fleetData.vehicleRegistrationNumberPart4',
    }
};

// helper function for our pages/componnents
export const hasValidationError = (field, validationErrors) => {
    return !!validationErrors.find((item) => {
        return item.field === field;
    });
};

export const validationErrorTranslation = (field, validationErrors, t) => {
    let foundErrorObj = validationErrors.find((item) => {
        return item.field === field;
    });

    if (foundErrorObj && foundErrorObj.messages[0]) {
        return t(`common:validation.${foundErrorObj.messages[0]}`);
    }
    return '';
};

// generic function so we don't have to copy over the object each time for similar patterns
function createRuleRequired(message, param) {
    return {
        constraints: {
            presence: {
                allowEmpty: false,
                message: message
            }
        },
        param: param
    };
}

function createRuleMinMaxRangeCharacters(message, param, minChars, maxChars) {
    return {
        constraints: {
            presence: {
                allowEmpty: false,
                message: message
            },
            length: {
                minimum: minChars,
                maximum: maxChars,
                message: message
            }
        },
        param: param
    };
}

// generic function so we don't have to copy over the object each time for similar patterns
function createRuleRequiredAndMoreThanXChars(message, param, minChars) {
    return {
        constraints: {
            presence: {
                allowEmpty: false,
                message: message
            },
            length: {
                minimum: minChars,
                message: '^' + param + '_min_length'
            }
        },
        param: param
    };
}

function createRuleTooLongXChars(message, param, maxChars) {
    return {
        constraints: {
            length: {
                maximum: maxChars,
                message: message
            }
        },
        param: param
    };
}

function createPasswordRule(param) {
    return {
        constraints: {
            presence: {
                allowEmpty: false,
                message: '^password_required'
            },
            length: {
                minimum: 8,
                message: '^password_min_length'
            },
            regex: {
                pattern: '(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*[@\\$\\!%\\*=+\\-,?&\\(\\)\\{\\}#%&$.[\\]_/])',
                message: '^password_format'
            }
        },
        param: param
    };
}

function createPasswordConfirmationRule(matchAttribute, param) {
    return {
        constraints: {
            equality: {
                attribute: matchAttribute,
                message: '^password_confirmation_mismatch'
            },
            presence: {
                allowEmpty: false,
                message: '^password_required'
            }
        },
        param: param
    };
}
