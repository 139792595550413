import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Constants from '../constants';
import { routePaths } from '../route_paths';
import ConsentButton from './ConsentButton';
import LanguageSwitcher from 'components/LanguageSwitcher';

function Footer(props) {
    const { t, i18n } = useTranslation(['nav']);
    const location = useLocation();
    const [showPortalFooter, setShowPortalFooter] = useState(false);
    const [showLoginFooter, setShowLoginFooter] = useState(false);
    const [footerBlack, setFooterBlack] = useState(false);
    const [isFQAActive, setIsFQAActive] = useState(false);
    const [showAGB, setShowAGB] = useState(true);
    const [termsPageUrl, setTermsPageUrl] = useState(Constants.MARKETING_SITE_URLS.PRIVACY_DE);
    const [privacyPageUrl, setPrivacyPageUrl] = useState(Constants.MARKETING_SITE_URLS.PRIVACY_DE);
    const [imprintPageUrl, setImprintPageUrl] = useState(Constants.MARKETING_SITE_URLS.IMPRINT_DE);
    const [fqaPageUrl, setFqaPageUrl] = useState(Constants.MARKETING_SITE_URLS.FQA_DE);

    useEffect(() => {
        let pageName = location.pathname;
        switch (pageName) {
            case routePaths.login:
            case routePaths.resetPassword:
            case routePaths.resetPasswordFirstTime:
                setShowPortalFooter(false);
                setShowLoginFooter(true);
                setFooterBlack(true);
                break;
            case routePaths.terms:
            case routePaths.imprint:
            case routePaths.privacy:
                setFooterBlack(false);
                setShowPortalFooter(true);
                break;
            case routePaths.businessLogin:
            case routePaths.businessPlanning:
            case routePaths.businessPersonalInformation:
            case routePaths.businessInstallationDetail:
            case routePaths.businessConfirmation:
                setShowAGB(true);
                setFooterBlack(true);
                setShowPortalFooter(true);
                setIsFQAActive(true);
                break;
            case routePaths.portalOverview:
            case routePaths.portalCharger:
            case routePaths.portalDocuments:
            case routePaths.portalPhotoUpload:
            case routePaths.portalPayment:
            case routePaths.portalProfile:
            case routePaths.portalSupport:
            case routePaths.portalTariff:
            case routePaths.portalPayout:
            case routePaths.portalFleetData:
                setShowAGB(true);
                setFooterBlack(true);
                setShowPortalFooter(true);
                setIsFQAActive(true);
                break;
            default:
                setFooterBlack(true);
                setShowPortalFooter(true);
                break;
        }
    }, [location.pathname]);

    useEffect(() => {
        switch (i18n.language) {
            case 'de':
                setPrivacyPageUrl(Constants.MARKETING_SITE_URLS.PRIVACY_DE);
                setImprintPageUrl(Constants.MARKETING_SITE_URLS.IMPRINT_DE);
                setTermsPageUrl(Constants.MARKETING_SITE_URLS.TERMS_DE); // TODO: once terms is available on website, change this
                setFqaPageUrl(Constants.MARKETING_SITE_URLS.FQA_DE);
                break;
            case 'en':
            default:
                setPrivacyPageUrl(Constants.MARKETING_SITE_URLS.PRIVACY_EN);
                setImprintPageUrl(Constants.MARKETING_SITE_URLS.IMPRINT_EN);
                setTermsPageUrl(Constants.MARKETING_SITE_URLS.TERMS_EN); // TODO: once terms is available on website, change this
                setFqaPageUrl(Constants.MARKETING_SITE_URLS.FQA_EN);
                break;
        }
    }, [i18n.language]);

    const footerClass = `footer ${footerBlack ? 'footer--black' : ''}`;

    return showPortalFooter ? (
        <footer className={footerClass}>
            <div className='footer__links-container'>
                {<LanguageSwitcher />}
                {showAGB && (
                    <a href={termsPageUrl} className='footer__link' target={'_blank'} rel={'noopener noreferrer'}>
                        <p className='footer__link__text'>{t('terms')}</p>
                    </a>
                )}
                <a href={privacyPageUrl} className='footer__link' target={'_blank'} rel={'noopener noreferrer'}>
                    <p className='footer__link__text'>{t('privacy')}</p>
                </a>
                <a href={imprintPageUrl} className='footer__link' target={'_blank'} rel={'noopener noreferrer'}>
                    <p className='footer__link__text'>{t('imprint')}</p>
                </a>
                {isFQAActive && (
                    <a href={fqaPageUrl} className='footer__link' target={'_blank'} rel={'noopener noreferrer'}>
                        <p className='footer__link__text'>{t('fqa')}</p>
                    </a>
                )}
                <ConsentButton />
            </div>
        </footer>
    ) : showLoginFooter ? (
        <footer
            className={footerClass}
            style={{
                marginTop:
                    location.pathname === routePaths.login || location.pathname === routePaths.resetPassword
                        ? '-5.4rem'
                        : ''
            }}
        >
            <div className='footer__links-container'>
                {<LanguageSwitcher />}
                <ConsentButton />
            </div>
        </footer>
    ) : (
        <></>
    );
}

export default Footer;
