import { OPEN_TOAST, CLOSE_TOAST } from '.'

import { IToast } from '../../typeScript/interfaces/IToast'

export const initialState = {
    showToast: false,
    toastType: '',
    toastMessage: ''
};

export function toastReducer(state: IToast = initialState, action): IToast {
    switch (action.type) {
        case OPEN_TOAST:
            return {
                ...state,
                showToast: true,
                toastType: action.toastType,
                toastMessage: action.value
            }
        case CLOSE_TOAST:
            return {
                ...state,
                showToast: false
            }
        default:
            return state
    }
}