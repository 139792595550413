import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Input } from '.';
import InputType from '../constants/inputType';
import { hasValidationError, ruleNames, validationErrorTranslation } from '../validation';
import { TYPE_ERROR } from 'components/Toast';
import { openToast } from 'store/toast';
import { useDispatch } from 'react-redux';
import Tooltip from './Tooltip';

interface PayoutTariffFormFieldsProps {
    validationErrors: any;
    setFieldValues: any;
    fieldValues: any;
    isUpdateForm: boolean;
}

const MIN_DAYS_IN_THE_FUTURE_FOR_START_DATE = 1;
const MAX_CONTRACT_FILE_SIZE = 2000000;
const ACCEPTED_FILE_LIST = 'application/pdf, image/png, image/jpeg';

function PayoutTariffFormFields(props: PayoutTariffFormFieldsProps) {
    const { validationErrors, setFieldValues, fieldValues, isUpdateForm } = props;
    const dispatch = useDispatch();

    const minStartDate = (date) => {
        return date.isBefore(moment().startOf('day').add(MIN_DAYS_IN_THE_FUTURE_FOR_START_DATE, 'days'));
    };

    const { t } = useTranslation();

    useEffect(() => {
        !isUpdateForm &&
            setFieldValues({
                startDate: moment()
                    .startOf('day')
                    .add(MIN_DAYS_IN_THE_FUTURE_FOR_START_DATE, 'days')
                    .format('YYYY-MM-DD')
            });
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className='input-row mb__xl'>
                <Input
                    label={t('common:labels.payout_energyPrice')}
                    mandatory
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setFieldValues({ energyPrice: e.currentTarget.value })
                    }
                    hasValidationError={hasValidationError(ruleNames.payout.energyPrice, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.energyPrice, validationErrors, t)}
                    placeholder={t('common:placeholder.payout_energyPrice')}
                    containerClass='input__container--6-el input__container--6-el-row'
                    suffix={t('payout:energy_price_suffix')}
                />

                <div className='input__container input__container--6-el input__container--6-el-row'>
                    <div className={!isUpdateForm ? 'hidden' : ''}>
                        <div style={{ position: 'absolute', top: '34px', right: '50px', zIndex: '99' }}>
                            <Tooltip>{t('payout:date_tooltip')}</Tooltip>
                        </div>
                        <DatePicker
                            id='startDate'
                            value={fieldValues.startDate}
                            disabled={!isUpdateForm}
                            onChange={(selectedDate) =>
                                setFieldValues({
                                    startDate: moment(selectedDate).isValid()
                                        ? moment(selectedDate).format('YYYY-MM-DD')
                                        : ''
                                })
                            }
                            hasValidationError={hasValidationError(ruleNames.payout.startDate, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(
                                ruleNames.payout.startDate,
                                validationErrors,
                                t
                            )}
                            label={t('common:labels.payout_startDate') + '*'}
                            isOutsideRange={minStartDate}
                            displayFormat={() => 'DD.MM.YYYY'}
                            placeholder={t('common:placeholder.payout_startDate')}
                            isWeekendAllowed={true}
                        />
                    </div>
                </div>

                <Input
                    id='payout_energyContract'
                    label={t('common:labels.payout_energyContract', { maxFileSize: MAX_CONTRACT_FILE_SIZE / 1000000 })}
                    mandatory
                    accept={ACCEPTED_FILE_LIST}
                    type={InputType.FILE}
                    onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget && e.currentTarget.files && e.currentTarget.files.length === 1) {
                            const size = e.currentTarget.files[0].size;
                            if (size > MAX_CONTRACT_FILE_SIZE) {
                                setFieldValues({
                                    energyContract: {}
                                });
                                e.currentTarget.value = '';
                                dispatch(
                                    openToast(
                                        TYPE_ERROR,
                                        t('toast:contract_file_size_too_large', {
                                            maxFileSize: MAX_CONTRACT_FILE_SIZE / 1000000
                                        })
                                    )
                                );
                            } else {
                                setFieldValues({
                                    // energyContract: await prepareFile(e.currentTarget.files[0])
                                    energyContract: e.currentTarget.files[0]
                                });
                            }
                        }
                    }}
                    hasValidationError={hasValidationError(ruleNames.payout.energyContract, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(
                        ruleNames.payout.energyContract,
                        validationErrors,
                        t
                    )}
                    placeholder={t('common:placeholder.payout_energyContract')}
                    containerClass='input__container--6-el input__container--6-el-row'
                />
            </div>
            <div className='input-row mb__xl'>
                <Input
                    label={t('common:labels.street_address')}
                    mandatory
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setFieldValues({ streetName: e.currentTarget.value })
                    }
                    hasValidationError={hasValidationError(ruleNames.payout.streetName, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.streetName, validationErrors, t)}
                    placeholder={t('common:placeholder.street_address')}
                    containerClass='input__container--6-el input__container--6-el-row'
                />
                <Input
                    label={t('common:labels.street_no')}
                    mandatory
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setFieldValues({ houseNumber: e.currentTarget.value })
                    }
                    hasValidationError={hasValidationError(ruleNames.payout.houseNumber, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.houseNumber, validationErrors, t)}
                    placeholder={t('common:placeholder.street_no')}
                    containerClass='input__container--6-el'
                />
                <Input
                    label={t('common:labels.postal_code')}
                    autoComplete='postal-code'
                    mandatory
                    type={InputType.NUMBER}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setFieldValues({ postalCode: e.currentTarget.value })
                    }
                    hasValidationError={hasValidationError(ruleNames.payout.postalCode, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.postalCode, validationErrors, t)}
                    placeholder={t('common:placeholder.postal_code')}
                    containerClass='input__container--6-el input__container--6-el-row '
                />
                <Input
                    label={t('common:labels.city')}
                    mandatory
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setFieldValues({ cityName: e.currentTarget.value })
                    }
                    hasValidationError={hasValidationError(ruleNames.payout.cityName, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.cityName, validationErrors, t)}
                    placeholder={t('common:placeholder.city')}
                    containerClass='input__container--6-el'
                />
                <Input
                    id='country'
                    label={t('common:labels.country')}
                    disabled
                    mandatory
                    value={t('common:labels.country_default').toString()}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setFieldValues({ country: e.currentTarget.value })
                    }
                    hasValidationError={hasValidationError(ruleNames.payout.country, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.country, validationErrors, t)}
                    placeholder={t('common:placeholder.country')}
                    containerClass='input__container--6-el'
                />
            </div>
        </div>
    );
}

export default PayoutTariffFormFields;
