import ChargerCardDetail from 'components/Charger/ChargerCardDetail';
import Chargers from 'components/Charger/Chargers';
import lodash from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import { InnerSpinner, Input, Page, Switch } from '../../components';
import GridType from '../../constants/gridType';
import getTailwindConfig from '../../mixins/getTailwindConfig';
import { IRootState } from '../../store';
import loaderActions from '../../store/loader';
import { ILoggedUser, IUserCharger } from '../../typeScript/interfaces/IUser';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { showApiError } from '../../utils/error-handler';
import { openToast } from 'store/toast';
import { TYPE_ERROR } from 'components/Toast';

const searchIcon = require('../../assets/Search-dark.svg');
const tableViewIcon = require('../../assets/TableView.svg');
const gridViewIcon = require('../../assets/GridView.svg');

function Charger() {
    const { t, i18n } = useTranslation(['charger']);
    const [chargerErrorMessage, setChargerErrorMessage] = useState(null);
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);
    const dispatch = useDispatch();
    const [userChargersLoaded, setUserChargersLoaded] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    const [gridMode, setGridMode] = useState(GridType.CARD);
    const [userChargers, setUserChargers] = useState<IUserCharger[]>([]);
    const [tableChargers, setTableChargers] = useState<IUserCharger[]>([]);

    const [filterText, setFilterText] = useState<string>('');
    const [searchFocused, setSearchFocused] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('resize', handleDisplayMode);
        handleDisplayMode();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let filteredChargers = userChargers.filter((item) => filterChargers(item));
        setTableChargers([...filteredChargers]);
        // eslint-disable-next-line
    }, [userChargers, filterText]);

    useEffect(() => {
        if (chargerErrorMessage) {
            setChargerErrorMessage(t('charger_not_found'));
        }
        // eslint-disable-next-line
    }, [i18n.language]);

    useEffect(() => {
        api.charger
            .getChargers()
            .then((response) => {
                setUserChargers(lodash.sortBy(response.data, (c) => c.chargerName.toLowerCase()));
            })
            .then(() => {
                dispatch(loaderActions.setShowLoader(false));
                setUserChargersLoaded(true);
            })
            .catch((error) => {
                dispatch(loaderActions.setShowLoader(false));
                setUserChargersLoaded(true);
                if (error.response.data.code === 'CHARGER_OWNER_NOT_FOUND') {
                    setChargerErrorMessage(t('charger_not_found'));
                    dispatch(openToast(TYPE_ERROR, t('charger:charger_paragraph_no_charger')));
                } else {
                    showApiError(error.response, dispatch, t);
                }
            });
    }, [dispatch, isLogged, t, user]);

    const handleDisplayMode = () => {
        const breakpoints = getTailwindConfig().screensRaw;
        window.innerWidth < breakpoints.md ? setMobileView(true) : setMobileView(false);
    };

    const filterFunction = (container, text: string) => {
        return container.toUpperCase().includes(text.toUpperCase());
    };

    const updateList = (id: string, name: string, mode: boolean) => {
        var charger = userChargers.find(function (c) {
            return c.id === id;
        });
        charger!.authorizationModeEnabled = mode;
        charger!.chargerName = name;

        setUserChargers([...userChargers]);
    };

    const filterChargers = (item: IUserCharger) => {
        return (
            filterFunction(item.chargerName, filterText) ||
            filterFunction(item.adress1, filterText) ||
            filterFunction(item.city, filterText) ||
            filterFunction(item.zipCode, filterText)
        );
    };

    const filterChargerList = (newVal: string) => {
        setTableChargers([...userChargers.filter((item) => filterChargers(item))]);
        setFilterText(newVal);
    };

    const getInputProps = () => {
        return {
            onFocus: () => setSearchFocused(!searchFocused),
            onBlur: () => setSearchFocused(!searchFocused)
        };
    };

    return (
        <Page
            pageTheme={PageTheme.light}
            title={userChargersLoaded ? (userChargers.length ? t('charger_title') : t('charger_title_no_charger')) : ''}
            intro={
                userChargersLoaded
                    ? userChargers.length
                        ? t('charger_paragraph')
                        : t('charger_paragraph_no_charger')
                    : ''
            }
        >
            <InnerSpinner isActive={!userChargersLoaded}>
                {/* FILTER + GRID-ROW-TOGGLE */}
                {userChargers.length > 1 && (
                    <>
                        <div className='charger-page__options'>
                            {userChargers.length >= 5 && (
                                <div
                                    className={
                                        'mb-10 charger-page__input ' +
                                        (searchFocused ? 'charger-page__input__focused' : '')
                                    }
                                >
                                    <img src={searchIcon} alt='' />
                                    <Input
                                        placeholder={t('charger_search_placeholder')}
                                        value={filterText}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                            filterChargerList(e.currentTarget.value)
                                        }
                                        {...getInputProps()}
                                    />
                                </div>
                            )}
                            {!mobileView && (
                                <Switch
                                    className='ml-auto mb-10'
                                    checked={gridMode === GridType.TABLE ? true : false}
                                    onChange={() => {
                                        setGridMode(gridMode === GridType.TABLE ? GridType.CARD : GridType.TABLE);
                                    }}
                                    switchInnerText={''}
                                    toggleButtonClassName={'switch__color switch__color__purple'}
                                    iconsEnabled={true}
                                    trueIconSrc={tableViewIcon}
                                    falseIconSrc={gridViewIcon}
                                />
                            )}
                        </div>
                    </>
                )}

                {userChargers.length === 1 && (
                    <ChargerCardDetail
                        key={userChargers[0].id}
                        handleClose={() => {}}
                        data={userChargers[0].id}
                        isBusiness={false}
                        updateList={() => {}}
                        isSingleChargerView={true}
                    />
                )}
                {userChargers.length > 1 && (
                    <Chargers
                        userChargers={tableChargers}
                        updateList={updateList}
                        gridMode={mobileView ? GridType.TABLE : gridMode}
                    />
                )}
            </InnerSpinner>
        </Page>
    );
}

export default Charger;
