export default {
    payment: {
        title: 'Zahlungsmethode'
    },
    invoices: {
        title: 'Rechnungen & Dokumente',
        no_invoices_or_quotes: 'Keine Rechnungen oder Dokumente verfügbar',
        loading: 'Laden von Rechnungen & Angebote...'
    }
}
