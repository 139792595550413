import { DisplayCardTypes } from 'components/DisplayCard';
import { TYPE_ERROR } from 'components/Toast';
import { HomeGarageImage } from 'constants/images';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import loaderActions from 'store/loader';
import { openToast } from 'store/toast';
import userActions, { setRegistrationComplete } from 'store/user';
import api from '../api/api';
import Token from '../api/auth-token-wrapper';
import { Button, DisplayCard, Input, Page } from '../components';
import InputType from '../constants/inputType';
import UserType from '../constants/userType';
import { routePaths } from '../route_paths';
import { IRootState } from '../store';
import { ILoggedUser } from '../typeScript/interfaces/IUser';
import { ButtonType } from '../typeScript/types/ButtonTypes';
import { showApiError } from '../utils/error-handler';
import {
    getValidationRules,
    hasValidationError,
    ruleNames,
    validationErrorTranslation,
    validationService
} from '../validation';

function Login(props) {
    const { t } = useTranslation('auth');
    const history = props.history;
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState<any[]>([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [login, setLogin] = useState({
        email: '',
        password: ''
    });
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);

    const handleChange = (event) => {
        let update = event.target.id;
        setLogin({ ...login, [update]: event.target.value });
    };

    const goToForgotPassword = () => {
        history.push(routePaths.resetPassword);
    };

    const userLogin = () => {
        dispatch(loaderActions.setShowLoader(true));
        dispatch(loaderActions.setLoaderMessage(t('loader:loading_data')));
        api.auth
            .login(login.email, login.password)
            .then((response) => {
                Token.setAuthTokens(response.data);
            })
            .then(() => {
                return api.user.getLoggedUser().then((response) => {
                    dispatch(userActions.setUserIsLogged(true));
                });
            })
            .then(() => {
                api.user.getAccount().then((response) => {
                    dispatch(
                        userActions.setUserData({
                            salutation: response.data.salutation,
                            language: response.data.language,
                            type: response.data.type
                        })
                    );
                });

                api.leads.getLeadStatus().then((response) => {
                    if (response.data?.stageName?.toLowerCase() === 'new') {
                        dispatch(setRegistrationComplete(false));
                    } else {
                        dispatch(setRegistrationComplete(true));
                    }
                });
            })
            .catch((error) => {
                dispatch(loaderActions.setShowLoader(false));
                setButtonLoading(false);

                if (error.response.data.message === '401 Unauthorized: [no body]') {
                    dispatch(openToast(TYPE_ERROR, t('toast:wrong_credentials')));
                    return;
                }
                showApiError(error.response, dispatch, t);
            });
    };

    const handleLoginButton = (event) => {
        if (event) event.preventDefault();

        let rules = getValidationRules([ruleNames.email, ruleNames.loginPassword]);
        let params = {
            email: login.email,
            password: login.password
        };

        const validation = validationService(params, rules);

        if (validation.passes()) {
            setValidationErrors([]);
            setButtonLoading(true);
            userLogin();
        } else {
            const errors = validation.getErrors();
            setValidationErrors(errors);
        }
    };

    useEffect(() => {
        if (
            !!user?.type &&
            isLogged &&
            user?.registrationComplete !== undefined &&
            user?.registrationComplete !== null
        ) {
            if (!user.registrationComplete) {
                dispatch(openToast(TYPE_ERROR, t('toast:login_stage_new_error')));
                const link = user.type === UserType.EMPLOYEE ? routePaths.businessLogin : routePaths.portalOverview;

                history.push(link);
            } else {
                history.push(routePaths.portalOverview);
            }
        }
        // eslint-disable-next-line
    }, [user, isLogged]);

    return (
        <Page className='page--footer auth-page'>
            <div className='auth-page__content'>
                <div className='auth-page__content__image'>
                    <div className='auth-page__content__image__inner'>
                        <img src={HomeGarageImage} alt='' />
                    </div>
                </div>
                <div className='md:w-11/12 xl:w-6/12'>
                    <DisplayCard type={DisplayCardTypes.PLAIN} label={t('login.title')}>
                        <form>
                            <div className='input-col'>
                                <Input
                                    id='email'
                                    inputClass='input--white'
                                    type={InputType.EMAIL}
                                    value={login.email}
                                    label={t('common:labels.your_email')}
                                    onChange={(event) => handleChange(event)}
                                    hasValidationError={hasValidationError(ruleNames.email, validationErrors)}
                                    validationErrorLabel={validationErrorTranslation(
                                        ruleNames.email,
                                        validationErrors,
                                        t
                                    )}
                                    placeholder={t('common:placeholder.email_address')}
                                    containerClass='input__container mb__m'
                                />
                                <Input
                                    id='password'
                                    inputClass='input--white'
                                    type={InputType.PASSWORD}
                                    label={t('common:labels.your_password')}
                                    value={login.password}
                                    onChange={(event) => handleChange(event)}
                                    hasValidationError={hasValidationError(ruleNames.loginPassword, validationErrors)}
                                    validationErrorLabel={validationErrorTranslation(
                                        ruleNames.loginPassword,
                                        validationErrors,
                                        t
                                    )}
                                    placeholder={t('common:placeholder.password')}
                                    containerClass='input__container mb__ml'
                                />
                                <div className='buttons'>
                                    <Button type={ButtonType.quaternary} onClick={goToForgotPassword}>
                                        {t('login.forgot_password')}
                                    </Button>
                                    <Button
                                        loading={buttonLoading}
                                        buttonType='submit'
                                        onClick={(e) => handleLoginButton(e)}
                                    >
                                        {t('login.button')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </DisplayCard>
                </div>
            </div>
        </Page>
    );
}

export default Login;
