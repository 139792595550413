import React from 'react';
import cssVariables from '../utils/css-variables';

type Props = {
    color?: string;
    name: string;
    size: number;
    className?: string;
    onClick?: () => void;
};

function Icon(props: Props) {
    const { name, size = 24, color, ...restProps } = props;

    switch (name) {
        case iconTypes.progressSuccess:
            return (
                <svg
                    {...restProps}
                    width={size}
                    height={size}
                    viewBox='0 0 32 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <circle cx='16' cy='16' r='16' fill='#59BBB5' />
                    <path
                        d='M12 16.75L15.0222 19.5L20 14'
                        stroke='#3B4281'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
            );
        case iconTypes.receipt:
            return (
                <svg
                    {...restProps}
                    width={size}
                    height={size}
                    viewBox='0 0 61 80'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M2 12C2 6.47715 6.47715 2 12 2H56.1835C57.2881 2 58.1835 2.89543 58.1835 4V68C58.1835 73.5229 53.7063 78 48.1835 78H12C6.47716 78 2 73.5228 2 68V12Z'
                        stroke='white'
                        strokeWidth='4'
                    />
                    <line
                        x1='12.0303'
                        y1='21.3333'
                        x2='48.1526'
                        y2='21.3333'
                        stroke='white'
                        strokeWidth='4'
                        strokeLinecap='round'
                    />
                    <line
                        x1='12.0303'
                        y1='34.6667'
                        x2='48.1526'
                        y2='34.6667'
                        stroke='white'
                        strokeWidth='4'
                        strokeLinecap='round'
                    />
                    <line
                        x1='12.0303'
                        y1='48'
                        x2='48.1526'
                        y2='48'
                        stroke='white'
                        strokeWidth='4'
                        strokeLinecap='round'
                    />
                </svg>
            );
        case iconTypes.enabled:
            return (
                <svg
                    {...restProps}
                    width={size}
                    height={size}
                    viewBox='0 0 90 72'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <g filter='url(#enabled_filter)'>
                        <circle cx='40' cy='34' r='24' fill='white' />
                    </g>
                    <path
                        d='M32 34.4706L39.1111 40.9412L50.8235 28'
                        stroke='url(#enabled_line)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <defs>
                        <linearGradient
                            id='enabled_line'
                            x1='50.4121'
                            y1='26.5586'
                            x2='45.4121'
                            y2='47.0586'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop stopColor='#000000' />
                            <stop offset='1' stopColor='#000000' />
                        </linearGradient>
                    </defs>
                </svg>
            );

        case iconTypes.close:
            return (
                <svg
                    {...restProps}
                    width={size}
                    height={size}
                    viewBox='0 0 329.26933 329'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        fill={color || cssVariables.colors.black}
                        d='m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0'
                    />
                </svg>
            );

        case iconTypes.back:
            return (
                <svg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M18.9999 6.99997H4.41394L9.70694 1.70697L8.29294 0.292969L0.585938 7.99997L8.29294 15.707L9.70694 14.293L4.41394 8.99997H18.9999V6.99997Z'
                        fill='white'
                    />
                </svg>
            );
        case iconTypes.calendar:
            return (
                <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M8 10H14V16H8V10Z' fill='black' />
                    <path
                        d='M16 2H14V0H12V2H6V0H4V2H2C0.897 2 0 2.897 0 4V18C0 19.103 0.897 20 2 20H16C17.103 20 18 19.103 18 18V4C18 2.897 17.103 2 16 2ZM16.001 18H2V6H16L16.001 18Z'
                        fill='black'
                    />
                </svg>
            );
        case iconTypes.check:
            return (
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M16.6875 7.31201L10.3992 16.2949C10.3162 16.4141 10.2061 16.5119 10.0779 16.5803C9.94973 16.6486 9.80715 16.6855 9.66192 16.6879C9.51669 16.6903 9.37297 16.6581 9.2426 16.5941C9.11223 16.53 8.99895 16.4359 8.91208 16.3195L7.3125 14.187'
                        stroke='#000000'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
            );
        case iconTypes.x:
            return (
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M8 16L16 8'
                        stroke='#000000'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        d='M16 16L8 8'
                        stroke='#000000'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
            );
        case iconTypes.exclamationMark:
            return (
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <line
                        x1='11.8'
                        y1='5.8'
                        x2='11.8'
                        y2='14.2'
                        stroke='#000000'
                        strokeWidth='1.6'
                        strokeLinecap='round'
                    />
                    <line
                        x1='11.8'
                        y1='17.8'
                        x2='11.8'
                        y2='18.2'
                        stroke='#000000'
                        strokeWidth='1.6'
                        strokeLinecap='round'
                    />
                </svg>
            );
        case iconTypes.paymentcard:
            return (
                <svg width='63' height='48' viewBox='0 0 63 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M5.90625 47.25C2.64862 47.25 0 44.6014 0 41.3438V5.90625C0 2.64862 2.64862 0 5.90625 0H57.0938C60.3514 0 63 2.64862 63 5.90625V41.3438C63 44.6014 60.3514 47.25 57.0938 47.25H5.90625ZM3.9375 41.3438C3.9375 42.4305 4.8195 43.3125 5.90625 43.3125H57.0938C58.1805 43.3125 59.0625 42.4305 59.0625 41.3438V15.75H3.9375V41.3438ZM59.0625 11.8125V5.90625C59.0625 4.8195 58.1805 3.9375 57.0938 3.9375H5.90625C4.8195 3.9375 3.9375 4.8195 3.9375 5.90625V11.8125H59.0625Z'
                        fill='white'
                    />
                    <path
                        d='M13.7812 27.5625C12.6945 27.5625 11.8125 26.6805 11.8125 25.5938C11.8125 24.507 12.6945 23.625 13.7812 23.625H35.4375C36.5243 23.625 37.4062 24.507 37.4062 25.5938C37.4062 26.6805 36.5243 27.5625 35.4375 27.5625H13.7812Z'
                        fill='white'
                    />
                    <path
                        d='M13.7812 35.4375C12.6945 35.4375 11.8125 34.5555 11.8125 33.4688C11.8125 32.382 12.6945 31.5 13.7812 31.5H27.5625C28.6493 31.5 29.5312 32.382 29.5312 33.4688C29.5312 34.5555 28.6493 35.4375 27.5625 35.4375H13.7812Z'
                        fill='white'
                    />
                </svg>
            );
        default:
            return null;
    }
}

export const iconTypes = {
    progressSuccess: 'progressSuccess',
    receipt: 'receipt',
    enabled: 'enabled',
    plus: 'plus',
    plusRaw: 'plusRaw',
    close: 'close',
    back: 'back',
    calendar: 'calendar',
    check: 'check',
    x: 'x',
    exclamationMark: 'exclamationMark',
    paymentcard: 'paymentcard'
};

export default Icon;
