export default {
    support: {
        title: "Kontaktieren Sie unseren Kundenservice",
        small_title: "Wie können wir Ihnen weiterhelfen?",
        hotline: {
            title: "Kundenservice",
            text_1: "Wir sind Montag bis Freitag von 08.00 Uhr - 18.00 Uhr für Sie da:",
        },
        email: {
            title: "Schreiben Sie uns eine Nachricht",
            text: "Wir werden Ihre Nachricht so schnell wie möglich bearbeiten und uns bei Ihnen melden.",
        },
        description: "Kontaktieren Sie unser Support-Team. Wir machen guten Service zu unserer Priorität und werden uns innerhalb der nächsten 24h bei Ihnen melden. Wollen Sie nicht warten? Dann rufen Sie direkt unsere Hotline an.",
        placeholders: {
            textarea: 'Bitte schreiben Sie Ihre Nachricht hier',
            loading_topics: 'Themen laden...'
        },
        files: {
            title: "Anhänge"
        },
        buttons: {
            cancel: 'Abbrechen',
            send: 'Senden'
        }
    },
}