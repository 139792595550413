export default {
    support: {
        title: "Get help from our support",
        small_title: "Here to help",
        hotline: {
            title: "Support Hotline",
            text_1: "We’re available from Monday until Friday between 08:00 and 18:00 h.",
        },
        email: {
            title: "Email Support",
            text: "Send us an e-mail and our Support Team will get in touch with you shortly.",
        },
        description: "Get in touch with our support team. We make service our priority and will come back to within the next 24hr. Can’t wait? Don’t hesitate to call our hotline!",
        placeholders: {
            textarea: 'Please write your message here...',
            loading_topics: 'Loading topics...'
        },
        files: {
            title: "Your attachments"
        },
        buttons: {
            cancel: 'Cancel',
            send: 'Send'
        }
    },
}