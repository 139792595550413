import React from 'react';
import { Checkbox } from '.';
import { hasValidationError, ruleNames, validationErrorTranslation } from '../validation';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface AcceptTermsCheckboxProps {
    validationErrors: any;
    infoState: any;
    updateInfoState: (attribute, value, dispatch) => void;
}
function AcceptTermsCheckbox(props: AcceptTermsCheckboxProps) {
    const PersonalDetailAGB = require('../assets/pdfs/tcs/PersonalDetailAGB.pdf');
    const acceptTermsCheckboxHtmlShort = () => {
        const termsLink = PersonalDetailAGB;
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: t('checkout:personal_info_terms', { termsLink: termsLink })
                }}
            />
        );
    };
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <Checkbox
            id='terms'
            className='mb__xs'
            type='small'
            checked={props.infoState.terms_accepted_account}
            hasValidationError={hasValidationError(ruleNames.termsAcceptedAccount, props.validationErrors)}
            validationErrorLabel={validationErrorTranslation(ruleNames.termsAcceptedAccount, props.validationErrors, t)}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                props.updateInfoState('terms_accepted_account', !props.infoState.terms_accepted_account, dispatch)
            }
            label={acceptTermsCheckboxHtmlShort()}
        />
    );
}

export default AcceptTermsCheckbox;
