export default {
  payout_card_title: 'Home Charging Rückvergütung',
  payout_invitation: 'Verwalten und aktualisieren Sie Ihre Tarif- und Zahlungsinformationen.',
  payout_invitation_cta: 'Rückvergütung einrichten',
  header: {
    title: "Home-Charging Rückvergütung",
  },
  no_history: "Willkommen auf Ihrer Seite zur Home Charging Rückerstattung für das Laden zu Hause. Beginnen Sie damit, die Details Ihrer Tarife für firmenbezogene Ladevorgänge einzugeben. Sobald gespeichert, haben Sie eine umfassende Historie zur Hand, die es Ihnen ermöglicht, geplante sowie umgesetzte Änderungen im Laufe der Zeit zu verfolgen.",
  with_history: "Willkommen auf Ihrer Seite zur Home Charging Rückerstattung für das Laden zu Hause. Hier finden Sie eine detaillierte Aufzeichnung Ihrer gespeicherten Tarife für firmenbezogene Ladevorgänge. Die präsentierte Historie bietet umfassende Einblicke und ermöglicht es Ihnen, geplante sowie umgesetzte Änderungen im Laufe der Zeit zu verfolgen.",
  form_title: 'Ihr Ladetarif-Formular',
  form_intro: 'Bitte geben Sie hier Ihre Daten ein',
  button_show_tariff_form: 'Tarif aktualisieren',
  button_show_IBAN_form: 'IBAN aktualisieren',
  button_hide_form: 'Abbrechen',
  energy_price_suffix: 'Cent/kWh',
  tariff_header: 'Tarif Information',
  tariff_intro: 'Bruttoarbeitspreis in Cent/kWh',
  history_header_status: 'Status',
  history_header_price: 'Ladetarif',
  history_header_start_date: 'Startdatum',
  history_status_pending: 'In Bearbeitung',
  history_status_succeeded: 'Aktiv',
  history_status_ongoing: 'Geplant',
  history_status_failed: 'Fehler',
  history_status_expired: 'Abgelaufen',
  iban_header: 'IBAN Information',
  iban_creation_date: 'Änderungsdatum',
  iban_added: 'IBAN hinterlegt',
  date_tooltip: 'Bitte wählen Sie ein Datum in der Zukunft aus. Bei Problemen, wenden Sie sich bitte an unser Support Team.'
}