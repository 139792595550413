export default {
    something_went_wrong: 'Etwas ist schiefgelaufen, bitte versuchen Sie es später noch einmal.',
    personal_info_updated: 'Ihre persönlichen Daten wurden erfolgreich aktualisiert.',
    wrong_credentials:
        'Ungültige E-Mail-Adresse oder Passwort. Bitte versuchen Sie es erneut oder verwenden Sie "Passwort vergessen", um Ihre Anmeldedaten zurückzusetzen.',
    password_updated: 'Ihr Passwort wurde aktualisiert.',
    reset_password_success: 'Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse geschickt.',
    offline: 'Sie sind derzeit offline. Bitte laden Sie die Seite neu oder versuchen Sie es später erneut.',
    referral_code_success: 'Juhu! Ihr Code wurde erfolgreich angewendet.',
    support_success:
        'Ihre Nachricht wurde erfolgreich versendet. Wir werden uns so schnell wie möglich bei Ihnen melden!',
    login_stage_new_error: 'Für diese E-Mail-Adresse existiert kein my<b>En</b>OS<sup>TM</sup> Account.',
    added_payment_method_successful: 'Die Zahlungsmethode für die monatliche Rechnung wurde erfolgreich hinterlegt.',
    added_payment_method_failed:
        'Die Zahlungsmethode konnte nicht hinterlegt werde. Probieren Sie es erneut oder wenden Sie sich an den Kundenservice.',
    resend_email_to_reset_password: 'Ein neuer Link zum setzen des Passworts wurde an Ihre E-Mail-Adresse geschickt.',
    contract_termination_200: 'Ihre Kündigung ist bei uns eingegangen.',
    contract_file_size_too_large: 'Die Datei darf nicht größer als {{maxFileSize}}MB sein'
};
