import React from "react";

type Props = {
  text: string,
};

function Notification(props: Props) {
  const { text } = props;

  return (
    <div className="notification notification--secondary">
      <div
        className="notification__text"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
}

export default Notification;
