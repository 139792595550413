import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayCard } from '..';
import SortingType from '../../constants/SortingType';
import getChargerImage from '../../mixins/getChargerImage';
import { IUserCharger } from '../../typeScript/interfaces/IUser';
import { DisplayCardTypes } from '../DisplayCard';
import ChargerAuthIcon from './ChargerAuthIcon';

const desc = require('../../assets/GridSortUp.svg');
const asc = require('../../assets/GridSortDown.svg');

type Props = {
    chargers: IUserCharger[];
    handleRowClick: (e: any) => void;
};

const Grid = (props: Props) => {
    const { chargers, handleRowClick } = props;
    const { t } = useTranslation();
    const [sortDirection, setSortDirection] = useState<SortingType>(SortingType.DESC);
    const [sortField, setSortField] = useState<string>('chargerName');

    useEffect(() => {
        sortChargers(sortField, true);
        // eslint-disable-next-line
    }, [chargers]);

    const sortChargers = (fieldName: string, switchDirection: boolean = false) => {
        let direction = sortDirection;
        if (!switchDirection) {
            if (sortField === fieldName)
                direction = sortDirection === SortingType.ASC ? SortingType.DESC : SortingType.ASC;
            else direction = SortingType.DESC;
        }

        chargers.sort(function (a, b) {
            var textA =
                typeof a[fieldName] === 'string' || a[fieldName] instanceof String
                    ? a[fieldName]
                    : a[fieldName].toString();
            var textB =
                typeof b[fieldName] === 'string' || b[fieldName] instanceof String
                    ? b[fieldName]
                    : b[fieldName].toString();
            return direction === SortingType.DESC ? textA.localeCompare(textB) : textB.localeCompare(textA);
        });

        setSortDirection(direction);
        setSortField(fieldName);
    };

    const displayFilterImage = (sortedField, fieldName, order) => {
        if (sortedField !== fieldName) return null;
        else {
            if (order === SortingType.ASC) return <img src={asc} alt='' className='w-5' />;
            else if (order === SortingType.DESC) return <img src={desc} alt='' className='w-5' />;
            else return null;
        }
    };

    const gridColumnHeader = (sortDirection, sortedField, sortColumn) => {
        return (
            <DisplayCard type={DisplayCardTypes.TRANSPARENT_REDUCED} className='my-6'>
                <div className='grid grid-cols-5 lg:grid-cols-12 gap-4'>
                    <div className='col-span-3 flex flex-wrap items-center'>
                        {gridColumnHeaderItem(
                            sortDirection,
                            t('charger:charger_name'),
                            sortedField,
                            'chargerName',
                            sortColumn
                        )}
                    </div>
                    <div className='col-span-3 hidden lg:flex flex-wrap items-center text-sm'>
                        {gridColumnHeaderItem(
                            sortDirection,
                            t('charger:charger_address'),
                            sortedField,
                            'adress1',
                            sortColumn
                        )}
                    </div>
                    <div className='col-span-2 hidden lg:flex flex-wrap items-center text-sm'>
                        {gridColumnHeaderItem(
                            sortDirection,
                            t('charger:charger_zipCode'),
                            sortedField,
                            'zipCode',
                            sortColumn
                        )}
                    </div>
                    <div className='col-span-2 hidden lg:flex flex-wrap items-center text-sm'>
                        {gridColumnHeaderItem(
                            sortDirection,
                            t('charger:charger_city'),
                            sortedField,
                            'city',
                            sortColumn
                        )}
                    </div>
                    <div className='col-span-2 flex flex-wrap items-center text-sm'>
                        {gridColumnHeaderItem(
                            sortDirection,
                            t('charger:charger_authMode'),
                            sortedField,
                            'authorizationModeEnabled',
                            sortColumn
                        )}
                    </div>
                </div>
            </DisplayCard>
        );
    };

    const gridColumnHeaderItem = (order, headerText, sortedField, fieldName, sortClick) => {
        return (
            <div
                className={`truncate cursor-pointer flex items-center ${sortedField === fieldName ? 'font-bold' : ''}`}
            >
                <span className='truncate' onClick={() => sortClick(fieldName)} title={headerText}>
                    {headerText}
                </span>
                {displayFilterImage(sortedField, fieldName, order)}
            </div>
        );
    };

    return (
        <div className='charger-list__grid'>
            <hr />
            {gridColumnHeader(sortDirection, sortField, sortChargers)}
            {chargers.map((charger, index) => {
                return (
                    <DisplayCard
                        type={DisplayCardTypes.PLAIN_REDUCED}
                        className='mb-3'
                        onClick={() => handleRowClick(charger.id)}
                        key={index}
                    >
                        <div className='grid grid-cols-5 lg:grid-cols-12 gap-4'>
                            <div className='col-span-3 flex flex-wrap'>
                                {/* TODO::: DYNAMIC CHARGER IMAGE */}
                                <div className='whitespace-nowrap flex items-center'>
                                    <img src={getChargerImage(charger.stationModelName)} className='w-12' alt='' />
                                    <span className='truncate' title={charger.chargerName}>
                                        {charger.chargerName}
                                    </span>
                                </div>
                            </div>
                            <div className='col-span-3 hidden lg:flex flex-wrap items-center text-sm'>
                                <span title={charger.adress1}>{charger.adress1}</span>
                            </div>
                            <div className='col-span-2 hidden lg:flex flex-wrap items-center text-sm'>
                                <span title={charger.zipCode}>{charger.zipCode}</span>
                            </div>
                            <div className='col-span-2 hidden lg:flex flex-wrap items-center text-sm'>
                                <span title={charger.city}>{charger.city}</span>
                            </div>
                            <div className='col-span-2 flex flex-wrap items-center text-sm'>
                                <ChargerAuthIcon authMode={charger.authorizationModeEnabled} />
                            </div>
                        </div>
                    </DisplayCard>
                );
            })}
        </div>
    );
};

export default Grid;
