import {
	SET_CHARGER_PRODUCTS,
	SET_TARIFF_PRODUCTS,
	SET_PRODUCTS_LOADING,
	// SET_SMART_METER_PRODUCTS,
	SET_PRE_VISIT_PRODUCTS,
	SET_PRE_VISIT_ONLY_PRODUCTS,
	SET_DOWN_PAYMENT_PRODUCTS,
	SET_INSTALLATION_PACKAGES_PRODUCTS,
} from './'

import { IProduct } from '../../typeScript/interfaces/IProduct';

export const initialState: IProduct = {
	chargerProducts: [],
	// smartMeterProducts: [],
	installationPackagesProducts: [],
	tariffProducts: [],
	preVisitProducts: [],
	preVisitOnlyProducts: [],
	downPaymentProducts: [],
	productsLoading: false,
	productsLoaded: false,
	smartMeterProducts: []
};

export function productsReducer(state: IProduct = initialState, action): IProduct {
	switch (action.type) {
		case SET_CHARGER_PRODUCTS:
			return {
				...state,
				chargerProducts: action.value
			};
		// case SET_SMART_METER_PRODUCTS:
		// 	return {
		// 		...state,
		// 		smartMeterProducts: action.value
		// 	};
		case SET_INSTALLATION_PACKAGES_PRODUCTS:
			const installPackages = action.value.sort((a, b) => a.prices[0]?.unitPrice - b.prices[0]?.unitPrice);
			return {
				...state,
				installationPackagesProducts: installPackages
			};
		case SET_TARIFF_PRODUCTS:
			const products = action.value.sort((a, b) => a.kwh.split("")[0] - b.kwh.split("")[0]);
			return {
				...state,
				tariffProducts: products
			};
		case SET_PRE_VISIT_PRODUCTS:
			return {
				...state,
				preVisitProducts: action.value
			};

			case SET_PRE_VISIT_ONLY_PRODUCTS:
			return {
				...state,
				preVisitOnlyProducts: action.value
			};

		case SET_DOWN_PAYMENT_PRODUCTS:
			return {
				...state,
				downPaymentProducts: action.value
			};
		case SET_PRODUCTS_LOADING:
			return {
				...state,
				productsLoading: action.value,
				productsLoaded: !action.value
			};
		default:
			// We need the default to ALWAYS return the initial state.
			return state
	}
}
