import {
  SET_HAS_RECURRING_PAYMENT_DETAILS, SET_IS_PAYMENT_INFORMATION_IS_NEEDED, SET_PAYMENT_STATUS,
  SET_PAYMENT_STATUS_LOADING
} from ".";
import { IPayment, IPaymentStatus } from "../../typeScript/interfaces/IPayment";

const DEFAULT_PAYMENT_STATUS: IPaymentStatus = {
    amount: 0,
    method: "",
    reference: "",
    status: "",
    cardType: "",
};

export const initialState: IPayment = {
    status: DEFAULT_PAYMENT_STATUS,
    statusLoading: false,
    statusLoaded: false,
    statusHasRecurringPaymentDetails: false,
    statusCanUpdatePaymentInformation: false
};

export function paymentReducer(
    state: IPayment = initialState,
    action
): IPayment {
    switch (action.type) {
        case SET_PAYMENT_STATUS:
            return {
                ...state,
                status: action.value
            };
        case SET_PAYMENT_STATUS_LOADING:
            return {
                ...state,
                statusLoading: action.value,
                statusLoaded: !action.value
            };
        case SET_HAS_RECURRING_PAYMENT_DETAILS:
            return {
                ...state,
                statusHasRecurringPaymentDetails: action.value
            };
        case SET_IS_PAYMENT_INFORMATION_IS_NEEDED:
            return {
                ...state,
                statusCanUpdatePaymentInformation: action.value
            };
        default:
            // We need the default to ALWAYS return the initial state.
            return state;
    }
}
