export default {
    menu: {
        planning: 'Planung und Ablauf ',
        personal_detail: 'Persönliche Informationen',
        installation_detail: 'Details zum Pre-Visit',
        confirmation: 'Bestätigung'
    },
    login: {
        title: 'Charging by <b>En</b>OS<sup>TM</sup>',
        companyCode: 'Firmencode',
        definition:
            'Charger, grüner Strom und fachmännische Installation - das Komplettpaket zum Laden zuhause. Am Anfang steht die Planung. Geben Sie Ihren Code ein, um einen Pre-Visit Termin mit einem unserer Elektriker zu buchen:',
        note: 'Dies ist ein exklusives Angebot für Mitarbeiter. Eine Veröffentlichung oder die Weitergabe des Firmencodes an Dritte ist nicht gestattet.'
    }
};

const businessNissan = {
    menu: {
        planning: 'Planung und Ablauf ',
        personal_detail: 'Persönliche Informationen',
        installation_detail: 'Details zum Pre-Visit',
        confirmation: 'Bestätigung'
    },
    login: {
        title: 'Charging by <b>En</b>OS<sup>TM</sup> - Ladelösungen von Univers',
        companyCode: 'Nissan Code',
        definition:
            'Ihre fachmännische Installation Ihrer Nissan Ladestation bei Ihnen zuhause. Am Anfang steht die Planung. Geben Sie Ihren Code ein, um einen Termin für einen Vor-Ort Besuch mit einem unserer Elektriker zu buchen.',
        note: 'Dies ist ein exklusives Angebot für Nissan Kunden. Eine Veröffentlichung oder die Weitergabe des Codes an Dritte ist nicht gestattet.'
    }
};

const businessGoinGreen = {
    menu: {
        installation_detail: 'Details zum Vor-Ort-Besuch',
    },
    login: {
        title: 'Charging by <b>En</b>OS<sup>TM</sup> - Ladelösungen von Univers',
        companyCode: 'Aktionscode',
        definition:
            'Univers ist der Profi für die fachmännische Installation von E-Auto Ladelösungen. Am Anfang steht die Planung. Geben Sie Ihren Aktionscode ein, um einen Termin für einen Vor-Ort-Besuch mit einem unserer Elektriker zu buchen.',
    }
};

export { businessNissan, businessGoinGreen };
