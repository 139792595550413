export default {
    headline: 'Übersicht über Ihre Pre-Visit Buchung',
    description: 'Bitte überprüfen Sie nochmal Ihre Angaben.',
    product: {
        title: 'Produkt',
        description: 'Pre-Visit: Vor-Ort-Check durch einen zertifizierten Elektriker',
    },
    personal_information: {
        title: 'Persönliche Informationen',
        name: 'Name',
        birthdate: 'Geburtsdatum',
        street: 'Straßenname / Hausnummer',
        city: 'Stadt',
        postal_code: 'Postleitzahl',
        country: 'Land'
    },
    appointment_information: {
        title: 'Termin Informationen',
        email_address: 'E-Mail Adresse',
        phone_number: 'Telefonnummer',
        preferred_date: 'Wunschdatum'
    },
    informational_note: {
        B2B2E: 'Für den Pre-Visit fällt eine einmalige Gebühr von 99,00 EUR inkl. MwSt. an, die nach der Durchführung des Termins aufgrund der mit Ihrem Arbeitgeber getroffenen Vereinbarungen durch Ihren Arbeitgeber entrichtet wird.',
        B2B2C: 'Für den Pre-Visit fällt eine einmalige Gebühr von 99,00 EUR inkl. MwSt. an, die nach der Durchführung des Termins aufgrund der mit Ihrem Arbeitgeber getroffenen Vereinbarungen durch Sie zu entrichten ist.'
    },
    terms_and_conditions: 'Ja, ich stimme den <1>Nutzungsbedingungen</1> des my<2></2>OS<3></3> Kundenportals und den <4>Allgemeinen Geschäftsbedingungen</4> des Pre-Visits zu.'
};

const businessOrderConfirmationNissan = {
    headline: 'Übersicht über Ihre Terminvereinbarung',
    product: {
        description: 'Pre-Visit: Vorab-Check durch einen zertifizierten Elektriker von Univers',
    },
    informational_note: {
        B2B2C: 'Die für den Pre-Visit anfallende Gebühr i.H.v. 99,00 EUR inkl. MwSt. ist Bestandteil des von Ihnen bereits bezahlten Gutscheins.'
    },
    terms_and_conditions: 'Ja, ich stimme zu, dass mein Autohändler über meinen Installationsfortschritt informiert wird.',
    extra_info: 'Hinweis: Sollten Sie das Installationsangebot ablehnen, wird Univers diese Information mit Ihrem Autohändler teilen um eine Rückabwicklung gewährleisten zu können.',
}


const businessOrderConfirmationGoinGreen = {
    product: {
        description: 'Vor-Ort-Besuch: Analyse und Angebot durch einen zertifizierten Elektriker von Univers',
    },
    informational_note: {
        B2B2C: 'Für den Vor-Ort-Besuch (Pre-Visit) fällt eine einmalige Gebühr von 99,00 EUR inkl. MwSt. an, die von Ihnen nach erfolgreicher Durchführung zu entrichten ist. Sollten Sie sich im Anschluss für eine Installations-Beauftragung entscheiden, wird Ihnen dieser Betrag und zzgl. 199,00 EUR inkl. MwSt., insgesamt also 298,00 EUR inkl. MwSt., auf das Installations-Angebot angerechnet und vom Rechnungsbetrag abgezogen.'
    },
    terms_and_conditions: 'Ja, ich stimme den <1>Nutzungsbedingungen</1> des my<2></2>OS<3></3> Kundenportals und den <4>Allgemeinen Geschäftsbedingungen</4> des Vor-Ort-Besuchs zu.'
}

export { businessOrderConfirmationNissan, businessOrderConfirmationGoinGreen };
