import React, { useState, useRef, useEffect } from 'react';
import { isDescendant } from '../utils/helpers';
import { Label } from '.';
import lodash from 'lodash-es';

type Option = {
    value: string;
    label: string;
};

type Props = {
    id: string;
    options: Option[];
    includePlaceholderAsOption?: boolean;
    selected: Option | string;
    placeholder?: string;
    toggleClass?: string;
    menuClass?: string;
    onSelect: (s: string) => void;
    hasValidationError?: boolean;
    validationErrorLabel?: string;
    label?: string;
    labelClassName?: string;
    disabled?: boolean;
    required?: boolean;
    inputClasses?: string;
    expandToTop?: boolean;
};

function Dropdown(props: Props) {
    const {
        id = '',
        options = [],
        includePlaceholderAsOption = false,
        selected = { value: '', label: '' },
        hasValidationError = false,
        validationErrorLabel = '',
        placeholder = '',
        label = '',
        toggleClass = '',
        menuClass = '',
        disabled = false,
        labelClassName = '',
        required = true,
        inputClasses = '',
        onSelect = (option: Option) => {},
        expandToTop = false
    } = props;

    const [showMenu, setShowMenu] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const showPlaceholder = !selected || !(selected as Option).value;

    const toggleMenu = () => {
        if (!disabled) setShowMenu(!showMenu);
    };

    const getAvailableOptions = () => {
        let optionsCopy = lodash.cloneDeep(options);
        if (includePlaceholderAsOption) {
            optionsCopy.push({ label: placeholder, value: '' });
        }
        return optionsCopy;
    };

    const availableOptions = getAvailableOptions();

    const updateOption = (option) => {
        if (option.value !== (selected as Option).value) {
            onSelect && onSelect(option);
        }
    };

    const activeOptionClass = (option) => {
        if (selected && (selected as Option).value === option.value) {
            return 'dropdown__item--active';
        }
        return '';
    };

    const handleOutsideClick = (e) => {
        e.stopPropagation();
        e.cancelBubble = true;
        if (e.target !== dropdownRef.current && !isDescendant(dropdownRef.current, e.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        window.addEventListener('mouseup', handleOutsideClick);

        return () => {
            window.removeEventListener('mouseup', handleOutsideClick);
        };
    }, []);

    return (
        <>
            {label && (
                <Label
                    htmlFor='contact_method'
                    className={labelClassName}
                    type={hasValidationError ? 'error' : 'default'}
                    errorLabel={validationErrorLabel}
                >
                    {label}
                    {required ? '*' : ''}
                </Label>
            )}
            <div className='dropdown' onClick={toggleMenu} ref={dropdownRef}>
                <div className={'dropdown__toggle ' + toggleClass}>
                    <input
                        id={id}
                        disabled={disabled}
                        className={
                            inputClasses +
                            ' input dropdown__selected' +
                            (showPlaceholder ? ' dropdown__selected--placeholder' : '') +
                            (showMenu ? ' dropdown__selected--show' : '')
                        }
                        placeholder={placeholder}
                        value={selected && typeof selected === 'object' ? selected.label : ''}
                        readOnly
                        data-expand-to-top={expandToTop}
                    />

                    {disabled ? '' : <span className='caret' />}
                </div>
                {showMenu && (
                    <ul className={'dropdown__menu ' + menuClass} data-expand-to-top={expandToTop}>
                        {availableOptions.map((option, index) => (
                            <li key={'key' + index}>
                                <p
                                    onClick={() => updateOption(option)}
                                    className={'dropdown__item ' + activeOptionClass(option)}
                                >
                                    {option.label}
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
}

export default Dropdown;
