import Constants from '../../constants';

export default {
    reference_number: 'Reference number: {{number}}',
    personal_info: 'Personal information',
    personal_info_b2b2e: 'Personal information',
    personal_info_tag: 'To make an appointment, we need some information from you first',
    personal_info_terms: 'By registering, I declare that I have read and agree to the <a href="{{termsLink}}" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>',
    mandatory_fields: "* Mandatory fields",

    create_envision_account: 'create my<b>En</b>OS<sup>TM</sup> account',
    envision_account_text_not_logged: 'One account for everything. Your my<b>En</b>OS<sup>TM</sup> account is your future access to all Univers products and services. You can use it to log in to the customer portal and the app.',

    create_envision_account_previsit_only: 'my<b>En</b>OS<sup>TM</sup> portal access',
    envision_account_text_not_logged_previsit_only: 'In the portal, we provide you with information on the electricity contract, GTC, invoices and product data sheets for your charging station, among other things. The access data is also your login information for the "EnOS Charge" app for your smartphone to control the chargers',

    envision_account_text_logged: 'You are currently logged in as {{email}}',
    password_policy: 'The password should contain a number, a symbol (!@#%&*(){}[]-_=|) and at least one lower case and one upper case letter. The password should contain at least 8 characters',

    sign_into_envision: 'Log in to myUnivers',
    sign_into_envision_text: 'Log in to myUnivers to provide the installer with the details required to schedule their visit and track the installation process.',

    make_mistake: 'If you think you have made a mistake, please contact us',

    install_info: 'Installation information',
    install_address: 'Installation address',
    installation_details: 'Installation details',
    install_info_tagline: 'Please enter the address where the pre-visit should be performed',
    preferred_install_date: 'Preferred date',
    payment_info: 'Payment methods',
    payment_method: 'Payment method',
    payment_subtext_title: '1. Pay now when ordering',
    payment_subtext: 'We will save the payment method you selected for the one-off payment as the payment method of your choice for the monthly payments of your tariff package.*',
    payment_previsit_only_subtext: "We save the payment method you choose for the one-off payment as your payment method of choice for the monthly payments of your tariff package incl. electricity, should you decide to become an Univers customer.*",
    payment_second_subtext_title: "2nd remaining payment after successful installation",
    payment_second_subtext: "You pay the full amount only after your charger has been installed. You will receive the access with your invoice by e-mail. You can also finance the remaining amount conveniently and flexibly with Klarna.",
    payment_review: "*By choosing the payment method Giropay/Direct Debit, you are granting Univers Operations GmbH a SEPA direct debit mandate for the bank account specified above. You can revoke a direct debit within eight weeks after the amount has been debited from your account.",
    payment_methode_selection_hint: "Payment method update hint: If you select credit card or SEPA direct debit as the payment method for the monthly invoice, a transaction or reservation of 0 euros will be made to verify the newly deposited payment information.",
    payment_methode_selection_confirmation: "I hereby authorize Univers Operations GmbH to debit the monthly amount due for the tariff package incl. VAT. Strom to automatically debit the payment method I have deposited.",
    review_order: 'Please review your order',
    review_order_small_print: 'All prices include VAT (currently 19%).',
    small_print_all_prices: 'Univers Operations GmbH will provide you with the meter required for our Bundle Charging by <b>En</b>OS<sup>TM</sup> at no extra charge. To do this, Univers will enter into a contract on your behalf and for your account with a metering point operator, Discovergy GmbH, for a period of two years after conclusion of the contract. The general terms and conditions of the metering point operator apply. <br>If the information you provided during the online order process regarding the actual and/or technical conditions on site is incorrect or inaccurate, Univers reserves the right to offer you a different installation package. <br>Do you have any questions about your order? Our service team will be happy to help you. You can reach them by e-mail at kundenservice@univers.de or by telephone on +49 (0)30 - 89 89 88 24.',
    small_print_pre_visit_only: '',
    payment_confirmation_page: {
        thank_you_for_order: 'Thank you for your order',
        error_header: 'Payment error.',
        refused_header: 'Payment refused',
        cancelled_header: 'Payment cancelled',
        payment_success: 'We have received your order. You will receive an order confirmation by email shortly. <br><br>Do you have any questions about your order? Our service team will be happy to help you. You can reach us by email at <1>kundenservice@univers.de</1> or call us at <2>030 - 89 89 88 24</2>.',
        payment_received: 'Your payment is currently being processed. You will receive your order confirmation by email shortly. Do you have any questions about your order? Our service team will be happy to help you. You can reach us by email at <1>kundenservice@univers.de</1> or by phone at <2>030 - 89 89 88 24</2>.',
        payment_refused: 'Unfortunately, your payment was declined. To try the payment again, click the button below.',
        payment_error: 'Something went wrong while trying to process your payment. To try the payment again, click the button below',
        payment_cancelled: 'Your payment was cancelled during the checkout process. To retry the payment, click on the button below.',
        continue_header: 'my<b>En</b>OS<sup>TM</sup> customer portal',
        continue_text: 'In the customer portal we keep you up to date with all your appointments. You can also view your contract details, make changes to your details and browse useful tips here.<br><br><b>The more information we receive about the installation site, the better we can plan! Our partners would be happy if you upload a few photos using the installation guide in the customer portal.</b>',
        back_header: 'Repeat your payment',
        back_text: 'Repeat your payment process or contact our customer support: {{phone}}',
        continue_button: 'Register now',
    },

    confirmation_step: {
        terms: 'Yes, I agree to the <1>Terms and Conditions</1> and have noted my right of withdrawal.',
        info_install_address:"{{streetAddress}} {{streetNo}}<1/>{{postCode}} {{city}}, {{country}}",
        payment_title: 'Payment',
        payment_text: 'To be clarified in the next step',
    },

    payment_type: {
        [Constants.PAYMENT_TYPES.DIRECT_DEBIT]: 'Direct debit',
        [Constants.PAYMENT_TYPES.CREDIT_CARD]: 'Credit card',
        giropay: 'Giropay',
        scheme: 'Debit/Credit Card'
            //[Constants.PAYMENT_TYPES.PAYPAL]: 'Paypal',
    },
    payment_summary: {
        [Constants.PAYMENT_TYPES.DIRECT_DEBIT]: '{{accountHolder}}, IBAN {{iban}}, BIC {{bic}}, {{bankName}}',
        [Constants.PAYMENT_TYPES.CREDIT_CARD]: '{{cardHolder}}, card {{cardNo}}, expiry date {{expiryDate}}',
    },

    salutations: {
        marriedCouple: 'MarriedCouple',
        mr: 'Mr.',
        mrs: 'Mrs.'
    },

    contact_methods: {
        email: 'Email',
        phone: 'Phone',
        post: 'Mail'
    },

    product: {
        title: 'product selection',
        hardware: {
            smart_meter: 'Smart Meter',
            title: 'Charger incl. Smart Meter',
            type: 'version',
            payment: 'Total price',
            payment_by_order: 'Deposit when order is placed',
            payment_discount: 'Friends and family discount',
            payment_by_installation: 'Final payment (after successful installation)',
        },
        package: {
            title: 'Installation package {{name}}',
            pre_visit_title: 'Pre-visit',
            pre_visit_desc: 'Pre-visit (credited against installation cost, due immediately)',
            install_cost: 'Installation cost',
            total_cost: 'Total price',
            payment_by_order: 'Down payment when order is placed',
            payment_by_installation: 'Final payment (after successful installation)',
            answer_package: 'Why {{package_name}}?',
            check_answer: 'Check answers'
        },
        total_price: 'Total amount payable now'
    }
}


const checkoutNissan = {
    envision_account_text_not_logged: 'In our portal we provide you with information and you can view your data and contracts. Your email and the password you assign here will also give you access to our app, which you can use to control your charging station after installation. If you decide to become a green electricity customer, you can also view and download your monthly electricity bill on the portal.',
    envision_account_text_not_logged_previsit_only: 'In our portal we provide you with information and you can view your data and contracts. Your email and the password you assign here will also give you access to our app, which you can use to control your charging station after installation. Should you decide to become a green electricity customer, you can also view and download your monthly electricity bill in the portal.'
};


const checkoutGoinGreen = {
    installation_details: 'Installation details',
    install_info_tagline: 'Please enter the address where the pre-visit should be performed',
    envision_account_text_not_logged: 'In our portal we provide you with information and you can view your offers. Your email and the password you assign here will also give you access to our app, which you can use to control your charging station after installation. If you decide to become a green electricity customer, you can also view and download your monthly electricity bill on the portal.',
    envision_account_text_not_logged_previsit_only: 'In our portal we provide you with information and you can view your offers. Your email and the password you assign here will also give you access to our app, which you can use to control your charging station after installation. Should you decide to become a green electricity customer, you can also view and download your monthly electricity bill in the portal.'
};

export { checkoutNissan, checkoutGoinGreen };
