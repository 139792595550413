export default {
    intro: {
        title: "Welcome to your installation guide",
        text: "This guide includes all you need for a successful installation. Let us help you make sure you are all set and ready to go.",
        button: "Get started"
    },
    photo_upload: {
        title: 'Upload photos',
        text: 'Your photos will help our electrician to get a feel for the on-site conditions.',
        // house_connection: 'Photo of your house connection',
        // meter_location: 'Photo of your meter location',
        // meter_fuse: 'Photo of your meter fuse',
        // charging_location: 'Photo of your planned charging location',
        extra_image: 'Extra image #{{number}}',
        distribution_board: 'Distribution board (consumer unit)',
        distribution_board_fuse: 'Distribution board – fuse',
        garage_inside: 'Garage – inside view',
        garage_outside: 'Garage – outside view',
        distance_house_garage: 'Distance house – garage'
    },
    end: {
        title: "Well done, {{name}}",
        text: "That’s it! Your photos will be forwarded to the electrician in charge as soon as possible. Thank you!",
    }
}
