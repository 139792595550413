import UserType from "constants/userType";
import userActions from "store/user";
import api from "../api/api";
import Token from "../api/auth-token-wrapper";

export default async(dispatch, successCallback, errorCallback) => {
    dispatch(userActions.setUserLoading(true));

    // We have to refresh/reload only if the token was added already.
    let shouldRefresh = Token.getToken();

    try {
        let response = await api.user.getLoggedUser();
        dispatch(userActions.setUserData(response.data));
        dispatch(userActions.setUserIsLogged(true));

        let lead = await api.user.getAccount();
        dispatch(userActions.setUserData({ salutation: lead.data.salutation, language: lead.data.language, type: lead.data.type }));

        successCallback && successCallback(lead.data.type === UserType.EMPLOYEE);
    } catch (error) {
        if (!is401Error(error)) {
            try {
                let response = await api.auth.refreshToken();
                Token.setAuthTokens(response.data);
                shouldRefresh = Token.getToken();
                try {
                    let userDataResponse = await api.user.getLoggedUser();
                    dispatch(userActions.setUserIsLogged(true));
                    dispatch(userActions.setUserData(userDataResponse.data));
                    let lead = await api.user.getAccount();
                    dispatch(userActions.setUserData({ salutation: lead.data.salutation, language: lead.data.language, type: lead.data.type }));

                    successCallback && successCallback(lead.data.type === UserType.EMPLOYEE);
                } catch (error) {
                    if (is401Error(error)) {
                        // Remove token.
                        removeAndReload(shouldRefresh);
                    }

                    dispatch(userActions.resetUser()); // note that we shouldn't remove the tokens at this point, because perhaps the api call failed due to internet connectivity
                    errorCallback && errorCallback(error);
                }
            } catch (error) {
                if (is401Error(error)) {
                    // Remove token.
                    removeAndReload(shouldRefresh);
                }
                dispatch(userActions.resetUser()); // note that we shouldn't remove the tokens at this point, because perhaps the api call failed due to internet connectivity
                errorCallback && errorCallback(error);
            }
        } else {
            // Remove token.
            removeAndReload(shouldRefresh);
            errorCallback && errorCallback(error);
        }
    }

    dispatch(userActions.setUserLoading(false));
}

const removeAndReload = (shouldReload) => {
    Token.removeAuthTokens();
    if (shouldReload) {
        window.location.reload();
    }
}

const is401Error = (error) => {
    return JSON.stringify(error).indexOf('401') !== -1
}