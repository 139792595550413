export default {
    header: {
        title: "Vielen Dank für Ihre Terminbuchung",
        text: "Wir haben Ihre Terminanfrage mit der Referenznummer <span>{{reference_number}}</span> erhalten. In Kürze erhalten Sie die Bestätigung per E-Mail.",
        text2: "In der Zwischenzeit können Sie sich in das my<span>En</span>OS<sup>TM</sup> Portal einloggen. Im Portal stellen wir nützliche Informationen bereit, Sie können Ihre Daten einsehen und ändern, den Status der Installation verfolgen oder mit unserem Kundenservice in Kontakt treten.",
        genericErrorTitle: "Oops - etwas ist schief gelaufen.",
        genericErrorText: "Leider ist bei der Übermittlung der Daten ein Fehler aufgetreten. <br/> <br/> Bitte probieren Sie es erneut oder wenden Sie sich an den Kundenservice. "
    },
    tryAgain: "Erneut probieren",
    closeConfirmation: 'Fertig',
};

const b2b2eConfirmationNissan = {
    header: {
        text: "Vielen Dank für Ihre Terminbuchung mit der Referenznummer <span>{{reference_number}}</span>. In Kürze erhalten Sie eine Bestätigung per E-Mail."
    }
}

export { b2b2eConfirmationNissan };
