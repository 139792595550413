import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'typeScript/types/ButtonTypes';
import { Input, Switch } from '../';
import InputType from '../../constants/inputType';
import getChargerImage from '../../mixins/getChargerImage';
import { hasValidationError, ruleNames, validationErrorTranslation } from '../../validation';
import Button from '.././Button';
import { ChargerDetailCardCommonProps } from './ChargerCardDetail';

const editButton = require('../../assets/EditButton-2.svg');

export function BusinessChargerCardDetail(props: ChargerDetailCardCommonProps) {
    const {
        userCharger,
        editClicked,
        handleSaveButton,
        loading,
        onInputChange,
        validationErrors,
        isSingleChargerView,
        onEditClicked,
        onCancelClicked,
        onSwitchClicked,
        toggle
    } = props;

    const { t } = useTranslation(['charger', 'common']);

    return (
        <div className='charger-card-detail--business'>
            {/* TODO::: DYNAMIC CHARGER IMAGE */}
            <div className='charger-card-detail--business__img max-w-xs mx-auto w-full'>
                <img src={getChargerImage(userCharger.stationModelName)} alt='' />
            </div>

            <div className='charger-card-detail--business__info'>
                {(() => {
                    if (loading) {
                        return <div className='spinner'></div>;
                    } else {
                        return (
                            <>
                                <div className='charger-card-detail--business__properties'>
                                    <div className='charger-card-detail--business__feature charger-card-detail--business--name'>
                                        <hr />
                                        <span>
                                            <h6>{t('charger_name')}</h6>
                                            {editClicked ? (
                                                <Input
                                                    id='chargerName'
                                                    value={userCharger.chargerName}
                                                    inputClass='input--grey'
                                                    type={InputType.TEXT}
                                                    disabled={!editClicked}
                                                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                        onInputChange('chargerName', e.currentTarget.value)
                                                    }
                                                    hasValidationError={hasValidationError(
                                                        ruleNames.chargerName,
                                                        validationErrors
                                                    )}
                                                    validationErrorLabel={validationErrorTranslation(
                                                        ruleNames.chargerName,
                                                        validationErrors,
                                                        t
                                                    )}
                                                    containerClass='input__container mb__m'
                                                />
                                            ) : (
                                                <h3 title={userCharger.chargerName}> {userCharger.chargerName} </h3>
                                            )}
                                        </span>
                                        <img
                                            className={editClicked ? 'charger-card-detail__name-img' : ''}
                                            onClick={onEditClicked}
                                            src={editButton}
                                            alt='button'
                                        />
                                        <hr />
                                    </div>

                                    <div className='charger-card-detail--business__feature charger-card-detail--business--authmode'>
                                        <div className='row'>
                                            <h5> {t('charger_detail_authMode')} </h5>
                                            <h6> {t('charger_detail_authMode')} </h6>
                                            <span className='charger-card-detail--business__label'>
                                                {toggle ? t('charger_auth.activated') : t('charger_auth.disabled')}
                                            </span>
                                            <Switch
                                                className='charger-card-detail__toggle-button'
                                                checked={toggle}
                                                onChange={onSwitchClicked}
                                                switchInnerText={''}
                                                toggleButtonClassName={
                                                    'switch__color ' +
                                                    (toggle ? 'switch__color__green' : 'switch__color__grey')
                                                }
                                            />
                                        </div>
                                        <p
                                            className='charger-card-detail-auth-info'
                                            dangerouslySetInnerHTML={{ __html: t('charger_detail_text') }}
                                        ></p>
                                    </div>

                                    <div className='charger-card-detail--business__feature divider'>
                                        <hr />
                                    </div>

                                    <div className='charger-card-detail--business__feature'>
                                        <h6> {t('charger_detail_city')} </h6>
                                        <span className='charger-card-detail__label'>{userCharger.city}</span>
                                    </div>

                                    <div className='charger-card-detail--business__feature'>
                                        <h6> {t('charger_detail_address')} </h6>
                                        <span className='charger-card-detail__label'>{userCharger.adress1}</span>
                                    </div>

                                    <div className='charger-card-detail--business__feature'>
                                        <h6> {t('charger_detail_capacity')} </h6>
                                        <span className='charger-card-detail__label'>{`${userCharger.chargingCapacity} kW`}</span>
                                    </div>

                                    <div className='charger-card-detail--business__feature'>
                                        <h6> {t('charger_detail_zipCode')} </h6>
                                        <span className='charger-card-detail__label'>{userCharger.zipCode}</span>
                                    </div>

                                    <div className='charger-card-detail--business__feature charger-info-serial-number'>
                                        <h6>{t('charger_detail_serialNumber')}</h6>
                                        <span className='charger-card-detail__label'>{userCharger.serialNumber}</span>
                                    </div>

                                    <div className='charger-card-detail--business__feature'>
                                        <h6> {t('charger_detail_manufacturer_model')} </h6>
                                        <span className='charger-card-detail__label'>{`${
                                            userCharger.manufacturerName + ' ' + userCharger.stationModelName
                                        }`}</span>
                                    </div>
                                    <div className='charger-card-detail__feature divider'>
                                        <hr />
                                    </div>
                                </div>
                                <div className='charger-card-detail--business__form'>
                                    <form className='mt-4'>
                                        {isSingleChargerView && (
                                            <Button
                                                buttonType='button'
                                                type={ButtonType.secondary}
                                                onClick={onCancelClicked}
                                            >{`${t('common:buttons:cancel')}`}</Button>
                                        )}
                                        <Button buttonType='submit' onClick={(e) => handleSaveButton(e)}>{`${t(
                                            'charger_detail_save_button'
                                        )}`}</Button>
                                    </form>
                                </div>
                            </>
                        );
                    }
                })()}
            </div>
        </div>
    );
}
