import React from 'react';
import { AlertType } from '../typeScript/types/AlertTypes';
// import './Alert.scss';

export interface AlertProps {
    className?: string;
    type?: AlertType;
    children?: React.ReactNode;
}

function Alert(props: AlertProps) {
    const { className = '', type = AlertType.primary, ...restprops } = props;

    const alertClass = () => {
        let resultClass = 'alert';
        resultClass += className ? ' ' + className : '';
        resultClass += ' alert--' + type;
        return resultClass;
    };

    return (
        <div {...restprops} className={alertClass()}>
            <div className='text'>{props.children}</div>
        </div>
    );
}

export default Alert;
