import React from 'react';
import styles from './Tooltip.module.scss';

export interface TooltipProps {
    children: React.ReactNode;
}

function Tooltip(props: TooltipProps) {
    return (
        <div className={styles.tooltip}>
            <span className={styles.tooltipText}>{props.children}</span>
        </div>
    );
}

export default Tooltip;
