export default {
    customer: {
        title: "Customer info",
        terms: "Terms and Conditions",
        privacy: "Privacy Policy",
        charger_manual: "Charger ENV 01",
        open: "Open",
        download: "Download PDF"
    },
    support: {
        title: "Get in touch",
        phone: {
            title: "Support Hotline",
            text_1: "We are available Monday until Saturday between 08:30 and 21:00 h.",
        },
        email: {
            title: "Direct Support",
            text: "Send us a message, and our Support Team will get in touch with you shortly."
        },
        placeholders: {
            textarea: 'Please write your message here',
            loading_topics: 'Loading topics...'
        },
        files: {
            title: "Your attachments"
        },
        buttons: {
            cancel: 'Cancel',
            send: 'Send'
        }
    },
    message_popup: {
        title: "Contact our customer support",
        text: "Please select the category of your support request and write us a short message explaining your concern."
    },
    topic: {
        payment_and_billing: "Payment and Billing",
        charger: "Charger",
        installation: "Installation",
        mobile_app: "Mobile App",
        tariff_package_and_contracts: "Tarif Package and Contracts",
        general_question: "General Question",
        charging_issue: "Charging Issue",
        advice_counselling: "Advice / Counselling",
        smart_meter: "Smart Meter",
        customer_data_issue: "Customer Data Issue",
        account: "Account"
    }
}