import Constants from "../constants";

export const STATUS_INSTALLATION_DONE = "installation done";
export const STATUS_INSTALLATION_CANCELLED = "installation cancelled";
export const STATUS_CUSTOMER_CANCELLED = "customer cancelled";
export const STATUS_ENVISION_DIGITAL_CANCELLED = "envision digital cancelled";
export const STATUS_DOWNPAYMENT_RETURNED = "downpayment returned";
export const STATUS_INSTALLATION_ON_HOLD = "installation on hold";
export const STATUS_INSTALLATION_INCOMPLETE = "installation incomplete";

export const INSTALLATION_NAGATIVE_STATUSES = [
    STATUS_INSTALLATION_CANCELLED,
    STATUS_CUSTOMER_CANCELLED,
    STATUS_ENVISION_DIGITAL_CANCELLED,
    STATUS_DOWNPAYMENT_RETURNED
];

export const INSTALLATION_ON_HOLD_STATUSES = [
    STATUS_INSTALLATION_ON_HOLD,
    STATUS_INSTALLATION_INCOMPLETE
];

export interface InstallationStatus {
    categoryData: object[] | undefined;
    description: object | undefined;
    status: string | undefined;
    statusByLanguage: object | undefined;
}

export enum StatusColors {
    RED = "--red",
    YELLOW = '--yellow'
}

// Those are the installation categories in SFDC
const CATEGORY_ORDER = "order";
const CATEGORY_INSTALLATION_PREPARATION = "installation preparation";
const CATEGORY_INSTALLATION = "installation";

// Those are the installation event types in SFDC
const EVENT_TYPE_PRE_INSTALLATION_VISIT = "pre-installation visit";
const EVENT_TYPE_INSTALLATION_VISIT = "installation visit";

const installationEventStatus = Constants.INSTALLATION_PROGRESS.STATUS;

export const prepareInstallationStatusData = (
    installationStatusData,
    event,
    t
) => {
    const categoriesTemplate = [
        // note that there's no way for us at the moment to tell what the translation of the category is from the backend if it isn't the current active category...
        {
            categoryKey: CATEGORY_ORDER,
            categoryEn: t("installation_progress.category.order"),
            categoryDe: t("installation_progress.category.order")
        },
        {
            categoryKey: CATEGORY_INSTALLATION_PREPARATION,
            categoryEn: t(
                "installation_progress.category.installation_preparation"
            ),
            categoryDe: t(
                "installation_progress.category.installation_preparation"
            )
        },
        {
            categoryKey: CATEGORY_INSTALLATION,
            categoryEn: t("installation_progress.category.installation"),
            categoryDe: t("installation_progress.category.installation")
        }
    ];

    const mapEventTypeToCategory = (type) => {
        switch (type?.toLowerCase()) {
            case EVENT_TYPE_PRE_INSTALLATION_VISIT:
                return CATEGORY_INSTALLATION_PREPARATION;
            case EVENT_TYPE_INSTALLATION_VISIT:
                return CATEGORY_INSTALLATION;
            default:
                return "";
        }
    };

    const getEventDetail = (category) => {
        return mapEventTypeToCategory(event?.type) === category.categoryKey
            ? event
            : null;
    };

    const getColor = (status) => {
        return status === installationEventStatus.COMPLETED
            ? ""
            : INSTALLATION_NAGATIVE_STATUSES.includes(
                  installationStatusData.status?.toLowerCase()
              )
            ? StatusColors.RED
            : INSTALLATION_ON_HOLD_STATUSES.includes(
                  installationStatusData.status?.toLowerCase()
              )
            ? StatusColors.YELLOW
            : "";
    };

    const currentCategoryKey = installationStatusData.categoryEn
        ? installationStatusData.categoryEn.toLowerCase()
        : null;

    // If installation status is NONE in SFDC return undefined
    if (!currentCategoryKey) {
        return undefined;
    }

    let currentCategoryUsed = !installationStatusData.categoryEn;
    const categoryData = categoriesTemplate.map((category) => {
        let accumulatedStatus;
        let eventDetail = getEventDetail(category);
        if (category.categoryKey === currentCategoryKey) {
            accumulatedStatus =
                (currentCategoryKey === CATEGORY_ORDER || installationStatusData.status.toLowerCase() === STATUS_INSTALLATION_DONE)
                    ? installationEventStatus.COMPLETED
                    : installationEventStatus.NOT_PLANNED;
            currentCategoryUsed = true;

            return {
                ...installationStatusData,
                statusKey: accumulatedStatus,
                isCurrentActiveStep: true,
                eventDetail: eventDetail,
                color: getColor(accumulatedStatus)
            };
        } else {
            accumulatedStatus = currentCategoryUsed
                ? installationEventStatus.NOT_PLANNED
                : installationEventStatus.COMPLETED;
            return {
                ...category,
                statusKey: accumulatedStatus,
                eventDetail: eventDetail,
                color: getColor(accumulatedStatus)
            };
        }
    });

    return {
        categoryData: categoryData,
        status: installationStatusData.status
            ? installationStatusData.status.toLowerCase()
            : "",
        statusByLanguage: {
            en: installationStatusData.status,
            de: installationStatusData.statusDe
        },
        description: {
            en: installationStatusData.descriptionEn,
            de: installationStatusData.descriptionDe
        }
    };
};
