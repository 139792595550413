import React, { useEffect, useRef, useState } from 'react';

type Props = {
    className?: string;
    checked: boolean;
    value?: string;
    onChange: (c: boolean) => void;
    children?: React.ReactNode;
    switchInnerText: string;
    toggleButtonClassName: string;
    iconsEnabled?: boolean;
    trueIconSrc?: string;
    falseIconSrc?: string;
    disabled?: boolean;
};

function Switch(props: Props) {
    const {
        className = '',
        checked = false,
        value = '',
        onChange,
        switchInnerText = '',
        toggleButtonClassName = '',
        iconsEnabled = false,
        trueIconSrc = '',
        falseIconSrc = '',
        disabled = false
    } = props;

    const switchBoxRef = useRef<HTMLDivElement>(document.createElement('div'));
    const switchCircleRef = useRef<HTMLDivElement>(document.createElement('div'));
    const [circlePosition, setCirclePosition] = useState(0);
    const [componentLoaded, setComponentLoaded] = useState(false);

    const onChangeWrapped = () => {
        onChange && onChange(!checked);
    };

    useEffect(() => {
        let timeoutOne;
        let timeoutTwo;

        if (switchBoxRef.current && switchCircleRef.current) {
            const newCirclePosition = checked
                ? switchBoxRef.current.clientWidth - switchCircleRef.current.clientWidth - (switchInnerText ? 8 : 4)
                : 0;
            setCirclePosition(newCirclePosition);
            setComponentLoaded(true);
        }

        return () => {
            timeoutOne && clearTimeout(timeoutOne);
            timeoutTwo && clearTimeout(timeoutTwo);
        };
        // eslint-disable-next-line
    }, [checked, switchBoxRef.current, switchCircleRef.current]);

    useEffect(() => {
        if (switchBoxRef.current) {
            switchBoxRef.current.style.width = switchBoxRef.current.clientWidth + 'px';
        }
        // eslint-disable-next-line
    }, [switchBoxRef.current]);

    const displayIcon = () => {
        return (
            <div className={'switch-icon-container'}>
                <img src={trueIconSrc} className='switch-icon switch-icon__true' alt='' />
                <img src={falseIconSrc} className='switch-icon switch-icon__false' alt='' />
            </div>
        );
    };

    return (
        <div
            className={'switch ' + className + (componentLoaded ? ' switch--show' : '') + (disabled ? ' disabled' : '')}
        >
            <label className='switch__inner'>
                <input type='checkbox' checked={checked} value={value} onChange={onChangeWrapped} disabled={disabled} />
                <div
                    className={
                        (toggleButtonClassName ? toggleButtonClassName : 'switch__box') +
                        (switchInnerText ? ' switch__box--text' : '') +
                        (checked ? ' switch__box--checked' : ' switch__box--unchecked')
                    }
                    ref={switchBoxRef}
                >
                    <div
                        className='switch__circle'
                        style={{ transform: `translateX(${circlePosition}px)` }}
                        ref={switchCircleRef}
                    />
                    {switchInnerText}

                    {iconsEnabled ? displayIcon() : null}
                </div>
                <p className='switch__text'>{props.children}</p>
            </label>
        </div>
    );
}

export default Switch;
