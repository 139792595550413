export const routePaths = {
    terms: '/terms',
    privacy: '/privacy',
    imprint: '/imprint',
    login: '/user/login',

    resetPassword: '/user/reset-password',
    resetPasswordFirstTime: '/password-reset',

    // CHECKOUT B2B
    businessLogin: '/business/login',
    businessPlanning: '/business/planning',
    businessPersonalInformation: '/business/personal-information',
    businessInstallationDetail: '/business/installation',
    businessOrderConfirmation: '/business/order-confirmation',
    businessConfirmation: '/business/confirmation',

    // Portal-Merge
    portalOverview: '/',
    portalCharger: '/ladestation',
    portalDocuments: '/dokumente',
    portalCards: '/ladekarten',
    portalSupport: '/kundenservice',
    portalProfile: '/profil',
    portalPayment: '/zahlungsmethode',
    portalPhotoUpload: '/foto-upload',
    portalTariff: '/vertrag',
    portalPayout: '/auszahlung',
    portalFleetData: '/flottendaten',
};