export const SET_IS_COOKIE_CONSENT_OPEN = 'SET_IS_COOKIE_CONSENT_OPEN';
export const SET_IS_MARKETING_COOKIE = 'SET_IS_MARKETING_COOKIE';

export function setIsCookieConsentOpen(value: boolean) {
	return {
		type: SET_IS_COOKIE_CONSENT_OPEN,
		value,
	}
}
export function setIsMarketingCookie(value: boolean) {
	return {
		type: SET_IS_MARKETING_COOKIE,
		value,
	}
}

export default {
	setIsCookieConsentOpen,
	setIsMarketingCookie
}