import {
  FleetDataErrorCode
} from './../../_generated/openapi';

export default {
  USER_NOT_FOUND: "Der Link ist fehlerhaft oder der Benutzer existiert nicht.",
  USER_DOES_NOT_HAVE_CHARGER: "Der Benutzer besitzt keine Ladegeräte",
  WRONG_REQUEST_PARAMS: "Es wurden die falschen Parameter gesendet",
  PAYOUT_NEW_TARIFF_START_DATE_SHOULD_BE_AFTER_THE_EFFECTIVE_ONE: "Das Datum des Beginns der Auszahlung des neuen Tarifs, sollte nach dem Datum des Inkrafttretens liegen",

  IBAN_VALIDATION_FAILED: "Bitte überprüfen Sie Ihre Bankdaten",

  [FleetDataErrorCode.EmployeeCostUnitIsNull]: 'Bitte überprüfen Sie die Kosten-Stelle',
  [FleetDataErrorCode.EmployeeCostUnitValidationError]: 'Bitte überprüfen Sie die Kosten-Stelle',
  [FleetDataErrorCode.EmployeeIdIsNull]: 'Bitte überprüfen Sie Ihre Mitarbeiter ID',
  [FleetDataErrorCode.EmployeeIdValidationError]: 'Bitte überprüfen Sie Ihre Mitarbeiter ID',
  [FleetDataErrorCode.RenewableEnergyIsNull]: 'Bitte überprüfen Sie das Feld für erneuerbare Energien',
  [FleetDataErrorCode.RenewableEnergyValidationError]: 'Bitte überprüfen Sie das Feld für erneuerbare Energien',
  [FleetDataErrorCode.VehicleRegistrationNumberIstNull]: 'Bitte überprüfen Sie Ihr Nummernschild',
  [FleetDataErrorCode.VehicleRegistrationNumberValidationError]: 'Bitte überprüfen Sie Ihr Nummernschild',
}
