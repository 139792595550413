export const isIosDevice = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const isIPad = () => {
    const found = navigator.userAgent.match(/iPad/i);
    return found && found.length > 0;
}