export const SET_SHOW_LOADER = 'SET_SHOW_LOADER';
export const SET_LOADER_MESSAGE= 'SET_LOADER_MESSAGE';
export const SET_IS_INITIAL_LOAD= 'SET_IS_INITIAL_LOAD';


export function setShowLoader(value) {
    return {
        type: SET_SHOW_LOADER,
        value
    }
}

export function setLoaderMessage(value) {
    return {
        type: SET_LOADER_MESSAGE,
        value
    }
}

export function setIsInitialLoad(value) {
    return {
        type: SET_IS_INITIAL_LOAD,
        value
    }
}


export default {
    setShowLoader,
    setLoaderMessage,
    setIsInitialLoad
}