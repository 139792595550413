export default {
    Units: {
        kwh: 'kWh'
    },
    buttons: {
        edit: 'Edit',
        continue: 'Continue',
        confirm: 'Confirm',
        back: 'Back',
        log_into: 'Log in to myUnivers',
        check_out: 'Proceed to checkout',
        save: 'Save',
        select: 'Select',
        selected: 'Selected',
        getStarted: 'Begin',
        next: 'Continue',
        download: 'Download',
        downloading: 'Download',
        downloadError: 'Error',
        yes: 'Yes',
        no: 'No',
        attached: 'Attached',
        detached: 'Not attached',
        more: 'More',
        less: 'Less',
        dashboard: 'Back to your dashboard',
        logout: 'Log off',
        login: 'Go to login',
        order_now: 'Order Now',
        pdf: 'PDF',
        go_to_contracts: 'Go to contracts',
        submit: 'Submit',
        promotion: 'Redeem',
        continue_to_configuration: 'Continue to configuration',
        change: 'Change',
        send_message: 'Send message',
        cancel: 'Cancel',
        start: 'Start',
        go_to_documents: 'Access Documents',
        make_an_appointment: 'Arrange now',
        add_now: 'Enter Details',
        cookie_confirm_technical: 'Only required cookies',
        cookie_confirm_all: 'Accept all'
    },
    validation: {
        first_name_required: 'First name required',
        last_name_required: 'Last name required',
        name_min_length: 'First name is too short (at least 2 characters)',
        surname_min_length: 'Last name is too short (at least 2 characters)',
        email_required: 'Email required',
        email_invalid: 'Please check your email address',
        phone_no_required: 'Phone number required',
        company_name_required: 'Company name required',
        company_type_required: 'Company type required',
        contact_method: 'Preferred contact method required',
        birthday_invalid_date: 'Please check your date of birth',
        payout_invalid_date: 'Please entry a date in the future',
        password_required: 'Password required',
        password_min_length: 'Password is too short (at least 8 characters)',
        password_format: 'Password must contain a number, a symbol and a lower and upper case letter',
        password_confirmation_mismatch: 'Password confirmation mismatch',
        street_address_required: 'Street address required',
        street_no_required: 'Street number required',
        street_no_invalid: 'Street number invalid',
        postal_code_required: 'Postcode required',
        city_required: 'city required',
        state_required: 'region required',
        country_required: 'country required',
        installation_invalid_date: 'Required installation date',
        installation_message_toolong: 'Message is too long',
        account_holder_required: 'Account holder required',
        bank_name_required: 'Bank_name required',
        iban_required: 'IBAN required',
        bic_required: 'BIC required',
        ccv_required: 'CVV required',
        card_holder_required: 'Cardholder required',
        credit_card_no_required: 'Credit card number required',
        expiry_date_required: 'Expiry date required',
        terms_not_accepted: 'Terms not accepted',
        referral_code: 'Incorrect referral code',
        salutation_required: 'Salutation required',
        support_subject_required: 'Subject of support is required',
        support_description_required: 'Support message is required',
        language_required: 'Language is required',
        charger_name_min_max_range: 'The name must be between 1 and 50 characters long',
        charger_name_required: 'Charger name must contain at least one character',
        charger_name_min_length: 'Charger name is too short (at least 1 character)',
        company_code: 'Invalid code',
        termination_reason_required: 'Reason required',
        termination_reason_min_length: 'Reason is too short (at least 2 characters)',
        termination_type_required: 'Nature of termination required',
        customer_number_required: 'Customer number required',
        customer_number_min_length: 'Customer number is too short (at least 2 characters)',
        contract_number_required: 'Contract number required',
        contract_number_min_length: 'Contract number is too short (at least 2 characters)',
        termination_date_decision_required: 'Please choose',
        termination_date_required: 'Required date',
        payout_invalid_start_date: 'Date is invalid',
        payout_start_date_required: 'Start of validity in future',
        payout_household_tariff_required: 'Tariff (Gross working price) in Cent, max. 8 decimals',
        payout_energyContract_required: 'Verification document required, (e.g. energy tariff)',
        payout_termsAndConditionsAccepted_required: 'Terms not accepted',
        bank_account_firstName_required: 'Bank account owner required',
        bank_account_lastName_required: 'Bank account owner required',
        employeeCostUnit_required: 'Employee Cost Center required',
        employeeId_required: 'Employee ID required',
        vehicleRegistrationNumber_required: 'Check your Vehicle Registration Number',
        vehicleRegistrationNumberPart1_required: 'I',
        vehicleRegistrationNumberPart2_required: 'II',
        vehicleRegistrationNumberPart3_required: 'III',
        vehicleRegistrationNumberPart4_required: 'IV',
        renewableEnergy_required: 'Renewable energy required',
    },
    labels: {
        total_price: 'Total price',
        name: 'Name',
        first_name: 'First name',
        last_name: 'Last name',
        email_initial: 'Email address',
        email: 'Email',
        your_email: 'Your Email address',
        phone: 'Phone number',
        birthday: 'Birthday',
        date_of_birth: 'Date of birth',
        vat_no: 'VAT number (optional)',
        set_password: 'Create password',
        your_password: 'Your password',
        confirm_password: 'Confirm password',
        installation_address: 'Installation address',
        street_address: 'Street name',
        street_no: 'House no.',
        postal_code: 'Postcode',
        postal_code_full: 'Postcode',
        city: 'City',
        state: 'Region',
        country: 'Country',
        country_default: 'Germany',
        country_code_default: 'DE',
        install_date: 'Date of preliminary visit or installation',
        contact_method: 'Preferred contact method',
        message_installer: 'Your message to us (optional)',
        billing_address_match: 'Billing address is identical to installation address',
        billing_address: 'Billing address',
        payment_method: 'Payment method',
        payment_debit: 'Direct debit method',
        payment_unknown: 'Unknown card',
        direct_debit: 'Giropay',
        payment_credit: 'Credit card',
        account_holder: 'Account holder',
        bank_name: 'Bank name',
        iban: 'IBAN',
        bic: 'BIC',
        card_holder: 'cardholder',
        card_holder_first_name: 'cardholder first name',
        card_holder_last_name: 'cardholder last name',
        credit_card_number: 'credit_card_number',
        expiry_date: 'Expiry date',
        ccv: 'CCV',
        referred_you: 'Who referred you',
        promotion_offers: 'Yes, I would like to receive email updates on all news, offers and promotions. I can unsubscribe at any time',
        password_new: 'Your new password',
        password_old: 'Your old password',
        your_answers: 'Your data:',
        salutation: 'Salutation',
        language: 'Language',
        select_support_topic: 'Select topic',
        support_message: 'Your message',
        install_date2: 'Preferred Date',
        termination_type: 'Nature of termination',
        termination_type_ordinary: 'Ordinary',
        termination_type_extra_ordinary: 'Extraordinary',
        termination_reason: 'Reason for termination',
        customer_number: 'Customer number',
        contract_number: 'Contract number',
        termination_comment: 'comment',
        termination_date: 'Termination as of',
        termination_date_decision_1: 'As soon as possible',
        termination_date_decision_2: 'For the following date',
        payout_startDate: 'Start of validity charging tariff',
        payout_energyPrice: 'Tariff (Gross working price) in Cent',
        payout_energyContract: 'Verification document, (e.g. energy tariff, max size {{maxFileSize}}MB)',
        iban_owner_name: 'Bank account holder',
        employeeCostUnit: 'Employee Cost Center',
        employeeId: 'Employee ID',
        vehicleRegistrationNumber: 'Vehicle Registration Number',
        vehicleRegistrationNumberPart1: '',
        vehicleRegistrationNumberPart2: '',
        vehicleRegistrationNumberPart3: '',
        vehicleRegistrationNumberPart4: '',
        renewableEnergy: 'I confirm that I use Renewable Energy',
    },
    placeholder: {
        first_name: 'First name',
        last_name: 'Last name',
        email: 'your@email.de',
        email_address: 'email address',
        password: 'Password',
        phone: 'phone number',
        vat_no: 'VAT registration number',
        street_address: 'street name',
        street_no: 'street number',
        postal_code: 'Postcode',
        city: 'City',
        country: 'Country',
        state: 'region',
        install_date: 'Preferred date',
        contact_method: 'Contact method',
        message_installer: 'Your message',
        account_holder: 'Name of account holder',
        bank_name: 'Name of the bank',
        iban: 'IBAN',
        bic: 'BIC',
        card_holder: 'Cardholder',
        card_no: 'credit card number',
        expiry_date: 'Expiry date',
        ccv: 'CCV',
        referral_code: 'xx-xx-xx-xx',
        salutation: 'Salutation',
        language: 'language',
        support_topic: 'Please choose a topic',
        support_input: 'Write your message here',
        termination_type: 'Nature of termination',
        termination_reason: 'Reason',
        customer_number: 'Customer',
        contract_number: 'Contract number',
        termination_comment: 'comment',
        termination_date: 'Termination as of',
        payout_startDate: 'XX.XX.XXXX',
        payout_energyPrice: 'xx,xx',
        payout_energyContract: 'Contract as PDF',
        iban_owner_name: 'Bank account holder',
        employeeCostUnit: 'Employee Cost Center',
        employeeId: 'Employee ID',
        vehicleRegistrationNumber: '',
        vehicleRegistrationNumberPart1: 'AA',
        vehicleRegistrationNumberPart2: 'AA',
        vehicleRegistrationNumberPart3: '00',
        vehicleRegistrationNumberPart4: 'E',
        renewableEnergy: 'You charge your vehicle with electricity from renewable energy.',
    },
    cookie: {
        cookie_consent_intro:
            'We use cookies to provide you with a good user experience. This includes cookies that are necessary for the operation of the website as well as cookies that are only used for anonymous statistical purposes. You can decide for yourself which categories you wish to allow. For more information, please see our privacy policy.'
    },
    customer_support: {
        send_message: 'Send us a message:',
        send_message_email: 'kundenservice@univers.de',
        call_phone: 'Or contact us by phone:',
        call_phone_number: '+49 800 4302255'
    },
    language: {
        english: 'English',
        german: 'Deutsch'
    },
};


const commonNissan = {
    placeholder: {
        message_installer: 'Please let us know of any special requests (e.g. interest in green electricity).'
    }
};

export { commonNissan };
