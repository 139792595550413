enum InputType {
    TEXT = 'text',
    NUMBER = 'number',
    EMAIL = 'email',
    TEL = 'tel',
    PASSWORD = 'password',
    FILE = 'file',
    HIDDEN = 'hidden'
}

export default InputType;
