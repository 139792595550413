import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { routePaths } from '../route_paths';
import { IRootState } from '../store';
import { ILoggedUser } from '../typeScript/interfaces/IUser';

const ArrowDown = require('../assets/ArrowDown.svg');

export type Props = {
    handleLogout: () => void;
    isPortal: boolean;
};

function ProfileDropdown(props: Props) {
    const { handleLogout, isPortal } = props;
    const { t } = useTranslation(['nav']);
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);

    const onWindowClick = (e: MouseEvent) => {
        setOpen(false);
    };

    const onClick = (e) => {
        e.stopPropagation();
        setOpen(!open);
    };

    useEffect(() => {
        window.addEventListener('click', onWindowClick);

        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    }, []);

    return (
        <div className={'profile-dropdown'} onClick={onClick}>
            {isPortal && (
                <>
                    <span
                        className={
                            'header ' + (history.location.pathname === routePaths.portalProfile ? 'header--active' : '')
                        }
                    >
                        {t('profile')}
                    </span>
                    <span className={open ? 'profile-dropdown--open' : ''}>
                        <img src={ArrowDown} alt='ArrowDown' />
                    </span>
                    {open && (
                        <div className='profile-dropdown--content'>
                            <ul>
                                <li>
                                    <NavLink
                                        className={'profile-dropdown--link'}
                                        to={routePaths.portalProfile}
                                        activeClassName={'profile-dropdown--link-active'}
                                    >
                                        {t('my_profile')}
                                    </NavLink>
                                </li>
                                {user?.canUpdatePaymentInformation && (
                                    <li>
                                        <NavLink
                                            className={'profile-dropdown--link'}
                                            to={routePaths.portalPayment}
                                            activeClassName={'profile-dropdown--link-active'}
                                        >
                                            {t('my_payment')}
                                        </NavLink>
                                    </li>
                                )}
                                <li>
                                    <NavLink
                                        className={'profile-dropdown--link'}
                                        to={routePaths.portalTariff}
                                        activeClassName={'profile-dropdown--link-active'}
                                    >
                                        {t('tariff')}
                                    </NavLink>
                                </li>
                                {user?.isReimbursementActivated && (
                                    <li>
                                        <NavLink
                                            className={'profile-dropdown--link'}
                                            to={routePaths.portalPayout}
                                            activeClassName={'profile-dropdown--link-active'}
                                        >
                                            {t('payout')}
                                        </NavLink>
                                    </li>
                                )}
                                {user?.isFleetDataEnrichment && (
                                    <li>
                                        <NavLink
                                            className={'profile-dropdown--link'}
                                            to={routePaths.portalFleetData}
                                            activeClassName={'profile-dropdown--link-active'}
                                        >
                                            {t('fleetData')}
                                        </NavLink>
                                    </li>
                                )}
                                <li>
                                    <span className={'profile-dropdown--link'} onClick={handleLogout}>
                                        {t('log_out')}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default ProfileDropdown;
