import _ from 'lodash-es';
import Token from './auth-token-wrapper';
import axiosWrapper from './axios-wrapper';

export const PAYMENT_REDIRECT_URL = '/onboarding/payments/redirect';

const getDataAsURLParams = (data) => {
    let params = new URLSearchParams();

    for (const [key, value] of Object.entries(data)) {
        params.append(key, value);
    }

    return params;
};

const parseObjectToQueryString = (objectQuery) => {
    let queryString;

    if (!_.isEmpty(objectQuery)) {
        queryString = Object.keys(objectQuery)
            .map((key) => `${key}=${objectQuery[key]}`)
            .join('&');
        return queryString;
    }
    return '';
};

const API = {
    products: {
        getProducts(queryObject) {
            const queryString = parseObjectToQueryString(queryObject);
            return axiosWrapper.callGet(`/onboarding/products?${queryString}`);
        }
    },
    auth: {
        login(email, password) {
            const finalData = {
                grant_type: 'password',
                username: email,
                password
            };

            return axiosWrapper.callPost('/authorization/oauth/token', getDataAsURLParams(finalData));
        },
        registerUser(params) {
            return axiosWrapper.callPost('/authorization/users/create', params);
        },
        requestNewPassword(email) {
            return axiosWrapper.callGet('/authorization/users/reset-password', {
                email
            });
        },
        refreshToken() {
            let refreshToken = Token.getRefreshToken();

            const finalData = {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            };

            return axiosWrapper.callPost('/authorization/oauth/token', getDataAsURLParams(finalData));
        },
        getPasswordTokenStatus(params) {
            return axiosWrapper.callGet('/authorization/users/get-password-token-status', params);
        }
    },
    leads: {
        saveLeadData(params) {
            return axiosWrapper.callPost('/onboarding/signups/me/account', params);
        },
        createLead(params, queryObject) {
            const queryString = parseObjectToQueryString(queryObject);
            return axiosWrapper.callPost(`/onboarding/signups/me/new?${queryString}`, params);
        },

        saveProducts(params) {
            return axiosWrapper.callPost('/onboarding/signups/me/products', params);
        },
        saveQuestionsAnswers(params) {
            return axiosWrapper.callPost('/onboarding/signups/me/questions', params);
        },
        getLeadStatus() {
            return axiosWrapper.callGet('/onboarding/signups/me/status');
        },
        confirmEmployeeCustomer(params) {
            return axiosWrapper.callPost('/onboarding/signups/employee-confirm', params);
        }
    },
    installation: {
        getInstallationData() {
            return axiosWrapper.callGet('/onboarding/signups/me/installation');
        },
        updateInstallationData(params) {
            return axiosWrapper.callPost('/onboarding/signups/me/installation', params);
        },
        getInstallationEvent() {
            return axiosWrapper.callGet('/onboarding/installations/me/events');
        },
        getInstallationStatus() {
            return axiosWrapper.callGet('/onboarding/installations/me/status');
        },
        uploadInstallationPhoto(params) {
            return axiosWrapper.callPost('/onboarding/signups/me/files', params);
        },
        getUploadedInstallationPhotos() {
            return axiosWrapper.callGet('/onboarding/signups/me/files');
        },
        getUploadedInstallationPhoto(id) {
            return axiosWrapper.callGet('/onboarding/signups/me/files/' + id);
        }
    },
    payment: {
        getPaymentAmount() {
            return axiosWrapper.callGet('/onboarding/signups/me/payment-amount');
        },
        getPaymentMethods(params) {
            return axiosWrapper.callPost('/onboarding/payments/payment-methods', params);
        },
        doPayment(params) {
            return axiosWrapper.callPost('/onboarding/payments/do-payment', params);
        },
        submitAdditionalDetails(params) {
            return axiosWrapper.callPost('/onboarding/payments/payment-details', params);
        },
        getPaymentStatus() {
            return axiosWrapper.callGet('/onboarding/signups/me/payment-status');
        },
        confirmOrder(params) {
            return axiosWrapper.callPost('/onboarding/signups/me/confirm', params);
        },
        getB2bEmployeeSetup(code) {
            return axiosWrapper.callGet('/onboarding/payments/b2b-employee-setup/' + code);
        }
    },
    payout:{
        payoutEnergyTariffRegistration(energyTariffCreateRequest, contractProof) {
            const bodyFormData = new FormData();
            bodyFormData.append('energyTariffCreateRequest', new Blob([
                JSON.stringify(energyTariffCreateRequest)
            ], {
                type: "application/json"
            }));
            bodyFormData.append('contractProof', contractProof, contractProof.name);
            const customConfig = {'Content-Type':  'multipart/form-data;'}

            return axiosWrapper.callPost('/customer-life-cycle/registration/payout-energy-tariff', bodyFormData, customConfig);
        },
        getPayoutHistory() {
            return axiosWrapper.callGet('/customer-life-cycle/registration/payout-energy-tariff');
        },
        payoutIBANRegistration(params) {
            return axiosWrapper.callPost('/payaut-service/account', params);
        },
        getPayoutIBANInfo() {
            return axiosWrapper.callGet('/payaut-service/account/payout-iban');
        },
    },
    fleetData: {
        getFleetData() {
            return axiosWrapper.callGet('/customer-life-cycle/registration/fleetdata');
        },
        fleetDataUpdate(params) {
            return axiosWrapper.callPut('/customer-life-cycle/registration/fleetdata', params);
        }
    },
    user: {
        hasRecurringPaymentDetails() {
            return axiosWrapper.callGet('/onboarding/userpayments/me/has-recurring-payment-details');
        },
        getLoggedUser() {
            return axiosWrapper.callGet('/authorization/users/me');
        },
        getBillingData() {
            return axiosWrapper.callGet('/onboarding/signups/me/payment');
        },
        updateBillingData(params) {
            return axiosWrapper.callPost('/onboarding/signups/me/payment', params);
        },
        updatePassword(passwordData) {
            return axiosWrapper.callPost('/authorization/users/change-password', passwordData);
        },
        setPassword(passwordData) {
            return axiosWrapper.callPost('/authorization/users/set-password', passwordData);
        },
        getTariffs() {
            return axiosWrapper.callGet('/onboarding/users/me/tariffs');
        },
        getInvoices() {
            return axiosWrapper.callGet('/onboarding/users/me/invoices');
        },
        getInvoice(id) {
            return axiosWrapper.callGet(`/onboarding/users/me/invoices/${id}`);
        },
        getInvoiceFile(invoiceId, fileId) {
            return axiosWrapper.callGet(`/onboarding/users/me/invoices/${invoiceId}/files/${fileId}`);
        },
        getContracts() {
            return axiosWrapper.callGet('/onboarding/users/me/contracts');
        },
        getContract(id) {
            return axiosWrapper.callGet(`/onboarding/users/me/contracts/${id}`);
        },
        getQuotes() {
            return axiosWrapper.callGet('/onboarding/users/me/quotes');
        },
        getQuote(id) {
            return axiosWrapper.callGet(`/onboarding/users/me/quotes/${id}`);
        },
        getQuoteFile(quoteId, fileId) {
            return axiosWrapper.callGet(`/onboarding/users/me/quotes/${quoteId}/files/${fileId}`);
        },
        getProducts() {
            return axiosWrapper.callGet('/onboarding/signups/me/products');
        },
        getAccount() {
            return axiosWrapper.callGet('/onboarding/users/me/info');
        },
        updateUserInfo(params) {
            return axiosWrapper.callPatch('/onboarding/users/me/update-info', params);
        }
    },
    charger: {
        getChargers() {
            return axiosWrapper.callGet('/charger-management/owners/me/chargers');
        },
        getCharger(id) {
            return axiosWrapper.callGet(`/charger-management/owners/me/chargers/${id}`);
        },
        updateChargerAuthorization(id, params) {
            return axiosWrapper.callPost(`charger-management/owners/me/chargers/${id}/authorization-mode`, params);
        },
        updateChargerName(id, params) {
            return axiosWrapper.callPatch(`charger-management/owners/me/chargers/${id}`, params);
        }
    },
    support: {
        getSupportTopics() {
            return axiosWrapper.callGet('onboarding/contactus/support-requests/subjects');
        },
        saveSupportRequest(params) {
            return axiosWrapper.callPost(`onboarding/contactus/support-requests/me`, params);
        }
    },
    business: {
        confirmCompanyCode(code) {
            return axiosWrapper.callPost(`/onboarding/promo-codes/validate-company-code`, code);
        }
    },
    documents: {
        getDocuments() {
            return axiosWrapper.callGet(`/document-manager/document`);
        },
        getDocumentById(id) {
            return axiosWrapper.callGet(`/document-manager/document/${id}`);
        }
    },
    contactForm: {
        cancellation(params) {
            return axiosWrapper.callPost('/contact-form/contract-cancellation', params);
        }
    }
};

export default API;
