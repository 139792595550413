export const SET_CHARGER_PRODUCTS = 'SET_CHARGER_PRODUCTS';
// export const SET_SMART_METER_PRODUCTS = 'SET_SMART_METER_PRODUCTS';
export const SET_INSTALLATION_PACKAGES_PRODUCTS = 'SET_INSTALLATION_PACKAGES_PRODUCTS';
export const SET_TARIFF_PRODUCTS = 'SET_TARIFF_PRODUCTS';
export const SET_PRE_VISIT_PRODUCTS = 'SET_PRE_VISIT_PRODUCTS';
export const SET_PRE_VISIT_ONLY_PRODUCTS = 'SET_PRE_VISIT_ONLY_PRODUCTS';
export const SET_DOWN_PAYMENT_PRODUCTS = 'SET_DOWN_PAYMENT_PRODUCTS';
export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING';

export function setChargerProducts(value) {
	return {
		type: SET_CHARGER_PRODUCTS,
		value
	}
}

// export function setSmartMeterProducts(value) {
// 	return {
// 		type: SET_SMART_METER_PRODUCTS,
// 		value
// 	}
// }

export function setInstallationPackagesProducts(value) {
	return {
		type: SET_INSTALLATION_PACKAGES_PRODUCTS,
		value
	}
}

export function setTariffProducts(value) {
	return {
		type: SET_TARIFF_PRODUCTS,
		value
	}
}

export function setPreVisitProducts(value) {
	return {
		type: SET_PRE_VISIT_PRODUCTS,
		value
	}
}

export function setPreVisitOnlyProducts(value) {
	return {
		type: SET_PRE_VISIT_ONLY_PRODUCTS,
		value
	}
}


export function setDownPaymentProducts(value) {
	return {
		type: SET_DOWN_PAYMENT_PRODUCTS,
		value
	}
}

export function setProductsLoading(value) {
	return {
		type: SET_PRODUCTS_LOADING,
		value
	}
}

export default {
	setChargerProducts,
	// setSmartMeterProducts,
	setInstallationPackagesProducts,
	setTariffProducts,
	setPreVisitProducts,
	setDownPaymentProducts,
	setProductsLoading,
	setPreVisitOnlyProducts
}
