import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import cookieActions from '../store/cookie';

function ConsentButton() {
    const { t } = useTranslation(['nav']);
    const dispatch = useDispatch();

    const openCookieConsent = (e: MouseEvent<Element>) => {
        e.preventDefault();
        dispatch(cookieActions.setIsCookieConsentOpen(true));
    };

    return (
        <a
            onClick={(e) => openCookieConsent(e)}
            href='/'
            className='footer__link'
            target={'_blank'}
            rel={'noopener noreferrer'}
        >
            <p className='footer__link__text'>{t('revokeCookie')}</p>
        </a>
    );
}

export default ConsentButton;
