export default {
    fleetData_invitation: 'Erfassen und aktualisieren Sie Ihre Flottendaten',
    fleetData_invitation_cta: 'Daten eingeben',

    title: 'Flottendaten',
    card_title: 'Ihre hinterlegten Daten:',

    no_history: 'Willkommen auf Ihrer Flottendatenseite. Starten Sie mit der Eingabe Ihrer Flottendetails.',
    with_history:
        'Willkommen zurück in Ihrem aktualisierten Flottendaten-Bereich. Halten Sie Ihre Flottendetails aktuell für effizientes Flottenmanagement!',

    form_title: 'Ihr Flottendaten Formular',
    form_intro: 'Bitte geben Sie hier Ihre Daten ein',

    button_show_form: 'Daten Aktualisieren',
    button_hide_form: 'Abbrechen',

    license_plate_check: 'Haben Sie ein deutsches EU Kennzeichen?',
    license_plate_check_boolean: 'Ja',

    license_plate_tooltip_intro: 'Informationen zum Nummernschild:',
    license_plate_part_1: 'Optional 1-3 Buchstaben',
    license_plate_part_2: 'Optional 1-2 Buchstaben',
    license_plate_part_3: 'Optional 1-4 Zahlen',
    license_plate_part_4: 'Optional 1 Buchstabe',

    show_data_renewable_energy: 'Erneuerbare Energie'
};
