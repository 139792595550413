import {
	RESET_CHECKOUT_DATA,
	// RESET_CHECKOUT_DATA_NOT_PERSONAL_INFO,
	SET_CHECKOUT_STEPS_COMPLETED, UPDATE_CHECKOUT_INFO,
	UPDATE_CHECKOUT_WITH_USER
} from '.';
import { ICheckOut } from '../../typeScript/interfaces/ICheckOut';
import { concealMessageDeterministic, concealObjectNonDeterministic, recoverObjectNonDeterministic } from '../../utils/encrypt';

export const initialState: ICheckOut = {
	// checkoutStep: 1,
	checkoutInfo: {
		first_name: '',
		last_name: '',
		email: '',
		phone_no: '',
		birthday_day: '',
		birthday_month: '',
		birthday_year: '',
		vat_number: '',
		password: '',
		password_confirmation: '',
		street_address: '',
		street_no: '',
		postal_code: '',
		city: '',
		country: '',
		contact_method: '',
		installation_date: '',
		message_to_installer: '',
		billing_address_match: true,
		first_name_billing: '',
		last_name_billing: '',
		street_address_billing: '',
		street_no_billing: '',
		postal_code_billing: '',
		city_billing: '',
		state_billing: '',
		country_billing: '',
		terms_accepted_account: false,
		terms_accepted_account_secondary: false,
		terms_accepted: false,
		receive_info: false,
		checkoutStepsCompleted: [],
		referenceNumber: null,
		salutation: '',
		company_code: ''
		// language: ''
		//checkoutStepPermission: [1],
		//checkoutStepDone: [""]
	},
};

const checkoutLocalStorageKey = concealMessageDeterministic("checkout");

export function checkoutReducer(state: ICheckOut = initialState, action: any): ICheckOut {
	let stringifiedState;
	let stateToStore;

	switch (action.type) {
		case UPDATE_CHECKOUT_INFO:
			stateToStore = {
				...state.checkoutInfo,
				[action.attribute]: action.value,
			};
			stringifiedState = concealObjectNonDeterministic(stateToStore)
			sessionStorage.setItem(checkoutLocalStorageKey, stringifiedState);

			return {
				...state,
				checkoutInfo: {
					...state.checkoutInfo,
					[action.attribute]: action.value
				}
			};

		case UPDATE_CHECKOUT_WITH_USER:
			stateToStore = {
				...state.checkoutInfo,
				first_name: state.checkoutInfo.first_name ? state.checkoutInfo.first_name : action?.value.name,
				last_name: state.checkoutInfo.last_name ? state.checkoutInfo.last_name : action?.value.familyName,
				email: state.checkoutInfo.email ? state.checkoutInfo.email : action?.value.email,
			};

			stringifiedState = concealObjectNonDeterministic(stateToStore)
			sessionStorage.setItem(checkoutLocalStorageKey, stringifiedState);

			return {
				...state,
				checkoutInfo: stateToStore
			};
		// case RESET_CHECKOUT_DATA_NOT_PERSONAL_INFO:
		// 	stateToStore = {
		// 		...initialState.checkoutInfo,
		// 		first_name: state.checkoutInfo.first_name,
		// 		last_name: state.checkoutInfo.last_name,
		// 		email: state.checkoutInfo.email,
		// 		birthday_day: state.checkoutInfo.birthday_day,
		// 		birthday_month: state.checkoutInfo.birthday_month,
		// 		birthday_year: state.checkoutInfo.birthday_year,
		// 		vat_number: state.checkoutInfo.vat_number,
		// 	};
		//
		// 	stringifiedState = concealObjectNonDeterministic(stateToStore);
		// 	sessionStorage.setItem(checkoutLocalStorageKey, stringifiedState);
		//
		// 	return {
		// 		...state,
		// 		checkoutInfo: stateToStore
		// 	};
		case RESET_CHECKOUT_DATA:
			stateToStore = {
				...initialState.checkoutInfo
			};
			stringifiedState = concealObjectNonDeterministic(stateToStore);
			sessionStorage.setItem(checkoutLocalStorageKey, stringifiedState);
			return {
				...state,
				checkoutInfo: stateToStore
			};
		case SET_CHECKOUT_STEPS_COMPLETED:
			stateToStore = {
				...state.checkoutInfo,
				checkoutStepsCompleted: action.value
			};

			stringifiedState = concealObjectNonDeterministic(stateToStore);
			sessionStorage.setItem(checkoutLocalStorageKey, stringifiedState);

			return {
				...state,
				checkoutInfo: stateToStore
			};

		default:
			stringifiedState = sessionStorage.getItem(checkoutLocalStorageKey);
			if (stringifiedState) {
				return {
					...state,
					checkoutInfo: recoverObjectNonDeterministic(stringifiedState),
				};
			} else {
				return state;
			}
	}
}
