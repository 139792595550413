import { DisplayCardTypes } from 'components/DisplayCard';
import { MyEnvisionAccountBlock } from 'components/MyEnvisionAccountBlock';
import { TYPE_SUCCESS } from 'components/Toast';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import loaderActions from 'store/loader';
import { openToast } from 'store/toast';
import api from '../api/api';
import { Button, DisplayCard, Input, Page } from '../components';
import InputType from '../constants/inputType';
import { routePaths } from '../route_paths';
import { IRootState } from '../store';
import { ICheckOutInfo } from '../typeScript/interfaces/ICheckOut';
import { PageTheme } from '../typeScript/types/PageTheme';
import { updateInfoState as updateInfoStateGeneric } from '../utils/checkout';
import { showApiError } from '../utils/error-handler';
import { decodeURLParams } from '../utils/helpers';
import { getValidationRules, ruleNames, validationService } from '../validation';

enum PasswordTokenStatus {
    'VALID' = 'VALID',
    'INVALID' = 'INVALID',
    'EXPIRED' = 'EXPIRED',
    'DISMISSED' = 'DISMISSED'
}

function ResetPasswordFirstTime() {
    const { t } = useTranslation('auth');
    const [validationErrors, setValidationErrors] = useState<object[]>([]);
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [token, setToken] = useState('');
    const [resetPasswordSuccessfully, setResetPasswordSuccessfully] = useState(false);
    const [passwordTokenStatus, setPasswordTokenStatus] = useState<PasswordTokenStatus | undefined>(undefined);
    const history = useHistory();
    const infoState = useSelector<IRootState, ICheckOutInfo>((state) => state.checkout.checkoutInfo);
    const dispatch = useDispatch();
    const updateInfoState = updateInfoStateGeneric;

    useEffect(() => {
        checkTokenInitial();
        // eslint-disable-next-line
    }, []);

    const checkTokenInitial = () => {
        dispatch(loaderActions.setShowLoader(true));
        dispatch(loaderActions.setLoaderMessage(t('loader:loading_data')));

        const userParams = decodeURLParams(window.location.search);
        updateInfoState('email', userParams.email, dispatch);
        updateInfoState('first_name', userParams.name, dispatch);
        setToken(userParams.token);

        api.auth
            .getPasswordTokenStatus({ token: userParams.token })
            .then((res) => {
                dispatch(loaderActions.setShowLoader(false));

                let stat =
                    res.data === PasswordTokenStatus.DISMISSED
                        ? PasswordTokenStatus.DISMISSED
                        : res.data === PasswordTokenStatus.EXPIRED
                        ? PasswordTokenStatus.EXPIRED
                        : res.data === PasswordTokenStatus.VALID
                        ? PasswordTokenStatus.VALID
                        : PasswordTokenStatus.INVALID;

                setPasswordTokenStatus(stat);
            })
            .catch((error) => {
                dispatch(loaderActions.setShowLoader(false));
                showApiError(error.response, dispatch, t);
            });
    };

    const createPassword = () => {
        dispatch(loaderActions.setShowLoader(true));
        dispatch(loaderActions.setLoaderMessage(t('loader:loading_data')));

        let paramsSetPassword = {
            newPassword: infoState.password_confirmation,
            token: token
        };

        api.user
            .setPassword(paramsSetPassword)
            .then(() => {
                dispatch(loaderActions.setShowLoader(false));
                setResetPasswordSuccessfully(true);
            })
            .catch((error) => {
                dispatch(loaderActions.setShowLoader(false));
                setButtonIsLoading(false);
                showApiError(error.response, dispatch, t);
            });
    };

    const handleSavePasswordButton = (event) => {
        if (event) event.preventDefault();

        let rules = getValidationRules([
            ruleNames.email,
            ruleNames.createPassword,
            ruleNames.passwordConfirmation,
            ruleNames.termsAccepted
        ]);
        let params = {
            email: infoState.email,
            password: infoState.password,
            passwordConfirmation: infoState.password_confirmation,
            termsAccepted: infoState.terms_accepted_account
        };

        const validation = validationService(params, rules);

        if (validation.passes()) {
            setValidationErrors([]);
            setButtonIsLoading(true);
            createPassword();
        } else {
            const errors = validation.getErrors();
            setValidationErrors(errors);
        }
    };

    const welcomeLabel = () => {
        let label;
        infoState.first_name
            ? (label = t('reset_password_first_time.title_custom', { name: infoState.first_name }))
            : (label = t('reset_password_first_time.title'));
        return label;
    };

    const resendEmail = () => {
        api.auth
            .requestNewPassword(infoState.email)
            .then(() => {
                dispatch(openToast(TYPE_SUCCESS, t('toast:resend_email_to_reset_password')));
            })
            .catch((error) => {
                showApiError(error.response, dispatch, t);
            });
    };

    const renderSupportText = () => {
        return (
            <div className='grid grid-cols-2 gap-x-6'>
                <p className='text-sm'>{t('common:customer_support.send_message')}</p>
                <p className='text-sm'>{t('common:customer_support.call_phone')}</p>
                <p className='md:text-2xl break-words'>
                    <b>{t('common:customer_support.send_message_email')}</b>
                </p>
                <p className='md:text-2xl break-all'>
                    <b>{t('common:customer_support.call_phone_number')}</b>
                </p>
            </div>
        );
    };

    return (
        <Page pageTheme={PageTheme.light}>
            {/* DISMISSED */}
            {passwordTokenStatus === PasswordTokenStatus.DISMISSED && (
                <DisplayCard
                    type={DisplayCardTypes.PLAIN}
                    className='xl:w-9/12 2xl:w-6/12'
                    label={t('reset_password_first_time.tokenDismissed.title')}
                >
                    <p className='mb__m'>{t('reset_password_first_time.tokenDismissed.intro')}</p>
                    <Button
                        onClick={() => {
                            history.push(routePaths.login);
                        }}
                    >
                        {t('common:buttons.login')}
                    </Button>
                </DisplayCard>
            )}

            {/* EXPIRED */}
            {passwordTokenStatus === PasswordTokenStatus.EXPIRED && (
                <DisplayCard
                    type={DisplayCardTypes.PLAIN}
                    className='2xl:w-9/12'
                    label={t('reset_password_first_time.tokenExpired.title')}
                >
                    <p className='mb-8'>{t('reset_password_first_time.tokenExpired.intro')}</p>
                    <Button buttonType='submit' className='mb-8' onClick={resendEmail}>
                        {t('reset_password_first_time.tokenExpired.button')}
                    </Button>

                    {renderSupportText()}
                </DisplayCard>
            )}

            {/* VALID */}
            {passwordTokenStatus === PasswordTokenStatus.VALID &&
                (resetPasswordSuccessfully ? (
                    <DisplayCard
                        type={DisplayCardTypes.PLAIN}
                        className='xl:w-9/12 2xl:w-6/12'
                        label={t('reset_password_first_time.set_password_successfully.title')}
                    >
                        <p className='mb__m'>{t('reset_password_first_time.set_password_successfully.intro')}</p>
                        <Button
                            onClick={() => {
                                history.push(routePaths.login);
                            }}
                        >
                            {t('common:buttons.login')}
                        </Button>
                    </DisplayCard>
                ) : (
                    <DisplayCard type={DisplayCardTypes.PLAIN} className='2xl:w-9/12' label={welcomeLabel()}>
                        <form onSubmit={handleSavePasswordButton}>
                            <p
                                className='mb__ml'
                                dangerouslySetInnerHTML={{
                                    __html: t('reset_password_first_time.intro')
                                }}
                            />
                            <div className='input-col mb__l'>
                                <Input
                                    id='email'
                                    inputClass='input--white'
                                    type={InputType.EMAIL}
                                    label={t('common:labels.your_email')}
                                    value={infoState.email}
                                    disabled={true}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => false}
                                    containerClass='input__container mb__m'
                                />
                            </div>
                            {/* set isB2B2E to false to show the right tings! BAN-3072 */}
                            <MyEnvisionAccountBlock
                                isLogged={false}
                                infoState={infoState}
                                updateInfoState={updateInfoState}
                                validationErrors={validationErrors}
                                isB2B2E={false}
                            />

                            <div className='buttons'>
                                <Button
                                    buttonType='submit'
                                    disabled={!infoState.terms_accepted_account || buttonIsLoading}
                                    loading={buttonIsLoading}
                                >
                                    {t('common:buttons.save')}
                                </Button>
                            </div>
                        </form>
                    </DisplayCard>
                ))}

            {/* INVALID */}
            {passwordTokenStatus === PasswordTokenStatus.INVALID && (
                <DisplayCard
                    type={DisplayCardTypes.PLAIN}
                    className='2xl:w-9/12'
                    label={t('reset_password_first_time.tokenInvalid.title')}
                >
                    <p className='mb-8'>{t('reset_password_first_time.tokenInvalid.intro')}</p>
                    {renderSupportText()}
                </DisplayCard>
            )}
        </Page>
    );
}

export default ResetPasswordFirstTime;
