export default {
    login: {
        title: 'Log in',
        forgot_password: 'Forgot password',
        button: 'Log in'
    },
    reset_password: {
        title: 'Reset password',
    },
    reset_password_first_time: {
        title: 'Welcome to the <b>En</b>OS<sup>TM</sup> Portal!',
        // TODO::: Var: <b>En</b>OS<sup>TM</sup>.
        title_custom: 'Hello {{name}}, welcome to the <b>En</b>OS<sup>TM</sup> Portal!',
        intro: 'The portal is your place for all settings and personal information. Here you will find contract documents and invoices as well as your personal installation quote, which will be created for you. <br/><br/>To continue, please first create a password. Please remember this for future logins.',
        set_password_successfully: {
            title: 'Your password was successfully saved!',
            intro: 'Have fun using our portal.'
        },
        tokenDismissed: {
            title: 'You have already successfully set your password!',
            intro: 'Have fun using the portal.'
        },
        tokenExpired: {
            title: 'Link expired!',
            intro: 'For security reasons, the link you followed has expired',
            text_1: 'Send us a message:',
            text_2: 'kundenservice@univers.de',
            text_3: 'Or contact us by phone:',
            text_4: '+49 800 4302255',
            button: 'Request email again',
        },
        tokenInvalid: {
            title: 'Link invalid!',
            intro: 'The link you followed is not valid. Please check the link from the email or contact customer service.',
        }
    }
}