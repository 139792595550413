export const SET_USER_IS_LOGGED = 'SET_USER_IS_LOGGED';
export const SET_USER_DATA = 'SET_USER_DATA';
export const RESET_USER = 'RESET_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_AUTO_USER_LOGIN_TRIGGERED = 'SET_AUTO_USER_LOGIN_TRIGGERED';
export const SET_USER_CHARGERS = 'SET_USER_CHARGERS';
export const SET_REGISTRATION_COMPLETE = 'SET_REGISTRATION_COMPLETE';

export function setUserIsLogged(value) {
	return {
		type: SET_USER_IS_LOGGED,
		value
	}
}

export function setUserData(value) {
	return {
		type: SET_USER_DATA,
		value
	}
}

export function resetUser() {
	return {
		type: RESET_USER,
	}
}

export function setUserLoading(value) {
	return {
		type: SET_USER_LOADING,
		value
	}
}

export function setAutoUserLoginTriggered(value) {
	return {
		type: SET_AUTO_USER_LOGIN_TRIGGERED,
		value
	}
}

export function setUserChargers(value){
	return {
		type: SET_USER_CHARGERS,
		value
	}
}

export function setRegistrationComplete(value: boolean) {
	return {
		type: SET_REGISTRATION_COMPLETE,
		value,
	}
}

export default {
	setUserIsLogged,
	setUserData,
	resetUser,
	setUserLoading,
	setAutoUserLoginTriggered,
	setUserChargers,
	setRegistrationComplete
}