import { routePaths } from "../../route_paths";
import { ICart, ICartItem } from "../../typeScript/interfaces/ICart";
import lodash from "lodash-es";
import {
	SET_CART_OPENED,
	UPDATE_PRODUCTS_FOR_ITEM_TYPE,
	SET_DOWN_PAYMENT_TOTAL_AMOUNT,
	RESET_CART_DATA, SET_HARDWARE_DOWN_PAYMENT_AMOUNT, SET_INSTALLATION_DOWN_PAYMENT_AMOUNT,
	RESET_PREVISIT_PRODUCT
} from './'

const DEFAULT_CART_ITEMS: ICartItem = {
	charger: {
		key: 'charger',
		show: true,
		oneTimePaymentAmount: null,
		monthlyPaymentAmount: null,
		cardTextValues: {},
		editPageRoute: routePaths.configurationChargerSelection,
		added: false,
		products: [],
		prices: []
	},
	tariff: {
		key: 'tariff',
		show: true,
		oneTimePaymentAmount: null,
		monthlyPaymentAmount: null,
		cardTextValues: {},
		editPageRoute: routePaths.configurationTariff,
		added: false,
		products: [],
		prices: []
	},
	installation: {
		key: 'installation',
		show: true,
		oneTimePaymentAmount: null,
		monthlyPaymentAmount: null,
		cardTextValues: {},
		editPageRoute: routePaths.configurationInstallation,
		added: false,
		products: []
	},
	pre_visit: {
		key: 'pre_visit',
		show: false,
		oneTimePaymentAmount: null,
		monthlyPaymentAmount: null,
		cardTextValues: {},
		editPageRoute: routePaths.configurationInstallation,
		added: false,
		products: []
	},
	only_pre_visit: {
		key: 'only_pre_visit',
		show: false,
		oneTimePaymentAmount: null,
		monthlyPaymentAmount: null,
		cardTextValues: {},
		editPageRoute: routePaths.configuration,
		added: false,
		products: []
	}
}

export const initialState: ICart = {
	opened: false,
	items: lodash.cloneDeep(DEFAULT_CART_ITEMS),
	totalPaymentAmount: null,
	downPaymentTotalAmount: null,
	installationDownPaymentAmount: null,
	hardwareDownPaymentAmount: null,
	lastUpdated: null,
};

export function cartReducer(state: ICart = initialState, action): ICart {
	switch (action.type) {
		case SET_CART_OPENED:
			return {
				...state,
				opened: action.value
			}
		case UPDATE_PRODUCTS_FOR_ITEM_TYPE:
			const products = action.products;
			const itemKey = action.itemTypeKey;
			const cardTextValues = action.cardTextValues;
			const itemsToKeepForPreVisit = action.itemsToKeepForPreVisit;

			let isPreVisit = itemKey === 'pre_visit';

			// This will change in case we're adding a pre-visit.
			let itemsCopy: ICartItem = lodash.cloneDeep(state.items);

			// Calculate total so we do not have to set that manually.
			let newTotal = 0;

			if (cardTextValues) {
				itemsCopy[itemKey].cardTextValues = cardTextValues;
			}

			// We do this because we have to calculate how much is the one time payment for this item category.
			itemsCopy[itemKey].products = Array.isArray(products) ? products : [products];

			// Also flag this item category as added if there are products actually added.
			itemsCopy[itemKey].added = !!(itemsCopy[itemKey].products && itemsCopy[itemKey].products.length);

			// For tariff, we have monthly payments instead.
			for (const [key, object] of Object.entries(itemsCopy)) {
				// Calculate if we have to show it or not.
				itemsCopy[key].show = false;
				if (itemKey === 'only_pre_visit') {
					if (key === 'only_pre_visit')
						itemsCopy[key]!.show = true;
					else {
						itemsCopy[key].show = false;
					}
				}
				else {
					if (isPreVisit) {
						itemsCopy[key].show = (key === 'pre_visit' || (itemsToKeepForPreVisit && itemsToKeepForPreVisit.length > 0 && itemsToKeepForPreVisit.includes(key)));
					} else {
						if (key !== 'only_pre_visit')
							itemsCopy[key].show = key !== 'pre_visit';
					}
				}

				// Calculate costs only for the items we have to show.
				if (itemsCopy[key].show) {
					// Tariff is the only one that does not have one-time payment. For tariff, we have monthly payments instead.
					const subTotal = object.products.reduce((accumulated, currentItem) => accumulated + (!currentItem.code.includes('DOWN') ? currentItem?.prices[0]?.unitPrice : 0), 0);
					if (key === 'tariff') {
						itemsCopy[key]!.monthlyPaymentAmount = subTotal;
						itemsCopy[key]!.oneTimePaymentAmount = null;
					} else {
						itemsCopy[key].oneTimePaymentAmount = subTotal;
						itemsCopy[key].monthlyPaymentAmount = null;
						newTotal += subTotal;
					}
				}
			}

			return {
				...state,
				items: itemsCopy,
				totalPaymentAmount: newTotal,
			}
		case RESET_CART_DATA:
			return {
				opened: false,
				items: lodash.cloneDeep(DEFAULT_CART_ITEMS),
				totalPaymentAmount: null,
				downPaymentTotalAmount: null,
				installationDownPaymentAmount: null,
				hardwareDownPaymentAmount: null,
				lastUpdated: null,
			}
		case SET_DOWN_PAYMENT_TOTAL_AMOUNT:
			return {
				...state,
				downPaymentTotalAmount: action.value
			}
		case SET_INSTALLATION_DOWN_PAYMENT_AMOUNT:
			return {
				...state,
				installationDownPaymentAmount: action.value
			}
		case SET_HARDWARE_DOWN_PAYMENT_AMOUNT:
			return {
				...state,
				hardwareDownPaymentAmount: action.value
			}
		case RESET_PREVISIT_PRODUCT:
			let existingItems = state.items;
			existingItems['pre_visit']!.show = false;
			existingItems['pre_visit']!.oneTimePaymentAmount = null;
			existingItems['pre_visit']!.monthlyPaymentAmount = null;
			existingItems['pre_visit']!.cardTextValues = {};
			existingItems['pre_visit']!.added = false;
			existingItems['pre_visit']!.products = [];

			return {
				...state,
				items: existingItems
			}
		default:
			return state
	}
}
