export default {
    something_went_wrong: 'Something went wrong, please try again later',
    personal_info_updated: 'You personal information has been successfully updated',
    wrong_credentials:
        'Invalid email or password. Please try again, or use "Forgot Password" to reset your credentials.',
    password_updated: 'Your password has been updated.',
    reset_password_success: 'A reset password link has been sent to your email',
    offline: 'Your are currently offline. Please reload the page or try again later',
    referral_code_success: 'Your referral code was successfully applied.',
    support_success:
        'Your message has been sent successfully. Our customer support team will get in touch with you soon',
    login_stage_new_error: 'No my<b>En</b>OS<sup>TM</sup> account exists for this email address.',
    added_payment_method_successful: 'The payment method for the monthly invoice has been successfully deposited.',
    added_payment_method_failed:
        'The payment method could not be deposited. Please try again or contact customer service.',
    resend_email_to_reset_password: 'A new link to set the password has been sent to your email address.',
    contract_termination_200: 'We have received your cancellation.',
    contract_file_size_too_large: 'The file must be not larger than {{maxFileSize}}MB'
};
