import { SET_IS_COOKIE_CONSENT_OPEN, SET_IS_MARKETING_COOKIE } from '.';
import { ICookie } from '../../typeScript/interfaces/ICookie';


export const initialState = {
	isCookieConsentOpen: false,
	isMarketingCookie: false
};

export function cookieReducer(state: ICookie = initialState, action): ICookie {
	switch (action.type) {
		case SET_IS_COOKIE_CONSENT_OPEN:
			return {
				...state,
				isCookieConsentOpen:  action.value
			}
			case SET_IS_MARKETING_COOKIE:
				return {
					...state,
					isMarketingCookie:  action.value
				}
		default:
			// We need the default to ALWAYS return the initial state.
			return state
	}
}