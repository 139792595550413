export default {
    personal_info: {
        title: "Persönliche Informationen",
        notification_title: "Benachrichtigungs-Einstellungen",
        loading: 'Laden von Informationen...'
    },
    notifications: {
        title: "Benachrichtigungs-Einstellungen",
        statement_notification: "Ich möchte meinen Jahresabschlussbericht per E-Mail erhalten.",
        marketing_notification: "Ich möchte Werbebotschaften erhalten."
    }
}
