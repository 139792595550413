import { TYPE_SUCCESS } from 'components/Toast';
import { HomeGarageImage } from 'constants/images';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'store';
import loaderActions from 'store/loader';
import { openToast } from 'store/toast';
import api from '../api/api';
import { Button, Input, Page } from '../components';
import InputType from '../constants/inputType';
import { routePaths } from '../route_paths';
import { ILoggedUser } from '../typeScript/interfaces/IUser';
import { ButtonType } from '../typeScript/types/ButtonTypes';
import { showApiError } from '../utils/error-handler';
import {
    getValidationRules,
    hasValidationError,
    ruleNames,
    validationErrorTranslation,
    validationService
} from '../validation';

function ResetPassword(props) {
    const { t } = useTranslation('auth');
    const { history } = props;

    const [validationErrors, setValidationErrors] = useState<any[]>([]);
    const [email, setEmail] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const autoUserLoadTriggered = useSelector<IRootState, boolean>((state) => state.user.autoUserLoadTriggered);
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);
    const dispatch = useDispatch();

    const passwordReset = () => {
        api.auth
            .requestNewPassword(email)
            .then(() => {
                setButtonLoading(false);
                dispatch(openToast(TYPE_SUCCESS, t('toast:reset_password_success')));
            })
            .catch((error) => {
                setButtonLoading(false);
                showApiError(error.response, dispatch, t);
            });
    };

    const handleResetPassword = (event) => {
        if (event) {
            event.preventDefault();
        }
        let rules = getValidationRules([ruleNames.email]);
        let params = {
            email: email
        };

        const validation = validationService(params, rules);

        if (validation.passes()) {
            passwordReset();
            setButtonLoading(true);
        } else {
            const errors = validation.getErrors();
            setValidationErrors(errors);
        }
    };

    const goToLogin = () => {
        history.push(routePaths.login);
    };

    useEffect(() => {
        if (autoUserLoadTriggered) {
            if (isLogged && user && user.type) {
                const link = routePaths.portalOverview;
                history.push(link);
                dispatch(loaderActions.setShowLoader(false));
            } else {
                dispatch(loaderActions.setShowLoader(false));
            }
        }
    }, [autoUserLoadTriggered, dispatch, history, isLogged, user]);

    return (
        <Page className='page page--footer auth-page' gradientClassName='gradient-page--auth'>
            <div className='auth-page__content'>
                <form className='auth-page__content__form-container' onSubmit={handleResetPassword}>
                    <h2 className='mb__l'>{t('reset_password.title')}</h2>
                    <div className='input-col mb__l'>
                        <Input
                            id='email'
                            inputClass='input--white'
                            type={InputType.EMAIL}
                            label={t('common:labels.your_email')}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)}
                            hasValidationError={hasValidationError(ruleNames.email, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.email, validationErrors, t)}
                            placeholder={t('common:placeholder.email')}
                            containerClass='input__container mb__m'
                        />
                    </div>
                    <div className='buttons'>
                        <Button className='button' type={ButtonType.quaternary} onClick={goToLogin}>
                            {t('common:buttons.back')}
                        </Button>
                        <Button loading={buttonLoading} onClick={handleResetPassword}>
                            {t('common:buttons.confirm')}
                        </Button>
                        <br />
                        <br />
                    </div>
                </form>
                <div className='auth-page__content__image'>
                    <div className='auth-page__content__image__inner'>
                        <img src={HomeGarageImage} alt='' />
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default ResetPassword;
