export default {
    welcome: {
        headline: 'Before we begin configuring you Charging by <b>En</b>OS<sup>TM</sup> bundle...',
        selectHeadLine: 'Please select how you would like to proceed',
        text: '...we want to make sure that the installation is possible at your home. Therefore, we will first ask you two questions about the property conditions that are crucial for the installation.',
        button_text: 'Order pre-inspection now for 99€',
        sections: {
            Charger: 'Charger',
            tariff: 'Contract',
            installation: 'Installation'
        },
        question: 'question',
        answer: 'answer',
        contact: {
            headline: 'Thank you very much!',
            text: 'Unfortunately, we cannot easily perform an installation in your home at this time for the following reasons: You do not own the property, you do not have the owner\'s consent, or you do not live in a single-family dwelling.<br><br>On 17. September 2020, the new Condominium Modernisation Act (WEMoG) was passed, granting the right to install an electric vehicle charging station in a private environment. The law will come into force on 1 December 2020 and regulates the right of tenants and owners of condominiums to install a charging station in the communal garage or on the communal property. We will adjust our installation service as soon as possible after the law comes into force.',
            get_in_touch: 'We will be happy to advise you personally on how to proceed. Please contact us at:',
            small_print: 'Freephone number. Calls from the mobile network may incur additional charges depending on the mobile network operator.'
        },
        Overview: {
            headline: 'Welcome to the configuration wizard for your Charging by <b>En</b>OS<sup>TM</sup> package.',
            text: 'We will guide you through the configuration of your complete package. By asking you around ten relevant questions, we aim to help you choose your package configuration and remove as many ambiguities as possible.'
        },
        overview_actions_completed: {
            headline: 'Your package is complete!',
            text: 'All components Charging by <b>En</b>OS<sup>TM</sup> bundles are configured. You can check your order again in the shopping cart and finish by registering.'
        },
        referral_code: {
            headline: 'Do you have a referral code?',
            text: 'If one of your relatives has shared a referral code with you, please enter it now.'
        },
        notification: '<p>Subsidy for the purchase and connection of charging stations: 900 euros subsidy secured for the purchase of the Charging by <b>En</b>OS<sup>TM</sup> Bundle!&nbsp;<a target="_blank"  href="https://www.kfw.de/inlandsfoerderung/Privatpersonen/Bestehende-Immobilie/Förderprodukte/Ladestationen-für-Elektroautos-Wohngebäude-(440)/">learn more</a></p>',
    },
    hardware: {
        headline: 'The smart <b>En</b>OS<sup>TM</sup> charger',
        text: 'The <b>En</b>OS<sup>TM</sup> Charger is the "INCH HOME" model from Etrel. The charger supports 11 kW as standard and works perfectly with all car models with a type 2 connection.',
        small_print_one: '* The full price of the charger is only payable after successful installation. Currently, <b>En</b>OS<sup>TM</sup> supports the INCH Home model from Etrel. The range of charging stations is constantly being expanded.',
        small_print_two: '** Due to a mandatory preliminary visit by certified Univers electricians and the approval requirement by the grid operator, experience has shown that the installation of the 22 kW can take significantly longer.',
        discount_badge_title: 'Friends & Family Offer',
        discount_badge_save: 'You save',
        discount_instead: 'instead of',
        banner_title: 'up to 22 kW**',

        card: {
            headline: '<b>En</b>OS<sup>TM</sup> charger',
            text: 'Charge at 11 kW in the standard configuration about five times as fast as at a household socket. Common electric cars are fully charged overnight',
            sub_text_1: '- Integrated FI switch',
            sub_text_2: '- Internet connection via SIM card',
            sub_text_3: '- Authentication via app',
            sub_text_4: '- Made in the EU',
            documentation: 'Documentation',
            tech_specs: 'technical specifications',
        }
    },
    tariff: {
        headline: 'Choose your electricity tariff',
        text: 'Your electricity tariff includes: Basic fee, smart meter fee, SIM cards, 100% green electricity and your electricity package at a reduced price',
        card: {
            monthlyPrice: 'Monthly price',
            monthlyMileageLabel: 'Monthly mileage',
            addKwhCostLabel: 'Add. kwh cost',
            incPerMonth: 'included per month',
            perYear: 'per year',
            includedKwhCosts: 'Included kwh cost label',
        },
        package: {
            headline: 'All our packages include',
            check_list: {
                contract_period: '24-month contract period',
                price_guarantee: '24-month price guarantee *',
                service: '24/7 emergency service',
                smart_meter: 'Smart Meter',
                sim_card: 'Smart Meter & SIM cards',
                annual_maintenance: 'Annual maintenance',
                green_energy: '100% green energy',
                app_control: 'App control & analysis',
                include_kwh: 'Included kWh',
            },
        },
        small_print_one: '* The energy price for each additional kWh not included is not subject to the price guarantee.',
        small_print_1: '<sup>1</sup> The energy price for each additional kWh not included is not subject to the price guarantee.',
        small_print_2: '<sup>2</sup> Our package prices include 19% VAT. In the event of an increase in VAT, the package prices will increase accordingly.',
    },
    installation: {
        intro: {
            headline: 'Installation package',
            text: 'The installation package is the third part of the bundle. In order to find the right one for you, we ask you questions about the following areas below:',
            check_list: {
                charger_selection: 'Version of the charger',
                living_situation: 'living situation',
                parking_situation: 'Parking situation',
                distribution_closet: 'Distribution cabinet in the basement',
            },
            note: 'Note: Based on your answers, either an installation package or a personal pre-visit will be recommended to you for prior check.'
        },
        Information: {
            category: 'Distribution panel in the basement',
            text: 'After you have selected your <b>En</b>OS<sup>TM</sup> Charger and green electricity tariff package, we will need information about your distribution box to finish.',
            note: '<b>Note:</b> To answer some questions, you will need access to your distribution box.',
            check_list: {
                fuse_power: 'Sufficiently strong fuses?',
                space_in_cabinet: 'Space for a second meter?',
                space_next_to_cabinet: 'Space for a second distribution box?'
            },
        },
        question_progress: {
            completion: 'question {{current}} / {{total}}'
        },
        installation_package: {
            headline: 'Your individual installation package!',
            text: 'Based on the answers you gave, we have put together an individual installation package for you. This completes your personal Charging by <b>En</b>OS<sup>TM</sup> package! <br><br>Please make sure your details are correct and accurate for a speedy and straightforward installation. Thank you very much!',
            show_answers: 'Re-check your details',
            hide_answers: 'Hide data',
            package_s: {
                headline: 'Plug & Charge S',
                headline_short: 'S'
            },
            package_m: {
                Headline: 'Plug & Charge M',
                headline_short: 'M'
            },
            package_l: {
                Headline: 'Plug & Charge L',
                headline_short: 'L'
            },
            package_xl: {
                Headline: 'Plug & Charge XL',
                headline_short: 'XL'
            },
            check_list: {
                registration_vnb: 'Registration with the DSO',
                shipping: 'Shipping',
                trip_fee: 'Travel Fee',
                inspection: 'Inspection & consultation',
                install_charge_device: 'Assembly and installation of the charger',
                configuration: 'configuration, functional testing and instruction',
                install_smart_meter_existing_board: 'Smart meter installation at the distribution board according to the standards of the Association for Electrical, Electronic & Information Technologies (VDE)',
                final_check: 'Checking the existing board including all required components',
                switch_system: 'Switching the system to neutral',
                two_wall_throughs: 'Up to two wall penetrations including sealing material',
                up_to_15m_cable: 'Up to 15 metres of lightweight plastic-sheathed cable',
                up_to_15m_pipes: 'Up to 15 metres (49 ft) of plastic conduit',
                tubes_pipes: 'Installation of tubes and pipes',
                new_manifold_board: 'Installation of a new manifold',
                smart_meter: 'Installation of a smart meter',
                check_board: 'Check of the existing manifold including all necessary components',
            }
        }
    },
    pre_visit: {
        headline: 'A pre-visit is required!',
        text: 'Thank you for your information! Based on your information, we need to conduct a pre-visit of your premises by one of our professional partner companies',
        text_22kw: '<b>Thank you for your information! Based on the information you provided, we need to conduct a pre-inspection of your premises with one of our professional partner companies. </b><br><br>Chargers with a capacity of more than 11 kW must not only be registered with the distribution network operator (DSO), but also require the approval of the DSO. In addition, the infrastructure at the installation site must be prepared for the increased load.',
        text_building: '<b>Thank you for your information! Based on your information, we have to carry out a pre-visit at your site by one of our professional partner companies. </b><br><br>Installations in buildings with several residential units and parking spaces are usually more complex due to their structure, the more complex electrics and the greater distances from the charging point to the distribution box. Therefore, a certified electrician must walk the installation site to better estimate the effort and provide a quote.',
        text_facade: '<b>Thank you for sharing your information with us! Based on the information you have provided, we will need to arrange a pre-inspection appointment with one of our professional partner companies at your home. </b><br><br>Owners of listed buildings cannot simply make structural changes to the buildings. Many changes require permission from the authorities. As the type and extent of protection can vary greatly, a preliminary inspection by one of our partners is necessary.',
        text_garage: '<b>Thank you for your information! Based on the information you have provided, we will need to arrange a pre-visit with one of our professional partners at your home. </b><br><br>High voltage cables must be at least 80 cm (2.6 ft) underground. If your garage is not directly attached to your house, this means that earthworks are required. To better estimate the extent of the earthworks, one of our electricians will need to visit your home and assess the conditions on site.',
        text_cabinet: '<b>Thank you for sharing your information! Based on the information you have provided, we will need to arrange a preliminary visit to your home with one of our professional partner contractors. </b><br><br>Does your manifold look like this or similar to the one in the fourth picture? This means that it is an older generation distribution board. One of our certified electricians will need to inspect the distribution board and infrastructure on site and check that the fuses and cables are suitable for the <b>En</b>OS<sup>TM</sup> charger.',
        text_fuse: '<b>Thank you for sharing your information! Based on the information you have provided, we will need to make a preliminary appointment with one of our professional partners at your home. </b><br><br>You have indicated that the fuses in your distribution board are weaker than 32 amps. Therefore, one of our electricians will need to check that the fuses are strong enough to protect your household from overload.',
        text_2nd_cabinet: '<b>Thank you for sharing your information! Based on the information you have provided, we will need to make a preliminary appointment with one of our professional partner companies at your home. </b><br><br>You have indicated that there is not enough space next to your existing distribution board to install another small surface-mounted distribution board. Therefore, an expert will need to inspect the installation site to consider possible alternatives.',
        package: {
            headline: 'Preliminary inspection',
            headline_sub: '99,00 €',
            intro: 'content & procedure',
            check_list: {
                booking: '✓ Booking is made by the Univers Service Team',
                qualified_installers: '✓ Certified electricians',
                pre_check: '✓ On-site visit incl.. Analysis & advice',
                installation_package: '✓ Individual installation offer',
                installation_offer: '✓ Individual installation offer after visit',
                guarantee: '✓ 100% guaranteed installation after a positive evaluation',
            },
        },
        friends_and_family_code_info: 'The Friends & Family Code has been successfully saved and will be applied accordingly when a quote is generated after the pre-visit by one of our electricians.'
    },
}