import api from "../api/api";
import { isAdyenDevelopment } from "./env_helpers";
import eventBus from "./eventBus";

let adyenScriptAdded = false;

const initializeAdyenCheckout = (paymentRef, configuration, counter, onSuccessCallback, isNoPreSelection) => {
    // TODO: For some reason, the promise does not seem to work so we've moved this sync.
    // return new Promise((resolve) => {

    if (window.AdyenCheckout && paymentRef.current) {
        const AdyenCheckout = window.AdyenCheckout
        const checkout = new AdyenCheckout(configuration);
        const result = checkout.create('dropin', {
            openFirstPaymentMethod: isNoPreSelection ? false : true,
            onSelect: () => {
                eventBus.dispatch('paymentMethodSelected');
            },
            onReady: () => {
                eventBus.dispatch('adyenIsReady');
            }
        }).mount(paymentRef.current);
        onSuccessCallback && onSuccessCallback(result)
    } else {
        if (counter > 0) {
            setTimeout(() => {
                initializeAdyenCheckout(paymentRef, configuration, --counter, onSuccessCallback)
            }, 500)
        }
    }
    // });
}

export const createAdyenCheckout = (data, paymentRef, onSubmit, onAdditionalDetails, language, onSuccessCallback, isNoPreSelection) => {
    createAdyenScriptAndStyle();
    return api.payment.getPaymentMethods(data).then((response) => {
        return response.data;
    }).then(paymentData => {
        paymentData.storedPaymentMethods = [];
        const configuration = {
            paymentMethodsResponse: paymentData, // The `/paymentMethods` response from the server.
            clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
            locale: getAdyenLocale(language),
            environment: isAdyenDevelopment ? "test" : "live",
            onSubmit: (state, component) => {
                onSubmit && onSubmit(state)
            },
            // onAdditionalDetails: Create an event handler, called when a payment method requires more details, for example for native 3D Secure 2, or native QR code payment methods.
            onAdditionalDetails: (state, component) => {
                onAdditionalDetails && onAdditionalDetails(state, component);
            },
            showPayButton: false,
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    enableStoreDetails: false,
                },
                ideal: {
                    showImage: true
                },
            }
        };

        return initializeAdyenCheckout(paymentRef, configuration, 20, onSuccessCallback, isNoPreSelection);
    })
}

export const createAdyenScriptAndStyle = () => {
    if (!adyenScriptAdded) {

        // link
        const script = document.createElement('script');
        script.src = isAdyenDevelopment ? "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/4.1.0/adyen.js" : "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/4.1.0/adyen.js";
        script.integrity = "sha384-3tEepwhhMcyxgIbL3HBe3I59BpSMNyKoNrbKWARYH1tJ7K7K6NdTDqOltKlwiVsH";
        script.crossOrigin = "anonymous";
        script.async = true;
        document.body.appendChild(script);
        adyenScriptAdded = true

        // style
        const link = document.createElement('link');
        link.rel  = 'stylesheet';
        link.href = isAdyenDevelopment ? "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/4.1.0/adyen.css" : "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/4.1.0/adyen.css";
        link.integrity="sha384-+CPzBNZVkBXu4uXDECnVuVQ24Kl8vWrR61UzuuuUj5IBEP//BQ0G0KDNfz2iPcvJ";
        link.crossOrigin="anonymous";
        document.body.appendChild(link);

    }
};

export const getAdyenLocale = (i18nlang) => {
    const languageMap = {
        de: 'de-DE',
        en: 'en-US'
    };

    return languageMap[i18nlang] ? languageMap[i18nlang] : languageMap['en']
}