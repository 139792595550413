export default {
    header:{
        title: "Documents",
        text: "In here you will find all the documents you shared with Univers. You’ll also find all the documents you received.",
    },
    information:{
        title:"Information",
        charger_manuel: "Charger Manual",
        employer_permission: "Employer Permission"
    },
    contract:{
        title:"Contract"
    },
    invoices:{
        title:"Quotes & Invoices",
        invoice_charger: "Invoice Charger",
        installation_quote: "Installation Quote",
        loading: 'Loading invoices & quotes...'
    },
    no_documents: "No documents available"
}
