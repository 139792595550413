import api from 'api/api';
import Token from 'api/auth-token-wrapper';
import { CookieBanner, Footer, Nav, Toast } from 'components';
import Loader from 'components/Loader';
import 'i18n';
import loadLoggedUser from 'mixins/loadLoggedUser';
import React, { useEffect, useState } from 'react';
import 'react-dates/initialize'; // initialize the date-picker styles
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Routes from 'routes';
import { routePaths } from 'route_paths';
import { store } from 'store';
import configurationActions from 'store/configuration';
import loaderActions from 'store/loader';
import userActions, { setRegistrationComplete } from 'store/user';
import { envConsole } from 'utils/console';
import { concealMessageDeterministic } from 'utils/encrypt';
import Constants from './constants';
import { IMAGES } from './constants/images';
import './scss/main.scss';
import { IRootState } from './store';
import { ILoggedUser } from './typeScript/interfaces/IUser';

const authWall = [
    routePaths.portalOverview,
    routePaths.portalCharger,
    routePaths.portalDocuments,
    routePaths.portalPhotoUpload,
    routePaths.portalPayment,
    routePaths.portalProfile,
    routePaths.portalSupport,
    routePaths.portalTariff,
    routePaths.portalPayout,
    routePaths.portalFleetData
];

const portalRoutes = [
    routePaths.portalOverview,
    routePaths.portalCharger,
    routePaths.portalDocuments,
    routePaths.portalPhotoUpload,
    routePaths.portalPayment,
    routePaths.portalProfile,
    routePaths.portalSupport,
    routePaths.portalTariff,
    routePaths.portalPayout,
    routePaths.portalFleetData
];

function App() {
    const { dispatch } = store;
    const location = useLocation();
    const history = useHistory();
    const token = Token.getToken();
    const [gtmInit, setGtmInit] = useState(false);
    const [opportunityFetchComplete, setOpportunityFetchComplete] = useState(false);
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const isMarketingCookie = useSelector<IRootState, boolean>((state) => state.cookie.isMarketingCookie);

    useEffect(() => {
        api.user.getAccount().then((response) => {
            dispatch(userActions.setUserData(response.data));
        });
        // eslint-disable-next-line
    }, [isLogged]);

    const redirectOnNotLoggedIn = () => {
        dispatch(userActions.setAutoUserLoginTriggered(false));
        let pageName = location.pathname;

        switch (pageName) {
            case routePaths.portalOverview:
            case routePaths.portalCharger:
            case routePaths.portalDocuments:
            case routePaths.portalPhotoUpload:
            case routePaths.portalPayment:
            case routePaths.portalProfile:
            case routePaths.portalSupport:
            case routePaths.portalTariff:
            case routePaths.portalPayout:
            case routePaths.portalFleetData:
                history.push(routePaths.login);
                break;
            default:
                break;
        }
    };

    const loadAndCacheAssets = async () => {
        const imageAssets = IMAGES;
        cacheImages(imageAssets);
    };

    const cacheImages = async (images) => {
        const promises = await images.map((image) => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image();
                loadImg.src = image;
                loadImg.onload = () => resolve(image);
                loadImg.onerror = (err) => reject(err);
            });
        });

        await Promise.all([...promises])
            .then(() => dispatch(loaderActions.setShowLoader(false)))
            .catch((err) => {
                dispatch(loaderActions.setShowLoader(false));
                console.log('Failed to load images', err);
            });
    };

    useEffect(() => {
        loadAndCacheAssets();
        // on initial load, we try to log the user in...
        // dispatch(loaderActions.setShowLoader(true));
        if (token) {
            const successCallback = () => {
                dispatch(userActions.setAutoUserLoginTriggered(true));
            };
            loadLoggedUser(dispatch, successCallback, redirectOnNotLoggedIn);
        } else {
            redirectOnNotLoggedIn();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user?.registrationComplete === null || user?.registrationComplete === undefined) {
            api.leads
                .getLeadStatus()
                .then((response) => {
                    setOpportunityFetchComplete(true);
                    if (response.data?.stageName?.toLowerCase() === 'new') {
                        dispatch(setRegistrationComplete(false));
                    } else {
                        dispatch(setRegistrationComplete(true));
                    }
                })
                .catch(() => {
                    setOpportunityFetchComplete(true);
                });
        }
        // eslint-disable-next-line
    }, [user?.registrationComplete]);

    useEffect(() => {
        if (!user?.canUpdatePaymentInformation) {
            if (location.pathname === routePaths.portalPayment) {
                history.push(routePaths.portalOverview);
            }
        }
        if (!user?.isReimbursementActivated) {
            if (location.pathname === routePaths.portalPayout) {
                history.push(routePaths.portalOverview);
            }
        }
        if (!user?.isFleetDataEnrichment) {
            if (location.pathname === routePaths.portalFleetData) {
                history.push(routePaths.portalOverview);
            }
        }

        if (!isLogged && authWall.includes(location.pathname) && opportunityFetchComplete) {
            history.push(routePaths.login);
        }
        user?.type && envConsole('USERTYPE: ' + user?.type);
        // eslint-disable-next-line
    }, [isLogged, location.pathname, user, opportunityFetchComplete]);

    useEffect(() => {
        // Init GTM.
        isMarketingCookie && initGtm();
        // If you make changes that need to re-work the data stored in the localStorage, then you should bump this version up.
        // You can find that string in constants/index.js as VERSION.
        checkAndCleanUpLocalStorageForCurrentVersion();
        // eslint-disable-next-line
    }, [isMarketingCookie]);

    const initGtm = () => {
        if (!gtmInit) {
            const tagManagerArgs = {
                gtmId: Constants.GTM_ID
            };

            TagManager.initialize(tagManagerArgs);

            setGtmInit(true);
        }
    };

    const checkAndCleanUpLocalStorageForCurrentVersion = () => {
        const key = `version_${concealMessageDeterministic('cache_key').toString()}`;
        // If you want to restart the local st
        const encodedCurrentVal = localStorage.getItem(key);
        const encodedNewVal = concealMessageDeterministic(Constants.VERSION).toString();
        if (!encodedCurrentVal || encodedCurrentVal !== encodedNewVal) {
            localStorage.removeItem(key);
            Token.removeAuthTokens();
            dispatch(userActions.resetUser());
            dispatch(configurationActions.resetConfigurationData());
            dispatch(configurationActions.resetInstallationAnswersSelection());
            // And now add the key again.
            localStorage.setItem(key, encodedNewVal);
        }
    };

    return (
        <>
            <Loader />
            <Nav />

            <Routes />
            <Footer />
            <Toast />
            <CookieBanner />
        </>
    );
}

export default App;
