export default {
    overview: 'Overview',
    personal_info: 'Personal info',
    install_details: 'Installation address',
    payment_options: 'Payment options',
    confirmation: 'Confirmation',
    shopping_cart: 'Shopping cart',
    charger: 'Charger',
    tariff_package: 'Electricity Tariff',
    installation_package: 'Installation Package',
    terms: 'Terms',
    privacy: 'Privacy',
    imprint: 'Imprint',
    revokeCookie: 'Cookie-Consent',
    cancel: 'Cancel',
    billing: 'Billing',
    contract: 'Contract',
    support: 'Support',
    log_out: 'Log out',
    basket: 'Your basket',
    fqa: 'FAQ',
    back: 'Back',
    back_to_start: 'Back to start',
    documents: 'Documents',
    my_profile: 'My Profile',
    my_payment: 'Payment',
    tariff: 'Contract',
    payout: 'Reimbursement',
    fleetData: 'Fleet Data',
    profile: 'Profile',
    chargingCards: 'Charging Cards',
}