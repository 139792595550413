import { useEffect, useRef } from 'react';


// example usage: const isMounted = useIsMounted()
export const useIsMounted = () => {
    const isMounted = useRef(false);
    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
        }, 10)
        return () => isMounted.current = false;
    }, []);
    return isMounted;
};