import { TYPE_ERROR } from 'components/Toast';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import { IRootState } from '../../store';
import loaderActions from '../../store/loader';
import { openToast } from '../../store/toast';
import { IUserCharger } from '../../typeScript/interfaces/IUser';
import { showApiError } from '../../utils/error-handler';
import { getValidationRules, ruleNames, validationService } from '../../validation';
import { BusinessChargerCardDetail } from './BusinessChargerCardDetail';
import { CustomerChargerCardDetail } from './CustomerChargerCardDetail';

export type ChargerDetailCardCommonProps = {
    loading: boolean;
    editClicked: boolean;
    userCharger: IUserCharger;
    handleSaveButton: (e: any) => void;
    onInputChange: (field: string, value: any) => void;
    onCheckedChange: (field: string, value: any) => void;
    validationErrors: object[];
    onEditClicked: () => void;
    onCancelClicked: (e) => void;
    onSwitchClicked: () => void;
    isSingleChargerView: boolean;
    toggle: boolean;
};

type Props = {
    data: string;
    handleClose: (e) => void;
    updateList: (id, name, mode) => void;
    isBusiness: boolean;
    isSingleChargerView: boolean;
};

function ChargerCardDetail(props: Props) {
    const { isSingleChargerView } = props;
    const { t } = useTranslation(['charger']);
    const [toggle, setToggle] = useState(false);
    const [userCharger, setUserCharger] = useState<IUserCharger>({} as IUserCharger);
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const [loading, setLoading] = useState(true);
    const [, setChargerErrorMessage] = useState(null);
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState<any[]>([]);
    const [editClicked, setEditClicked] = useState(false);
    const [preSaveChargerName, setPreSaveChargerName] = useState('');

    useEffect(() => {
        if (isLogged) {
            setLoading(true);
            getChargerDetail().then((response) => {
                if (Object.keys(response.data).length > 0) {
                    setToggle(response.data.authorizationModeEnabled);
                } else {
                    props.handleClose(false);
                    dispatch(openToast(TYPE_ERROR, t('toast:something_went_wrong')));
                }
            });
        }
        // eslint-disable-next-line
    }, [isLogged]);

    const getChargerDetail = () => {
        return api.charger
            .getCharger(props.data)
            .then((response) => {
                setUserCharger(response.data);
                setLoading(false);
                dispatch(loaderActions.setShowLoader(false));
                return response;
            })
            .catch((error) => {
                dispatch(loaderActions.setShowLoader(false));
                setLoading(false);
                if (error.response.data.code === 'CHARGER_OWNER_NOT_FOUND') {
                    setChargerErrorMessage(t('charger_not_found'));
                } else {
                    showApiError(error.response, dispatch, t);
                }
            });
    };

    const showApiErrorOnCatch = (error) => {
        showApiError(error.response, dispatch, t);
        dispatch(loaderActions.setShowLoader(false));
    };

    const showAuthApiErrorOnCatch = (error) => {
        error.response.data.message = t('charger_auth_error_message', { chargerName: userCharger.chargerName });
        showApiError(error.response, dispatch, t);
        dispatch(loaderActions.setShowLoader(false));
    };

    const updateChargerName = () => {
        return api.charger
            .updateChargerName(userCharger.id, { caption: userCharger?.chargerName, id: userCharger?.id })
            .then(() => {
                setEditClicked(false);
            })
            .catch((error) => {
                showApiError(error.response, dispatch, t);
            });
    };

    const updateChargerStatus = () => {
        return api.charger.updateChargerAuthorization(userCharger?.id, { isEnabled: toggle }).then(() => {
            setValidationErrors([]);
        });
    };

    const revertToggleOnApiError = (error) => {
        dispatch(loaderActions.setShowLoader(false));
        showAuthApiErrorOnCatch(error);
        setToggle(!toggle);
    };

    const updateChargerList = (charger: IUserCharger) => {
        props.updateList(charger.id, charger.chargerName, charger.authorizationModeEnabled);
    };

    const saveCharger = () => {
        if (preSaveChargerName !== userCharger?.chargerName && toggle !== userCharger?.authorizationModeEnabled) {
            dispatch(loaderActions.setShowLoader(true));
            updateChargerName()
                .then(() => {
                    return updateChargerList({
                        id: userCharger?.id,
                        chargerName: userCharger?.chargerName,
                        authorizationModeEnabled: userCharger?.authorizationModeEnabled
                    } as IUserCharger);
                }, showApiErrorOnCatch)
                .then(() => {
                    return updateChargerStatus();
                })
                .catch(revertToggleOnApiError)
                .then(() => {
                    onCheckedChange('authorizationModeEnabled', toggle);
                    return updateChargerList({
                        id: userCharger?.id,
                        chargerName: userCharger?.chargerName,
                        authorizationModeEnabled: toggle
                    } as IUserCharger);
                })
                .then(() => {
                    dispatch(loaderActions.setShowLoader(false));
                });
        } else {
            dispatch(loaderActions.setShowLoader(true));
            if (preSaveChargerName !== userCharger?.chargerName) {
                updateChargerName()
                    .then(() => {
                        updateChargerList({
                            id: userCharger?.id,
                            chargerName: userCharger?.chargerName,
                            authorizationModeEnabled: userCharger?.authorizationModeEnabled
                        } as IUserCharger);
                    }, showApiErrorOnCatch)
                    .then(() => {
                        dispatch(loaderActions.setShowLoader(false));
                    });
            } else if (toggle !== userCharger.authorizationModeEnabled) {
                updateChargerStatus()
                    .then(() => {
                        updateChargerList({
                            id: userCharger?.id,
                            chargerName: userCharger?.chargerName,
                            authorizationModeEnabled: toggle
                        } as IUserCharger);
                        onCheckedChange('authorizationModeEnabled', toggle);
                    }, revertToggleOnApiError)
                    .then(() => {
                        dispatch(loaderActions.setShowLoader(false));
                    });
            } else {
                dispatch(loaderActions.setShowLoader(false));
            }
        }
    };

    const handleSaveButton = (event) => {
        if (event) {
            event.preventDefault();
        }

        let paramsForValidation = {
            charger_name: userCharger?.chargerName
        };

        const rulesChargerName = getValidationRules(ruleNames.chargerName);
        const validationChargerName = validationService(paramsForValidation, rulesChargerName);

        if (validationChargerName.passes()) {
            setValidationErrors([]);
            saveCharger();
        } else {
            setValidationErrors(validationChargerName.getErrors());
        }

        if (validationChargerName.passes()) props.handleClose(event);
    };

    const onCheckedChange = (field, value) => {
        setUserCharger({
            ...userCharger,
            [field]: value
        } as IUserCharger);
    };

    const onEditClicked = () => {
        setPreSaveChargerName(userCharger.chargerName);
        setEditClicked(!editClicked);
    };

    const onCancelClicked = (e) => {
        e.preventDefault();
        let charger = {
            ...userCharger
        };
        if (editClicked) {
            charger.chargerName = preSaveChargerName;
        }

        if (toggle !== userCharger.authorizationModeEnabled) {
            setToggle(userCharger.authorizationModeEnabled);
        }

        setUserCharger(charger);
        setEditClicked(false);
        setValidationErrors([]);
    };

    const onSwitchClicked = () => {
        setToggle(!toggle);
    };

    const onInputChange = (field, value) => {
        setUserCharger({
            ...userCharger,
            [field]: value
        } as IUserCharger);
    };

    const chargerCardDetailProps = {
        userCharger,
        editClicked,
        handleSaveButton,
        loading,
        onInputChange,
        onEditClicked,
        validationErrors,
        onCheckedChange,
        isSingleChargerView,
        onCancelClicked,
        onSwitchClicked,
        toggle
    };

    const chargerCardDetail = props.isBusiness ? (
        // TODO::: Torsten, need a business user with charger
        <BusinessChargerCardDetail {...chargerCardDetailProps} />
    ) : (
        <CustomerChargerCardDetail {...chargerCardDetailProps} />
    );

    return <>{chargerCardDetail}</>;
}

export default ChargerCardDetail;
