export const OPEN_TOAST = 'OPEN_TOAST';
export const CLOSE_TOAST = 'CLOSE_TOAST';

export function openToast(toastType, value) {
    return {
        type: OPEN_TOAST,
        toastType: toastType,
        value: value
    }
}

export function closeToast() {
    return {
        type: CLOSE_TOAST,
    }
}