export default {
    header: {
        title: 'Planung und Ablauf',
        text: 'Vom ersten Vor-Ort-Check bis zur Installation.'
    },
    step: 'Schritt',
    sectionNow: {
        title: 'Jetzt',
        step1: {
            title: 'Terminvereinbarung',
            content:
                'Planen Sie einen Termin mit einem unserer Elektriker. Dieser kommt bei Ihnen vorbei und sieht sich die individuellen Gegebenheiten vor Ort an.'
        },
        step2: {
            title: 'Pre-Visit Besuch',
            content:
                'Ihre Infrastruktur wird geprüft, der Installationsort wird vorausgewählt und Sie erhalten Beratung zur Wahl der passenden Lösung bei Ihnen zuhause.'
        }
    },
    sectionPreVisit: {
        title: 'Nach dem Pre-Visit',
        step3: {
            title: 'Kostenvoranschlag',
            content: 'Basierend auf den Erkenntnissen des Pre-Visits erhalten Sie ein Angebot für das Komplettpaket.'
        },
        step4: {
            title: 'Bestellung',
            content:
                'Sagt Ihnen das Angebot zu, können Sie dieses ganz einfach direkt bestellen. Wir kümmern uns um den Rest.'
        },
        step5: {
            title: 'Installation & Einrichtung',
            content: 'Die Koordination übernehmen wir, die Installation und Inbetriebnahme einer unserer Fachbetriebe.'
        },
        step6: {
            title: 'Zahlung nach Installation',
            content: 'Die Zahlung erfolgt erst nach  erfolgreicher Installation ganz einfach per Rechnung.'
        }
    },
    faq: {
        title: 'Haben Sie fragen?'
    },
    contacts: {
        titleEmail: 'Senden Sie uns eine Nachricht:',
        email: "<a href='mailto:kundenservice@univers.de'><span>kundenservice@univers.de</span></a>",
        contactUs: 'Oder melden Sie sich telefonisch:',
        phone: '<a>+49 800 4302255</a>'
    }
};

const b2b2ePlanningNissan = {
    header: {
        text: 'Vom Vorab-Check bis zur Installation Ihrer Nissan Ladelösung'
    },
    sectionNow: {
        step1: {
            content:
                'Buchen Sie jetzt einen Termin mit einem unserer zertifizierten Elektriker. Dieser kommt bei Ihnen zuhause vorbei und analysiert die Installationsbedingungen vor Ort.'
        }
    },
    sectionPreVisit: {
        step3: {
            content:
                'Basierend auf den Erkenntnissen des Pre-Visits erhalten Sie ein Angebot für die Installation. Bereits geleistete Zahlungen werden hier berücksichtigt.'
        },
        step6: {
            title: 'Zahlung',
            content:
                'Für Leistungen die durch die bereits geleistete Zahlung noch nicht abgedeckt sind, erfolgt die Zahlung erst nach erfolgreicher Installation ganz einfach per Rechnung.'
        }
    }
};

const b2b2ePlanningGoinGreen = {
    header: {
        text: 'Vom Vor-Ort-Besuch bis zur Installation Ihrer Ladelösung'
    },
    sectionNow: {
        step2: {
            title: 'Vor-Ort-Besuch'
        }
    },
    sectionPreVisit: {
        title: 'Nach dem Vor-Ort-Besuch',
        step3: {
            content:
                'Basierend auf den Erkenntnissen des Vor-Ort-Besuchs erhalten Sie ein Angebot für die Installation. Bereits geleistete Zahlungen werden hier berücksichtigt.'
        },
    }
};

export { b2b2ePlanningNissan,b2b2ePlanningGoinGreen };
