export default {
    title: 'Herzlich Willkommen, {{ name }}',
    installation_progress: {
        description:
            'in Ihrem my<b>En</b>OS<sup>TM</sup> Kundenportal halten wir alle wichtigen Informationen rund um Ihre Ladestation und Ihre Verträge bereits. ',
        text: 'Im Bereich Charger sehen Sie Details zu dem bei Ihnen verbauten Charger Modell. Sie können den Authentifizierungsmodus ein- sowie ausschalten und den Namen der Ladestation ändern. <br/> Unter Dokumente finden Sie alle wichtigen Dokumente wie beispielsweise die Allgemeinen Geschäftsbedingungen, Bedienungsanleitungen für Ihren Charger, sowie Ihre einmaligen und - sofern zutreffend - monatlichen Strom- und Servicerechnungen. Wählen Sie Kundenservice im Menü, können Sie direkt eine Nachricht an unseren Kundenservice schicken und gegebenenfalls Bilder anhängen. <br/><br/> Im Profil können Sie Ihre persönlichen Daten einsehen, Ihr Passwort ändern, die hinterlegte Zahlungsmethode bearbeiten, sowie Ihren Stromtarif und Ihre Vertragsnummer einsehen.',
        status_date: {
            completed: 'Beendet am {{date}}',
            planned: 'Geplant für {{date}}',
            not_planned: 'Nicht geplant'
        },
        event_type: {
            order_completed_contacted: 'Bestellung bestätigt',
            order_completed_will_contact:
                'Vielen Dank für die Bestätigung Ihrer Bestellung. Wir werden Sie bald kontaktieren.',
            order_completed: 'Auftragsfertigung',
            previsit: 'Pre-Visit',
            quote: 'Angebotsannahme',
            installation: 'Installation',
            quote_accepted: 'Angebot akzeptiert'
        },
        category: {
            order: 'Bestellung',
            installation_preparation: 'Installationsplanung',
            installation: 'Installation & Inbetriebnahme'
        }
    },
    add_payment: {
        title: 'Zahlungsdetails',
        text: 'Fügen Sie Ihre Zahlungsmethode für monatliche Abrechnung hinzu',
        button: 'Jetzt hinterlegen',
        notificationText:
            'Bitte hinterlegen Sie eine Zahlungsmethode für Ihre monatliche Rechnung. <span class="whitespace-nowrap"><a href="{{path}}">Jetzt hinterlegen</a><img class="lang-switcher__arrow-icon" src="{{icon}}" /></span>'
    },
    upload_photos: {
        title: 'Fotos hochladen',
        text: 'Wir freuen uns, wenn Sie Fotos des Installationsorts hochladen. Das erleichtert uns nicht nur die Begutachtung, sondern beschleunigt ggf. Ihren Installationsprozess.',
        button: 'Fotos hochladen'
    },
    get_app: {
        title: 'Holen Sie sich die App',
        text: 'Mit der Charging Solutions by Univers App können Sie Ihren Ladevorgang starten, überwachen und stoppen von überall und wann Sie wollen.',
        button: 'Hole Sie sich jetzt die App'
    },
    business_documents: {
        title: 'Dokumente',
        text: 'Sehen Sie jetzt Ihre Dokumente an.'
    },
    news: {
        label: 'NACHRICHTEN'
    },
    installation_completed: {
        text: 'Wir hoffen, Sie sind mit Charging Solutions by Univers zufrieden und haben Spaß an unserem Service.'
    }
};
