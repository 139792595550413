export const COMPANY_CODE_CONFIRMED = 'COMPANY_CODE_CONFIRMED';
export const SET_PLANNING_STEP_COMPLETED = 'SET_PLANNING_STEP_COMPLETED';
export const SET_PERSONAL_DETAIL_STEP_COMPLETED = 'SET_PERSONAL_DETAIL_STEP_COMPLETED';
export const SET_INSTALLATION_DETAIL_STEP_COMPLETED = 'SET_INSTALLATION_DETAIL_STEP_COMPLETED';
export const SET_ORDER_CONFIRMATION_STEP_COMPLETED = 'SET_ORDER_CONFIRMATION_STEP_COMPLETED';
export const SET_B2B_EMPLOYEE_SETUP = 'SET_B2B_EMPLOYEE_SETUP';
export const RESET_BUSINESS_DATA = 'RESET_BUSINESS_DATA';


export function companyCodeConfirmed(value) {
	return {
		type: COMPANY_CODE_CONFIRMED,
		value
	}
}

export function setPlanningStepCompleted(value) {
	return {
		type: SET_PLANNING_STEP_COMPLETED,
		value
	}
}

export function setPersonalDetailStepCompleted(value) {
	return {
		type: SET_PERSONAL_DETAIL_STEP_COMPLETED,
		value
	}
}

export function setInstallationDetailStepCompleted(value) {
	return {
		type: SET_INSTALLATION_DETAIL_STEP_COMPLETED,
		value
	}
}

export function setOrderConfirmationStepCompleted(value) {
	return {
		type: SET_ORDER_CONFIRMATION_STEP_COMPLETED,
		value
	}
}

export function setB2bEmployeeSetup(value) {
	return {
		type: SET_B2B_EMPLOYEE_SETUP,
		value
	}
}

export function resetBusinessData() {
	return {
		type: RESET_BUSINESS_DATA
	}
}

export default {
	companyCodeConfirmed,
	setPlanningStepCompleted,
	setPersonalDetailStepCompleted,
	setInstallationDetailStepCompleted,
	setOrderConfirmationStepCompleted,
	setB2bEmployeeSetup,
	resetBusinessData
}
