export default {
    title: '<b>En</b>OS<sup>TM</sup> Charger',
    serial_no: 'Seriennummer: {{ number }}',
    loading: 'Charger Daten laden...',
    charger_auth: {
        title: 'Authentifizierungsmodus',
        enabled_text: 'Sicherheit ist wichtig. Wenn der Authentifizierungsmodus aktiviert ist, können Sie Ihren Ladevorgang in der App starten und stoppen.',
        disabled_text: 'Wenn der Charger eingeschaltet ist, wird ein Code benötigt, um den Ladevorgang zu starten.',
        subtext: "Hinweis: Wenn Ihr Charger offen sichtbar ist, empfehlen wir Ihnen, den Authentifizierungsmodus zu aktivieren.",
        activated: 'aktiviert',
        disabled: 'deaktiviert',
        updating: 'Bitte warten...',
    },
    charger_title: "Dies sind Ihre Charger",
    charger_business_title: "<b>En</b>OS<sup>TM</sup> Charger",
    charger_page_description: "Sicherheit ist wichtig. Vor allem wenn Ihr Charger offen sichtbar ist. Mit aktiviertem Authentifizierungsmodus können Sie Ihren Ladevorgang nur in der App starten und stoppen.",
    charger_business_page_description: 'Hier finden Sie alle Informationen und Einstellungen zum Charger. Für die Steuerung des Chargers, genaue Statistiken über historische Ladevorgänge und zur aktuell verbrauchten Strommenge, sowie die "Freunde Einladen" Funktion, nutzen Sie die Charging by <b>En</b>OS<sup>TM</sup> App für iOS und Android.',
    charger_paragraph: "Sobald der Authentifizierungsmodus aktiviert ist, startet der Charger das Laden nur über die Charging by <b>En</b>OS<sup>TM</sup> App.",
    charger_not_found: 'Nach der Installation und Inbetriebnahme werden Sie hier Informationen und Einstellungen zu Ihrem Charger finden.',
    charger_detail_text: "Sobald der Authentifizierungsmodus aktiviert ist, startet der Charger das Laden nur über die Charging by <b>En</b>OS<sup>TM</sup> App. Wir empfehlen, die Authentifizierung zu aktivieren, wenn Ihr Charger nicht in einer abgeschlossenen Garage montiert ist.",
    charger_detail_authMode: "Authentifizierungsmodus",
    charger_detail_address: "Straße",
    charger_detail_city: "Stadt",
    charger_detail_zipCode: "Postleitzahl",
    charger_detail_capacity: "Ladekapazität",
    charger_detail_manufacturer_model: "Hersteller und Modell",
    charger_detail_save_button: "Speichern",
    charger_detail_serialNumber: "Seriennummer",
    charger_title_no_charger: "<b>En</b>OS<sup>TM</sup> Charger",
    charger_paragraph_no_charger: "Nach der Installation und Inbetriebnahme werden Sie hier Informationen und Einstellungen zu Ihrem Charger finden.",
    charger_auth_error_message: 'Der Autorisierungsmodus für das Ladegerät {{chargerName}} konnte nicht geändert werden. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundendienst',
    charger_search_placeholder: 'suche einen Charger',
    charger_name: "Charger Name",
    charger_address: "Straße",
    charger_zipCode: "Postleitzahl",
    charger_city: "Stadt",
    charger_authMode: "Authentifizierungsmodus"

}
