export default {
    payment: {
        title: 'Payment Preferences',
        text: 'To change your payment preferences, please contact customer support via phone ({{phone}}) or email (<a href="mailto:{{email}}">{{email}}</a>).'
    },
    invoices: {
        title: 'Invoices & Quotes',
        no_invoices_or_quotes: 'No invoices or quotes to show',
        loading: 'Loading invoices & quotes...'
    }
}
