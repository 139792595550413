import Cookie from 'js-cookie';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../components';
import { IRootState } from '../store';
import cookieActions from '../store/cookie';
import { ButtonType } from '../typeScript/types/ButtonTypes';

const COOKIE_NAME = 'cookieConsent';
const EXPIRED_IN_DAYS = 60;

export enum CookieTypes {
    COOKIE_CONSENT_ALL_VALUE = 'all',
    COOKIE_CONSENT_TECHNICAL_VALUE = 'tech'
}

function CookieBanner() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // const [isCookieConsented, setIsCookieConsented] = useState<boolean>(false);
    const isCookieConsentOpen = useSelector<IRootState, boolean>((state) => state.cookie.isCookieConsentOpen);

    useEffect(() => {
        isConsentCookieSet()
            ? dispatch(cookieActions.setIsCookieConsentOpen(false))
            : dispatch(cookieActions.setIsCookieConsentOpen(true));
        // eslint-disable-next-line
    }, []);

    const clearAllCookies = () => {
        const cookies = document.cookie.split('; ');
        for (let c = 0; c < cookies.length; c++) {
            const d = window.location.hostname.split('.');
            while (d.length > 0) {
                const cookieBase =
                    encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
                    '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
                    d.join('.') +
                    ' ;path=';
                const p = window.location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                }
                d.shift();
            }
        }
    };

    const isConsentCookieSet = () => {
        return Object.values(CookieTypes).includes(Cookie.get(COOKIE_NAME));
    };

    const handleConfirmSelected = () => {
        clearAllCookies();
        dispatch(cookieActions.setIsMarketingCookie(false));
        Cookie.set(COOKIE_NAME, CookieTypes.COOKIE_CONSENT_TECHNICAL_VALUE, { expires: EXPIRED_IN_DAYS });
        dispatch(cookieActions.setIsCookieConsentOpen(false));
        if (window && window.location) {
            window.location.reload();
        }
    };

    const handleConfirmAll = () => {
        Cookie.set(COOKIE_NAME, CookieTypes.COOKIE_CONSENT_ALL_VALUE, { expires: EXPIRED_IN_DAYS });
        dispatch(cookieActions.setIsCookieConsentOpen(false));
        dispatch(cookieActions.setIsMarketingCookie(true));
    };

    return isCookieConsentOpen ? (
        <div className='cookieBanner fixed bottom-0 left-0 right-0 z-50 py-4 md:py-10 bg-bg_primary shadow'>
            <div className='container max-w-full'>
                <p className='text-sm text-white'>{t('common:cookie.cookie_consent_intro')}</p>

                <div className='md:flex md:justify-end mt-6'>
                    <Button
                        type={ButtonType.quinary}
                        className='w-full md:w-auto mr-0 md:mr-4 mb-4 md:mb-0'
                        onClick={handleConfirmSelected}
                    >
                        {t('common:buttons.cookie_confirm_technical')}
                    </Button>
                    <Button type={ButtonType.secondary} className='w-full md:w-auto' onClick={handleConfirmAll}>
                        {t('common:buttons.cookie_confirm_all')}
                    </Button>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}

export default CookieBanner;
