import { TYPE_SUCCESS } from 'components/Toast';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openToast } from 'store/toast';
import api from '../api/api';
import { Button, InnerSpinner, Input, Switch } from '../components';
import InputType from '../constants/inputType';
import { IRootState } from '../store';
import { ButtonType } from '../typeScript/types/ButtonTypes';
import { showApiError } from '../utils/error-handler';
import {
    getValidationRules,
    hasValidationError,
    ruleNames,
    validationErrorTranslation,
    validationService
} from '../validation';

export interface PersonalInfoFormProps {}

interface IInstallationData {
    installationAddress: IInstallationAddress;
}

interface IInstallationAddress {
    street: string;
    streetNumber: string;
    postalCode: string;
    city: string;
    country: string;
}

function PersonalInfoForm(props: PersonalInfoFormProps) {
    const [installationData, setInstallationData] = useState<IInstallationData | undefined | any>();
    const [installationDataLoaded, setInstallationDataLoaded] = useState(false);
    const [accountDataLoaded, setAccountDataLoaded] = useState(false);
    const [personalDataLoading, setPersonalDataLoading] = useState(false);

    const { t } = useTranslation(['user_info', 'businessProfile', 'charger']);
    const [validationErrors, setValidationErrors] = useState<any[]>([]);
    const [isReadable, setIsReadable] = useState(true);
    const dispatch = useDispatch();
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const [newsletterLabel, setNewsletterLabel] = useState('');

    useEffect(() => {
        if (isLogged) {
            loadUserInfoData();
            loadInstallationData();
        }
    }, [isLogged]);

    const loadInstallationData = () => {
        api.installation
            .getInstallationData()
            .then((response) => {
                setInstallationData(response.data);
            })
            .catch(() => {})
            .then(() => {
                setInstallationDataLoaded(true);
            });
    };

    const onInputChange = (field, value) => {
        setUserInfo((state) => {
            return {
                ...state,
                [field]: value
            };
        });
    };

    const toggleNewsletter = (value: boolean) => {
        const translation = value ? 'charger:charger_auth.activated' : 'charger:charger_auth.disabled';
        setNewsletterLabel(t(translation));
    };

    const onNewsletterChange = (value: boolean) => {
        toggleNewsletter(value);
        setUserInfo((state) => {
            return {
                ...state,
                isNewsletter: value
            };
        });
    };

    const onInstallationInputChange = (field, value) => {
        setInstallationData((state) => {
            return {
                ...state,
                installationAddress: {
                    ...state?.installationAddress,
                    [field]: value
                }
            };
        });
    };

    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone_no: '',
        birthday: '',
        salutation: '',
        type: '',
        isNewsletter: false
    });

    const loadUserInfoData = () => {
        setPersonalDataLoading(true);
        api.user
            .getAccount()
            .then((response) => {
                setUserInfo({
                    firstName: response.data.name,
                    lastName: response.data.surname,
                    email: response.data.email,
                    phone_no: response.data.phone,
                    birthday: response.data.dateOfBirth,
                    salutation: response.data.salutation,
                    type: response.data.type,
                    isNewsletter: response.data.isNewsletterSubscribed
                });
            })
            .catch(() => {})
            .then(() => {
                setAccountDataLoaded(true);
                setPersonalDataLoading(false);
            });
    };

    const handleSaveButton = (event) => {
        if (event) {
            event.preventDefault();
        }

        let validationPhone;
        let rulesPhone;
        let paramsPhone;

        rulesPhone = getValidationRules([ruleNames.phoneNo]);

        paramsPhone = {
            phone: userInfo.phone_no
        };
        validationPhone = validationService(paramsPhone, rulesPhone);

        if (paramsPhone && validationPhone.passes()) {
            setValidationErrors([]);
            setPersonalDataLoading(true);
            api.user
                .updateUserInfo({
                    phone: userInfo.phone_no,
                    isNewsletterSubscribed: userInfo.isNewsletter
                })
                .then((res) => {
                    loadUserInfoData();
                    dispatch(openToast(TYPE_SUCCESS, t('toast:personal_info_updated')));
                    setPersonalDataLoading(false);
                    setIsReadable(true);
                })
                .catch((error) => {
                    setPersonalDataLoading(false);
                    showApiError(error.response, dispatch, t);
                });
        } else {
            setValidationErrors(validationPhone.getErrors());
        }
    };

    const handleCancelButton = () => {
        loadUserInfoData();
        setIsReadable(true);
    };

    return (
        <>
            <InnerSpinner isActive={!installationDataLoaded || !accountDataLoaded || personalDataLoading}>
                <form onSubmit={handleSaveButton}>
                    <div className='grid grid-cols-8 gap-4 mb-4'>
                        <Input
                            id='salutation'
                            label={t('common:labels.salutation')}
                            value={userInfo.salutation}
                            autoComplete='salutation'
                            disabled
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('salutation', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.salutation, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.salutation, validationErrors, t)}
                            placeholder={t('common:placeholder.salutation')}
                            containerClass='md:col-span-2 col-span-8'
                        />
                        <Input
                            id='first_name'
                            label={t('common:labels.first_name')}
                            value={userInfo.firstName}
                            autoComplete='given-name'
                            disabled
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('firstName', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.firstName, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.firstName, validationErrors, t)}
                            placeholder={t('common:placeholder.first_name')}
                            containerClass='md:col-span-3 col-span-8'
                        />
                        <Input
                            id='last_name'
                            label={t('common:labels.last_name')}
                            autoComplete='family-name'
                            disabled
                            value={userInfo.lastName}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('lastName', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.lastName, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.lastName, validationErrors, t)}
                            placeholder={t('common:placeholder.last_name')}
                            containerClass='md:col-span-3 col-span-8'
                        />
                    </div>
                    <div className='grid grid-cols-8 gap-4 mb-4'>
                        <Input
                            id='street_address'
                            label={t('common:labels.street_address')}
                            value={
                                installationData && installationData.installationAddress
                                    ? installationData.installationAddress.street
                                    : ''
                            }
                            autoComplete='street-address'
                            disabled
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInstallationInputChange('street', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.streetAddress, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(
                                ruleNames.streetAddress,
                                validationErrors,
                                t
                            )}
                            placeholder={t('common:placeholder.street_address')}
                            containerClass='md:col-span-6 col-span-5'
                        />

                        <Input
                            id='street_no'
                            label={t('common:labels.street_no')}
                            value={
                                installationData && installationData.installationAddress
                                    ? installationData.installationAddress.streetNumber
                                    : ''
                            }
                            autoComplete='street-no'
                            disabled
                            onChange={(e) => onInstallationInputChange('streetNumber', e.currentTarget.value)}
                            hasValidationError={hasValidationError(ruleNames.streetNo, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.streetNo, validationErrors, t)}
                            placeholder={t('common:placeholder.street_no')}
                            containerClass='md:col-span-2 col-span-3'
                        />
                    </div>
                    <div className='grid grid-cols-8 gap-4 mb-4'>
                        <Input
                            id='postal_code'
                            label={t('common:labels.postal_code')}
                            value={
                                installationData && installationData.installationAddress
                                    ? installationData.installationAddress.postalCode
                                    : ''
                            }
                            autoComplete='postal-code'
                            disabled
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInstallationInputChange('postalCode', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.postalCode, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.postalCode, validationErrors, t)}
                            placeholder={t('common:placeholder.postal_code')}
                            containerClass='md:col-span-2 col-span-3'
                        />
                        <Input
                            id='city'
                            label={t('common:labels.city')}
                            value={
                                installationData && installationData.installationAddress
                                    ? installationData.installationAddress.city
                                    : ''
                            }
                            autoComplete='city'
                            disabled
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInstallationInputChange('city', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.city, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.city, validationErrors, t)}
                            placeholder={t('common:placeholder.city')}
                            containerClass='md:col-span-2 col-span-5'
                        />
                        <Input
                            id='country'
                            label={t('common:labels.country')}
                            value={
                                installationData && installationData.installationAddress
                                    ? installationData.installationAddress.country
                                    : ''
                            }
                            autoComplete='country'
                            disabled
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInstallationInputChange('country', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.country, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.country, validationErrors, t)}
                            placeholder={t('common:placeholder.country')}
                            containerClass='md:col-span-2 col-span-8 md:col-end-9'
                        />
                    </div>
                    <div className='grid grid-cols-8 gap-4 mb-4'>
                        <Input
                            id='email'
                            label={t('common:labels.email')}
                            autoComplete='email'
                            disabled
                            type={InputType.EMAIL}
                            value={userInfo.email}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('email', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.email, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.email, validationErrors, t)}
                            placeholder={t('common:placeholder.email')}
                            containerClass='md:col-span-4 col-span-8'
                        />

                        <Input
                            id='phone_no'
                            label={t('common:labels.phone')}
                            type={InputType.TEL}
                            autoComplete='tel'
                            disabled={isReadable ? true : false}
                            value={userInfo.phone_no}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('phone_no', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.phoneNo, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.phoneNo, validationErrors, t)}
                            placeholder={t('common:placeholder.phone')}
                            containerClass='md:col-span-4 col-span-8'
                        />
                    </div>
                    <div className='grid grid-cols-8 gap-4 mb-4'>
                        <Input
                            id='birthdate'
                            label={t('common:labels.birthday')}
                            value={moment(`${userInfo.birthday}`, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                            autoComplete='birthdate'
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('birthday', e.currentTarget.value)
                            }
                            disabled
                            placeholder={t('common:placeholder.birthday')}
                            hasValidationError={hasValidationError(ruleNames.birthday, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(ruleNames.birthday, validationErrors, t)}
                            containerClass='md:col-span-3 col-span-5'
                        />
                    </div>

                    {/* TODO::: We need a perfect switch-component */}
                    <div>
                        <label className='input__label  '>{t('businessProfile:newsletter.title')}</label>
                        <div className='profile-newsletter'>
                            <div className='profile-newsletter__switch-wrapper'>
                                <Switch
                                    className='profile-newsletter__switch'
                                    switchInnerText=''
                                    toggleButtonClassName='switch__box profile-switch'
                                    checked={userInfo.isNewsletter}
                                    onChange={onNewsletterChange}
                                    disabled={isReadable ? true : false}
                                />
                                <span className='description'>{newsletterLabel}</span>
                            </div>
                        </div>
                    </div>

                    <div className='buttons'>
                        {isReadable && (
                            <Button onClick={() => setIsReadable(false)}>
                                {t('common:buttons.change')}
                            </Button>
                        )}
                        {!isReadable && (
                            <>
                                <Button type={ButtonType.secondary} onClick={handleCancelButton}>
                                    {t('common:buttons.cancel')}
                                </Button>
                                <Button buttonType='submit'>
                                    {t('common:buttons.save')}
                                </Button>
                            </>
                        )}
                    </div>
                </form>
            </InnerSpinner>
        </>
    );
}

export default PersonalInfoForm;
