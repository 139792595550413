import { DisplayCardTypes } from 'components/DisplayCard';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import api from '../../api/api';
import { Button, DisplayCard, InnerSpinner, Page } from '../../components';
import ContractTerminationForm from 'components/ContractTerminationForm';
import { ContractType } from '../../constants/contractType';
import { IRootState } from '../../store';
import { ITariff } from '../../typeScript/interfaces/ICart';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { useIsMounted } from '../../utils/hooks';
import intl from '../../utils/intl';
import { ButtonType } from '../../typeScript/types/ButtonTypes';

const ContractStartImage = require('../../assets/Contract_start.svg');
const ContractNumberImage = require('../../assets/Contract_number.svg');

// Decide which contracts may shown
const contracts = [ContractType.CHARGING_SERVICES];

function Tariff() {
    const { t } = useTranslation();
    const [contractsData, setContractsData] = useState([]);
    const [contractsLoaded, setContractsLoaded] = useState(false);
    const [tariff, setTariff] = useState<ITariff | null>();
    const [tariffLoaded, setTariffLoaded] = useState(false);
    const [isPrevisitOnly, setIsPrevisitOnly] = useState(false);
    const [isPrevisitOnlyLoaded, setIsPrevisitOnlyLoaded] = useState(false);
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const isMounted = useIsMounted();

    const [showContractTerminationForm, setShowContractTerminationForm] = useState(false);
    const [isContractTerminationFormSubmitted, setIsContractTerminationFormSubmitted] = useState(false);

    useEffect(() => {
        if (isLogged) {
            getTariff();
            getProducts();
            getContracts();
        }
        // eslint-disable-next-line
    }, [isLogged]);

    const getContracts = () => {
        api.user
            .getContracts()
            .then((response) => {
                if (isMounted.current) {
                    setContractsData(response.data);
                    setContractsLoaded(true);
                }
            })
            .catch(() => {
                setContractsData([]);
                setContractsLoaded(true);
            });
    };

    const getProducts = () => {
        // If user pre-visit only then product length must be equal to 1.
        api.user
            .getProducts()
            .then((response) => {
                if (isMounted.current) {
                    setIsPrevisitOnly(response.data.productDtoList.length === 1);
                }
            })
            .catch(() => {})
            .then(() => {
                setIsPrevisitOnlyLoaded(true);
            });
    };

    const getTariff = () => {
        api.user
            .getTariffs()
            .then((response) => {
                if (isMounted.current) {
                    if (response.data && response.data.length) {
                        setTariff(response.data[0]);
                    }
                }
            })
            .catch(() => {
                setTariff(null);
            })
            .then(() => {
                setTariffLoaded(true);
            });
    };

    // const getContractItemTitle = (contractType) => {
    //     switch (contractType) {
    //         case CONTRACT_TYPE_CHARGING_SERVICES:
    //             return t('contract:contracts.hardware_installation_electricity.title');
    //         case CONTRACT_TYPE_SMART_METER:
    //             return t('contract:contracts.smart_meter.title');
    //         case CONTRACT_TYPE_CUSTOMER_PORTAL:
    //             return t('contract:contracts.customer_portal.title');
    //         default:
    //             return t('contract:contracts.default.title');
    //     }
    // };

    const renderContracts = () => {
        if (!(contractsLoaded && isPrevisitOnlyLoaded)) {
            return <p>{t('contract:contracts.loading')}</p>;
        } else if (
            (isPrevisitOnlyLoaded && isPrevisitOnly) ||
            (isPrevisitOnlyLoaded && !isPrevisitOnly && contractsData.length)
        ) {
            return contracts.map((contractType) => {
                let contract = contractsData.find((data) => (data as any).type === contractType);
                return renderContract(contractType, contract);
            });
        } else {
            return <p>{t('contract:contracts.no_contracts')}</p>;
        }
    };

    const renderContract = (type, contract) => {
        return (
            <div key={type}>
                {!isPrevisitOnly && contract && (
                    <div className='grid lg:grid-cols-2 gap-4'>
                        <div>
                            <img className='float-left mr-4' src={ContractStartImage} alt='' />
                            {renderContractPeriodInfo(contract)}
                        </div>
                        <div>
                            <img className='float-left mr-4' src={ContractNumberImage} alt='' />
                            <b>{t('contract:contracts.ref_no')}</b>
                            <br />
                            <p className='text-xs'>{contract.customContractId}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderContractPeriodInfo = (contract) => {
        switch (contract.status) {
            case 'Draft':
                return (
                    <>
                        <b>{t('contract:contracts.contract_period_of_draft_title')}</b>
                        <br />
                        <p className='text-xs'>{t('contract:contracts.contract_period_of_draft')}</p>
                    </>
                );
            case 'Confirmed':
                return (
                    <>
                        <b>{t('contract:contracts.contract_period_of_confirmed')}</b>
                        <br />
                        <p className='text-xs'>
                            {moment(contract.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY')} -{' '}
                            {moment(contract.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                        </p>
                    </>
                );
            case 'Cancelled':
                return (
                    <>
                        <b>{t('contract:contracts.contract_period_of_canceled')}</b>
                        <br />
                        <p className='text-xs'>{moment(contract.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}</p>
                    </>
                );
            default:
                return;
        }
    };

    const renderTariffTableRow = (key, value) => {
        return (
            <tr className='border-b border-solid border-grey-table-border'>
                <td className='py-3 pr-3 text-fg_secondary'>{key}</td>
                <td className='text-right'>{value}</td>
            </tr>
        );
    };

    return (
        <Page pageTheme={PageTheme.light}>
            <div className='xl:w-9/12 2xl:w-9/12'>
                <DisplayCard
                    type={DisplayCardTypes.PLAIN}
                    label={t('portal:tariff.header.title')}
                    intro={t('portal:tariff.header.intro')}
                    className='mb-6'
                >
                    <InnerSpinner isActive={!tariffLoaded || !contractsLoaded}>
                        <div className='mb-10'>{renderContracts()}</div>
                        {tariff ? (
                            <>
                                <table className='w-full'>
                                    <tbody>
                                        {renderTariffTableRow(t('contract:tariff.your_tariff'), tariff?.name)}
                                        {renderTariffTableRow(t('contract:tariff.kwh_per_month'), tariff?.kwh)}
                                        {renderTariffTableRow(
                                            t('contract:tariff.monthly_cost'),
                                            intl.currencyFormat(tariff?.prices[0]?.unitPrice)
                                        )}
                                        {renderTariffTableRow(
                                            t('contract:tariff.included_energy_cost'),
                                            `${intl.currencyFormat(tariff?.includedPriceKwh)}/kWh`
                                        )}
                                        {renderTariffTableRow(
                                            t('contract:tariff.additional_energy_cost'),
                                            `${intl.currencyFormat(tariff?.additionalEnergyCost)}/kWh`
                                        )}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <p>{t('contract:tariff.no_tariff_available')}</p>
                        )}
                    </InnerSpinner>
                </DisplayCard>

                <DisplayCard
                    type={DisplayCardTypes.PLAIN}
                    label={isContractTerminationFormSubmitted ? t('contract:contractTermination.submitted.title') : ''}
                    intro={isContractTerminationFormSubmitted ? t('contract:contractTermination.submitted.text') : ''}
                >
                    {!isContractTerminationFormSubmitted && !showContractTerminationForm && (
                        <Button
                            type={ButtonType.primary}
                            onClick={() => setShowContractTerminationForm(true)}
                            className='mt-6'
                        >
                            {t('portal:contractTermination.btn_contract_termination')}
                        </Button>
                    )}
                    {!isContractTerminationFormSubmitted && showContractTerminationForm && (
                        <ContractTerminationForm
                            setShowContractTerminationForm={setShowContractTerminationForm}
                            setIsContractTerminationFormSubmitted={setIsContractTerminationFormSubmitted}
                        />
                    )}
                </DisplayCard>
            </div>
        </Page>
    );
}

export default Tariff;
