import IconAlfenDoubleProLine from '../assets/charger/alfen-double-proline.svg';
import IconAlfenSingleSLine from '../assets/charger/alfen-s-line.svg';
import IconAlfenSingleProline from '../assets/charger/alfen-single-proline.svg';
import IconEtrelInchHome from '../assets/charger/etrel-inch-home.svg';
import IconKebaC from '../assets/charger/keba_c_series.svg';
import IconKebaX from '../assets/charger/keba_x_series.svg';
import IconWallboxPulsarPlus from '../assets/charger/wallbox_pulsar_plus.svg';

export enum ChargerStationModelName {
    ALFEN_DOUBLE_PROLINE = 'Eve Double Pro-Line',
    ALFEN_SINGLE_PROLINE = 'Eve Single Pro-line',
    ALFEN_SINGLE_SLINE = 'Eve Single S-line',
    ETREL_INCH_HOME = 'INCH Home',
    KEBA_C = 'KeContact P30 c-series',
    KEBA_X = 'KeContact P30 x-series',
    WALLBOX_PULSAR_PLUS = 'Pulsar Plus'
}

const chargers = [
    {
        stationName: ChargerStationModelName.ALFEN_DOUBLE_PROLINE,
        asset: IconAlfenDoubleProLine
    },
    {
        stationName: ChargerStationModelName.ALFEN_SINGLE_PROLINE,
        asset: IconAlfenSingleProline
    },
    {
        stationName: ChargerStationModelName.ALFEN_SINGLE_SLINE,
        asset: IconAlfenSingleSLine
    },
    {
        stationName: ChargerStationModelName.ETREL_INCH_HOME,
        asset: IconEtrelInchHome
    },
    {
        stationName: ChargerStationModelName.KEBA_X,
        asset: IconKebaX
    },
    {
        stationName: ChargerStationModelName.KEBA_C,
        asset: IconKebaC
    },
    {
        stationName: ChargerStationModelName.WALLBOX_PULSAR_PLUS,
        asset: IconWallboxPulsarPlus
    }
];

// TODO::: DYNAMIC CHARGER IMAGE
export default function getChargerImage(stationModelName) {
    const matchedCharger =  chargers.find(charger => charger.stationName === stationModelName);
    return matchedCharger ? matchedCharger?.asset : IconEtrelInchHome;
}
