import React from 'react';
import { Button } from '.';

type Props = {
    isActive: boolean;
    step: number;
    headline: string;
    dataSet: string;
    text: string;
    image: string;
    btnText: string;
    position: string;
    key: string;
    onClick: (event: EventTarget) => void;
};

function InstallationCard(props: Props) {
    const {
        isActive = false,
        step = '',
        headline = '',
        text = '',
        image = '',
        position = '',
        btnText = '',
        key = '',
        onClick = () => {},
        dataSet = ''
    } = props;

    const finalClassName = `card ${isActive ? 'card--active' : 'card--inactive'}`;
    return (
        <div
            className={finalClassName}
            onClick={!btnText ? (e) => onClick(e.target) : undefined}
            data-settype={dataSet}
            key={key}
        >
            {image && (
                <div className={`card__img ${image ? 'no--image' : null}`}>
                    {step && <span className={`card__step ${position}`}>{step}</span>}
                    <img src={image} alt='' />
                </div>
            )}
            <div className='card__body'>
                {headline && <h5 className='card__title'>{headline}</h5>}
                {text && <p className='card__text'>{text}</p>}
                {btnText && (
                    <div className='card__button'>
                        <Button
                            className={isActive ? 'card__button--active' : ''}
                            onClick={(e) => onClick(e.target)}
                            data-settype={dataSet}
                        >
                            {btnText}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default InstallationCard;
