import Login from 'pages/Login';
import PortalCharger from 'pages/portal/Charger';
import PortalDocuments from 'pages/portal/Documents';
import PortalInstallationAssistant from 'pages/portal/InstallationAssistant';
import PortalOverview from 'pages/portal/Overview';
import PortalPayment from 'pages/portal/Payment';
import PortalProfile from 'pages/portal/Profile';
import PortalSupport from 'pages/portal/Support';
import PortalTariff from 'pages/portal/Tariff';
import PortalPayout from 'pages/portal/Payout';
import PortalFleetData from 'pages/portal/FleetData';
import ResetPassword from 'pages/ResetPassword';
import ResetPasswordFirstTime from 'pages/ResetPasswordFirstTime';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { routePaths } from './route_paths';
import Token from "./api/auth-token-wrapper";
import Language from "./constants/language";
import i18n from "i18next";

function Routes({ location }) {
    return (
        <Switch>
            {/* AUTH PAGES */}
            <Route path={routePaths.login} component={Login} exact />
            <Route path={routePaths.resetPassword} component={ResetPassword} exact />
            <Route path={routePaths.resetPasswordFirstTime} component={ResetPasswordFirstTime} exact />

            {/* PORTAL MERGE */}
            <Route path={routePaths.portalOverview} component={PortalOverview} exact />

            <Route path={routePaths.portalCharger} component={PortalCharger} exact />
            <Redirect from={`/de-DE${routePaths.portalCharger}`} to={routePaths.portalCharger} />
            <Redirect from={`/en-DE${routePaths.portalCharger}`} to={routePaths.portalCharger} />

            <Route path={routePaths.portalDocuments} component={PortalDocuments} exact />
            <Redirect from={`/de-DE${routePaths.portalDocuments}`} to={routePaths.portalDocuments} />
            <Redirect from={`/en-DE${routePaths.portalDocuments}`} to={routePaths.portalDocuments} />

            <Route path={routePaths.portalSupport} component={PortalSupport} exact />
            <Redirect from={`/de-DE${routePaths.portalSupport}`} to={routePaths.portalSupport} />
            <Redirect from={`/en-DE${routePaths.portalSupport}`} to={routePaths.portalSupport} />

            <Route path={routePaths.portalProfile} component={PortalProfile} exact />
            <Redirect from={`/de-DE${routePaths.portalProfile}`} to={routePaths.portalProfile} />
            <Redirect from={`/en-DE${routePaths.portalProfile}`} to={routePaths.portalProfile} />

            <Route path={routePaths.portalPayment} component={PortalPayment} exact />
            <Redirect from={`/de-DE${routePaths.portalPayment}`} to={routePaths.portalPayment} />
            <Redirect from={`/en-DE${routePaths.portalPayment}`} to={routePaths.portalPayment} />

            <Route path={routePaths.portalPhotoUpload} component={PortalInstallationAssistant} exact />

            <Route path={routePaths.portalTariff} component={PortalTariff} exact />
            <Redirect from={`/de-DE${routePaths.portalTariff}`} to={routePaths.portalTariff} />
            <Redirect from={`/en-DE${routePaths.portalTariff}`} to={routePaths.portalTariff} />

            <Route path={routePaths.portalPayout} component={PortalPayout} exact />
            <Redirect from={`/de-DE${routePaths.portalPayout}`} to={routePaths.portalPayout} />
            <Redirect from={`/en-DE${routePaths.portalPayout}`} to={routePaths.portalPayout} />

            <Route path={routePaths.portalFleetData} component={PortalFleetData} exact />
            <Redirect from={`/de-DE${routePaths.portalFleetData}`} to={routePaths.portalFleetData} />
            <Redirect from={`/en-DE${routePaths.portalFleetData}`} to={routePaths.portalFleetData} />

            <Route path={routePaths.portalCards}  component={() => {
                    const locale = i18n.language === Language.ENGLISH ? 'en-DE' : 'de-DE';
                    const token = Token.getToken();
                    const url = `/${locale}${routePaths.portalCards}`;
                    window.history.replaceState(null, null, url)
                    window.location.href = url;
                    document.cookie = `Auth=${token};secure;path=/`
                    return null;
            }}>
            </Route>

            {/* NOT FOUND */}
            <Route>
                <Redirect to={routePaths.login} />
            </Route>
        </Switch>
    );
}

const RoutesComponent = withRouter(Routes);

export default RoutesComponent;
