import paymentActions from "store/payment";
import api from "../api/api";

export default async (dispatch, successCallback, errorCallback) => {
	dispatch(paymentActions.setPaymentStatusLoading(true));

	try {
		let response = await api.payment.getPaymentStatus();
		dispatch(paymentActions.setPaymentStatus(response.data));
		successCallback && successCallback()
	} catch (error) {
		errorCallback && errorCallback(error.response)
	}

	dispatch(paymentActions.setPaymentStatusLoading(false));
}
