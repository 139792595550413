export default {
  fleetData_invitation: 'Add and update your Fleet data',
  fleetData_invitation_cta: 'Fleet Details',

  title: "Fleet Data",
  card_title: "Your stored data:",

  no_history: "Welcome to Your Fleet Data Area. Start entering your Fleet Details now!",
  with_history: "Welcome back to your updated Fleet Data Page. Keep your Fleet Details current for efficient Fleet Management!",

  form_title: 'Your fleet data form',
  form_intro: 'Please insert your information',

  button_show_form: 'Update Data',
  button_hide_form: 'Cancel',

  license_plate_check: 'Do you have a German EU Vehicle Registration Number?',
  license_plate_check_boolean: 'Yes',

  license_plate_tooltip_intro: 'Vehicle Registration Number information:',
  license_plate_part_1: 'Optional 1-3 letters',
  license_plate_part_2: 'Optional 1-2 letters',
  license_plate_part_3: 'Optional 1-4 digits',
  license_plate_part_4: 'Optional 1 letter',

  show_data_renewable_energy: 'Electricity from Renewable Energy'
}