export default {
    profile: {
        title: "Persönliche Informationen",
        description: "Hier können Sie Ihre Persönlichen Informationen einsehen und Ihre Telefonnummer ändern.",
        newsletter: 'Newsletter',
    },
    newsletter: {
        title: 'Newsletter'
    },
    password: {
        title: 'Passwort',
        description: 'Hier können Sie Ihr Passwort ändern.'
    }
};
