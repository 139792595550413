export default {
  payout_card_title: 'Home Charging Reimbursement',
  payout_invitation: 'Add and update your tariff and payment information',
  payout_invitation_cta: 'Reimbursement Details',
  header: {
    title: "Home-Charging Reimbursement",
  },
  no_history: "Welcome to your Home Charging Reimbursement Page. Begin by entering the details of your charging tariffs for company-related charging events. Once stored, you'll have a comprehensive history at your fingertips, enabling you to track planned and implemented changes over time.",
  with_history: "Welcome to your Home Charging Reimbursement Page. Here, you'll find a detailed record of your stored charging tariffs for company-related charging events. The presented history offers comprehensive insights, allowing you to track both planned and implemented changes over time.",
  form_title: 'Your Charging tariff form',
  form_intro: 'Please insert your tariff information',
  button_show_tariff_form: 'Tariff update',
  button_show_IBAN_form: 'IBAN update',
  button_hide_form: 'Cancel',
  energy_price_suffix: 'Cent/kWh',
  tariff_header: 'Tariff Information',
  tariff_intro: 'Gross energy price in Cent/kWh',
  history_header_status: 'Status',
  history_header_price: 'Charging tariff',
  history_header_start_date: 'Start date',
  history_status_pending: 'In Progress',
  history_status_succeeded: 'Active',
  history_status_ongoing: 'Planned',
  history_status_failed: 'Error',
  history_status_expired: 'Expired',
  iban_header: 'IBAN Information',
  iban_creation_date: 'Modified date',
  iban_added: 'IBAN added',
  date_tooltip: 'Please select a future date. If you encounter any problems, please contact our support team.'
}