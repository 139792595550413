import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showApiError } from '../utils/error-handler';
import { DisplayCard } from './';
import { DisplayCardTypes } from './DisplayCard';
import Icon, { iconTypes } from './Icon';

const plusIcon = require('../assets/plus.svg');

type Props = {
    src: string;
    className: string;
    onDrop: () => void;
};

function ImageUploadCard(props) {
    const { placeholderSrc = '', imageSrc = false, description = '', onDrop = () => {} } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [uploadedImage, setUploadedImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleDrop = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const binaryStrReader = new FileReader();

            binaryStrReader.onload = () => {
                const binaryStr = binaryStrReader.result;
                const base64Str = binaryStr ? window.btoa(binaryStr.toString()) : '';

                const extension = file.type.split('/')[1];

                setLoading(true);

                onDrop(base64Str, file.type, description, extension)
                    .then((res) => {
                        setLoading(false);
                    })
                    .catch((error) => {
                        setLoading(false);
                        showApiError(error.response, dispatch, t);
                    });
            };

            binaryStrReader.readAsBinaryString(file);
        });
    };

    useEffect(() => {
        if (imageSrc) {
            setUploadedImage(imageSrc);
        }
    }, [imageSrc]);

    return (
        <Dropzone onDrop={handleDrop} accept='image/*' multiple={false}>
            {({ getRootProps, getInputProps }) => {
                return (
                    <div className={`image-upload ${loading ? 'image-upload--loading' : ''}`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <DisplayCard
                            className={`image-upload__dropzone text-center ${
                                uploadedImage ? '' : 'image-upload__dropzone--show'
                            }`}
                            type={DisplayCardTypes.TRANSPARENT_REDUCED}
                            onClick={() => {}}
                        >
                            {!loading && <img className='w-10' src={plusIcon} alt='' />}
                        </DisplayCard>
                        <div className='image-upload__image__wrapper'>
                            {!loading && uploadedImage && (
                                <Icon className='image-upload__success-icon' name={iconTypes.enabled} size={90} />
                            )}
                            {!loading && (
                                <img
                                    className='image-upload__image'
                                    src={uploadedImage ? uploadedImage : placeholderSrc}
                                    alt=''
                                />
                            )}
                        </div>
                    </div>
                );
            }}
        </Dropzone>
    );
}

export default ImageUploadCard;
