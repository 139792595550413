import React, { ChangeEvent, useRef } from "react";

type Props = {
    id: string;
    className: string;
    disabled?: boolean;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    label: string | object;
    type?: "normal" | "small";
    hasValidationError?: boolean;
    validationErrorLabel?: string;
};

function Checkbox(props: Props) {
    const {
        id = "",
        className = "",
        disabled = false,
        onChange = (event: ChangeEvent<HTMLInputElement>) => {},
        label = "",
        type = "normal",
        checked = false,
        hasValidationError = false
    } = props;

    const checkedInput = useRef<HTMLInputElement>(null);

    const containerClassName = `checkbox__container  ${className} ${
        type === "normal" || type === "small"
            ? "checkbox__container--white"
            : ""
    }`;
    const labelClassName = `checkbox__label ${
        type === "small" ? "checkbox__label--small" : ""
    } ${hasValidationError ? "checkbox__label--error" : ""}`;

    return (
        <div className={containerClassName}>
            <input
                defaultChecked={checked}
                className="checkbox__input"
                type="checkbox"
                ref={checkedInput}
                onChange={onChange}
                disabled={disabled}
                id={id}
            />
            <label
                className={`checkbox__custom-input ${
                    type === "normal" || type === "small"
                        ? "checkbox__custom-input--normal"
                        : ""
                }`}
                htmlFor={id}
            />
            <label className={labelClassName} htmlFor={id}>
                {label}
            </label>
        </div>
    );
}

export default Checkbox;
