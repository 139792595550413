/* tslint:disable */
/* eslint-disable */
/**
 * Registration and fleetdata api
 * handles customer lifecycle information, i.e. fleetdata
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Fleetdata,
  ResponseWithError,
} from '../models';
import {
    FleetdataFromJSON,
    FleetdataToJSON,
    ResponseWithErrorFromJSON,
    ResponseWithErrorToJSON,
} from '../models';

export interface PutFleetDataRequest {
    fleetdata: Fleetdata;
}

/**
 * 
 */
export class FleetdataApiApi extends runtime.BaseAPI {

    /**
     * request for fleetdata
     * request for fleetdata
     */
    async getFleetDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Fleetdata>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/registration/fleetdata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FleetdataFromJSON(jsonValue));
    }

    /**
     * request for fleetdata
     * request for fleetdata
     */
    async getFleetData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Fleetdata> {
        const response = await this.getFleetDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * creates fleetdata
     * creates fleetdata
     */
    async putFleetDataRaw(requestParameters: PutFleetDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fleetdata === null || requestParameters.fleetdata === undefined) {
            throw new runtime.RequiredError('fleetdata','Required parameter requestParameters.fleetdata was null or undefined when calling putFleetData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/registration/fleetdata`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FleetdataToJSON(requestParameters.fleetdata),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * creates fleetdata
     * creates fleetdata
     */
    async putFleetData(requestParameters: PutFleetDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putFleetDataRaw(requestParameters, initOverrides);
    }

}
