import {concealMessageDeterministic, concealObjectNonDeterministic, recoverObjectNonDeterministic} from '../utils/encrypt';

let Token = {};
let authStorageKey = `auth_${concealMessageDeterministic('auth')}`;

const getAuthObject = () => {
    try {
        const authObject = window.localStorage.getItem(authStorageKey);
        return authObject ? recoverObjectNonDeterministic(authObject) : null;
    } catch(error) {
        Token.removeAuthTokens();
    }
}

Token.getToken = () => {
    const authObject = getAuthObject();
    return authObject ? authObject.access_token : null;
};

Token.getRefreshToken = () => {
    const authObject = getAuthObject();
    return authObject ? authObject.refresh_token : null;
};

Token.setAuthTokens = ( authObject ) => {
    const newObject = concealObjectNonDeterministic(authObject);
    window.localStorage.setItem(authStorageKey, newObject);
};

Token.removeAuthTokens = () => {
    window.localStorage.removeItem(authStorageKey);
    document.cookie = `Auth=;Max-Age=0;secure;path=/`;
};

Token.tokenIsValid = () => {
    const token = Token.getToken();
    if ( token ) {
        const authObject = getAuthObject();
        const expiresAt = Math.floor(Date.now() / 1000) + authObject.expires_in;
        const rightNow = Math.floor(Date.now() / 1000);
        const tokenIsExpired =  !expiresAt || expiresAt === undefined || expiresAt === null || (expiresAt && expiresAt <= rightNow);

        return !tokenIsExpired;
    }

    return false;
};

Token.refreshTokenIsValid = () => {
    const refreshToken = Token.getRefreshToken();
    if ( refreshToken ) {
        const authObject = getAuthObject();
        const expiresAt = Math.floor(Date.now() / 1000) + authObject.refresh_expires_in;
        const rightNow = Math.floor(Date.now() / 1000);
        const tokenIsExpired =  !expiresAt || expiresAt === undefined || expiresAt === null || (expiresAt && expiresAt <= rightNow);

        return !tokenIsExpired;
    }

    return false;
};

export default Token;
