export default {
    loading_data: 'Initialisieren...',
    loading_products: 'Verladen von Produkten...',
    please_wait: 'Bitte warten...',
    registering_user: 'Benutzer registrieren...',
    logging_in: 'Anmelden...',
    initializing_checkout: 'Initialisierung des Auszahlungsprozesses...',
    saving_checkout_data: 'Speichern von Ausgangsdaten...',
    saving_product_selection: 'Produktauswahl speichern...',
    still_working: 'Noch nicht fertig, bitte warten...',
    saving_data: 'Daten speichern...',
    processing_payment: 'Zahlungsverarbeitung...',
    confirming_order: 'Auftragsbestätigung, bitte warten Sie...',
    business_saving_data: 'Daten werden übermittelt'
}