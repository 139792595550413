import React from "react";

type Props = {
    onClick: () => void;
    headline?: string;
    text?: string;
    children?: React.ReactNode;
};

function ContactFormOverlay(props: Props) {
    const { headline = "", text = "", onClick } = props;

    return (
        <div className="overlay">
            <div className="overlay__background" onClick={onClick}></div>
            <div className="overlay__main">
                {headline && <h1 className="overlay__headline mb__m">{headline}</h1>}
                {text && <p className="overlay__text mb__l">{text}</p>}
                {props.children}
            </div>
        </div>
    );
}

export default ContactFormOverlay;
