import loaderActions from "store/loader";
import paymentActions from "store/payment";
import api from "../api/api";

export default async (dispatch) => {
    api.user.hasRecurringPaymentDetails().then((response) => {
        if (typeof response.data === 'boolean') {
            dispatch(paymentActions.hasRecurringPaymentDetails(response.data));
        }
    }).catch((error) => {
        dispatch(loaderActions.setShowLoader(false));
    })
}
