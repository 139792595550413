import { DisplayCardTypes } from 'components/DisplayCard';
import { TYPE_ERROR, TYPE_SUCCESS } from 'components/Toast';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AdyenPayment, Button, DisplayCard, Page } from '../../components';
import { PaymentCardType } from '../../constants/paymentCardType';
import loadPaymentStatus from '../../mixins/loadPaymentStatus';
import loadRecurringPaymentStatus from '../../mixins/loadRecurringPaymentStatus';
import { IRootState } from '../../store';
import loaderActions from '../../store/loader';
import { openToast } from '../../store/toast';
import { IPaymentStatus } from '../../typeScript/interfaces/IPayment';
import { ButtonType } from '../../typeScript/types/ButtonTypes';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { decodeURLParams } from '../../utils/helpers';

const visaIcon = require('../../assets/payment/visa.svg');
const sepaIcon = require('../../assets/payment/sepa2.svg');
const mastercard = require('../../assets/payment/mastercard.svg');

function Payment() {
    const { t } = useTranslation(['user_info', 'businessProfile', 'charger']);
    const dispatch = useDispatch();
    const paymentStatus = useSelector<IRootState, IPaymentStatus>((state) => state.payment.status);
    const [displayAdyenForm, setDisplayAdyenForm] = useState(false);
    const hasRecurringPaymentDetails = useSelector<IRootState, boolean>(
        (state) => state.payment.statusHasRecurringPaymentDetails
    );

    useEffect(() => {
        loadRecurringPaymentStatus(dispatch);
        loadPaymentStatus(dispatch).then(() => dispatch(loaderActions.setShowLoader(false)));

        showToastAfterLeavingPageAndComeBackThroughAdyen();

        // eslint-disable-next-line
    }, []);

    const showToastAfterLeavingPageAndComeBackThroughAdyen = () => {
        const userParams = decodeURLParams(window.location.search);
        const statusErrorArray = ['failed', 'refused', 'error', 'cancelled'];
        const statusSuccessArray = ['success', 'received', 'pending'];

        if (statusErrorArray.includes(userParams.status)) {
            showErrorPaymentToast();
        } else if (statusSuccessArray.includes(userParams.status)) {
            showSuccessfullyPaymentToast();
        }
    };

    const showSuccessfullyPaymentToast = () => {
        dispatch(openToast(TYPE_SUCCESS, t('toast:added_payment_method_successful')));
    };

    const showErrorPaymentToast = () => {
        dispatch(openToast(TYPE_ERROR, t('toast:added_payment_method_failed')));
    };

    const renderCardTypeAndLogo = () => {
        return (
            <>
                {paymentStatus.cardType === PaymentCardType.MASTER_CARD ? (
                    <>
                        {t('payment:monthly_payment_method_title')} &nbsp; {t('common:labels.payment_credit')}{' '}
                        <img
                            src={mastercard}
                            alt={t('common:labels.payment_credit')}
                            className='h-6 ml-2 inline-block align-middle'
                        />
                    </>
                ) : paymentStatus.cardType === PaymentCardType.VISA ? (
                    <>
                        {t('payment:monthly_payment_method_title')} &nbsp; {t('common:labels.payment_credit')}{' '}
                        <img
                            src={visaIcon}
                            alt={t('common:labels.payment_credit')}
                            className='h-6 ml-2 inline-block align-middle'
                        />
                    </>
                ) : paymentStatus.cardType === PaymentCardType.SEPA ? (
                    <>
                        {t('payment:monthly_payment_method_title')} &nbsp; {t('common:labels.payment_debit')}
                        <img
                            src={sepaIcon}
                            alt={t('common:labels.payment_debit')}
                            className='h-6 ml-2 inline-block align-middle'
                        />
                    </>
                ) : (
                    t('payment:monthly_payment_method_not_specified_title')
                )}
            </>
        );
    };

    return (
        <Page pageTheme={PageTheme.light}>
            <div className='xl:w-9/12 2xl:w-6/12'>
                {!displayAdyenForm && (
                    <DisplayCard type={DisplayCardTypes.PLAIN} label={t('payment:payment_methods_title')}>
                        <div>{hasRecurringPaymentDetails ? renderCardTypeAndLogo() : t('payment:non_deposited')}</div>
                        <Button type={ButtonType.primary} onClick={() => setDisplayAdyenForm(true)} className='mt-6'>
                            {hasRecurringPaymentDetails ? t('common:buttons.change') : t('common:buttons.add_now')}
                        </Button>
                        {/* --------------------------------------- */}
                    </DisplayCard>
                )}

                {/* Preload Adyen-form */}
                <div className={` ${displayAdyenForm ? '' : 'hidden'}`}>
                    <DisplayCard
                        type={DisplayCardTypes.PLAIN}
                        label={t('payment:payment_methods_title')}
                        intro={t('payment:payment_methods_intro')}
                    >
                        <AdyenPayment
                            capturePaymentDataOnly={true}
                            hideAdyenForm={() => setDisplayAdyenForm(false)}
                        ></AdyenPayment>
                    </DisplayCard>
                </div>
            </div>
        </Page>
    );
}

export default Payment;
