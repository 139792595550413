export default {
    header:{
        title: "Dokumente",
        text: "Hier finden Sie alle Unterlagen die Sie uns gesendet und von uns erhalten haben.",
    },
    information:{
        title:"Information",
        charger_manuel: "Charger Manual",
        employer_permission: "Zustimmung des Arbeitgebers"
    },
    contract:{
        title:"Verträge"
    },
    invoices:{
        title:"Angebote & Rechnungen",
        invoice_charger: "Rechnung Charger",
        installation_quote: "Installationsangebot",
        loading: 'Loading invoices & quotes...'
    },
    no_documents: "Keine Dokumente verfügbar"
}
