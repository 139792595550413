import React from "react";

type Props = {
    className?: string;
    type?: "default" | "error";
    errorLabel?: string;
    children?: React.ReactNode;
    htmlFor?: string;
};

function Label(props: Props) {
    const {
        className = "",
        errorLabel = "",
        type = "default",
        htmlFor = "",
        ...restProps
    } = props;
    const labelText =
        type === "error"
            ? errorLabel
                ? errorLabel
                : props.children
            : props.children;

    return (
        <label
            htmlFor={htmlFor}
            {...restProps}
            className={`input__label ${
                type === "error" ? "input__label--error" : ""
            } ${className}`}
        >
            {labelText}
        </label>
    );
}

export default Label;
