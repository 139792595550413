import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { routePaths } from '../route_paths';
import { IRootState } from '../store';
import Button from './Button';
import { ILoggedUser } from '../typeScript/interfaces/IUser';

export type Props = {
    mobileMenuOpen: boolean;
    onClick: (e) => void;
    handleLogout: () => void;
    isPortal: boolean;
};

const PortalLinks = [
    {
        path: routePaths.portalOverview,
        label: 'overview'
    },
    {
        path: routePaths.portalCharger,
        label: 'charger'
    },
    {
        path: routePaths.portalDocuments,
        label: 'documents'
    },
    {
        path: routePaths.portalSupport,
        label: 'support'
    },
    {
        path: routePaths.portalCards,
        label: 'chargingCards'
    },
    {
        path: routePaths.portalPayment,
        label: 'my_payment'
    },
    {
        path: routePaths.portalProfile,
        label: 'Profile'
    },
    {
        path: routePaths.portalTariff,
        label: 'tariff'
    },
    {
        path: routePaths.portalPayout,
        label: 'payout'
    },
    {
        path: routePaths.portalFleetData,
        label: 'fleetData'
    }
];

function MobileMenu(props: Props) {
    const { mobileMenuOpen, onClick, handleLogout } = props;
    const { t } = useTranslation(['nav']);
    const history = useHistory();
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);

    const navLinks = PortalLinks.map((link, i) => {
        let dynamicActiveClass = '';
        let activeClassName = i > 0 ? 'nav__link--active' : '';
        let doRender = true;

        if (i === 0) {
            const route = routePaths.portalOverview;
            dynamicActiveClass = history.location.pathname === route ? ' nav__link--active' : '';
        }

        if (link.path === routePaths.portalPayment) {
            doRender = user?.canUpdatePaymentInformation ? true : false;
        }
        if (link.path === routePaths.portalPayout) {
            doRender = user?.isReimbursementActivated ? true : false;
        }
        if (link.path === routePaths.portalFleetData) {
            doRender = user?.isFleetDataEnrichment ? true : false;
        }

        return (
            doRender && (
                <NavLink
                    to={link.path}
                    className={`nav__link nav__link--mob ${dynamicActiveClass}`}
                    activeClassName={activeClassName}
                    onClick={onClick}
                    key={link.label}
                >
                    <p className='nav__link__text nav__link__text--mob'>{t(link.label)}</p>
                </NavLink>
            )
        );
    });

    return (
        <>
            <div
                className={`mobile-menu
                    ${mobileMenuOpen ? 'mobile-menu--show' : ''}
                `}
            >
                <div className='mobile-menu__content'>
                    <div className='mobile-menu__content__links'>{navLinks}</div>
                </div>
                <div className='mobile-menu__content__bottom'>
                    <Button onClick={handleLogout}>{t('log_out')}</Button>
                    {/*<LanguageSwitcher type='box'/>*/}
                </div>
            </div>
            <div className={`mobile-menu__bg ${mobileMenuOpen ? 'mobile-menu__bg--show' : ''}`} onClick={onClick} />
        </>
    );
}

export default MobileMenu;
