export default {
    contracts: {
        title: 'Contracts',
        hardware_installation_electricity: {
            title: 'Hardware, Installation & Electricity',
        },
        smart_meter: {
            title: "Smart Meter",
        },
        customer_portal: {
            title: "Customer Portal"
        },
        default: {
            title: "Univers Contract",
        },
        ref_no: "Ref. number",
        contract_period_of_confirmed: "Contract period",
        contract_period_of_draft_title: "Start of contract",
        contract_period_of_draft: "Contract start: First charging session or two months after installation",
        contract_period_of_canceled: "Contract terminated on: {{ to }}",
        download_terms: 'Download Terms',
        open_terms: 'Open',
        cancel: 'Cancel Contract',
        no_contracts: 'No contracts available',
        loading: 'Loading contracts...',
        no_contract: 'No documents available'
    },
    tariff: {
        loading: 'Loading tariff data...',
        title: 'Your electricity tariff',
        your_tariff: 'Your tariff',
        kwh_per_month: 'kwh included per month',
        range_km: 'Range (in km, approx.)',
        monthly_cost: 'Monthly cost',
        included_energy_cost: 'Included energy cost',
        additional_energy_cost: 'Additional energy cost',
        no_tariff_available: 'No tariff data available'
    },
    contractTermination: {
        submitted: {
            title: 'Cancellation request submitted successfully',
            text:'We have received your cancellation request and will now review it. We will then get in touch with you.'
        }
    }
}