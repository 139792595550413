import React from 'react';
import Spinner, { SpinnerTheme } from "./Spinner";

export interface InnerSpinnerProps {
    isActive: boolean;
    children: React.ReactNode;
}

function InnerSpinner(props: InnerSpinnerProps) {
    return (
        <div className={props.isActive ? 'relative h-32' : ''}>
            {props.isActive && (
                <div className='absolute inset-0 flex justify-center flex-wrap content-center'>
                    <Spinner theme={SpinnerTheme.light} />
                </div>
            )}
            <div className={props.isActive ? 'invisible' : ''}>{props.children}</div>
        </div>
    );
}

export default InnerSpinner;
