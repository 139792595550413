import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';
import Language from '../constants/language';
import { Dropdown } from '.';

export const LOCALE_COOKIE_NAME = 'locale';

type Props = {};

function LanguageSwitcher(props: Props) {
    const { i18n, t } = useTranslation();

    // INFO::: Note that language is changed upon initial load by our library 'i18next-browser-languagedetector' in the i18n/index.js file
    const changeLanguage = (languageObj) => {
        setLocale(languageObj.value);
    };

    const setLocale = (locale) => {
        i18n.changeLanguage(locale);
        Cookie.set(LOCALE_COOKIE_NAME, locale, { expires: 30 });
    };

    return (
        <>
            <Dropdown
                id='languageSwitch'
                options={[
                    {
                        value: Language.GERMAN,
                        label: t('common:language.german')
                    },
                    {
                        value: Language.ENGLISH,
                        label: t('common:language.english')
                    }
                ]}
                // selected={{
                //     value: i18n.language,
                //     label:
                //         i18n.language === Language.GERMAN ? t('common:language.german') : t('common:language.english')
                // }}
                selected={i18n.language}
                onSelect={(selected) => changeLanguage(selected)}
                expandToTop={true}
                placeholder={
                    i18n.language === Language.GERMAN ? t('common:language.german') : t('common:language.english')
                }
            />
        </>
    );
}

export default LanguageSwitcher;
