export default {
    title: 'Welcome, {{ name }}',
    installation_progress: {
        description:
            'In your my<b>En</b>OS<sup>TM</sup> customer portal, we will keep you up to date with all aspects of your installation. Here you will also find all contracts and be able to make changes to your personal information.',
        text: 'In the Charger area, you can see details of the Charger model installed at your site. You can switch the authentication mode on and off and change the name of the charging station. <br/> In Documents you will find all your important documents such as the General Terms and Conditions, operating instructions for your Charger, as well as your one-time and - if applicable - monthly electricity and service bills. <br/><br/> In Customer Service, you can directly send a message to our customer service and attach pictures if necessary. In your profile, you can check your personal data, change your password, edit the payment method for your monthly payments, and find your electricity tariff and contract number.',
        status_date: {
            completed: 'Completed on {{date}}',
            planned: 'Planned for {{date}}',
            not_planned: 'Not yet planned'
        },
        event_type: {
            order_completed_contacted: 'Order confirmed',
            order_completed_will_contact: 'Thank you for confirming your order. We will be in touch with you soon.',
            order_completed: 'Order completed',
            previsit: 'Pre-visit successfully completed on ',
            quote: 'quote acceptance',
            installation: 'Installation',
            quote_accepted: 'offer accepted'
        },
        category: {
            order: 'order',
            installation_preparation: 'Installation preparation',
            installation: 'Installation'
        }
    },
    add_payment: {
        title: 'Payment details',
        text: 'Add your payment method for your monthly billing',
        button: 'Payment Details',
        notificationText:
            'Please deposit a payment method for your monthly bill. <span class="whitespace-nowrap"><a href="{{path}}">Deposit now</a><img class="lang-switcher__arrow-icon" src="{{icon}}" /></span>'
    },
    upload_photos: {
        title: 'Upload photos',
        text: 'Our customer service team would like you to provide some photos for your installer to finalise their quote:',
        button: 'Add pictures'
    },
    get_app: {
        title: 'The Charging Solutions by Univers app',
        text: 'The app empowers you to initiate and halt charging from anywhere, access your charging history, and invite your friends.',
        button: 'Download the app'
    },
    business_documents: {
        title: 'Documents',
        text: 'View your available documents'
    },
    news: {
        label: 'NEWS'
    },
    installation_completed: {
        text: 'We hope you are happy with Charging Solutions by Univers. Enjoy our service!'
    }
};
