import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayCard } from '../';
import { DisplayCardTypes } from '../DisplayCard';
import ChargerAuthIcon from './ChargerAuthIcon';

type Props = {
    chargerImage: string;
    chargerName: string;
    authMode: boolean;
    onClick?: MouseEventHandler;
};

function ChargerCard(props: Props) {
    const { chargerImage = '', chargerName = '', authMode = false, onClick = () => {} } = props;
    const { t } = useTranslation(['charger']);

    return (
        <>
            <DisplayCard type={DisplayCardTypes.IMAGE_TOP} src={chargerImage} onClick={onClick} label={chargerName}>
                <span className='text-sm'>{t('charger_detail_authMode')}: &nbsp;&nbsp;</span>
                <ChargerAuthIcon authMode={authMode} />
            </DisplayCard>
        </>
    );
}

export default ChargerCard;
