export default {
    login: {
        title: 'Einloggen',
        forgot_password: 'Passwort vergessen',
        button: 'Anmelden'
    },
    reset_password: {
        title: 'Passwort zurücksetzen',
    },
    reset_password_first_time: {
        title: 'Willkommen im <b>En</b>OS<sup>TM</sup> Portal!',
        // TODO::: Var: <b>En</b>OS<sup>TM</sup>
        title_custom: 'Hallo {{name}}, willkommen im <b>En</b>OS<sup>TM</sup> Portal!',
        intro: 'Das Portal ist Ihr Ort für alle Einstellungen und persönlichen Informationen. Hier finden Sie neben den Vertragsdokumenten und Rechnungen auch Ihr persönliches Installationsangebot, das für Sie erstellt wird. <br/><br/>Um fortfahren zu können, erstellen Sie bitte zunächst ein Passwort. Bitte merken Sie sich dieses für zukünftige Anmeldungen.',
        set_password_successfully: {
            title: 'Ihr Passwort wurde erfolgreich gespeichert!',
            intro: 'Viel Spaß beim nutzen unseres Portals.'
        },
        tokenDismissed: {
            title: 'Sie haben Ihr Passwort bereits erfolgreich gesetzt!',
            intro: 'Viel Spaß beim nutzen des Portals.'
        },
        tokenExpired: {
            title: 'Link abgelaufen!',
            intro: 'Aus Sicherheitsgründen ist der Link, dem Sie gefolgt sind, abgelaufen.',
            text_1: 'Senden Sie uns eine Nachricht:',
            text_2: 'kundenservice@univers.de',
            text_3: 'Oder melden Sie sich telefonisch:',
            text_4: '+49 800 4302255',
            button: 'E-Mail erneut anfordern',
        },
        tokenInvalid: {
            title: 'Link ungültig!',
            intro: 'Der Link dem Sie gefolgt sind ist nicht gültig. Bitte überprüfen Sie den Link aus der E-Mail oder wenden Sie sich an den Kundenservice.'
        }
    }
}