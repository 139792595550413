export const UPDATE_PRODUCTS_FOR_ITEM_TYPE = 'UPDATE_PRODUCTS_FOR_ITEM_TYPE';
export const SET_CART_OPENED = 'SET_CART_OPENED';
export const SET_NEEDS_PRE_VISIT = 'SET_NEEDS_PRE_VISIT';
export const SET_DOWN_PAYMENT_TOTAL_AMOUNT = 'SET_DOWN_PAYMENT_TOTAL_AMOUNT';
export const SET_INSTALLATION_DOWN_PAYMENT_AMOUNT = 'SET_INSTALLATION_DOWN_PAYMENT_AMOUNT';
export const SET_HARDWARE_DOWN_PAYMENT_AMOUNT = 'SET_HARDWARE_DOWN_PAYMENT_AMOUNT';
export const RESET_CART_DATA = 'RESET_CART_DATA';
export const RESET_PREVISIT_PRODUCT = 'RESET_PREVISIT_PRODUCT';


export function updateProductsForItemType(products, itemTypeKey, cardTextValues, itemsToKeepForPreVisit) {
	return {
		type: UPDATE_PRODUCTS_FOR_ITEM_TYPE,
		products,
		itemTypeKey,
		cardTextValues,
		itemsToKeepForPreVisit
	}
}

export function setCartOpened(value) {
	return {
		type: SET_CART_OPENED,
		value
	}
}

export function setNeedsPreVisit(value) {
	return {
		type: SET_NEEDS_PRE_VISIT,
		value
	}
}

export function setDownPaymentTotalAmount(value) {
	return {
		type: SET_DOWN_PAYMENT_TOTAL_AMOUNT,
		value
	}
}

export function setInstallationDownPaymentAmount(value) {
	return {
		type: SET_INSTALLATION_DOWN_PAYMENT_AMOUNT,
		value
	}
}

export function setHardwareDownPaymentAmount(value) {
	return {
		type: SET_HARDWARE_DOWN_PAYMENT_AMOUNT,
		value
	}
}

export function resetCartData() {
	return {
		type: RESET_CART_DATA
	}
}

export function resetPrevisitProduct() {
	return {
		type: RESET_PREVISIT_PRODUCT
	}
}

export default {
	updateProductsForItemType,
	setCartOpened,
	setNeedsPreVisit,
	setDownPaymentTotalAmount,
	resetCartData,
	setInstallationDownPaymentAmount,
	setHardwareDownPaymentAmount,
	resetPrevisitProduct
}
