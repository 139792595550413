export default {
    profile: {
        title: "Your Profile",
        description: "Here you can view your personal data and change your telephone number.",
        newsletter: 'Newsletter',
    },
    newsletter: {
        title: 'Newsletter'
    },
    password: {
        title: 'Password',
        description: 'You can change your password here.'
    }
};
