export default {
    welcome: {
        headline: 'Unverbindlicher Pre-Visit Check für 99 Euro!',
        selectHeadLine: 'Bitte wählen Sie wie Sie fortfahren möchten',
        text: 'Bevor eine Ladestation installiert werden kann, muss der Installationsort und die Infrastruktur bei einem Pre-Visit durch einen Elektriker professionell geprüft werden.',
        button_text: 'Jetzt Pre-Visit für 99€ bestellen',
        sections: {
            charger: 'Ladestation',
            tariff: 'Vertrag',
            installation: 'Installation'
        },
        question: 'Frage',
        answer: 'Angabe',
        contact: {
            headline: 'Vielen Dank!',
            text: 'Leider können wir zur Zeit nicht ohne Weiteres eine Installation bei Ihnen durchführen, da Sie entweder nicht der Besitzer der Immobilie sind, Ihnen keine Einwilligung des Eigentümers vorliegt oder Sie nicht in einem Einfamilienhaus' +
                ' lebst.<br><br>Am 17. September 2020 hat die Regierung im Rahmen des Wohnungseigentumsmodernisierungsgesetzes den Anspruch auf den Einbau einer Ladesäule für E-Autos im privaten Bereich beschlossen. Dieses Gesetz tritt zum 1. Dezember 2020' +
                ' in Kraft und regelt den Anspruch von Mietern und Eigentümern von Wohnungen auf die Installation von Ladesäulen in der gemeinsamen Garage oder auf dem gemeinsamen Grundstück. Wir passen unseren Installationsservice nach Inkrafttreten des Anspruchs schnellstmöglich an.',
            get_in_touch: 'Gerne beraten wir Sie in einem persönlichen Gespräch zum weiteren Vorgehen. Bitte kontaktieren Sie uns unter:',
            small_print: 'Kostenlose Telefonnummer. Bei Anrufen aus dem Mobilfunknetz können je nach Mobilfunknetzbetreiber zusätzliche Kosten anfallen.'
        },
        overview: {
            headline: 'Produktkonfigurator Übersicht',
            text: 'Die Konfiguration Ihres All-in-One Pakets zum Laden von E-Autos besteht aus drei Teilen - Charger, Tarifpaket und Installationspaket. Mit Hilfe von bis zu neun Fragen stellen wir Ihnen Ihr persönliches Bundle zusammen. Ist das aufgrund der Gegebenheiten nicht möglich, ist eine vorherige persönliche Begutachtung durch einen unserer zertifizierten Elektriker notwendig.',
        },
        overview_actions_completed: {
            headline: 'Ihr Paket ist vollständig!',
            text: 'Alle Bestandteile Charging by <b>En</b>OS<sup>TM</sup> Bundles sind konfiguriert. Im Warenkorb können Sie nochmal Ihre Bestellung überprüfen und mit der Registrierung abschließen.',
        },
        referral_code: {
            headline: 'Friends & Family Programm',
            text: 'Bitte geben Sie hier Ihren Referral Code ein',
        },
        notification: '<p><b>Wichtig:</b> Die Förderung der KfW für private Ladepunkte in Höhe von 900 Euro muss vor der Bestellung des Charging by <b>En</b>OS<sup>TM</sup> Paketes beantragt werden.&nbsp;<a target="_blank"' +
            ' href="https://www.kfw.de/inlandsfoerderung/Privatpersonen/Bestehende-Immobilie/Förderprodukte/Ladestationen-für-Elektroautos-Wohngebäude-(440)/">Zum Antrag</a></p>',
    },
    hardware: {
        headline: 'Der intelligente <b>En</b>OS<sup>TM</sup> Charger',
        text: 'Bei dem <b>En</b>OS<sup>TM</sup> Charger handelt es sich um das Modell “INCH HOME” von Etrel. Der Charger unterstützt standardmäßig 11 kW und ist mit allen Automodellen mit Typ-2-Anschlus kompatibel.*',
        small_print_one: '* Die Ladegeschwindigkeit basiert auf der realen Ladeleistung des Fahrzeugmodells.',
        small_print_two: '** Aufgrund eines zwingend erforderlichen Vorabbesuchs durch zertifizierte Univers Elektriker und der Genehmigungspflicht durch den Netzbetreiber, kann sich die Installation der 22 kW erfahrungsgemäß deutlich verlängern.',
        discount_badge_title: 'Friends & Family Angebot',
        discount_badge_save: 'Sie sparen',
        discount_instead: 'Anstatt',
        banner_title: 'bis zu 22 kW**',

        card: {
            headline: '<b>En</b>OS<sup>TM</sup> Charger',
            text: 'Lade in der Standardkonfiguration mit 11 kW ca. fünf Mal so schnell als an einer Haushaltssteckdose. Gängige Elektroautos werden über Nacht komplett aufgeladen.',
            sub_text_1: '• Integrierter FI-Schalter',
            sub_text_2: '• Internetanbindung durch SIM-Karte',
            sub_text_3: '• Authentifizierung per App',
            sub_text_4: '• Hergestellt in der EU',
            documentation: 'Dokumentation',
            tech_specs: 'Technische Daten'
        }
    },
    tariff: {
        headline: 'Wählen Sie Ihr Tarifpaket',
        text: 'In Ihrem Tarifpaket enthalten sind: Grundgebühr, Smart Meter Gebühr, 100% grüne Energie und Ihr Strompaket zum attraktiven Preis.',
        card: {
            monthlyPrice: 'Monatlicher Preis',
            monthlyMileageLabel: 'Monatliche Fahrleistung',
            addKwhCostLabel: 'Zusätzliche kWh',
            incPerMonth: 'pro Monat inkludiert',
            perYear: '{{costs}} pro Jahr',
            includedKwhCosts: 'Inkludierte kWh'
        },
        package: {
            headline: 'Alle Pakete beinhalten',
            check_list: {
                contract_period: '24 Monate Vertragslaufzeit',
                price_guarantee: '24 Monate Preisgarantie&nbsp;<sup>1, 2</sup>',
                service: '24 Stunden Notfallservice',
                smart_meter: 'Smart Meter',
                sim_card: 'SIM Karten',
                annual_maintenance: 'Jährliche Wartung',
                green_energy: '100% Grüner Strom',
                app_control: 'App Steuerung & Analyse',
                include_kwh: 'Inkludierte kWh',
            },
        },
        small_print_one: '* Der Arbeitspreis für jede weitere nicht inkludierte kWh ist nicht Gegenstand der Preisgarantie.',
        small_print_1: '<sup>1</sup> Der Arbeitspreis für jede weitere nicht inkludierte kWh ist nicht Gegenstand der Preisgarantie.',
        small_print_2: '<sup>2</sup> Unsere Paketpreise beinhalten 19 % Umsatzsteuer. Bei einer Erhöhung der Umsatzsteuer erhöhen sich die Paketpreise entsprechend.'
    },
    installation: {
        intro: {
            headline: 'Installationspaket',
            text: 'Das Installationspaket ist der dritte Teil des Bundles. Um das passende für Sie zu finden, stellen wir Ihnen im Folgenden Fragen zu den folgenden Bereichen:',
            check_list: {
                charger_selection: 'Version des Chargers',
                living_situation: 'Wohnsituation',
                parking_situation: 'Parksituation',
                distribution_cabinet: 'Verteilerschrank im Keller',
            },
            note: 'Hinweis: Auf Basis Ihrer Antworten wird Ihnen entweder ein Installationspaket oder ein persönlicher Pre-Visit zum vorherigen Check empfohlen.'
        },
        information: {
            category: 'Verteilerschrank im Keller',
            text: 'Nachdem Sie Ihren <b>En</b>OS<sup>TM</sup> Charger und das Tarifpaket mit grünem Strom ausgewählt haben, benötigen wir zum Abschluss Informationen zu Ihrem Verteilerkasten.',
            note: '<b>Hinweis:</b> Zur Beantwortung einiger Fragen, benötigen Sie Zugang zu Ihrem Verteilerschrank.',
            check_list: {
                fuse_power: 'Ausreichend starke Vorsicherungen?',
                space_in_cabinet: 'Platz für einen zweiten Zähler?',
                space_next_to_cabinet: 'Platz für einen zweiten Verteilerkasten',
            },
        },
        question_progress: {
            completion: 'FRAGE {{current}} / {{total}}'
        },
        installation_package: {
            headline: 'Ihr persönliches Installationspaket!',
            text: 'Basierend auf Ihren Antworten haben wir Ihnen Ihr persönliches Installationspaket zusammengestellt. <b>Damit ist Ihr persönliches Charging by <b>En</b>OS<sup>TM</sup> Bundle komplett!</b> <br><br>Bitte stellen Sie sicher, dass Ihre Angaben' +
                ' wahrheitsgetreu und zutreffend sind, sodass eine Installation schnellstmöglich und unkompliziert durchgeführt werden kann. Danke!',
            show_answers: 'Überprüfen Sie Ihre Angaben noch einmal',
            hide_answers: 'Angaben ausblenden',
            package_s: {
                headline: 'Plug & Charge S',
                headline_short: 'S'
            },
            package_m: {
                headline: 'Plug & Charge M',
                headline_short: 'M'
            },
            package_l: {
                headline: 'Plug & Charge L',
                headline_short: 'L'
            },
            package_xl: {
                headline: 'Plug & Charge XL',
                headline_short: 'XL'
            },
            check_list: {
                registration_vnb: 'Anmeldung beim Verteilnetzbetreiber',
                shipping: 'Versand',
                trip_fee: 'Anfahrt',
                inspection: 'Inspektion & Beratung',
                install_charge_device: 'Montage & Installation des Chargers',
                configuration: 'Konfiguration, Funktionstest und Einweisung',
                install_smart_meter_existing_board: 'Installation des Smart Meters in den bestehenden Verteilerschrank nach VDE Standard',
                final_check: 'Überprüfung des bestehenden Schranks inkl. aller erforderlichen Komponenten',
                switch_system: 'Anlage spannungsfrei schalten',
                two_wall_throughs: 'Bis zu zwei Wanddurchbrüche inkl. Dichtungsmaterial',
                up_to_15m_cable: 'Bis zu 15 Meter Mantelleitung',
                up_to_15m_pipes: 'Bis zu 15 Meter Kunststoffrohr',
                tubes_pipes: 'Verlegung Leitungen & Rohre',
                new_distribution_board: 'Montage eines neuen Verteilerschranks nach VDE Standard',
                smart_meter: 'Installation Smart Meter',
                check_board: 'Überprüfung des bestehenden Schranks inkl. aller nötigen Komponenten',
            }
        }
    },
    pre_visit: {
        headline: 'Pre-Visit: der professionelle Vorab-Check vor Ort',
        text: 'Ein zertifizierter Elektriker prüft die Bedingungen vor Ort und schätzt den Aufwand der kompletten Installation. Im Anschluss erhalten Sie ein unverbindliches Angebot für ein Installationspaket. ',
        text_22kw: '<b>Vielen Dank für Ihre Auskunft! Aufgrund Ihrer Angaben müssen wir uns vor der Installation zunächst die Gegebenheiten vor Ort überprüfen. Dazu vereinbaren wir während Ihrer Registrierung einen Termin bei Ihnen für einen Pre-Visit (Vorabbesuch) zum Festpreis. </b><br><br>Charger mit einer Leistung von mehr als 11 kW müssen nicht nur beim Verteilnetzbetreiber angemeldet werden, sondern sind auch genehmigungspflichtig. Zudem muss die  Infrastruktur am Installationsort auf die stärkeren Belastungen ausgerichtet sein.',
        text_building: '<b>Vielen Dank für Ihre Auskunft! Aufgrund Ihrer Angaben müssen wir uns vor der Installation zunächst die Gegebenheiten vor Ort überprüfen. Dazu vereinbaren wir während Ihrer Registrierung einen Termin bei Ihnen für einen Pre-Visit (Vorabbesuch) zum Festpreis. </b><br><br>Installationen in Gebäuden mit mehreren Wohneinheiten und Stellplätzen sind aufgrund ihrer Struktur, der komplexeren Elektrik und den größeren Entfernungen vom Ladepunkt zum Verteilerkasten meist aufwendiger. Daher muss ein zertifizierter Elektriker den Installationsort begehen, um den Aufwand besser abschätzen und ein Angebot unterbreiten zu können.',
        text_facade: '<b>Vielen Dank für Ihre Auskunft! Aufgrund Ihrer Angaben müssen wir uns vor der Installation zunächst die Gegebenheiten vor Ort überprüfen. Dazu vereinbaren wir während Ihrer Registrierung einen Termin bei Ihnen für einen Pre-Visit (Vorabbesuch) zum Festpreis. </b><br><br>Besitzer einer unter Denkmal stehenden Immobilie können bauliche Veränderungen am Gebäude nicht ohne Weiteres vornehmen. Viele Veränderungen bedürfen einer Zustimmung der Behörden. Da der Schutz in Art und Umfang sehr stark variieren kann, ist ein Vorabbesuch durch einer unserer Partner notwendig.',
        text_garage: '<b>Vielen Dank für Ihre Auskunft! Aufgrund Ihrer Angaben müssen wir uns vor der Installation zunächst die Gegebenheiten vor Ort überprüfen. Dazu vereinbaren wir während Ihrer Registrierung einen Termin bei Ihnen für einen Pre-Visit (Vorabbesuch) zum Festpreis. </b><br><br>Starkstromkabel müssen mindestens 80 Zentimeter unter der Erde' +
            ' verlegt werden. Wenn Ihre Garage nicht direkt mit dem Haus verbunden ist, sind daher Erdarbeiten notwendig. Um einen besseren Eindruck davon zu bekommen, in welchem Umfang Grabungen notwendig sind, muss sich einer unserer Elektriker die Situation vor Ort anschauen.',
        text_cabinet: '<b>Vielen Dank für Ihre Auskunft! Aufgrund Ihrer Angaben müssen wir uns vor der Installation zunächst die Gegebenheiten vor Ort überprüfen. Dazu vereinbaren wir während Ihrer Registrierung einen Termin bei Ihnen für einen Pre-Visit (Vorabbesuch) zum Festpreis. </b><br><br>Wenn Ihr Verteilerschrank dem auf dem vierten Bild ähnlich sieht oder gleicht, handelt es sich um einen Schrank der älteren Generation. Einer unserer zertifizierten Elektriker muss den Schrank und die Infrastruktur vor Ort inspizieren und überprüfen, ob die Sicherungen und die Kabel für den <b>En</b>OS<sup>TM</sup>  Charger ausgelegt sind.',
        text_fuse: '<b>Vielen Dank für Ihre Auskunft! Aufgrund Ihrer Angaben müssen wir uns vor der Installation zunächst die Gegebenheiten vor Ort überprüfen. Dazu vereinbaren wir während Ihrer Registrierung einen Termin bei Ihnen für einen Pre-Visit (Vorabbesuch) zum Festpreis. </b><br><br>Laut Ihrer Angaben sind die Sicherungen in Ihrem Verteilerschrank weniger als 32 Ampere stark. Daher muss einer unserer  Elektriker überprüfen, ob die Sicherungen ausreichend sind, um den Haushalt vor Überlastung zu schützen. ',
        text_2nd_cabinet: '<b>Vielen Dank für Ihre Auskunft! Aufgrund Ihrer Angaben müssen wir uns vor der Installation zunächst die Gegebenheiten vor Ort überprüfen. Dazu vereinbaren wir während Ihrer Registrierung einen Termin bei Ihnen für einen Pre-Visit (Vorabbesuch) zum Festpreis. </b><br><br>Laut Ihrer Angaben ist neben Ihrem bestehenden Verteilerschrank nicht genügend Platz für einen weiteren kleinen Verteilerschrank (einen sogenannten Aufputzschrank). Daher muss der Installationsort fachmännisch inspiziert werden, um mögliche Alternativen bewerten zu können.',
        package: {
            headline: 'Pre-Visit Check',
            headline_sub: '99,00 €',
            intro: 'Inhalt & Ablauf',
            check_list: {
                booking: '✓ Buchung durch Univers Service Team',
                qualified_installers: '✓ Durchführung durch einen zerfizierten Elektriker',
                pre_check: '✓ Vor-Ort-Begehung inkl. Analyse & Beratung',
                installation_package: '✓ Finden des optimalen Installationsorts',
                installation_offer: '✓ Individuelles Installationsangebot nach dem Besuch',
                guarantee: '✓ 100% Installationsgarantie bei positiver Bewertung',
            },
        },
        friends_and_family_code_info: 'Der Friends & Family Code wurde erfolgreich gespeichert und wird bei Erstellung eines Angebots nach dem Pre-Visit durch einen unserer Elektriker entsprechend angewendet.'
    },
}