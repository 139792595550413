import React from 'react';
const closeButton = require('../assets/CloseButton.svg');

type Props = {
    children: React.ReactNode;
    handleClose: (e) => void;
    popup: boolean;
};

function Modal(props: Props) {
    return (
        <div className='overlay z-50 fixed inset-0'>
            <div className='modal__container overlay-charger'>
                <img className='modal__container__img' alt='' src={closeButton} onClick={props.handleClose} />
                {props.children}
            </div>
        </div>
    );
}

export default Modal;
