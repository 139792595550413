import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '.';
import { hasValidationError, ruleNames, validationErrorTranslation } from '../validation';

interface PayoutIBANFormFieldsProps {
    validationErrors: any;
    setFieldValues: any;
    isIBANDataValid: boolean;
}

function PayoutIBANFormFields(props: PayoutIBANFormFieldsProps) {
    const { validationErrors, setFieldValues, isIBANDataValid } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className='input-row mb__xl'>
                <Input
                    label={t('common:labels.first_name')}
                    mandatory
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setFieldValues({ firstName: e.currentTarget.value })
                    }
                    hasValidationError={hasValidationError(ruleNames.payout.firstName, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.firstName, validationErrors, t)}
                    placeholder={t('common:placeholder.first_name')}
                    containerClass='input__container--6-el input__container--6-el-row'
                />
                <Input
                    label={t('common:labels.last_name')}
                    mandatory
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setFieldValues({ lastName: e.currentTarget.value })
                    }
                    hasValidationError={hasValidationError(ruleNames.payout.lastName, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.lastName, validationErrors, t)}
                    placeholder={t('common:placeholder.last_name')}
                    containerClass='input__container--6-el input__container--6-el-row'
                />
                <Input
                    label={t('common:labels.iban')}
                    mandatory
                    onChange={(e: React.FormEvent<HTMLInputElement>) => setFieldValues({ iban: e.currentTarget.value })}
                    hasValidationError={!isIBANDataValid || hasValidationError(ruleNames.payout.iban, validationErrors)}
                    validationErrorLabel={validationErrorTranslation(ruleNames.payout.iban, validationErrors, t)}
                    placeholder={t('common:placeholder.iban')}
                    containerClass='input__container--6-el input__container--6-el-row'
                />
            </div>

            {process.env.REACT_APP_ENABLE_DEVELOPER_MODE && <>SEPA: F. Schneider | DE60444488880023456789</>}
        </div>
    );
}

export default PayoutIBANFormFields;
