export default {
    title: 'Your charger',
    serial_no: 'Serial number: {{ number }}',
    loading: 'Loading charger data...',
    charger_auth: {
        title: 'Charger authorization',
        enabled_text: 'Security matters. When the charger is switched on, a code is required to start charging.',
        disabled_text: 'Security matters. When the charger is switched on, a code is required to start charging.',
        subtext: "Note: If your charger is installed in plain sight, we recommend you enable authentication mode. You can easily start and stop the process via the app.\n",
        activated: 'Activated',
        disabled: 'Disabled',
        updating: 'Updating...',
    },
    charger_title: "These are your chargers",
    charger_business_title: "<b>En</b>OS<sup>TM</sup> Charger",
    charger_page_description: "When authentication mode is enabled, you can start the charging session only remotely via the app. We recommend to enable authentication when your charger is not mounted in a locked garage.",
    charger_business_page_description: 'Here you will find all information and settings for the charger. For control of the charger, precise statistics on historical charging processes and the current amount of electricity consumed, as well as the "Charging Friends" function, use the Charging by <b>En</b>OS<sup>TM</sup> app for iOS and Android',
    charger_paragraph: "When authentication mode is enabled, you can start the charging session only remotely via the <b>En</b>OS<sup>TM</sup> app.",
    charger_not_found: 'No charger found for your user. Have you completed your installation visit?',
    charger_detail_text: "When authentication mode is enabled, you can start the charging session only remotely via the <b>En</b>OS<sup>TM</sup> app. We recommend to enable authentication when your charger is not mounted in a locked garage.",
    charger_detail_authMode: "Authentication mode",
    charger_detail_address: "Address",
    charger_detail_city: "City",
    charger_detail_zipCode: "Zip Code",
    charger_detail_capacity: "Charging Capacity",
    charger_detail_manufacturer_model: "Manufacturer and Model",
    charger_detail_save_button: "Save",
    charger_detail_serialNumber: "Serial Number",
    charger_title_no_charger: "<b>En</b>OS<sup>TM</sup> Charger",
    charger_paragraph_no_charger: "After installation and commissioning, you will find information and settings for your charger here.",
    charger_auth_error_message: 'The update of authorization mode has failed for charger {{ chargerName }}. Please try again later or contact customer service.',
    charger_search_placeholder: 'Find your charger',
    charger_name: "Charger Name",
    charger_address: "Address",
    charger_zipCode: "Zip Code",
    charger_city: "City",
    charger_authMode: "Authentication"

}
