import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session';
import thunkMiddleware from 'redux-thunk';
import { ICart } from '../typeScript/interfaces/ICart';
import { ICheckOut } from '../typeScript/interfaces/ICheckOut';
import { IConfiguration } from '../typeScript/interfaces/IConfiguration';
import { ICookie } from '../typeScript/interfaces/ICookie';
import { ILoader } from '../typeScript/interfaces/ILoader';
import { INavigation } from '../typeScript/interfaces/INavigation';
import { IPayment } from '../typeScript/interfaces/IPayment';
import { IProduct } from '../typeScript/interfaces/IProduct';
import { ISupport } from '../typeScript/interfaces/ISupport';
import { IToast } from '../typeScript/interfaces/IToast';
import { IUser } from '../typeScript/interfaces/IUser';
import { cartReducer } from './cart/reducers';
import { checkoutReducer } from './checkout/reducers';
import { configurationReducer } from './configuration/reducers';
import { cookieReducer } from "./cookie/reducers";
import { loaderReducer } from "./loader/reducers";
import { navReducer } from "./nav/reducers";
import { paymentReducer } from "./payment/reducers";
import { productsReducer } from './products/reducers';
import { supportReducer } from "./support/reducers";
import { toastReducer } from "./toast/reducers";
import { userReducer } from "./user/reducers";


const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

export interface IRootState {
	checkout: ICheckOut,
	toast: IToast,
	configuration: IConfiguration,
	cart: ICart,
	user: IUser,
	products: IProduct,
	nav: INavigation,
	loader: ILoader,
	payment: IPayment,
	support: ISupport,
	cookie: ICookie
}
const paymentPersistConfig = {
	key: 'payment',
	storage: storageSession,
}

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['configuration', 'cart', 'nav'],
	blacklist: ['business']
}

const rootReducer = combineReducers({
	checkout: checkoutReducer,
	toast: toastReducer,
	configuration: configurationReducer,
	cart: cartReducer,
	user: userReducer,
	products: productsReducer,
	nav: navReducer,
	loader: loaderReducer,
	payment: persistReducer(paymentPersistConfig, paymentReducer),
	support: supportReducer,
	cookie: cookieReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, undefined, composedEnhancers)

export const persistor = persistStore(store);
export default { store, persistor };
