import React, { useEffect, useState } from 'react';
import type { PageTheme } from '../typeScript/types/PageTheme';
import Notification from './Notification';

type Prop = {
    title?: string;
    intro?: string;
    className?: string;
    notificationText?: string;
    withAnimation?: boolean;
    children?: React.ReactNode;
    gradientClassName?: string;
    pageTheme?: PageTheme;
};

const Page = (props: Prop) => {
    const { title, intro, className, withAnimation = true, notificationText = false, pageTheme } = props;
    const [paddingTop, setPaddingTop] = useState<number>();
    const navHeight = 74;

    useEffect(() => {
        if (notificationText) {
            let notification = document.querySelector('.notification');
            let oldNotificationHeight = notification && notification.clientHeight;

            oldNotificationHeight && notificationText
                ? setPaddingTop(oldNotificationHeight + navHeight)
                : setPaddingTop(navHeight);
        }
    }, [notificationText]);

    return (
        <div
            // TODO::: container mx-auto px-4
            className={`page ${pageTheme && pageTheme} ${withAnimation && 'page__animation'} ${className} `}
            style={{
                paddingTop: paddingTop && paddingTop
            }}
        >
            {notificationText && <Notification text={notificationText} />}
            {title && <h2 className='mb-5' dangerouslySetInnerHTML={{ __html: title }}></h2>}
            {intro && <p className='mb-12' dangerouslySetInnerHTML={{ __html: intro }}></p>}

            {props.children}
        </div>
    );
};

export default Page;
