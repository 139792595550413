import { DisplayCardTypes } from 'components/DisplayCard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import api from '../../api/api';
import { prepareInstallationStatusData } from '../../componentData/installation_status';
import {
    ApplicationDownloadCard,
    Button,
    DisplayCard,
    iconTypes,
    InnerSpinner,
    Page,
    PaymentInvitationCard,
    PhotoUploadCard
} from '../../components';
import { ButtonType } from '../../typeScript/types/ButtonTypes';

import UserType from '../../constants/userType';
import loadRecurringPaymentStatus from '../../mixins/loadRecurringPaymentStatus';
import { routePaths } from '../../route_paths';
import { IRootState } from '../../store';
import loaderActions from '../../store/loader';
import { ILoggedUser } from '../../typeScript/interfaces/IUser';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { showApiError } from '../../utils/error-handler';
import { useIsMounted } from '../../utils/hooks';

function Overview() {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const { t } = useTranslation(['home']);

    const user = useSelector<IRootState, ILoggedUser>((state) => state.user.user as ILoggedUser);
    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const hasRecurringPaymentDetails = useSelector<IRootState, boolean>(
        (state) => state.payment.statusHasRecurringPaymentDetails
    );
    const [installationStatusLoaded, setInstallationStatusLoaded] = useState(false);
    const [showContractCard, setShowContractCard] = useState(false);

    useEffect(() => {
        if (isLogged && user) {
            getInstallationAndEventData();
        }
        // eslint-disable-next-line
    }, [dispatch, history, isLogged, isMounted, t, user]);

    useEffect(() => {
        dispatch(loaderActions.setShowLoader(false));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        loadRecurringPaymentStatus(dispatch);
        // eslint-disable-next-line
    }, [isLogged]);

    const getInstallationData = () => {
        return api.installation.getInstallationStatus();
    };

    const getInstallationEvent = () => {
        return api.installation.getInstallationEvent();
    };

    const handleInstallationAndEventData = (installation, event) => {
        const installationStatusData = prepareInstallationStatusData(installation, event, t);
        // If installation status NONE or Installation done in SFDC show contract navigation card
        if (installationStatusData) {
            setShowContractCard(false);
        } else {
            setShowContractCard(true);
        }
        setInstallationStatusLoaded(true);
    };

    const getInstallationAndEventData = () => {
        return getInstallationData()
            .then((installationData) => {
                getInstallationEvent()
                    .then((event) => {
                        if (isMounted.current) {
                            if (installationData.data && event.data) {
                                handleInstallationAndEventData(installationData.data, event.data);
                            }
                        }
                    })
                    .catch(() => {
                        if (isMounted.current) {
                            if (installationData.data) {
                                handleInstallationAndEventData(installationData.data, null);
                            }
                        }
                    });
            })
            .catch((error) => {
                console.log(error);
                setInstallationStatusLoaded(true);
                showApiError(error.response, dispatch, t);
            });
    };

    // const noPaymentInvitationText = () => {
    //     const notificationText = t('add_payment.notificationText', {
    //         path: routePaths.portalPayment,
    //         icon: ArrowIcon
    //     });
    //     return notificationText;
    // };

    const reimbursementCard = () => (
        <>
            {user?.isReimbursementActivated && (
                <DisplayCard type={DisplayCardTypes.ICON_LEFT} src={iconTypes.paymentcard}>
                    <h3 className='mb__s'>{t('payout:payout_card_title')}</h3>
                    <p className='mb__m'>{t('payout:payout_invitation')}</p>
                    <div className='display-card__buttons'>
                        <Link to={routePaths.portalPayout} className='display-card__button'>
                            <Button>{t('payout:payout_invitation_cta')}</Button>
                        </Link>
                    </div>
                </DisplayCard>
            )}
        </>
    );
    const fleetDataCard = () => (
        <>
            {user?.isFleetDataEnrichment && (
                <DisplayCard type={DisplayCardTypes.ICON_LEFT} src={iconTypes.enabled}>
                    <h3 className='mb__s'>{t('nav:fleetData')}</h3>
                    <p className='mb__m'>{t('fleetData:fleetData_invitation')}</p>
                    <div className='display-card__buttons'>
                        <Link to={routePaths.portalFleetData} className='display-card__button'>
                            <Button>{t('fleetData:fleetData_invitation_cta')}</Button>
                        </Link>
                    </div>
                </DisplayCard>
            )}
        </>
    );

    return (
        <Page
            pageTheme={PageTheme.light}
            // notificationText={
            //     user?.canUpdatePaymentInformation && !hasRecurringPaymentDetails ? noPaymentInvitationText() : ''
            // }
            title={showContractCard ? t('title', { name: user && user.name }) : ''}
            intro={showContractCard ? t('installation_completed.text') : ''}
        >
            <InnerSpinner isActive={!user || !installationStatusLoaded}>
                {showContractCard ? (
                    // INFO::: grid equal height
                    <div className='grid auto-rows-fr grid-cols-1 lg:grid-cols-2 gap-6'>
                        {user?.canUpdatePaymentInformation && !hasRecurringPaymentDetails && (
                            <PaymentInvitationCard linkPath={routePaths.portalPayment} />
                        )}

                        {reimbursementCard()}
                        {fleetDataCard()}

                        {user && user.type === UserType.EMPLOYEE && (
                            <DisplayCard
                                type={DisplayCardTypes.ICON_LEFT}
                                src={iconTypes.receipt}
                                label={t('business_documents.title')}
                                intro={t('business_documents.text')}
                            >
                                <div className='display-card__buttons'>
                                    <Link to={routePaths.portalDocuments}>
                                        <Button type={ButtonType.secondary} className='display-card__button'>
                                            {t('common:buttons.go_to_documents')}
                                        </Button>
                                    </Link>
                                </div>
                            </DisplayCard>
                        )}
                        <ApplicationDownloadCard />
                    </div>
                ) : (
                    <div className='w-full flex flex-wrap lg:space-x-6 space-y-6 lg:space-y-0'>
                        <div className='w-full flex flex-col lg:w-1/2'>
                            <DisplayCard
                                type={DisplayCardTypes.PLAIN}
                                className='flex-1'
                                label={t('title', { name: user ? user.name : '' })}
                                intro={t('installation_progress.description')}
                            >
                                <p dangerouslySetInnerHTML={{ __html: t('installation_progress.text') }} />
                            </DisplayCard>
                        </div>
                        <div className='flex flex-1 flex-col lg:w-1/2 space-y-6'>
                            {user?.canUpdatePaymentInformation && !hasRecurringPaymentDetails && (
                                <PaymentInvitationCard className='flex-1' linkPath={routePaths.portalPayment} />
                            )}

                            {reimbursementCard()}
                            {fleetDataCard()}

                            <PhotoUploadCard
                                className='flex-1'
                                navigateUrl={routePaths.portalPhotoUpload}
                            ></PhotoUploadCard>

                            <ApplicationDownloadCard extraClass={'flex-1'}></ApplicationDownloadCard>
                        </div>
                    </div>
                )}
            </InnerSpinner>
        </Page>
    );
}

export default Overview;
