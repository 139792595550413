import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import de from './de';
import Language from '../constants/language';
import { LOCALE_COOKIE_NAME } from '../components/LanguageSwitcher';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: en,
    de: de
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: Language.GERMAN,

        ns: ['checkout', 'common', 'nav'],
        supportedLngs: [Language.GERMAN, Language.ENGLISH],

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: false
        },
        detection: {
            order: ['cookie', 'querystring', 'navigator'],
            lookupCookie: LOCALE_COOKIE_NAME,
            lookupQuerystring: 'lang',
            lookupFromPathIndex: 0
        }
    });

export default i18n;
