import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'typeScript/types/ButtonTypes';
import { Button, InnerSpinner, Input, Switch } from '../../components';
import InputType from '../../constants/inputType';
import getChargerImage from '../../mixins/getChargerImage';
import { hasValidationError, ruleNames, validationErrorTranslation } from '../../validation';
import { ChargerDetailCardCommonProps } from './ChargerCardDetail';

const editButton = require('../../assets/EditButton.svg');

export function CustomerChargerCardDetail(props: ChargerDetailCardCommonProps) {
    const {
        userCharger,
        editClicked,
        handleSaveButton,
        loading,
        onInputChange,
        validationErrors,
        isSingleChargerView,
        onEditClicked,
        onCancelClicked,
        onSwitchClicked,
        toggle
    } = props;

    const { t } = useTranslation(['charger', 'common']);

    return (
        <div className={`charger-card-detail ${isSingleChargerView ? 'single-charger-view' : ''}`}>
            <InnerSpinner isActive={loading}>
                <div className='md:flex'>
                    <div className='w-full md:w-1/3 sm:border-r border-solid border-grey-border text-center md:flex-shrink-0'>
                        {' '}
                        {/* TODO::: DYNAMIC CHARGER IMAGE */}
                        <img
                            className='max-w-xs mx-auto w-full'
                            src={getChargerImage(userCharger.stationModelName)}
                            alt=''
                        />{' '}
                    </div>
                    <div className='md:flex-1 p-6'>
                        <>
                            <div className='charger-card-detail__name'>
                                {editClicked ? (
                                    <Input
                                        id='chargerName'
                                        value={userCharger?.chargerName}
                                        inputClass='input--grey'
                                        type={InputType.TEXT}
                                        disabled={!editClicked}
                                        hasValidationError={hasValidationError(ruleNames.chargerName, validationErrors)}
                                        validationErrorLabel={validationErrorTranslation(
                                            ruleNames.chargerName,
                                            validationErrors,
                                            t
                                        )}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                            onInputChange('chargerName', e.currentTarget.value)
                                        }
                                        containerClass='input__container mb__m'
                                    />
                                ) : (
                                    <h3 title={userCharger?.chargerName}> {userCharger?.chargerName} </h3>
                                )}
                                <img
                                    className={editClicked ? 'charger-card-detail__name-img' : ''}
                                    onClick={onEditClicked}
                                    src={editButton}
                                    alt=''
                                />
                            </div>
                            <div className='charger-card-detail__text mb-4'>
                                <p dangerouslySetInnerHTML={{ __html: t('charger_detail_text') }}></p>
                            </div>
                            <div className='charger-card-detail__properties'>
                                <div className='charger-card-detail__feature'>
                                    <hr />
                                    <h6> {t('charger_detail_authMode')} </h6>
                                    <Switch
                                        className='charger-card-detail__toggle-button'
                                        checked={toggle}
                                        onChange={onSwitchClicked}
                                        switchInnerText={''}
                                        toggleButtonClassName={
                                            'switch__color ' + (toggle ? 'switch__color__green' : 'switch__color__grey')
                                        }
                                    />
                                    <span className='charger-card-detail__label'>
                                        {toggle ? t('charger_auth.activated') : t('charger_auth.disabled')}
                                    </span>
                                </div>
                                <div className='charger-card-detail__feature'>
                                    <hr />
                                    <h6> {t('charger_detail_address')} </h6>
                                    <span className='charger-card-detail__label'>{userCharger?.adress1}</span>
                                </div>

                                <div className='charger-card-detail__feature'>
                                    <hr />
                                    <h6> {t('charger_detail_city')} </h6>
                                    <span className='charger-card-detail__label'>{userCharger?.city}</span>
                                </div>
                                <div className='charger-card-detail__feature'>
                                    <hr />
                                    <h6> {t('charger_detail_zipCode')} </h6>
                                    <span className='charger-card-detail__label'>{userCharger?.zipCode}</span>
                                </div>

                                <div className='charger-card-detail__feature'>
                                    <hr />
                                    <h6> {t('charger_detail_capacity')} </h6>
                                    <span className='charger-card-detail__label'>{`${userCharger?.chargingCapacity} kW`}</span>
                                </div>
                                <div className='charger-card-detail__feature'>
                                    <hr />
                                    <h6> {t('charger_detail_manufacturer_model')} </h6>
                                    <span className='charger-card-detail__label'>{`${
                                        userCharger?.manufacturerName + ' ' + userCharger?.stationModelName
                                    }`}</span>
                                </div>

                                <div className='charger-card-detail__feature'>
                                    <hr />
                                </div>

                                <div className='charger-card-detail__feature charger-card-detail__feature--desk'>
                                    <hr />
                                </div>
                            </div>
                            <div className='buttons'>
                                {isSingleChargerView ? (
                                    editClicked && (
                                        <Button type={ButtonType.secondary} onClick={onCancelClicked}>
                                            {t('common:buttons:cancel')}
                                        </Button>
                                    )
                                ) : (
                                    <label className='mb-3 inline-block'>
                                        {t('charger_detail_serialNumber') + ': ' + userCharger?.serialNumber}
                                    </label>
                                )}
                                <Button buttonType='submit' onClick={(e) => handleSaveButton(e)}>
                                    {t('charger_detail_save_button')}
                                </Button>
                            </div>
                        </>
                    </div>
                </div>
            </InnerSpinner>
        </div>
    );
}
