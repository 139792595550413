import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'store';
import checkoutActions from 'store/checkout';
import loaderActions from 'store/loader';
import { Button, Checkbox, Input } from '.';
import Token from '../api/auth-token-wrapper';
import InputType from '../constants/inputType';

import { ILoggedUser } from '../typeScript/interfaces/IUser';
import { ButtonType } from '../typeScript/types/ButtonTypes';
import { hasValidationError, ruleNames, validationErrorTranslation } from '../validation';
import AcceptTermsCheckbox from './AcceptTermsCheckbox';

interface MyEnvisionAccountBlockProps {
    isLogged: boolean;
    validationErrors: any;
    infoState: any;
    updateInfoState: (attribute, value, dispatch) => void;
    isB2B2E: boolean;
}

export function MyEnvisionAccountBlock(props: MyEnvisionAccountBlockProps) {
    const { isLogged, validationErrors, infoState, updateInfoState, isB2B2E } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);

    if (isLogged) {
        const onLogoutHandle = () => {
            Token.removeAuthTokens();
            dispatch(checkoutActions.resetCheckoutData());
            window.location.reload();
            dispatch(loaderActions.setShowLoader(true));
        };

        return (
            <>
                <p className='mb__s'>
                    {t('checkout:envision_account_text_logged', { email: user ? user.email : null })}
                </p>
                <Button type={ButtonType.secondary} onClick={onLogoutHandle}>
                    {t('common:buttons.logout')}
                </Button>
            </>
        );
    } else {
        let text = (
            <p
                className='mb__base'
                dangerouslySetInnerHTML={{
                    __html: t('checkout:envision_account_text_not_logged')
                }}
            ></p>
        );

        return (
            <>
                {text}
                <div className='checkout__password_policy mb__ml'>
                    <p className='checkout__password_policy__text'>{t('checkout:password_policy')}</p>
                </div>

                <div className='input-row mb__xs'>
                    <Input
                        id='password'
                        label={t('common:labels.set_password')}
                        labelClass='input__label--long'
                        value={infoState.password}
                        type={InputType.PASSWORD}
                        mandatory
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateInfoState('password', e.currentTarget.value, dispatch)
                        }
                        hasValidationError={hasValidationError(ruleNames.createPassword, validationErrors)}
                        validationErrorLabel={validationErrorTranslation(ruleNames.createPassword, validationErrors, t)}
                        placeholder='********'
                        containerClass='input__container--6-el input__container--6-el-row'
                    />
                    <Input
                        id='confirm_password'
                        label={t('common:labels.confirm_password')}
                        type={InputType.PASSWORD}
                        mandatory
                        value={infoState.password_confirmation}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateInfoState('password_confirmation', e.currentTarget.value, dispatch)
                        }
                        hasValidationError={hasValidationError(ruleNames.passwordConfirmation, validationErrors)}
                        validationErrorLabel={validationErrorTranslation(
                            ruleNames.passwordConfirmation,
                            validationErrors,
                            t
                        )}
                        placeholder='********'
                        containerClass='input__container--6-el input__container--6-el-row'
                    />
                </div>

                {!isB2B2E && (
                    <AcceptTermsCheckbox
                        infoState={infoState}
                        updateInfoState={updateInfoState}
                        validationErrors={validationErrors}
                    />
                )}

                {!isB2B2E && (
                    <Checkbox
                        id='receive_info'
                        className='mb__m'
                        type='small'
                        checked={infoState.receive_info}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateInfoState('receive_info', !infoState.receive_info, dispatch)
                        }
                        label={t('common:labels.promotion_offers')}
                    />
                )}
            </>
        );
    }
}
