export default {
    loading_data: 'Initializing...',
    loading_products: 'Loading products...',
    please_wait: 'Please wait...',
    registering_user: 'Registering user...',
    logging_in: 'Logging in...',
    initializing_checkout: 'Initializing checkout process...',
    saving_checkout_data: 'Saving checkout data...',
    saving_product_selection: 'Saving product selection...',
    still_working: 'Still working, please wait...',
    saving_data: 'Saving data...',
    processing_payment: 'Processing payment...',
    confirming_order: 'Confirming order, please wait...',
    business_saving_data: 'Data being transmitted'
}