import React, { useState } from 'react';
import GridType from '../../constants/gridType';
import getChargerImage from '../../mixins/getChargerImage';
import { IUserCharger } from '../../typeScript/interfaces/IUser';
import Modal from '../Modal';
import ChargerCard from './ChargerCard';
import ChargerCardDetail from './ChargerCardDetail';
import ChargersListView from './ChargersListView';

type Props = {
    userChargers: IUserCharger[];
    updateList: (id, name, mode) => void;
    gridMode: GridType;
    className?: string;
};

function ChargerList(props: Props) {
    const { userChargers = [], updateList, gridMode, className } = props;

    const [popup, setPopup] = useState(false);
    const [selectedCharger, setSelectedCharger] = useState('');

    function handleClick(e) {
        setSelectedCharger(e);
        setPopup(!popup);
    }

    /*here implemented [ id , name, enabled ]*/
    const renderGrid = () => {
        return <ChargersListView chargers={[...userChargers]} handleRowClick={handleClick} />;
    };

    const renderCard = () => {
        return (
            <div className={`${className} grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5`}>
                {[...userChargers].map((card, index) => (
                    // TODO::: DYNAMIC CHARGER IMAGE
                    <ChargerCard
                        key={index}
                        chargerImage={getChargerImage(card.stationModelName)}
                        chargerName={card.chargerName}
                        authMode={card.authorizationModeEnabled}
                        onClick={() => handleClick(card.id)}
                    />
                ))}
            </div>
        );
    };

    const renderChargers = () => {
        return gridMode === GridType.CARD ? renderCard() : renderGrid();
    };

    return (
        <div className='charger-list'>
            {popup && (
                <Modal popup={popup} handleClose={handleClick}>
                    <ChargerCardDetail
                        isSingleChargerView={false}
                        updateList={updateList}
                        data={selectedCharger}
                        handleClose={handleClick}
                        isBusiness={false}
                    />
                </Modal>
            )}
            {renderChargers()}
        </div>
    );
}

export default ChargerList;
