export default {
    headline: 'Overview of your appointment booking',
    description: 'Please check your details again.',
    product: {
        title: 'Product',
        description: 'Pre-Visit: On-site check by a certified electrician'
    },
    personal_information: {
        title: 'Personal Information',
        name: 'Name',
        birthdate: 'Birth Date',
        street: 'Street name / House number',
        city: 'City',
        postal_code: 'Postal code',
        country: 'Country'
    },
    appointment_information: {
        title: 'Appointment Information',
        email_address: 'E-Mail Address',
        phone_number: 'Telephone number',
        preferred_date: 'Date'
    },
    informational_note: {
        B2B2E: 'There is a one-off fee of EUR 99.00 incl. VAT for the pre-visit, to be paid by your employer after the appointment has been made, based on the arrangements made with your employer',
        B2B2C: 'There is a one-off fee of 99.00 EUR incl. VAT for the pre-visit, which is payable by you after the appointment has been held on the basis of the arrangements made with your employer.'
    },
    terms_and_conditions:
        'Yes, I agree to the <1>Terms of Use</1> of the my<2></2>OS<3></3> Customer Portal and the <4>General Terms and Conditions</4> of the Pre-Visit.'
};

const businessOrderConfirmationNissan = {
    headline: 'Overview of your appointment',
    product: {
        description: 'Pre-visit: pre-appointment check by a certified electrician from Univers'
    },
    informational_note: {
        B2B2C: 'The pre-visit fee of 99.00 EUR incl. VAT is part of the voucher you have already paid.'
    },
    terms_and_conditions: 'Yes, I agree that my car dealer will be informed about my installation progress',
    extra_info:
        'Note: If you decline the installation offer, Univers will share this information with your car dealer to ensure reversal.'
};


const businessOrderConfirmationGoinGreen = {
    product: {
        description: 'Pre-visit: pre-appointment check by a certified electrician from Univers'
    },
    informational_note: {
        B2B2C: 'For the pre-visit, a one-time fee of EUR 99.00 incl. VAT will be charged, which you will have to pay after the successful completion of the visit. If you decide to place the order, this amount plus EUR 199.00 incl. VAT, i.e. a total of EUR 298.00 incl. VAT, will be credited to the offer and deducted from the invoice amount.'
    },
}

export { businessOrderConfirmationNissan, businessOrderConfirmationGoinGreen };
