export default {
    header: {
        title: "Thank you for booking your appointment",
        text: "We have received your appointment request with the reference number <span>48389221</span>. You will shortly receive the confirmation by email.",
        text2: "In the meantime you can log into the my<span>En</span>OS<span className='business-flex-page__sup'>TM</span> portal. We provide useful information in the portal, you can view and change your data, track the status of the installation or contact our customer service.",
        genericErrorTitle: "Oops - something went wrong",
        genericErrorText: "Unfortunately, an error occurred during the transmission of the data. <br/> <br/> Please try again or contact customer service. "
    },
    tryAgain: 'Try again',
    closeConfirmation: 'Done',
};

const b2b2eConfirmationNissan = {
    header: {
        text: "Thank you for booking your appointment with the reference number <span>{reference_number}}</span>. You will receive a confirmation by email shortly."
    }
}

export { b2b2eConfirmationNissan };
