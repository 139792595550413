import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'store';
import { closeToast } from 'store/toast';
import cssVariables from '../utils/css-variables';
import Icon, { iconTypes } from './Icon';

export const TYPE_SUCCESS = 'success';
export const TYPE_ERROR = 'error';

function Toast() {
    const showToast = useSelector<IRootState, boolean>((state) => state.toast.showToast);
    const toastMessage = useSelector<IRootState, string>((state) => state.toast.toastMessage);
    const cartIsOpened = useSelector<IRootState, boolean>((state) => state.cart.opened);

    const dispatch = useDispatch();
    let timeout: number | null;

    useEffect(() => {
        if (showToast) {
            timeout = window.setTimeout(() => {
                hideToast();
            }, 8000);
        }
        // eslint-disable-next-line
    }, [showToast]);

    const hideToast = () => {
        dispatch(closeToast());
    };

    const removeHideAction = () => {
        if (timeout !== null) {
            window.clearTimeout(timeout);
            timeout = null;
        }
    };

    return (
        <div
            className={`toast ${showToast ? 'toast--show' : ''} ${cartIsOpened ? 'toast--cart-open' : ''}`}
            onMouseOver={removeHideAction}
        >
            <p className='toast__text js__toast-text' dangerouslySetInnerHTML={{ __html: toastMessage }}></p>
            <Icon
                className='toast__close'
                name={iconTypes.close}
                size={16}
                color={cssVariables.colors.white}
                onClick={hideToast}
            />
        </div>
    );
}

export default Toast;
