export default {
    header: {
        title: "Planning and Installation",
        text: "From the first on-site check to installation.",
    },
    step: 'Step',
    sectionNow: {
        title: 'Now',
        step1: {
            title: 'Appointment',
            content: 'Schedule an appointment with one of our electricians. He will come to you and look at the individual conditions on site.',
        },
        step2: {
            title: 'Pre-Visit',
            content: 'Your infrastructure will be checked, the installation location will be preselected and you will receive advice on choosing the right solution at your home.',
        },
    },
    sectionPreVisit: {
        title: 'Pre-Visit',
        step3: {
            title: 'Cost estimate',
            content: 'Based on the findings of the pre-visit, you will receive an offer for the complete package.',
        },
        step4: {
            title: 'Order',
            content: 'If you like the offer, you can easily order it directly. We take care of the rest.',
        },
        step5: {
            title: 'Installation & Settings',
            content: 'We take care of the coordination, the installation and commissioning of one of our specialist companies.',
        },
        step6: {
            title: 'Payment after installation',
            content: 'Payment can only be made by invoice after successful installation.',
        },
    },
    faq: {
        title: 'Have Questions?',
    },
    contacts: {
        titleEmail: "Send your questions to us:",
        email: "<a href='mailto:kundenservice@univers.de'>kundenservice@univers.de</a>",
        contactUs: 'Or by phone:',
        phone: '<a>+49 800 4302255</a>',
    }
};

const b2b2ePlanningNissan = {
    header: {
        text: 'From the pre-check to the installation of your Nissan charging solution'
    },
    sectionNow: {
        step1: {
            content:
                'Book an appointment with one of our certified electricians now. He will come to your home and analyze the installation conditions on site.'
        }
    },
    sectionPreVisit: {
        step3: {
            content:
                'Based on the findings of the pre-visit, you will receive a quote for the installation. Payments already made will be taken into account here.'
        },
        step6: {
            title: 'Payment',
            content:
                'For services that are not yet covered by the payment already made, payment is only made by invoice after successful installation.'
        }
    }
};

const b2b2ePlanningGoinGreen = {
    header: {
        text: 'From the pre-check to the installation of your Nissan charging solution'
    },
    sectionPreVisit: {
        step3: {
            content:
            'Based on the findings of the pre-visit, you will receive a quote for the installation. Payments already made will be taken into account here.'
        },
    }
};

export { b2b2ePlanningNissan,b2b2ePlanningGoinGreen };
