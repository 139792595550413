import { IContractTerminationUserInfo } from '../typeScript/interfaces/IContractTerminationUserInfo';
import { getValidationRules, ruleNames, validationService } from '../validation';

import { TerminationType, TerminationDateDecision } from '../components/ContractTerminationForm';

export const validationForContractTermination = (params) => {
    let rulesArray = [
        ruleNames.terminationType,
        ruleNames.terminationReason,
        ruleNames.customerNumber,
        ruleNames.contractNumber,
        ruleNames.firstName,
        ruleNames.lastName,
        ruleNames.email,
        ruleNames.terminationDateDecision,
        ruleNames.terminationDate
    ];

    let rules = getValidationRules(rulesArray);

    return validationService(params, rules);
};

export const getContractTerminationApiData = (infoState: IContractTerminationUserInfo) => {
    return {
        terminationType: infoState.terminationType.value,
        terminationReason:
            infoState.terminationType.value === TerminationType.ORDINARY ? '' : infoState.terminationReason,
        customerNumber: infoState.customerNumber,
        contractNumber: infoState.contractNumber,
        firstName: infoState.firstName,
        lastName: infoState.lastName,
        email: infoState.email,
        terminationDate:
            infoState.terminationType.value === TerminationType.EXTRA_ORDINARY
                ? new Date()
                : infoState.terminationDateDecision.value === TerminationDateDecision.SELECTED_DATE
                ? infoState.terminationDate
                : new Date(),
        comment: infoState.comment
    };
};
