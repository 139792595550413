import React from "react";

export type Props = {
    mobileMenuOpen: boolean;
    setMobileMenuOpen: (open: boolean) => void;
};

export function NavBurger(props: Props) {
    const { mobileMenuOpen, setMobileMenuOpen } = props;

    return (
        <div
            className={`nav__burger ${
                mobileMenuOpen ? "nav__burger--open" : ""
            }`}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
            <div className="nav__burger__el"></div>
            <div className="nav__burger__el"></div>
            <div className="nav__burger__el"></div>
        </div>
    );
}
