export const UPDATE_CHECKOUT_INFO = 'UPDATE_CHECKOUT_INFO';
export const UPDATE_CHECKOUT_WITH_USER = 'UPDATE_CHECKOUT_WITH_USER';
export const RESET_CHECKOUT_DATA = 'RESET_CHECKOUT_DATA';
export const SET_CHECKOUT_STEPS_COMPLETED = 'SET_CHECKOUT_STEPS_COMPLETED';

export function updateCheckoutInfo(attribute, value) {
	return {
		type: UPDATE_CHECKOUT_INFO,
		attribute,
		value
	}
}

export function updateCheckoutWithUser(value) {
	return {
		type: UPDATE_CHECKOUT_WITH_USER,
		value
	}
}

export function resetCheckoutData() {
	return {
		type: RESET_CHECKOUT_DATA
	}
}

export function setCheckoutStepsCompleted(value) {
	return {
		type: SET_CHECKOUT_STEPS_COMPLETED,
		value
	}
}


export default {
	updateCheckoutInfo,
	updateCheckoutWithUser,
	resetCheckoutData,
	setCheckoutStepsCompleted
}
