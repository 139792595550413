import React, { useRef } from 'react';
import { regexNumericalTest } from '../utils/regex';
import Label from './Label';
import InputType from '../constants/inputType';

export interface InputProps {
    id?: string;
    containerClass?: string;
    inputClass?: string;
    type?: InputType;
    value?: string | number;
    labelClass?: string;
    label?: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    inputFunctionality?: 'expiry_date' | 'birthday';
    placeholder?: string;
    maxLength?: string;
    hasValidationError?: boolean;
    validationErrorLabel?: string;
    disabled?: boolean;
    mandatory?: boolean;
    restProps?: object;
    autoComplete?: string;
    min?: number | string;
    max?: number | string;
    multiline?: boolean;
    accept?: string;
    suffix?: string;
}

function Input(props: InputProps) {
    const {
        id = '',
        containerClass = '',
        inputClass = '',
        labelClass = '',
        type = InputType.TEXT,
        label = '',
        inputFunctionality = 'birthday',
        maxLength = false,
        onChange = (e: React.FormEvent<HTMLInputElement>) => {},
        hasValidationError = false,
        validationErrorLabel = '',
        mandatory = false,
        min,
        max,
        multiline = false,
        accept,
        suffix,
        ...restProps
    } = props;

    const ref = useRef<HTMLDivElement>(null);

    const testForNumbericalInput = (e) => {
        const keyCode = e.keyCode || e.charCode;
        return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || regexNumericalTest(e.key);
    };

    // for special cases such as the expiry date of the debit card, we need JS functionality
    const createOnKeyDown = () => {
        switch (inputFunctionality as any) {
            case 'exp_date':
                return (e) => {
                    const keyCode = e.keyCode || e.charCode;
                    if (e.key === 'Backspace' || keyCode === 8) {
                        if (e.target.value.length === 3 && e.target.value[2] === '/') {
                            e.target.value = e.target.value[0] + e.target.value[1];
                        }
                    } else if (!testForNumbericalInput(e)) {
                        e.preventDefault();
                    }
                };
            default:
                return () => {};
        }
    };

    const onKeyDown = createOnKeyDown();

    const createOnChange = () => {
        // general functionality
        const onChangeExtended = (e) => {
            if (maxLength) {
                if (e.target.value.length <= maxLength) {
                    onChange(e);
                }
            } else {
                onChange(e);
            }
        };

        // specific functionality
        switch (inputFunctionality) {
            case 'exp_date' as any:
                return (e) => {
                    if (e.target.value.length === 2) {
                        e.target.value += '/';
                    }
                    onChangeExtended(e);
                };
            case 'birthday':
                return (e) => {
                    onChangeExtended(e);
                    // focus on the next input
                    if (maxLength && e.target.value.length === Number(maxLength) && ref.current) {
                        ref.current.nextElementSibling?.querySelector('input')?.focus();
                    }
                };
            default:
                return onChangeExtended;
        }
    };

    const onChangeWrapped = createOnChange();

    const inputClassName =
        'input ' +
        inputClass +
        (restProps.disabled ? ' input--disabled' : '') +
        (hasValidationError ? ' input--error' : '');

    return (
        <div
            className={'input__container ' + String(containerClass)}
            data-has-suffix={suffix ? 'true' : 'false'}
            ref={ref}
        >
            <Label
                type={hasValidationError ? 'error' : 'default'}
                errorLabel={validationErrorLabel}
                className={labelClass}
            >
                {label}
                {mandatory ? '*' : ''}
            </Label>
            {!multiline && (
                <>
                    <input
                        {...restProps}
                        min={min}
                        max={max}
                        onKeyDown={onKeyDown}
                        onChange={onChangeWrapped}
                        type={type}
                        className={inputClassName}
                        id={id}
                        accept={accept}
                    />
                    {suffix && <span className='suffix'>{suffix}</span>}
                </>
            )}
            {multiline && (
                <textarea
                    {...restProps}
                    onKeyDown={onKeyDown}
                    onChange={onChangeWrapped}
                    className={inputClassName}
                    id={id}
                    rows={100}
                />
            )}
        </div>
    );
}

export default Input;
