export default {
    documents: {
        header: {
            title: 'Documents & Contracts',
            text: 'Welcome to your Document Page, where you\'ll find all relevant files for your charging station and contract.'
        },
        information: {
            title: 'General information Charger Models',
            alfen_eve_double: 'Alfen Eve Double Pro-line Datasheet',
            alfen_eve_single: 'Alfen Eve Single Datasheet',
            charger_manuel: 'Etrel INCH Home Datasheet',
            employer_permission: "Employer's Consent"
        },
        contract: {
            title: 'Contracts & T&Cs'
        },
        invoices: {
            title: 'Quotations & Invoices',
            invoice_charger: 'Invoice Charger',
            installation_quote: 'Installation quote',
            loading: 'Loading invoices & quotes...'
        },
        no_documents: 'No documents available'
    },
    tariff: {
        header: {
            title: 'Contract',
            intro: 'Here you will find all information about your tariff. Billing is monthly.',
        },
    },
    contractTermination: {
        btn_contract_termination: "Cancel Contract",
        btn_contract_termination_confirm: "Send",
    }
};
