/* tslint:disable */
/* eslint-disable */
/**
 * Registration and fleetdata api
 * handles customer lifecycle information, i.e. fleetdata
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * description of the errorcode
 * @export
 */
export const FleetDataErrorCode = {
    EmployeeCostUnitIsNull: 'EMPLOYEE_COST_UNIT_IS_NULL',
    EmployeeCostUnitValidationError: 'EMPLOYEE_COST_UNIT_VALIDATION_ERROR',
    EmployeeIdIsNull: 'EMPLOYEE_ID_IS_NULL',
    EmployeeIdValidationError: 'EMPLOYEE_ID_VALIDATION_ERROR',
    VehicleRegistrationNumberIstNull: 'VEHICLE_REGISTRATION_NUMBER_IST_NULL',
    VehicleRegistrationNumberValidationError: 'VEHICLE_REGISTRATION_NUMBER_VALIDATION_ERROR',
    RenewableEnergyIsNull: 'RENEWABLE_ENERGY_IS_NULL',
    RenewableEnergyValidationError: 'RENEWABLE_ENERGY_VALIDATION_ERROR'
} as const;
export type FleetDataErrorCode = typeof FleetDataErrorCode[keyof typeof FleetDataErrorCode];


export function FleetDataErrorCodeFromJSON(json: any): FleetDataErrorCode {
    return FleetDataErrorCodeFromJSONTyped(json, false);
}

export function FleetDataErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetDataErrorCode {
    return json as FleetDataErrorCode;
}

export function FleetDataErrorCodeToJSON(value?: FleetDataErrorCode | null): any {
    return value as any;
}

