export default {
    customer: {
        title: "Dokumente",
        terms: "AGB",
        privacy: "Datenschutzerklärung",
        charger_manual: "Charger ENV 01",
        open: "Öffnen",
        download: "PDF herunterladen"
    },
    support: {
        title: "Support",
        phone: {
            title: "Kundenservice",
            text_1: "Wir sind Montag bis Freitag von 08.00 Uhr - 18.00 Uhr für Sie da:",
        },
        email: {
            title: "Schreiben Sie uns eine Nachricht",
            text: "Wir werden Ihre Nachricht so schnell wie möglich bearbeiten und uns bei Ihnen melden.",
        },
        placeholders: {
            textarea: 'Bitte schreiben Sie Ihre Nachricht hier',
            loading_topics: 'Themen laden...'
        },
        files: {
            title: "Anhänge"
        },
        buttons: {
            cancel: 'Abbrechen',
            send: 'Senden'
        }
    },
    message_popup: {
        title: "Schreiben Sie uns eine Nachricht",
        text: "Bitte wählen Sie eine Kategorie für Ihre Anfrage und erläutern Sie in einer kurzen Nachricht Ihr Anliegen. "
    },
    topic: {
        payment_and_billing: "Bezahlung und Rechnung",
        charger: "Charger",
        installation: "Installation",
        mobile_app: "Mobile App",
        tariff_package_and_contracts: "Tarifpaket und Vertrag",
        general_question: "Allgemeine Frage",
        charging_issue: "Rund ums Laden",
        advice_counselling: "Beratung",
        smart_meter: "Smart Meter",
        customer_data_issue: "Kundendatenproblem",
        account: "Account"
    }
}