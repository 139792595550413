import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'store/index';
import loaderActions from 'store/loader';
import Spinner, { SpinnerTheme } from './Spinner';

let timeout: number | null;

function Loader() {
    const showLoaderEvent = useSelector<IRootState, boolean>((state) => state.loader.showLoader);
    const loaderMessage = useSelector<IRootState, string>((state) => state.loader.loaderMessage!);
    const isInitialLoad = useSelector<IRootState, boolean>((state) => state.loader.isInitialLoad);
    const [showLoader, setShowLoader] = useState(false);
    const [animationOut, setAnimationOut] = useState(false);
    const [animationIn, setAnimationIn] = useState(false);

    const dispatch = useDispatch();

    // TODO::: Do we need animationOut && animationIn ?

    useEffect(() => {
        if (showLoaderEvent) {
            document.body.style.overflow = 'hidden';
            setAnimationOut(false);

            // clear the timeout if we want to show loader becuase if there is a case where
            // we hide the loader and then show it again within a second then the timeout
            // will hide it
            if (timeout !== null) {
                window.clearTimeout(timeout);
                timeout = null;
            }

            setShowLoader(true);
            if (isInitialLoad) {
                dispatch(loaderActions.setIsInitialLoad(false));
            } else {
                setAnimationIn(true);
            }
        } else {
            document.body.style.overflow = '';
            if (!isInitialLoad) {
                setAnimationOut(false);
            }

            setAnimationIn(false);

            timeout = window.setTimeout(() => {
                setShowLoader(false);
                timeout = null;
            }, 1000);
        }
        // eslint-disable-next-line
    }, [showLoaderEvent]);

    return showLoader ? (
        <div className='loader-container'>
            <Spinner
                theme={SpinnerTheme.dark}
                animationIn={animationIn}
                animationOut={animationOut}
                loaderMessage={loaderMessage}
            />
        </div>
    ) : (
        <></>
    );
}

export default Loader;
