import React from 'react';
import Label from './Label';

type Props = {
    id: string;
    rows: number;
    containerClass: string;
    inputClass?: string;
    type?: 'text' | 'number' | 'email' | 'tel' | 'password';
    value: string | number;
    label: string;
    onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
    placeholder: string;
    maxLength?: number;
    hasValidationError?: boolean;
    validationErrorLabel?: string;
};

function TextArea(props: Props) {
    const {
        id = '',
        containerClass = '',
        inputClass = '',
        label = '',
        rows = 0,
        hasValidationError = false,
        validationErrorLabel = '',
        value = '',
        placeholder = '',
        maxLength,
        ...restProps
    } = props;

    return (
        <div className={'input__container input__container--fw ' + String(containerClass)}>
            <Label type={hasValidationError ? 'error' : 'default'} errorLabel={validationErrorLabel}>
                {label}
            </Label>
            <textarea
                className={'textarea ' + inputClass}
                id={id}
                rows={rows ? rows : 10}
                value={value}
                placeholder={placeholder}
                maxLength={maxLength}
                {...restProps}
            />
        </div>
    );
}

export default TextArea;
