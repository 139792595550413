import {
	UPDATE_PRE_CHECK_ANSWERS,
	SET_CURRENT_STEP,
	SET_CHARGER_SELECTION,
	SET_DEFAULT_CHARGER_SELECTION,
	SET_TARIFF_SELECTION,
	// SET_SMART_METER_SELECTION,
	UPDATE_INSTALLATION_ANSWERS_SELECTION,
	RESET_INSTALLATION_ANSWERS_SELECTION,
	SET_INSTALLATION_PACKAGE_SELECTION,
	SET_PRE_CHECK_COMPLETED_AND_PASSED,
	SET_INSTALLATION_ANSWERS_COMPLETED,
	SET_NEEDS_PRE_VISIT,
	SET_REDIRECT_TO_CHECKOUT_ON_SELECT,
	RESET_CONFIGURATION_DATA,
	SET_DOWN_PAYMENT_PRODUCT_SELECTION,
	SET_FRIENDS_AND_FAMILY_PROMOTION_CODE,
	SET_PRE_VISIT_PACKAGE_SELECTION,
	SET_DISCOUNT_AMOUNT,
	SET_SELECTED_PRODUCT,
	RESET_CONFIGURATION_DATA_WITHOUT_PROMOTION_CODE,
	RESET_CONFIGURATION_DATA_WITHOUT_SELECTED_PRODUCT
} from './';

import { IConfiguration } from '../../typeScript/interfaces/IConfiguration';

export const initialState: IConfiguration = {
	currentStep: null,
	preCheckCompletedAndPassed: false,
	selectedProduct: null,
	tariff: null,
	charger: null,
	downPayment: null,
	installationAnswers: {},
	preCheckAnswers: {},
	preCheckAnswersStepKey: null,
	installationAnswersCompleted: false,
	installationPackageSelection: null,
	preVisitPackageSelection: null,
	needsPreVisit: false,
	preVisitText: '',
	redirectToCheckoutOnSelect: false,
	familyFriendsPromotionCode: null,
	totalDiscountAmount: null,
	defaultCharger: null
};

export function configurationReducer(state: IConfiguration = initialState, action: any): IConfiguration {
	switch (action.type) {
		case UPDATE_PRE_CHECK_ANSWERS:
			return {
				...state,
				preCheckAnswersStepKey: action.step,
				preCheckAnswers: {
					...state.preCheckAnswers,
					[action.step]: action.value,
				}
			};
		case SET_CURRENT_STEP:
			return {
				...state,
				currentStep: action.value,
			};
		case SET_PRE_CHECK_COMPLETED_AND_PASSED:
			return {
				...state,
				preCheckCompletedAndPassed: action.value,
			};
		case SET_SELECTED_PRODUCT:
			return {
				...state,
				selectedProduct: action.value,
			};
		case SET_INSTALLATION_ANSWERS_COMPLETED:
			return {
				...state,
				installationAnswersCompleted: action.value,
			};
		case SET_CHARGER_SELECTION:
			return {
				...state,
				charger: action.value,
			};
		case SET_DEFAULT_CHARGER_SELECTION:
			return {
				...state,
				defaultCharger: action.value,
			};
		case SET_TARIFF_SELECTION:
			return {
				...state,
				tariff: action.value,
			};
		case SET_DOWN_PAYMENT_PRODUCT_SELECTION:
			return {
				...state,
				downPayment: action.value,
			};
		case UPDATE_INSTALLATION_ANSWERS_SELECTION:
			let answers = {
				...state.installationAnswers,
			}

			let updatedAnswers = {
				...state.installationAnswers,
				[action.step]: action.value
			}

			let sorted = {};
			let isAnswerChanged = false;

			Object.keys(answers).forEach(answer => {
				if (answers[answer].sort === action.value.sort) {
					isAnswerChanged = answers[answer].answer !== action.value.answer;
				}
			});
			
			if (isAnswerChanged) {
				Object.keys(updatedAnswers).forEach(answer => {
					if (updatedAnswers[answer].sort > action.value.sort) {
						delete updatedAnswers[answer];
					}
				});
			}

			Object.keys(updatedAnswers).sort((a, b) => {
				return updatedAnswers[a].sort - updatedAnswers[b].sort;
			}).forEach((key) => {
				sorted[key] = updatedAnswers[key];
			});

			return {
				...state,
				installationAnswers: sorted
			};
		case RESET_INSTALLATION_ANSWERS_SELECTION:
			return {
				...state,
				installationAnswers: []
			};
		case SET_INSTALLATION_PACKAGE_SELECTION:
			return {
				...state,
				installationPackageSelection: action.value,
			};
		case SET_PRE_VISIT_PACKAGE_SELECTION:
			return {
				...state,
				preVisitPackageSelection: action.value,
			};
		case SET_NEEDS_PRE_VISIT:
			return {
				...state,
				needsPreVisit: action.value,
				preVisitText: action.text ? action.text : false
			};
		case SET_REDIRECT_TO_CHECKOUT_ON_SELECT:
			return {
				...state,
				redirectToCheckoutOnSelect: action.value
			}
		case SET_FRIENDS_AND_FAMILY_PROMOTION_CODE:
			return {
				...state,
				familyFriendsPromotionCode: action.value
			}
		case SET_DISCOUNT_AMOUNT:
			return {
				...state,
				totalDiscountAmount: action.value
			}
		case RESET_CONFIGURATION_DATA:
			return {
				currentStep: null,
				preCheckCompletedAndPassed: false,
				selectedProduct: null,
				tariff: null,
				charger: null,
				downPayment: null,
				installationAnswers: [],
				preCheckAnswers: [],
				preCheckAnswersStepKey: null,
				installationAnswersCompleted: false,
				installationPackageSelection: null,
				preVisitPackageSelection: null,
				needsPreVisit: false,
				redirectToCheckoutOnSelect: false,
				preVisitText: '',
				familyFriendsPromotionCode: null,
				totalDiscountAmount: null,
				defaultCharger: null
			}
		case RESET_CONFIGURATION_DATA_WITHOUT_SELECTED_PRODUCT:
			return {
				currentStep: null,
				preCheckCompletedAndPassed: false,
				selectedProduct: state.selectedProduct,
				tariff: null,
				charger: null,
				downPayment: null,
				installationAnswers: [],
				preCheckAnswers: [],
				preCheckAnswersStepKey: null,
				installationAnswersCompleted: false,
				installationPackageSelection: null,
				preVisitPackageSelection: null,
				needsPreVisit: false,
				redirectToCheckoutOnSelect: false,
				preVisitText: '',
				familyFriendsPromotionCode: null,
				totalDiscountAmount: null,
				defaultCharger: null
			}
		case RESET_CONFIGURATION_DATA_WITHOUT_PROMOTION_CODE:
			return {
				currentStep: null,
				preCheckCompletedAndPassed: false,
				selectedProduct: null,
				tariff: null,
				charger: null,
				downPayment: null,
				installationAnswers: [],
				preCheckAnswers: [],
				preCheckAnswersStepKey: null,
				installationAnswersCompleted: false,
				installationPackageSelection: null,
				preVisitPackageSelection: null,
				needsPreVisit: false,
				redirectToCheckoutOnSelect: false,
				preVisitText: '',
				familyFriendsPromotionCode: state.familyFriendsPromotionCode,
				totalDiscountAmount: null,
				defaultCharger: null
			}
		default:
			return state
	}
}
