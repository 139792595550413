import React from 'react';

export enum BadgeState {
    DEFAULT = 'default',
    SUCCESS = 'success',
    INFO = 'info',
    ERROR = 'error'
}

export interface BadgeProps {
    state?: BadgeState;
    children: React.ReactNode;
}

function Badge(props: BadgeProps) {
    return <div className={`badge ${props.state}`}>{props.children}</div>;
}

export default Badge;
