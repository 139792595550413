import ContactFormOverlay from 'components/ContactFormOverlay';
import { TYPE_SUCCESS } from 'components/Toast';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import { Button, Dropdown, FilesUploadCard, Input, Page } from '../../components';
import Constants from '../../constants';
import InputType from '../../constants/inputType';
import UserType from '../../constants/userType';
import { IRootState } from '../../store';
import loaderActions from '../../store/loader';
import supportActions from '../../store/support';
import { openToast } from '../../store/toast';
import { ISupport } from '../../typeScript/interfaces/ISupport';
import { ILoggedUser } from '../../typeScript/interfaces/IUser';
import { ButtonType } from '../../typeScript/types/ButtonTypes';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { showApiError } from '../../utils/error-handler';
import { useIsMounted } from '../../utils/hooks';
import {
    getValidationRules,
    hasValidationError,
    ruleNames,
    validationErrorTranslation,
    validationService
} from '../../validation';
import common from 'i18n/en/common';

const PhoneSupportImage = require('../../assets/business/PhoneSupport.svg');
const EmailImage = require('../../assets/business/Email.svg');

enum ViewPorts {
    Desktop = 0,
    Tablet = 1,
    Mobile = 2
}

enum ViewSections {
    Desktop = 'desktop',
    Mobile = 'mobile'
}

function Support() {
    const [overlay, setOverlay] = useState(false);
    const { t } = useTranslation(['businessSupport', 'support']);
    const [loadingTopicsMessage, setLoadingTopicsMessage] = useState('');

    const support = useSelector<IRootState, ISupport>((state) => state.support);
    const [supportTopicsForApi, setSupportTopicsForApi] = useState([]);
    const [supportTopics, setSupportTopics] = useState([]);
    const [validationErrors, setValidationErrors] = useState<object[]>([]);
    const [supportTopicsLoaded, setSupportTopicsLoaded] = useState(false);
    const [files, setFiles] = useState<any[]>([]);

    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const [viewport, setViewport] = useState(0);
    const handleWindowSizeChange = () => {
        const mode =
            window.innerWidth < 768
                ? ViewPorts.Mobile
                : window.innerWidth < 1360
                ? ViewPorts.Tablet
                : ViewPorts.Desktop;
        setViewport(mode);
    };

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (isLogged) {
            setLoadingTopicsMessage(t('support.placeholders.loading_topics'));
            getSupportTopics();
        }
        // eslint-disable-next-line
    }, [isLogged, t]);

    useEffect(() => {
        if (supportTopicsLoaded && user && user.type === UserType.EMPLOYEE) {
            dispatch(loaderActions.setShowLoader(false));
        }
    }, [dispatch, supportTopicsLoaded, user]);

    const getSupportTopics = () => {
        api.support
            .getSupportTopics()
            .then((response) => {
                if (isMounted.current) {
                    setSupportTopicsForApi(response.data);
                    setLoadingTopicsMessage('');
                    setSupportTopics(
                        response.data
                            .sort((a, b) =>
                                t('support:topic.' + a.type.toLowerCase()).localeCompare(
                                    t('support:topic.' + b.type.toLowerCase())
                                )
                            )
                            .map((topic) => {
                                return {
                                    value: `${topic.type}`,
                                    label: `${t('support:topic.' + topic.type.toLowerCase())}`
                                };
                            })
                    );
                }
                setSupportTopicsLoaded(true);
            })
            .catch((error) => {
                setLoadingTopicsMessage('');
                setSupportTopicsLoaded(true);
                showApiError(error.response, dispatch, t);
            });
    };

    const updateSupportSubject = (value) => {
        dispatch(supportActions.setSupportTicketSubject(value));
        setValidationErrors([]);
    };

    const updateSupportDescription = (value) => {
        dispatch(supportActions.setSupportTicketDescription(value));
        setValidationErrors([]);
    };

    const toogleSendMessagePopup = () => {
        setOverlay(!overlay);
        setValidationErrors([]);
        dispatch(supportActions.resetSupportTicket());
    };

    const getSupportTicket = (supportDto) => {
        const subject = supportDto.subject !== '' ? getSubjectForApi(supportDto.subject.value) : '';
        return {
            type: subject,
            description: supportDto.description
        };
    };

    const getSubjectForApi = (subject) => {
        let item = supportTopicsForApi.find((s: any) => {
            return s.type === subject;
        });
        return (item as any).value;
    };

    const createSupportTicket = (event) => {
        if (event) {
            event.preventDefault();
        }

        const params = getSupportTicket(support);
        let validation = validateSupportTicket(params);

        if (validation.passes()) {
            dispatch(loaderActions.setShowLoader(true));
            toogleSendMessagePopup();
            setValidationErrors([]);
            // add files as attachments (with base64 body)
            const processedFiles = files.map((file) => file.raw);
            params['fileList'] = processedFiles;
            api.support
                .saveSupportRequest(params)
                .then((response) => {
                    dispatch(loaderActions.setShowLoader(false));
                    dispatch(openToast(TYPE_SUCCESS, t('toast:support_success')));
                })
                .catch((error) => {
                    dispatch(loaderActions.setShowLoader(false));
                    showApiError(error.response, dispatch, t);
                });
        } else {
            const errors = validation.getErrors();
            setValidationErrors(errors);
        }
    };

    const validateSupportTicket = (params) => {
        let rulesArray = [ruleNames.supportDescription, ruleNames.supportSubject];
        let rules = getValidationRules(rulesArray);

        return validationService(params, rules);
    };

    const renderTelephoneSection = () => {
        // const showWithLink = viewport === ViewPorts.Tablet || viewport === ViewPorts.Mobile;
        const plainText = <>{Constants.BUSINESS.CUSTOMER_SUPPORT_PHONE_NUMBER}</>;
        const linkView = <a href={`tel:${Constants.BUSINESS.CUSTOMER_SUPPORT_PHONE_NUMBER}`}>{plainText}</a>;
        return linkView;
    };

    const renderOverlayButtons = (section: string) => {
        const buttons = (
            <>
                <Button type={ButtonType.secondary} className='mr-5' onClick={toogleSendMessagePopup}>
                    {t('support.buttons.cancel')}
                </Button>
                <Button onClick={createSupportTicket}>{t('support.buttons.send')}</Button>
            </>
        );

        const mobile = <div className='overlay-buttons-mobile support-overlay-buttons'>{buttons}</div>;

        let view;
        if (viewport === ViewPorts.Mobile && section === ViewSections.Mobile) {
            view = mobile;
        }
        if ((viewport === ViewPorts.Desktop || viewport === ViewPorts.Tablet) && section === ViewSections.Desktop) {
            view = buttons;
        }

        return view;
    };

    // for now used as required
    const handleFileDrop = () => {
        return Promise.resolve();
    };

    const getProcessedFiles = (files) => {
        setFiles(files);
    };

    return (
        <Page pageTheme={PageTheme.light} title={t('support.title')} intro=''>
            <p className='mb-6 font-bold'>{t('support.small_title')}</p>
            <p className='mb-6'>{t('support.description')}</p>

            <div className='support-sections'>
                <div className='info-item support-section'>
                    <div className='info-item__left support-image'>
                        <img src={PhoneSupportImage} alt='' />
                    </div>
                    <div className='info-item__right'>
                        <h3 className='info-item__header'>{t('support.hotline.title')}</h3>
                        <p className='mb__xxs'>{t('support.hotline.text_1')}</p>
                        <p>{renderTelephoneSection()}</p>
                    </div>
                </div>

                <div className='info-item support-section'>
                    <div className='info-item__left support-image'>
                        <img src={EmailImage} alt='' />
                    </div>
                    <div className='info-item__right'>
                        <h3 className='info-item__header'>{t('support.email.title')}</h3>
                        <p className='mb__xxs'>{t('support.email.text')}</p>
                        <div className='button-container'>
                            <a href={`mailto:${t('common:customer_support.send_message_email')}`}>
                                {t('common:customer_support.send_message_email')}
                            </a>

                            {/* This Button is hidden due to the ticket request BAN-9793 */}
                            <Button className='invisible' onClick={toogleSendMessagePopup}>
                                {t('common:buttons.send_message')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {overlay && (
                <ContactFormOverlay onClick={toogleSendMessagePopup}>
                    <div className='support_overlay_main'>
                        <h1 className='headline mb__m'>{t('support:message_popup.title')}</h1>
                        <p className='text mb__l'>{t('support:message_popup.text')}</p>
                        {loadingTopicsMessage && <span className='topics_loading'>{loadingTopicsMessage}</span>}
                        <div className='support_overlay_wrapper'>
                            <Dropdown
                                id='support_subject'
                                hasValidationError={hasValidationError(ruleNames.supportSubject, validationErrors)}
                                validationErrorLabel={validationErrorTranslation(
                                    ruleNames.supportSubject,
                                    validationErrors,
                                    t
                                )}
                                label={t('common:labels.select_support_topic')}
                                options={supportTopics}
                                placeholder={t('common:placeholder.support_topic')}
                                selected={support.subject}
                                onSelect={(selected) => updateSupportSubject(selected)}
                            />
                            <Input
                                id='support_description'
                                inputClass='input--long textarea--start mb__m'
                                label={t('common:labels.support_message')}
                                value={support.description}
                                type={InputType.TEXT}
                                mandatory
                                multiline={true}
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    updateSupportDescription(e.currentTarget.value)
                                }
                                hasValidationError={hasValidationError(ruleNames.supportDescription, validationErrors)}
                                validationErrorLabel={validationErrorTranslation(
                                    ruleNames.supportDescription,
                                    validationErrors,
                                    t
                                )}
                                placeholder={t('support.placeholders.textarea')}
                                containerClass='input__container'
                            />
                            <div className='support_overlay_upload mb__xl'>
                                <div className='input__label mb__xs'>{t('support.files.title')}</div>
                                <FilesUploadCard
                                    description='file'
                                    onDrop={handleFileDrop}
                                    onGetFiles={getProcessedFiles}
                                    isCustomDrop={false}
                                    isMultipleFiles={true}
                                />
                            </div>

                            <div className='overlay__button-container support-overlay-buttons'>
                                {renderOverlayButtons(ViewSections.Desktop)}
                            </div>
                        </div>
                    </div>
                    {renderOverlayButtons(ViewSections.Mobile)}
                </ContactFormOverlay>
            )}
        </Page>
    );
}

export default Support;
