import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import React, { useState } from 'react';
import Label from './Label';
import Icon, { iconTypes } from './Icon';

type Props = {
    className?: string;
    id: string;
    value: string;
    onChange: (date: string) => void;
    placeholder?: string;
    hasValidationError?: boolean;
    validationErrorLabel?: string;
    label?: string;
    labelClass?: string;
    restProps?: object;
    isOutsideRange?: any;
    showDefaultInputIcon?: boolean;
    displayFormat: () => string;
    isWeekendAllowed?: boolean;
    disabled?: boolean;
};

function DatePicker(props: Props) {
    const [focus, setFocus] = useState(false);

    const {
        id = '',
        value = '',
        onChange = (date: string) => {},
        placeholder = '',
        hasValidationError = false,
        validationErrorLabel = '',
        label = '',
        labelClass = '',
        showDefaultInputIcon = true,
        isWeekendAllowed = false,
        ...restProps
    } = props;

    const setDate = (date) => {
        if (date) onChange(date.toLocaleString());
        else onChange(date);

        setFocus(false);
    };

    const isWeekend = (day) => {
        if (moment.weekdays(day.weekday()) === 'Freitag' || moment.weekdays(day.weekday()) === 'Friday') {
            return true;
        }
        if (moment.weekdays(day.weekday()) === 'Samstag' || moment.weekdays(day.weekday()) === 'Saturday') {
            return true;
        }
    };

    return (
        <>
            <Label
                type={hasValidationError ? 'error' : 'default'}
                errorLabel={validationErrorLabel}
                className={labelClass}
            >
                {label}
            </Label>

            <div className='date-picker' id={id}>
                <SingleDatePicker
                    date={value ? moment(value) : null} // momentPropTypes.momentObj or null
                    onDateChange={(date) => setDate(date)}
                    focused={focus} // PropTypes.bool
                    onFocusChange={({ focused }) => setFocus(focused)} // PropTypes.func.isRequired
                    id='date-input' // PropTypes.string.isRequired,
                    placeholder={placeholder}
                    customInputIcon={showDefaultInputIcon ? '' : <Icon name={iconTypes.calendar} size={18} />}
                    noBorder={true}
                    block={true}
                    numberOfMonths={1}
                    hideKeyboardShortcutsPanel={true}
                    firstDayOfWeek={1}
                    enableOutsideDays={true}
                    isDayBlocked={(day) => (!isWeekendAllowed ? isWeekend(day) : false)}
                    {...restProps}
                />
            </div>
        </>
    );
}

export default DatePicker;
