import AESencryption from 'crypto-js/aes'
import UTF8encryption from 'crypto-js/enc-utf8'
import HMACencryption from 'crypto-js/hmac-sha3'

export const concealMessageDeterministic = (message) => {
    return HMACencryption(message, "234IASDFIJ23");
};

export const concealObjectNonDeterministic = (object, asJson = true) => {
    const toEncode = asJson ? JSON.stringify(object) : object;
    return AESencryption.encrypt(toEncode, 'AHS383SIA38SHSHD28BMC').toString();
};

export const recoverObjectNonDeterministic = (string, asJson = true) => {
    let data = AESencryption.decrypt(string, 'AHS383SIA38SHSHD28BMC');
    const recovered = data.toString(UTF8encryption);
    return asJson ? JSON.parse(recovered) : recovered;
}

