import { CarChargingImage, InstallerDoneImage } from 'constants/images';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ButtonType } from 'typeScript/types/ButtonTypes';
import api from '../../api/api';
import { Button, ImageUploadCard, Page } from '../../components';
import { routePaths } from '../../route_paths';
import { IRootState } from '../../store';
import loaderActions from '../../store/loader';
import { setPreviousNavRoute } from '../../store/nav';
import { ILoggedUser } from '../../typeScript/interfaces/IUser';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { showApiError } from '../../utils/error-handler';
import { useIsMounted } from '../../utils/hooks';
interface ImageObj {
    Id: string;
}

function InstallationAssistant(props) {
    const { t } = useTranslation(['assistant']);
    const [step, setStep] = useState(1);
    const [distributionBoardImage, setDistributionBoardImage] = useState<string>();
    const [distributionBoardFuseImage, setDistributionBoardFuseImage] = useState<string>();
    const [garageInsideImage, setGarageInsideImage] = useState<string>();
    const [garageOutsideImage, setGarageOutsideImage] = useState<string>();
    const [distanceGarageHouseImage, setDistanceGarageHouseImage] = useState<string>();
    const [extraImage1, setExtraImage1] = useState<string>();
    const [extraImage2, setExtraImage2] = useState<string>();

    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);
    const user = useSelector<IRootState, ILoggedUser | null>((state) => state.user.user);

    const dispatch = useDispatch();

    const history = useHistory();
    const isMounted = useIsMounted();

    useEffect(() => {
        dispatch(setPreviousNavRoute(routePaths.portalOverview));
        // eslint-disable-next-line
    }, [history]);

    useEffect(() => {
        if (isLogged) {
            history.replace(routePaths.portalPhotoUpload, { step: step });

            api.installation
                .getUploadedInstallationPhotos()
                .then((response) => {
                    if (isMounted.current) {
                        dispatch(loaderActions.setShowLoader(false));

                        let distributionBoardObj: ImageObj | null = null;
                        let distributionBoardFuseObj: ImageObj | null = null;
                        let garageInsideObj: ImageObj | null = null;
                        let garageOutsideObj: ImageObj | null = null;
                        let distanceObj: ImageObj | null = null;
                        let extraImage1Obj: ImageObj | null = null;
                        let extraImage2Obj: ImageObj | null = null;

                        response.data.forEach((imageObj) => {
                            if (imageObj.Description === 'distribution_board') {
                                distributionBoardObj = imageObj;
                            } else if (imageObj.Description === 'distribution_board_fuse') {
                                distributionBoardFuseObj = imageObj;
                            } else if (imageObj.Description === 'garage_inside') {
                                garageInsideObj = imageObj;
                            } else if (imageObj.Description === 'garage_outside') {
                                garageOutsideObj = imageObj;
                            } else if (imageObj.Description === 'distance_house_garage') {
                                distanceObj = imageObj;
                            } else if (imageObj.Description === 'extra_image_1') {
                                extraImage1Obj = imageObj;
                            } else if (imageObj.Description === 'extra_image_2') {
                                extraImage2Obj = imageObj;
                            }
                        });

                        let imagesToSearch: ImageObj[] = [
                            distributionBoardObj!,
                            distributionBoardFuseObj!,
                            garageInsideObj!,
                            garageOutsideObj!,
                            distanceObj!,
                            extraImage1Obj!,
                            extraImage2Obj!
                        ];

                        imagesToSearch.forEach((responseObj) => {
                            if (responseObj) {
                                api.installation.getUploadedInstallationPhoto(responseObj.Id).then((imageRes) => {
                                    if (isMounted.current) {
                                        const imageObj = imageRes.data;

                                        const strSrc = 'data:image/' + imageObj.extension + ';base64,' + imageObj.body;
                                        if (distributionBoardObj && imageObj.id === distributionBoardObj.Id) {
                                            setDistributionBoardImage(strSrc);
                                        } else if (
                                            distributionBoardFuseObj &&
                                            imageObj.id === distributionBoardFuseObj.Id
                                        ) {
                                            setGarageInsideImage(strSrc);
                                        } else if (garageInsideObj && imageObj.id === garageInsideObj.Id) {
                                            setDistributionBoardFuseImage(strSrc);
                                        } else if (garageOutsideObj && imageObj.id === garageOutsideObj.Id) {
                                            setGarageOutsideImage(strSrc);
                                        } else if (distanceObj && imageObj.id === distanceObj.Id) {
                                            setDistanceGarageHouseImage(strSrc);
                                        } else if (extraImage1Obj && imageObj.id === extraImage1Obj.Id) {
                                            setExtraImage1(strSrc);
                                        } else if (extraImage2Obj && imageObj.id === extraImage2Obj.Id) {
                                            setExtraImage2(strSrc);
                                        }
                                    }
                                });
                            }
                        });
                    }
                })
                .catch((error) => {
                    showApiError(error.response, dispatch, t);
                });
        }
        // eslint-disable-next-line
    }, [isLogged]);

    const goToNextStep = () => {
        setStep(step + 1);
        history.push({
            pathname: routePaths.portalPhotoUpload,
            state: { step: step + 1 }
        });
    };

    const renderIntro = () => {
        return (
            <Page pageTheme={PageTheme.light}>
                <div className='image-page__left image-page__left--narrow image-page__left--white'>
                    <h2 className='mb-5'>{t('intro.title')}</h2>
                    <p className='mb-12'>{t('intro.text')}</p>
                    <Button onClick={goToNextStep}>{t('intro.button')}</Button>
                </div>

                <div className='image-page__right image-page__right--narrow'>
                    <img src={CarChargingImage} alt='' />
                </div>
            </Page>
        );
    };

    const handleFileDrop = (file, type, description: string, extension: string) => {
        return api.installation
            .uploadInstallationPhoto({
                body: file,
                type,
                description,
                extension
            })
            .then((res) => {
                return api.installation.getUploadedInstallationPhoto(res.data.Id).then((res) => {
                    if (isMounted.current) {
                        const imageObj = res.data;

                        const strSrc = 'data:image/' + imageObj.extension + ';base64,' + imageObj.body;
                        if (description === 'distribution_board') {
                            setDistributionBoardImage(strSrc);
                        } else if (description === 'distribution_board_fuse') {
                            setGarageInsideImage(strSrc);
                        } else if (description === 'garage_inside') {
                            setDistributionBoardFuseImage(strSrc);
                        } else if (description === 'garage_outside') {
                            setGarageOutsideImage(strSrc);
                        } else if (description === 'distance_house_garage') {
                            setDistanceGarageHouseImage(strSrc);
                        } else if (description === 'extra_image_1') {
                            setExtraImage1(strSrc);
                        } else if (description === 'extra_image_2') {
                            setExtraImage2(strSrc);
                        }
                    }
                });
            })
            .catch((error) => {
                showApiError(error.response, dispatch, t);
            });
    };

    const enableNextButton =
        distributionBoardImage ||
        garageInsideImage ||
        distributionBoardFuseImage ||
        garageOutsideImage ||
        distanceGarageHouseImage ||
        extraImage1 ||
        extraImage2;

    const renderPhotoUploadPage = () => {
        return (
            <Page pageTheme={PageTheme.light}>
                <div className='assistant__uploads__text assistant__uploads__text--white'>
                    <h2 className='mb-5'>{t('photo_upload.title')}</h2>
                    <p className='mb-12'>{t('photo_upload.text')}</p>
                </div>

                <div className='assistant__uploads__photos'>
                    <div className='image-upload__wrapper image-upload__wrapper--assistant'>
                        <ImageUploadCard
                            className='image-upload__wrapper--assistant'
                            imageSrc={distributionBoardImage}
                            description='distribution_board'
                            onDrop={handleFileDrop}
                        />
                        <p className='image-upload__sub-text'>{t('photo_upload.distribution_board')}</p>
                    </div>
                    <div className='image-upload__wrapper image-upload__wrapper--assistant'>
                        <ImageUploadCard
                            className='image-upload__wrapper--assistant'
                            imageSrc={garageInsideImage}
                            description='distribution_board_fuse'
                            onDrop={handleFileDrop}
                        />
                        <p className='image-upload__sub-text'>{t('photo_upload.distribution_board_fuse')}</p>
                    </div>
                    <div className='image-upload__wrapper image-upload__wrapper--assistant'>
                        <ImageUploadCard
                            className='image-upload__wrapper--assistant'
                            imageSrc={distributionBoardFuseImage}
                            description='garage_inside'
                            onDrop={handleFileDrop}
                        />
                        <p className='image-upload__sub-text'>{t('photo_upload.garage_inside')}</p>
                    </div>
                    <div className='image-upload__wrapper image-upload__wrapper--assistant'>
                        <ImageUploadCard
                            className='image-upload__wrapper--assistant'
                            imageSrc={garageOutsideImage}
                            description='garage_outside'
                            onDrop={handleFileDrop}
                        />
                        <p className='image-upload__sub-text'>{t('photo_upload.garage_outside')}</p>
                    </div>
                    <div className='image-upload__wrapper image-upload__wrapper--assistant'>
                        <ImageUploadCard
                            className='image-upload__wrapper--assistant'
                            imageSrc={distanceGarageHouseImage}
                            description='distance_house_garage'
                            onDrop={handleFileDrop}
                        />
                        <p className='image-upload__sub-text'>{t('photo_upload.distance_house_garage')}</p>
                    </div>
                    {/*Change images for these two uploaders.*/}
                    <div className='image-upload__wrapper image-upload__wrapper--assistant'>
                        <ImageUploadCard
                            className='image-upload__wrapper--assistant'
                            imageSrc={extraImage1}
                            description='extra_image_1'
                            onDrop={handleFileDrop}
                        />
                        <p className='image-upload__sub-text'>{t('photo_upload.extra_image', { number: 1 })}</p>
                    </div>
                    <div className='image-upload__wrapper image-upload__wrapper--assistant'>
                        <ImageUploadCard
                            className='image-upload__wrapper--assistant'
                            imageSrc={extraImage2}
                            description='extra_image_2'
                            onDrop={handleFileDrop}
                        />
                        <p className='image-upload__sub-text'>{t('photo_upload.extra_image', { number: 2 })}</p>
                    </div>
                </div>

                <div className='buttons'>
                    <Button type={ButtonType.secondary} onClick={() => setStep(1)}>
                        {t('common:buttons.back')}
                    </Button>
                    <Button disabled={!enableNextButton} onClick={goToNextStep}>
                        {t('common:buttons.save')}
                    </Button>
                </div>
            </Page>
        );
    };

    const renderEndingPage = () => {
        return (
            <Page pageTheme={PageTheme.light}>
                <div className='image-page__left image-page__left--narrow image-page__left--white'>
                    {/* EXCEPTION::: h2+a actually in pageTheme but here outside for float container */}
                    <h2 className='mb-5'>{t('end.title', { name: user && user.name })}</h2>
                    <p className='mb-12'>{t('end.text')}</p>
                    <Link to={routePaths.portalOverview} className='image-page__left--narrow__button'>
                        <Button>{t('common:buttons.dashboard')}</Button>
                    </Link>
                </div>

                <div className='image-page__right image-page__right--narrow image-page__right--top'>
                    <img src={InstallerDoneImage} alt='' />
                </div>
            </Page>
        );
    };

    switch (step) {
        case 1:
            return renderIntro();
        case 2:
            return renderPhotoUploadPage();
        case 3:
            return renderEndingPage();
    }
}

export default InstallationAssistant;
