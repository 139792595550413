import { GarageImage } from 'constants/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, DisplayCard } from '.';
import { DisplayCardTypes } from './DisplayCard';
import { ButtonType } from '../typeScript/types/ButtonTypes';

export interface PhotoUploadCardProp {
    className?: string;
    navigateUrl: string;
}

export default function PhotoUploadCard(props: PhotoUploadCardProp) {
    const { className, navigateUrl } = props;

    const { t } = useTranslation(['home']);

    return (
        <DisplayCard type={DisplayCardTypes.IMAGE_LEFT} className={`${className}`} src={GarageImage}>
            <h3 className='mb__s'>{t('upload_photos.title')}</h3>
            <p className='mb__m'>{t('upload_photos.text')}</p>
            <div className='display-card__buttons'>
                <Link to={navigateUrl} className='display-card__button'>
                    <Button type={ButtonType.secondary}>{t('upload_photos.button')}</Button>
                </Link>
            </div>
        </DisplayCard>
    );
}
