export default {
    units: {
        kwh: 'kWh'
    },
    buttons: {
        edit: 'Bearbeiten',
        continue: 'Weiter',
        confirm: 'Bestätigen',
        back: 'Zurück',
        log_into: 'Bei myUnivers anmelden',
        check_out: 'Zur Bestellung',
        save: 'Speichern',
        select: 'Auswählen',
        selected: 'Ausgewählt',
        getStarted: 'Starten',
        next: 'Weiter',
        download: 'Download',
        downloading: 'wird heruntergeladen',
        downloadingError: 'Fehler',
        yes: 'Ja',
        no: 'Nein',
        attached: 'Direkt am Haus',
        detached: 'Nicht direkt am Haus',
        more: 'Mehr',
        less: 'Weniger',
        dashboard: 'Zurück zum Dashboard',
        logout: 'Abmelden',
        login: 'Zum Login',
        order_now: 'Jetzt bestellen',
        pdf: 'PDF',
        go_to_contracts: 'Zu den Verträgen',
        submit: 'Absenden',
        promotion: 'Einlösen',
        continue_to_configuration: 'Weiter zur Konfiguration',
        change: 'Ändern',
        send_message: 'Nachricht schreiben',
        cancel: 'Abbrechen',
        start: 'Start',
        go_to_documents: 'Zu den Dokumenten',
        make_an_appointment: 'Jetzt vereinbaren',
        add_now: 'Jetzt hinterlegen',
        cookie_confirm_technical: 'Nur benötigte Cookies',
        cookie_confirm_all: 'Alle akzeptieren',

    },
    validation: {
        first_name_required: 'Vorname erforderlich',
        last_name_required: 'Nachname erforderlich',
        name_min_length: 'Vorname ist zu kurz (mindestens 2 Zeichen)',
        surname_min_length: 'Nachname ist zu kurz (mindestens 2 Zeichen)',
        email_required: 'E-Mail-Adresse erforderlich',
        email_invalid: 'E-Mail-Adresse ungültig',
        phone_no_required: 'Telefonnummer erforderlich',
        contact_method: 'Bevorzugter Kontakt erforderlich',
        company_name_required: 'unternehmen_name_erforderlich',
        company_type_required: 'Unternehmenstyp_erforderlich',
        birthday_invalid_date: 'Ungültiges Datum',
        password_required: 'Kennwort erforderlich',
        password_min_length: 'Das Passwort ist zu kurz (mindestens 8 Zeichen)',
        password_format: 'Das Passwort muss eine Zahl, ein Symbol und einen Klein- und Großbuchstaben enthalten',
        password_confirmation_mismatch: 'Passwörter stimmen nicht überein',
        street_address_required: 'Straßenname erforderlich',
        street_no_required: 'Hausnummer erforderlich',
        street_no_invalid: 'Hausnummer ungültig',
        postal_code_required: 'Postleitzahl erforderlich',
        city_required: 'Stadt erforderlich',
        state_required: 'Region erforderlich',
        country_required: 'Land erforderlich',
        installation_invalid_date: 'Gewünschtes Installationsdatum erforderlich',
        installation_message_toolong: 'Ihre Nachricht is leider zu lange',
        account_holder_required: 'konto_inhaber_erforderlich',
        bank_name_required: 'Name der Bank erforderlich',
        iban_required: 'IBAN erforderlich',
        bic_required: 'BIC erforderlich',
        ccv_required: 'CVV erforderlich',
        card_holder_required: 'Karteninhaber erforderlich',
        credit_card_no_required: 'Kreditkartennummer erforderlich',
        expiry_date_required: 'Ablaufdatum erforderlich',
        terms_not_accepted: 'Bedingungen nichtakzeptiert',
        referral_code: 'Der Referral Code funktioniert leider nicht, bitte probieren Sie einen anderen',
        salutation_required: 'Andrede erforderlich',
        support_subject_required: 'Thema erforderlich',
        support_description_required: 'Nachricht erforderlich',
        language_required: 'Sprache erforderlich',
        charger_name_min_max_range: 'Der Name muss zwischen 1 und 50 Zeichen lang sein.',
        charger_name_required: 'Chargername muss mindestens ein Zeichen enthalten',
        charger_name_min_length: 'Der Name des Ladegeräts ist zu kurz (mindestens 1 Zeichen).',
        company_code: 'Ungültiger Code',
        termination_reason_required: 'Kündigungsgrund erforderlich',
        termination_reason_min_length: 'Kündigungsgrund ist zu kurz (mindestens 2 Zeichen)',
        termination_type_required: 'Art der Kündigung erforderlich',
        customer_number_required: 'Kundennummer erforderlich',
        customer_number_min_length: 'Kundennummer ist zu kurz (mindestens 2 Zeichen)',
        contract_number_required: 'Vertragsnummer erforderlich',
        contract_number_min_length: 'Vertragsnummer ist zu kurz (mindestens 2 Zeichen)',
        termination_date_decision_required: 'Bitte wählen Sie',
        termination_date_required: 'Gewünschtes Datum erforderlich',
        payout_invalid_start_date: 'Das Datum muss in der Zukunft liegen',
        payout_start_date_required: 'Gültigkeitsbeginn muss in der Zukunft liegen',
        payout_household_tariff_required: 'Tarif (Bruttoarbeitspreis) in Cent, max. 8 Dezimalen',
        payout_energyContract_required: 'Tarif Vertrag erforderlich (pdf, jpeg, png)',
        payout_termsAndConditionsAccepted_required: 'Bedingungen nichtakzeptiert',
        bank_account_firstName_required: 'Kontoinhaber erforderlich',
        bank_account_lastName_required: 'Kontoinhaber erforderlich',
        employeeCostUnit_required: 'Kostenstelle erforderlich',
        employeeId_required: 'ID erforderlich',
        vehicleRegistrationNumber_required: 'Überprüfen Sie Ihr Kennzeichen',
        vehicleRegistrationNumberPart1_required: 'I',
        vehicleRegistrationNumberPart2_required: 'II',
        vehicleRegistrationNumberPart3_required: 'III',
        vehicleRegistrationNumberPart4_required: 'IV',
        renewableEnergy_required: 'Erneuerbare Energie erforderlich',
    },
    labels: {
        total_price: 'Gesamtpreis',
        name: 'Name',
        first_name: 'Vorname',
        last_name: 'Nachname',
        email_initial: 'E-Mail Adresse',
        email: 'E-mail Adresse',
        your_email: 'Ihre E-Mail Adresse',
        phone: 'Telefonnummer',
        birthday: 'Geburtstag',
        birthdate: 'Geburtsdatum',
        vat_no: 'Umsatzsteuernummer (optional)',
        set_password: 'Passwort erstellen',
        your_password: 'Ihr Passwort',
        confirm_password: 'Passwort bestätigen',
        installation_address: 'Installationsadresse',
        street_address: 'Straßenname',
        street_no: 'Hausnummer',
        postal_code: 'PLZ',
        postal_code_full: 'Postleitzahl',
        city: 'Stadt',
        state: 'Region',
        country: 'Land',
        country_default: 'Deutschland',
        country_code_default: 'DE',
        install_date: 'Pre-Visit oder Installationsdatum',
        contact_method: 'Bevorzugter Kontakt',
        message_installer: 'Ihre Nachricht an uns (optional)',
        billing_address_match: 'Die Rechnungsadresse entspricht der Installationsadresse',
        billing_address: 'Rechnungsadresse',
        payment_method: 'Zahlungsmethode',
        payment_debit: 'Lastschriftverfahren',
        payment_unknown: 'Unbekannte Karte',
        direct_debit: 'Giropay',
        payment_credit: 'Kreditkarte',
        account_holder: 'Kontoinhaber',
        bank_name: 'Name der Bank',
        iban: 'IBAN',
        bic: 'BIC',
        card_holder: 'Karteninhaber',
        card_holder_first_name: 'Card Holder First Name',
        card_holder_last_name: 'Card Holder Last Name',
        credit_card_no: 'Kreditkartennummer',
        expiry_date: 'Ablaufdatum',
        ccv: 'CCV',
        referred_you: 'Wer hat Sie verwiesen?',
        promotion_offers:
            'Ja, halten Sie mich über Neuigkeiten, Aktionen und Gutscheine auf dem Laufenden. Abmeldung jederzeit möglich.',
        password_new: 'Ihr neues Passwort',
        password_old: 'Ihr altes Passwort',
        your_answers: 'Ihre Antworten',
        salutation: 'Anrede',
        language: 'Sprache',
        select_support_topic: 'Wählen Sie ein Thema',
        support_message: 'Ihre Nachricht',
        install_date2: 'Datum',
        termination_type: 'Art der Kündigung',
        termination_type_ordinary: 'Ordentlich',
        termination_type_extra_ordinary: 'Außerordentlich',
        termination_reason: 'Kündigungsgrund',
        customer_number: 'Kundennummer',
        contract_number: 'Vertragsnummer',
        termination_comment: 'Kommentar',
        termination_date: 'Wann wollen Sie kündigen',
        termination_date_decision_1: 'Zum nächstmöglichen Zeitpunkt',
        termination_date_decision_2: 'Zu einem bestimmten Datum',
        payout_startDate: 'Gültigkeitsbeginn des Ladetarifs',
        payout_energyPrice: 'Tarif (Bruttoarbeitspreis) in Cent',
        payout_energyContract: 'Nachweisdokument (z.B. Energievertrag, max. Größe {{maxFileSize}}MB)',
        iban_owner_name: 'Inhaber des Kontos',
        employeeCostUnit: 'Kostenstelle',
        employeeId: 'Arbeitnehmer ID',
        vehicleRegistrationNumber: 'Auto-Kennzeichen',
        vehicleRegistrationNumberPart1: '',
        vehicleRegistrationNumberPart2: '',
        vehicleRegistrationNumberPart3: '',
        vehicleRegistrationNumberPart4: '',
        renewableEnergy: 'Strom aus erneuerbaren Energien',
    },
    placeholder: {
        first_name: 'Vorname',
        last_name: 'Nachname',
        email: 'ihre@email.de',
        email_address: 'E-Mail Adresse',
        password: 'Passwort',
        phone: 'Telefonnummer',
        vat_no: 'Umsatzsteuernummer',
        street_address: 'Straßenname',
        street_no: 'Hausnummer',
        postal_code: 'PLZ',
        city: 'Stadt',
        country: 'Land',
        state: 'Region',
        install_date: 'Datum',
        contact_method: 'Kontaktmethode',
        message_installer: 'Nachricht',
        account_holder: 'Name des Kontoinhabers',
        bank_name: 'Name der Bank',
        iban: 'IBAN',
        bic: 'BIC',
        card_holder: 'Karteninhaber',
        card_no: 'Kreditkartennummer',
        expiry_date: 'Ablaufdatum',
        ccv: 'CCV',
        referral_code: 'xx-xx-xx-xx',
        salutation: 'Anrede',
        language: 'Sprache',
        support_topic: 'Bitte Wählen Sie ein Thema',
        support_input: 'Schreiben Sie Ihre Nachricht hier...',
        termination_type: 'Art der Kündigung',
        termination_reason: 'Kündigungsgrund',
        customer_number: 'Kundennummer',
        contract_number: 'Vertragsnummer',
        termination_comment: 'Kommentar',
        termination_date: 'Wann wollen Sie kündigen',
        payout_startDate: 'XX.XX.XXXX',
        payout_energyPrice: 'xx,xx',
        payout_energyContract: 'Ihr Vertrag als PDF',
        iban_owner_name: 'Inhaber des Kontos',
        employeeCostUnit: 'Kostenstelle',
        employeeId: 'Arbeitnehmer ID',
        vehicleRegistrationNumber: '',
        vehicleRegistrationNumberPart1: 'AA',
        vehicleRegistrationNumberPart2: 'AA',
        vehicleRegistrationNumberPart3: '00',
        vehicleRegistrationNumberPart4: 'E',
        renewableEnergy: 'Tanken Sie Strom aus erneuerbaren Energien?',
    },
    cookie: {
        cookie_consent_intro:
            'Wir verwenden Cookies, um Ihnen eine gute User Experience zu bieten. Dazu gehören sowohl Cookies, die für den Betrieb der Website notwendig sind, als auch Cookies, die nur für anonyme statistische Zwecke verwendet werden. Sie können selbst entscheiden, welche Kategorien Sie zulassen möchten. Weitere Informationen finden Sie in unserer Datenschutzerklärung.'
    },
    customer_support: {
        send_message: 'Senden Sie uns eine Nachricht:',
        send_message_email: 'kundenservice@univers.de',
        call_phone: 'Oder melden Sie sich telefonisch:',
        call_phone_number: '+49 800 4302255'
    },
    language: {
        english: 'Englisch',
        german: 'Deutsch'
    },
};


const commonNissan = {
    placeholder: {
        message_installer: 'Bitte teilen Sie uns besondere Wünsche mit (z.B. Interesse an Ökostrom).',
    }
};

export { commonNissan };
