export default {
    overview: 'Übersicht',
    personal_info: 'Persönliche Informationen',
    install_details: 'Details zur Installation',
    payment_options: 'Zahlungsoptionen',
    confirmation: 'Bestätigung',
    shopping_cart: 'Warenkorb',
    charger: 'Charger',
    tariff_package: 'Tarifpaket',
    installation_package: 'Installationspaket',
    terms: 'AGB',
    privacy: 'Datenschutz',
    imprint: 'Impressum',
    revokeCookie: 'Cookie-Zustimmung',
    cancel: 'Abbrechen',
    billing: 'Rechnung',
    contract: 'Vertrag',
    support: 'Kundenservice',
    log_out: 'Abmelden',
    basket: 'Ihr Warenkorb',
    fqa: 'FAQ',
    back: 'Zurück',
    back_to_start: 'Zurück zum Start',
    documents: 'Dokumente',
    my_profile: 'Mein Profil',
    my_payment: 'Zahlung',
    tariff: 'Vertrag',
    payout: 'Rückvergütung',
    fleetData: 'Flottendaten',
    profile: 'Profil',
    chargingCards: 'Ladekarten',
}