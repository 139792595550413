import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import App from './App';
import {persistor, store} from "./store";
import {PersistGate} from 'redux-persist/integration/react'


ReactDOM.render(
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
          </PersistGate>
      </Provider>
  ,
  document.getElementById('root')
);
