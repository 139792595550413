import { DisplayCardTypes } from 'components/DisplayCard';
import PersonalInfoForm from 'components/PersonalInfoForm';
import { TYPE_SUCCESS } from 'components/Toast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import api from '../../api/api';
import { Button, DisplayCard, InnerSpinner, Input, Page } from '../../components';
import InputType from '../../constants/inputType';
import { openToast } from '../../store/toast';
import { ButtonType } from '../../typeScript/types/ButtonTypes';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { showApiError } from '../../utils/error-handler';
import {
    getValidationRules,
    hasValidationError,
    ruleNames,
    validationErrorTranslation,
    validationService
} from '../../validation';

function Profile() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [passwordDataLoading, setPasswordDataLoading] = useState(false);
    const [isPasswordReadable, setIsPasswordReadable] = useState(true);

    const [userInfo, setUserInfo] = useState({
        newPassword: '',
        oldPassword: '',
        passwordConfirmation: ''
    });

    const [validationErrors, setValidationErrors] = useState<any[]>([]);

    const onInputChange = (field, value) => {
        setUserInfo((state) => {
            return {
                ...state,
                [field]: value
            };
        });
    };

    const renderPassword = () => {
        return (
            <>
                {isPasswordReadable ? (
                    <p>{t('common:placeholder.password')}: XXXXXXXXXXX</p>
                ) : (
                    <>
                        <Input
                            id='passwordOld'
                            label={t('common:labels.password_old')}
                            value={userInfo.oldPassword}
                            type={InputType.PASSWORD}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                onInputChange('oldPassword', e.currentTarget.value)
                            }
                            hasValidationError={hasValidationError(ruleNames.oldPassword, validationErrors)}
                            validationErrorLabel={validationErrorTranslation(
                                ruleNames.oldPassword,
                                validationErrors,
                                t
                            )}
                            containerClass='business-input-container__col-md-4 business-input-container__col-sm-8 mb__ml'
                        />

                        <div className='checkout__password_policy mb__ml'>
                            <p className='checkout__password_policy__text'>{t('checkout:password_policy')}</p>
                        </div>

                        <div className='business-input-container__row'>
                            <Input
                                id='password'
                                label={t('common:labels.password_new')}
                                type={InputType.PASSWORD}
                                value={userInfo.newPassword}
                                labelClass='input__label input__label--long'
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    onInputChange('newPassword', e.currentTarget.value)
                                }
                                hasValidationError={hasValidationError(ruleNames.newPassword, validationErrors)}
                                validationErrorLabel={validationErrorTranslation(
                                    ruleNames.newPassword,
                                    validationErrors,
                                    t
                                )}
                                containerClass='business-input-container__col-md-4 business-input-container__col-sm-8 mb__base'
                            />
                            <Input
                                id='passwordConfirmation'
                                label={t('common:labels.confirm_password')}
                                value={userInfo.passwordConfirmation}
                                type={InputType.PASSWORD}
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    onInputChange('passwordConfirmation', e.currentTarget.value)
                                }
                                hasValidationError={hasValidationError(
                                    ruleNames.newPasswordConfirmation,
                                    validationErrors
                                )}
                                validationErrorLabel={validationErrorTranslation(
                                    ruleNames.newPasswordConfirmation,
                                    validationErrors,
                                    t
                                )}
                                containerClass='business-input-container__col-md-4 business-input-container__col-sm-8 mb__xl'
                            />
                        </div>
                    </>
                )}

                <div className='buttons'>
                    {isPasswordReadable ? (
                        <Button onClick={() => setIsPasswordReadable(false)}>
                            {t('common:buttons.change')}
                        </Button>
                    ) : (
                        <>
                            <Button type={ButtonType.secondary} onClick={() => setIsPasswordReadable(true)}>
                                {t('common:buttons.cancel')}
                            </Button>
                            <Button buttonType='submit'>
                                {t('common:buttons.save')}
                            </Button>
                        </>
                    )}
                </div>
            </>
        );
    };

    const handlePasswordButton = (event) => {
        if (event) {
            event.preventDefault();
        }

        let validationPassword;
        let rulesPassword;
        let paramsPassword;

        rulesPassword = getValidationRules([
            ruleNames.newPassword,
            ruleNames.oldPassword,
            ruleNames.newPasswordConfirmation
        ]);
        paramsPassword = {
            newPassword: userInfo.newPassword,
            oldPassword: userInfo.oldPassword,
            passwordConfirmation: userInfo.passwordConfirmation
        };

        validationPassword = validationService(paramsPassword, rulesPassword);

        if (paramsPassword && validationPassword.passes()) {
            setValidationErrors([]);
            // Call API to save password
            delete paramsPassword.passwordConfirmation;
            setPasswordDataLoading(true);

            api.user
                .updatePassword(paramsPassword)
                .then((res) => {
                    dispatch(openToast(TYPE_SUCCESS, t('toast:password_updated')));
                    setPasswordDataLoading(false);
                    setIsPasswordReadable(true);
                })
                .catch((error) => {
                    showApiError(error.response, dispatch, t);
                    setPasswordDataLoading(false);
                });
        } else {
            setValidationErrors(validationPassword.getErrors());
        }
    };

    return (
        <Page pageTheme={PageTheme.light}>
            <div className='2xl:w-9/12'>
                <DisplayCard
                    type={DisplayCardTypes.PLAIN}
                    className='mb-6'
                    label={t('businessProfile:profile.title')}
                    intro={t('businessProfile:profile.description')}
                >
                    <PersonalInfoForm />
                </DisplayCard>
                <DisplayCard
                    type={DisplayCardTypes.PLAIN}
                    label={t('businessProfile:password.title')}
                    intro={t('businessProfile:password.description')}
                >
                    <InnerSpinner isActive={passwordDataLoading}>
                        <form onSubmit={handlePasswordButton}>{renderPassword()}</form>
                    </InnerSpinner>
                </DisplayCard>
            </div>
        </Page>
    );
}

export default Profile;
