import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { isIPad } from '../utils/platform';
import { generateRandomInt } from '../utils/numbers';
const FilesUpload = require('../assets/upload/FilesUpload.svg');
const FileIcon = require('../assets/upload/File.png');
const closeIcon = require('../assets/BlackCloseIcon.svg');

const OBJECT_KEY = 'raw';
const DOC_MAX_SIZE = 10485760; // bytes, 10 mb
const PHOTO_MAX_SIZE = 5242880; // bytes, 5 mb
const Extenstions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'gif', 'jpg', 'png', 'jpeg'];
enum Errors {
    NONE = 'none',
    COMMON = 'common',
    FILE_TOO_LARGE = 'file too large'
}
type ErrorType = {
    isError: boolean;
    type: string;
};

function FilesUploadCard(props) {
    const {
        description = '',
        onGetFiles = () => {},
        onDrop = () => {},
        maxFileSize = DOC_MAX_SIZE,
        maxImageSize = PHOTO_MAX_SIZE,
        filesWrapperClass = '',
        isCustomDrop = false,
        isMultipleFiles = false
    } = props;
    const { t } = useTranslation();

    const [files, setFiles] = useState<any[]>([]);

    const [isMobile, setMobile] = useState(false);
    const handleWindowSizeChange = () => {
        // ipad exception to match tablet rules
        setMobile(window.innerWidth < 769 && !isIPad());
    };

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        onGetFiles(files);
        // eslint-disable-next-line
    }, [files]);

    const validateFile = (file) => {
        const fileExtension = getExtension(file.name);
        let extensionCorrect = false;
        for (const ext of Extenstions) {
            if (ext === fileExtension.toLowerCase()) {
                extensionCorrect = true;
                break;
            }
        }

        if (!extensionCorrect) {
            return { isError: true, type: Errors.COMMON };
        }

        if (fileExtension === 'jpg' || fileExtension === 'gif' || fileExtension === 'png' || fileExtension === 'jpeg') {
            if (file.size > maxImageSize) {
                return { isError: true, type: Errors.FILE_TOO_LARGE };
            }
        } else {
            if (file.size > maxFileSize) {
                return { isError: true, type: Errors.FILE_TOO_LARGE };
            }
        }

        return { isError: false, type: Errors.NONE };
    };

    const getExtension = (fileName: string): string => {
        const found = fileName.split('.');
        return found[found.length - 1];
    };

    const handleDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            const promises = await acceptedFiles.map(async (file) => {
                const processed = await processMultiFile(file);
                return processed;
            });
            const completedFiles = await Promise.all(promises);
            setFiles([...files, ...completedFiles]);
        } else {
            acceptedFiles.forEach((file) => {
                processSingleFile(file);
            });
        }
    };

    const processSingleFile = (file) => {
        file['uniqueId'] = createUniqueId(file.name);
        file['progress'] = 0;
        file['error'] = { isError: false, type: Errors.NONE };
        const validation = validateFile(file);
        if (validation.isError) {
            file.error = validation;
            setFiles([...files, file]);
        } else {
            prepareFile(file, true);
        }
    };

    const processMultiFile = async (file) => {
        file['uniqueId'] = createUniqueId(file.name);
        file['progress'] = 0;
        file['error'] = { isError: false, type: Errors.NONE };

        const validation = validateFile(file);
        if (validation.isError) {
            file.error = validation;
            return file;
        }

        const res = await prepareFile(file);
        return res;
    };

    const prepareFile = async (file, isSignleFile = false) => {
        const type = file.type;
        const id = createUniqueId(file.name);
        const extension = getExtension(file.name);
        const base64Str = await fileToBase64(file);
        const base64FileStr = getFileBase64Str(base64Str);

        file.uniqueId = id;
        file.progress = 100;

        const fileObj = {
            type: type,
            extension: extension,
            description: description,
            body: base64FileStr
        };
        file[OBJECT_KEY] = fileObj;

        if (isSignleFile) {
            setFiles([...files, file]);
        }
        return file;
    };

    const createUniqueId = (fileName: string): string => {
        return `${fileName}-${Date.now()}-${generateRandomInt(1, 1000)}`;
    };

    const fileToBase64 = (file): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader['result'] !== null ? reader.result.toString() : '');
            };
            reader.onerror = (error) => {
                file['error'] = { isError: false, type: Errors.COMMON };
                reject(error);
            };
        });
    };

    const getFileBase64Str = (base64Str: string) => {
        const base64FileStr = base64Str.replace('data:', '').replace(/^.+,/, '');
        return base64FileStr;
    };

    const renderErrorMsg = (error: ErrorType) => {
        return error.type === Errors.FILE_TOO_LARGE ? t('upload:error_too_large') : t('upload:error');
    };

    const handleRemoveFile = (fileId: string) => {
        setFiles(files.filter((item) => item.uniqueId !== fileId));
    };

    const uploadLabel = isMobile ? t('upload:browse') : t('upload:drop_files');

    const fileItems = files.map((fileItem) => {
        return (
            <div key={fileItem.uniqueId} className='files-upload__file--success'>
                <div className='files-upload__file__icon'>
                    <img src={FileIcon} alt={t('upload:file_upload')} />
                </div>
                <div className='files-upload__file__progress'>
                    <div className={`files-upload__file__name ${fileItem.error.isError ? 'files-upload--error' : ''}`}>
                        {fileItem.name}
                        {fileItem.error.isError && <span>{renderErrorMsg(fileItem.error)}</span>}
                    </div>
                    <div className='files-upload__file__progressbar'>
                        <span className='files-upload__file__rate'>{`${fileItem.progress}%`}</span>
                        <div className='files-upload__file__bar'>
                            <div
                                style={{ width: `${fileItem.progress}%` }}
                                className={
                                    fileItem.error.isError
                                        ? 'files-upload__file__bar--error'
                                        : fileItem.progress === 100
                                        ? 'files-upload__file__bar--complete'
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='files-upload__file__close'>
                    <img
                        src={closeIcon}
                        onClick={() => {
                            handleRemoveFile(fileItem.uniqueId);
                        }}
                        alt=''
                    />
                </div>
            </div>
        );
    });

    return (
        <>
            <div className={`files-upload mb__s`}>
                <Dropzone onDrop={isCustomDrop ? onDrop : handleDrop} multiple={isMultipleFiles}>
                    {({ getRootProps, getInputProps }) => {
                        return (
                            <div {...getRootProps()} className={'files-upload__container'}>
                                <input {...getInputProps()} />
                                <div
                                    className={`files-upload__dropzone ${files ? '' : 'files-upload__dropzone--show'}`}
                                >
                                    {
                                        <div className='files-upload__droplabel'>
                                            <img src={FilesUpload} alt={t('upload:file_upload')} />
                                            <div>{uploadLabel}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        );
                    }}
                </Dropzone>
            </div>
            {fileItems && fileItems.length > 0 && (
                <div className={`files-upload__files__wrapper${filesWrapperClass}`}>{fileItems}</div>
            )}
        </>
    );
}

export default FilesUploadCard;
