export const SET_SUPPORT_TICKET_SUBJECT = 'SET_SUPPORT_TICKET_SUBJECT';
export const SET_SUPPORT_TICKET_DESCRIPTION = 'SET_SUPPORT_TICKET_DESCRIPTION';
export const RESET_SUPPORT_TICKET = 'RESET_SUPPORT_TICKET';

export function setSupportTicketSubject(value) {
    return {
        type: SET_SUPPORT_TICKET_SUBJECT,
        value
    }
}

export function setSupportTicketDescription(value) {
    return {
        type: SET_SUPPORT_TICKET_DESCRIPTION,
        value
    }
}

export function resetSupportTicket() {
    return {
        type: RESET_SUPPORT_TICKET
    }
}

export default {
	setSupportTicketSubject,
	setSupportTicketDescription,
	resetSupportTicket
}