import installationProgress from './installationProgress';

export default {
    APP_URL_GOOGLE: 'https://play.google.com/store/apps/details?id=com.univers.ev.oma',
    APP_URL_IOS: 'https://apps.apple.com/app/univers-charge/id6499518572',
    SUPPORT_EMAIL: 'kundenservice@univers.de',
    PAYMENT_TYPES: {
        DIRECT_DEBIT: 'direct_debit',
        CREDIT_CARD: 'credit_card'
        // PAYPAL: 'paypal'
    },
    MARKETING_SITE_URLS: {
        PRIVACY_DE: 'https://www.univers.de/de-DE/service/datenschutz',
        PRIVACY_EN: 'https://www.univers.de/de-DE/service/datenschutz',
        IMPRINT_DE: 'https://www.univers.de/de-DE/service/impressum',
        IMPRINT_EN: 'https://www.univers.de/de-DE/service/impressum',
        // CHARGER_DOCUMENTATION_EN: 'https://www.univers.de/en',
        // CHARGER_DOCUMENTATION_DE: 'https://www.univers.de',
        TERMS_DE: 'https://www.univers.de/de-DE/service/agb',
        TERMS_EN: 'https://www.univers.de/de-DE/service/agb',
        FQA_DE: 'https://www.univers.de/de-DE/service/faqs',
        FQA_EN: 'https://www.univers.de/de-DE/service/faqs'
    },
    CUSTOMER_SUPPORT_PHONE_NUMBER: '0800-430 22 55',
    BUSINESS: {
        CUSTOMER_SUPPORT_PHONE_NUMBER: '0800-430 22 55'
    },
    INSTALLATION_PROGRESS: installationProgress,
    CHARGER_22KW_CODE: ['CHARGER-002', 'CHARGER-004'],
    DOWN_PAYMENT_CHARGER_CODE: 'DOWN-002',
    DOWN_PAYMENT_INSTALLATION_CODE: 'DOWN-001',
    VERSION: '1.0.1',
    GTM_ID: 'GTM-KP37JL2',
    REFERRAL_CODES_ARRAY: [
        'formel_e',
        'envision-enos-ff-122020-fl',
        'envision-enos-ff-122020-dn',
        'envision-enos-ff-122020-mf',
        'envision-enos-ff-122020-dm',
        'envision-enos-ff-122020-tk',
        'enos-envision-ff-122020-xx',
        'enos-envision-ff-122020-xy'
    ]
};
