import React from 'react';
// import './Spinner.scss';

export interface InnerSpinnerProps {
    theme: SpinnerTheme;
    animationOut?: boolean;
    animationIn?: boolean;
    loaderMessage?: string;
}
export enum SpinnerTheme {
    light = 'theme-light',
    dark = 'theme-dark'
}

function Spinner(props: InnerSpinnerProps) {
    const { theme, animationIn, animationOut, loaderMessage } = props;
    return (
        <>
            <div
                className={`spinner spinner-${theme} ${animationOut ? 'spinner--animation' : ''} ${
                    animationIn ? 'spinner--animation-in' : ''
                }`}
            >
                <div className='kv'>
                    <div className='al al--1'></div>
                    <div className='al al--2'></div>
                    <div className='al al--3'></div>
                    <div className='al al--4'></div>
                    <div className='al al--5'></div>
                    <div className='al al--6'></div>

                    <div className='hl hl--1'></div>
                    <div className='hl hl--2'></div>
                    <div className='hl hl--3'></div>
                    <div className='hl hl--4'></div>
                    <div className='hl hl--5'></div>
                </div>
                {loaderMessage && <div className='spinner-text-wrapper'>{loaderMessage}</div>}
            </div>
        </>
    );
}

export default Spinner;
