export default {
    personal_info: {
        title: "Personal Info",
        notification_title: "Notification Settings",
        loading: 'Loading information...'
    },
    notifications: {
        title: "Notification Settings",
        statement_notification: "I want to get my year end statement via e-mail.",
        marketing_notification: "I want to receive promotional messages"
    }
}
