import groupBy from 'lodash/groupBy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api';
import { DisplayCard, InnerSpinner, Page } from '../../components';
import { DisplayCardTypes } from '../../components/DisplayCard';
import { IRootState } from '../../store';
import loaderActions from '../../store/loader';
import { PageTheme } from '../../typeScript/types/PageTheme';
import { showDefaultError } from '../../utils/error-handler';
import { useIsMounted } from '../../utils/hooks';
import { isIosDevice } from 'utils/platform';
import { openToast } from 'store/toast';
import { TYPE_ERROR } from 'components/Toast';

const download = require('../../assets/b2b2e/common/Download.svg') as string;

interface IDocument {
    id: string;
    documentType: string;
    documentRubric: string;
    fileType: string;
    fileExtension: string;
    title: string;
    createDate: string;
}

function Documents() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const [documentsData, setDocumentsData] = useState<IDocument[]>([]);
    const [documentsLoaded, setDocumentsLoaded] = useState(false);
    const [rubrics, setRubrics] = useState<string[]>(['']);

    const isLogged = useSelector<IRootState, boolean>((state) => state.user.isLogged);

    useEffect(() => {
        dispatch(loaderActions.setShowLoader(false));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isLogged) {
            getAllDocuments();
        }
        // eslint-disable-next-line
    }, [isLogged]);

    const getAllDocuments = () => {
        api.documents
            .getDocuments()
            .then((documents) => {
                if (Array.isArray(documents.data)) {
                    if (documents.data.length === 0) {
                        console.warn('empty response');
                        dispatch(openToast(TYPE_ERROR, t('document:no_documents')));
                    }

                    if (isMounted.current) {
                        prepareAndStoreData(documents.data);
                    }
                } else {
                    showDefaultError(dispatch, t);
                }
                setDocumentsLoaded(true);
            })
            .catch(() => {
                showDefaultError(dispatch, t);
                setDocumentsLoaded(true);
            });
    };

    const prepareAndStoreData = (data: IDocument[]) => {
        // Find all rubrics to iterate <Cards>

        const grouped = groupBy(data, function (n) {
            return n.documentRubric;
        });
        const rubrics: string[] = Object.keys(grouped);
        setRubrics(rubrics);

        setDocumentsData(data);
    };

    const blobPdfFromBase64String = (base64String) => {
        const byteArray = Uint8Array.from(
            atob(base64String)
                .split('')
                .map((char) => char.charCodeAt(0))
        );
        return new Blob([byteArray], { type: 'application/pdf' });
    };

    const downloadPDF = async (base64String: string) => {
        if (base64String?.length === 0) return;
        const blob = blobPdfFromBase64String(base64String);
        const url = URL.createObjectURL(blob);
        window.location.href = url;
    };

    const handleDownload = (item: IDocument, setIsDownloading) => {
        setIsDownloading(true);
        api.documents
            .getDocumentById(item.id)
            .then((response) => {
                if (isIosDevice()) {
                    downloadPDF(response.data.body);
                } else {
                    const linkSource = `data:application/pdf;base64,${response.data.body}`;
                    const downloadLink = document.createElement('a');
                    const fileName = `${response.data.name}.${response.data.extension}`;

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }

                setIsDownloading(false);
            })
            .catch(() => {
                setIsDownloading('error');
            });
    };

    function GridRow(props) {
        const item = props.item;

        const date = moment.utc(item.createDate, 'YYYY-MM-DDTHH:mm:ss.SSS').local().format('DD.MM.YYYY HH:mm');

        const [isDownloading, setIsDownloading] = useState();
        return (
            <>
                <div className=''>{date}</div>
                <div className='lg:col-span-2 truncate' title={item.title}>
                    {item.title ? item.title : '-'}
                </div>
                <div className='text-right'>
                    <button onClick={() => handleDownload(item, setIsDownloading)}>
                        <img src={download} alt='download' className='mr-2' />
                        {isDownloading === true
                            ? t('common:buttons.downloading')
                            : isDownloading === 'error'
                            ? t('common:buttons.downloadingError')
                            : t('common:buttons.download')}
                    </button>
                    <hr className='my-6 lg:hidden' />
                </div>
            </>
        );
    }

    function GridContainer(props) {
        const children = props.children;
        return <div className='grid lg:grid-cols-4 gap-4'>{children}</div>;
    }

    return (
        <Page
            pageTheme={PageTheme.light}
            title={t('portal:documents.header.title')}
            intro={t('portal:documents.header.text')}
        >
            <div className='2xl:w-9/12'>
                <>
                    {rubrics.map((rubric, idx) => {
                        return (
                            <DisplayCard
                                key={idx}
                                type={DisplayCardTypes.PLAIN}
                                className='mb-16'
                                label={i18n.language === 'de' ? rubric : ''}
                            >
                                <InnerSpinner isActive={!documentsLoaded}>
                                    <GridContainer>
                                        {documentsData
                                            .filter((document) => document.documentRubric === rubric)
                                            .map((item, index) => (
                                                <GridRow key={index} item={item} />
                                            ))}
                                    </GridContainer>
                                </InnerSpinner>
                            </DisplayCard>
                        );
                    })}
                </>
            </div>
        </Page>
    );
}

export default Documents;
