import assistant from './assistant';
import auth from './auth';
import b2b2eConfirmation, { b2b2eConfirmationNissan } from './b2b2e/confirmation';
import b2b2eDocuments from './b2b2e/documents';
import b2b2ePlanning, { b2b2ePlanningNissan, b2b2ePlanningGoinGreen } from './b2b2e/planning';
import backend_errors from './backend_errors';
import billing from './billing';
import business, { businessNissan, businessGoinGreen } from './business';
import businessOrderConfirmation, {
    businessOrderConfirmationNissan,
    businessOrderConfirmationGoinGreen
} from './business/order_confirmation';
import businessProfile from './business/profile';
import businessSupport from './business/support';
import charger from './charger';
import checkout, { checkoutNissan, checkoutGoinGreen } from './checkout';
import common, { commonNissan } from './common';
import configuration from './configuration';
import contract from './contract';
import errorAPI from './errorAPI';
import home from './home';
import loader from './loader';
import nav from './nav';
import payment from './payment';
import portal from './portal';
import payout from './payout';
import fleetData from './fleetData';
import support from './support';
import toast from './toast';
import upload from './upload';
import user_info from './user_info';
import document from './document';

export default {
    common,
    commonNissan,
    auth,
    checkout,
    checkoutNissan,
    checkoutGoinGreen,
    nav,
    configuration,
    user_info,
    billing,
    contract,
    payout,
    fleetData,
    home,
    assistant,
    loader,
    charger,
    toast,
    support,
    upload,
    payment,
    b2b2ePlanning,
    b2b2ePlanningNissan,
    b2b2ePlanningGoinGreen,
    b2b2eConfirmation,
    b2b2eConfirmationNissan,
    business,
    businessNissan,
    businessGoinGreen,
    backend_errors,
    businessSupport,
    businessProfile,
    b2b2eDocuments,
    businessOrderConfirmation,
    businessOrderConfirmationNissan,
    businessOrderConfirmationGoinGreen,
    portal,
    errorAPI,
    document
};
