import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom';
import userActions from 'store/user';
import Token from '../api/auth-token-wrapper';
import { routePaths } from '../route_paths';
import { CompanyPartner } from '../typeScript/types/CompanyPartner';
import { decodeURLParams } from '../utils/helpers';
import MobileMenu from './MobileMenu';
import { NavBurger } from './NavBurger';
import ProfileDropdown from './ProfileDropdown';

export const NAV_TYPE__AUTH = 'auth';
export const NAV_TYPE__PROCESS = 'process';
export const NAV_TYPE__DEFAULT = 'default';
export const NAV_TYPE__PORTAL = 'portal';

const logo = require('../assets/univers-logo.svg');
const logoMobile = require('../assets/MobileLogo.svg');
const logoNissan = require('../assets/LogoNissanInverted.png');

const DESKTOP_BREAK_POINT_REGULAR = 1041;
const DESKTOP_BREAK_POINT_BUSINESS = 860;

export const portalNav = [
    routePaths.portalOverview,
    routePaths.portalCharger,
    routePaths.portalDocuments,
    routePaths.portalPhotoUpload,
    routePaths.portalPayment,
    routePaths.portalProfile,
    routePaths.portalSupport,
    routePaths.portalTariff,
    routePaths.portalPayout,
    routePaths.portalFleetData
];

function Nav() {
    const { t } = useTranslation(['nav']);
    const dispatch = useDispatch();
    const history = useHistory();
    const [navType, setNavType] = useState<string>();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [companyPartner, setCompanyPartner] = React.useState<CompanyPartner>(CompanyPartner.NONE);
    const location = useLocation();

    useEffect(() => {
        const urlParams = decodeURLParams(window.location.search);
        const partner = urlParams.partner && urlParams.partner.toUpperCase();
        if (Object.values(CompanyPartner).includes(partner)) {
            setCompanyPartner(partner);
        }
    }, [companyPartner]);

    useEffect(() => {
        if (portalNav.includes(location.pathname)) {
            setNavType(NAV_TYPE__PORTAL);
        } else if (
            location.pathname.indexOf('user/') !== -1 ||
            location.pathname.indexOf('business') !== -1 ||
            location.pathname.indexOf('/password-reset') !== -1
        ) {
            setNavType(NAV_TYPE__AUTH);
        } else {
            setNavType(NAV_TYPE__DEFAULT);
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        getProgressBarObject();
    }, [navType]);

    useEffect(() => {
        // window resize callback handler
        const handleResize = () => {
            const breakPoint =
                navType === NAV_TYPE__PORTAL ? DESKTOP_BREAK_POINT_BUSINESS : DESKTOP_BREAK_POINT_REGULAR;
            // set mobileMenuOpen to false
            if (window.innerWidth > breakPoint && mobileMenuOpen) {
                return setMobileMenuOpen(false);
            }
        };

        // Add event listener
        if (navType === NAV_TYPE__DEFAULT || navType === NAV_TYPE__PORTAL) {
            window.addEventListener('resize', handleResize);
        }

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, [mobileMenuOpen]); // Empty array ensures that effect is only run on mount

    const getProgressBarObject = () => {
        let total = {};
        const linksContainer = document.querySelector('.nav-container');
        const links = document.querySelectorAll('.nav__steps-link');
        if (linksContainer) {
            links.forEach((link, index) => {
                total[index + 1] = (link.clientWidth / linksContainer.clientWidth) * 100;
            });
        }
    };

    const handleLogout = () => {
        dispatch(userActions.resetUser());
        Token.removeAuthTokens();
        history.push(routePaths.login);
        if (mobileMenuOpen) {
            setMobileMenuOpen(false);
        }
    };

    const renderLogos = () => {
        const shouldHideBigLogo = true;
        return (
            <Link to={routePaths.portalOverview} className='nav__logo'>
                {!shouldHideBigLogo && <img className='nav__logo--mobile' src={logoMobile} alt='Univers logo' />}
                <img className={shouldHideBigLogo ? '' : 'nav__logo--normal'} src={logo} alt='Univers logo' />
                {companyPartner === CompanyPartner.NISSAN && <img src={logoNissan} alt='Nissan logo' />}
            </Link>
        );
    };

    const renderPortalPortalNav = () => {
        return (
            <>
                <div className='nav__links-container nav__links-container--with-mobile-nav'>
                    <div className='nav__links-container__inner'>
                        <NavLink
                            to={routePaths.portalOverview}
                            className={
                                'nav__link nav__link--dtp' +
                                (history.location.pathname === routePaths.portalOverview ||
                                history.location.pathname === routePaths.portalPhotoUpload
                                    ? ' nav__link--active'
                                    : '')
                            }
                        >
                            <p className='nav__link__text'>{t('overview')}</p>
                        </NavLink>
                        <NavLink
                            to={routePaths.portalCharger}
                            className={'nav__link nav__link--dtp'}
                            activeClassName='nav__link--active'
                        >
                            <p className='nav__link__text'>{t('charger')}</p>
                        </NavLink>
                        <NavLink
                            to={routePaths.portalDocuments}
                            className={'nav__link nav__link--dtp'}
                            activeClassName='nav__link--active'
                        >
                            <p className='nav__link__text'>{t('documents')}</p>
                        </NavLink>
                        <NavLink
                            to={routePaths.portalSupport}
                            className={'nav__link nav__link--dtp'}
                            activeClassName='nav__link--active'
                        >
                            <p className='nav__link__text'>{t('support')}</p>
                        </NavLink>
                        <NavLink
                            to={routePaths.portalCards}
                            className={'nav__link nav__link--dtp'}
                            activeClassName='nav__link--active'
                        >
                            <p className='nav__link__text'>{t('chargingCards')}</p>
                        </NavLink>
                    </div>
                </div>
                <NavBurger setMobileMenuOpen={setMobileMenuOpen} mobileMenuOpen={mobileMenuOpen} />
            </>
        );
    };

    return (
        <nav className={`nav  ${navType === NAV_TYPE__PORTAL ? 'nav--portal' : ''}`}>
            {renderLogos()}
            {navType === NAV_TYPE__PORTAL && renderPortalPortalNav()}
            {navType === NAV_TYPE__PORTAL && <ProfileDropdown handleLogout={handleLogout} isPortal={true} />}
            {mobileMenuOpen && (
                <MobileMenu
                    mobileMenuOpen={mobileMenuOpen}
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    handleLogout={handleLogout}
                    isPortal={navType === NAV_TYPE__PORTAL ? true : false}
                />
            )}
        </nav>
    );
}

export default Nav;
