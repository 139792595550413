export const SET_NAV_PREVIOUS_ROUTE = 'SET_NAV_PREVIOUS_ROUTE';
export const SET_CURRENT_PRODUCT_CONFIGURATION_STEP = 'SET_CURRENT_PRODUCT_CONFIGURATION_STEP';
export const SET_CURRENT_CHECKOUT_STEP = 'SET_CURRENT_CHECKOUT_STEP';

export function setPreviousNavRoute(value) {
    return {
        type: SET_NAV_PREVIOUS_ROUTE,
        value
    }
}

export function setCurrentProductConfigurationStep(value) {
    return {
        type: SET_CURRENT_PRODUCT_CONFIGURATION_STEP,
        value
    }
}

export function setCurrentCheckoutStep(value) {
    return {
        type: SET_CURRENT_CHECKOUT_STEP,
        value
    }
}


export default {
    setPreviousNavRoute,
    setCurrentProductConfigurationStep,
    setCurrentCheckoutStep
}